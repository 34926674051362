export class RxOrderSuccessModel {
  public drugName: string;
  public prescriberName: string;
  public startDate: Date;
  public directions: string;
  public submittedForReview: boolean = false;

  constructor() {
  }
}
