import { Component, Input, forwardRef, OnInit, EventEmitter, Output, ViewEncapsulation, ElementRef, AfterViewInit, Renderer2, NgZone, ChangeDetectorRef, ViewChild } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, Validator, FormControl } from "@angular/forms";
import { IMyOptions, IMyDateModel, IMyInputFieldChanged, IMyDate } from "mydatepicker";

@Component({
	selector: "input-datepicker",
	templateUrl: "./input-datepicker.template.html",
	styleUrls: ["./input-datepicker.scss"],
	encapsulation: ViewEncapsulation.None,
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => InputDatepickerComponent),
			multi: true
		},
		{
			provide: NG_VALIDATORS,
			useExisting: forwardRef(() => InputDatepickerComponent),
			multi: true
		}
	]
})
export class InputDatepickerComponent implements ControlValueAccessor, AfterViewInit, OnInit, Validator {
	_disabled: boolean = false;
	changed: boolean = false;
	value: any;

	datepickerOptions: any = {
		dateFormat: "mm/dd/yyyy",
		componentDisabled: this.isDisabled,
		disableUntil: { month: 0, day: 0, year: 0 },
		disableSince: { month: 12, day: 30, year: 2099 }
	};

	@Input() label: string;
	@Input() isInvalid: boolean;
	@Input() isRequired: boolean = false;

	@ViewChild("dp") dp: any;

	@Input()
	set isDisabled(val: boolean) {
		this._disabled = val;
		let clone = Object.assign({}, this.datepickerOptions);
		clone.componentDisabled = this._disabled;
		this.datepickerOptions = clone;
	}

	get isDisabled(): boolean {
		return this._disabled;
	}

	@Input() disableId: string;
	@Input() disabledLabel: string;
	@Input() placeholderText: string;

	@Input()
	set minDate(val: string) {
		let clone = Object.assign({}, this.datepickerOptions);
		if (val) {
			let date = new Date(val);

			date.setDate(date.getDate() - 1);

			clone.disableUntil.year = date.getUTCFullYear();
			clone.disableUntil.month = date.getUTCMonth() + 1;
			clone.disableUntil.day = date.getUTCDate();
		} else {
			clone.disableUntil.year = 0;
			clone.disableUntil.month = 0;
			clone.disableUntil.day = 0;
		}
		this.datepickerOptions = clone;
	}

	@Input()
	set maxDate(val: string) {
		let clone = Object.assign({}, this.datepickerOptions);
		if (val) {
			let date = new Date(val);

			date.setDate(date.getDate() + 1);

			clone.disableSince.year = date.getUTCFullYear();
			clone.disableSince.month = date.getUTCMonth() + 1;
			clone.disableSince.day = date.getUTCDate();
		} else {
			clone.disableSince.year = 2099;
			clone.disableSince.month = 12;
			clone.disableSince.day = 31;
		}
		this.datepickerOptions = clone;
	}

	@Output() isDisabledChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

	constructor(private _elementRef: ElementRef, private _rederer: Renderer2, private _cdr: ChangeDetectorRef, private _zone: NgZone) { }

	ngOnInit() {
		this.changed = false;

		setTimeout(() => {
			if (this.isRequired && (!this.value || !this.value.date)) {
				this.setValidation(true);
			} else {
				this.setValidation(false);
			}
		}, 200)
	}

	ngAfterViewInit() {
		// this is a hack to get keyboard on IOS to popup with a numeric keypad for the datepicker.
		let input = this._elementRef.nativeElement.querySelector("input");
		if (input) {
			this._rederer.setAttribute(input, "type", "tel");
			this._rederer.setAttribute(input, "inputmode", "numeric");
			this._rederer.setAttribute(input, "pattern", "[0-9]*");
		}
	}

	propagateChange = (_: string) => { };

	validate(control: FormControl) {
		if (this.isDisabled) {
			// if the control is disabled then we do not want to be validated.
			return null;
		}

		if (this.isInvalid) {
			if (this.changed) {
				this.setValidation(true);
			}

			return {
				formatError: { valid: false }
			};
		}

		return null;
	}

	private setValidation(valid: boolean) {
		this.isInvalid = valid;
		this._cdr.detectChanges();
	}

	onDateChanged(event: IMyDateModel) {
		this.changed = true;
		this.value = event;
		this.propagateChange(this.value.jsdate || null);
	}

	onInputFieldChanged(event: IMyInputFieldChanged) {
		let inputValue = this.applyMask(event.value);

		if (inputValue == "00/00/0") inputValue = "";

		if (this.dp?.elem?.nativeElement?.querySelectorAll("input")?.length > 0) {
			this.dp.elem.nativeElement.querySelectorAll("input")[0].value = inputValue;
		}

		if (!event.valid && (this.isRequired || (!this.isRequired && inputValue))) {
			this.setValidation(true);
			if (!this.value && !inputValue) {
				// values have not changed, don't propagate...
				return;
			}
			this.propagateChange(null);
		} else {
			let validChanged: boolean = false;
			if (this.isInvalid) {
				validChanged = true;
			}
			this.setValidation(false);
			let dateChanged: boolean = false;

			if ((inputValue && !this.value) || (!inputValue && this.value)) {
				dateChanged = true;
			} else if (inputValue && this.value) {
				let date = new Date(inputValue);
				let valDate = { date: { year: date.getUTCFullYear(), month: date.getUTCMonth() + 1, day: date.getUTCDate() } };
				if (this.value.date.year != valDate.date.year || this.value.date.month != valDate.date.month || this.value.date.day != valDate.date.day) {
					dateChanged = true;
				}
			}
			if (validChanged || dateChanged) {
				// only propagate changes if value or validation has changed.
				this.propagateChange(inputValue || null);
			}
		}
	}

	private applyMask(value: string): string {
		const numbers = value.replace(/\D/g, ''); // Remove non-digit characters

		// Split the string into parts for month, day, and year
		let month = '';
		let day = '';
		let year = '';

		if (numbers.length >= 2) {
			month = numbers.substring(0, 2); // First two digits for month
			if (numbers.length >= 4) {
				day = numbers.substring(2, 4); // Next two digits for day
				year = numbers.length > 4 ? numbers.substring(4) : ''; // Remaining digits for year
			} else {
				day = numbers.substring(2); // Remaining digits for day if less than 4 digits in total
			}
		} else {
			month = numbers; // If only one or two digits, assume it's the month
		}

		// Format the string based on the available parts
		let formattedValue = month;
		if (day) {
			formattedValue += `/${day}`;
			if (year) {
				formattedValue += `/${year}`;
			}
		}

		return formattedValue;
	}

	writeValue(value: string) {
		if (value) {
			let date = new Date(value);
			this.value = { date: { year: date.getUTCFullYear(), month: date.getUTCMonth() + 1, day: date.getUTCDate() } };
			this.setValidation(false);
		} else {
			this.value = "";
			if (this.isRequired) {
				this.setValidation(true);
			} else {
				this.setValidation(false);
			}
		}
	}

	onDisabledChanged(value: boolean) {
		this.isDisabledChanged.emit(value);
	}

	registerOnChange(fn: any) {
		this.propagateChange = fn;
	}

	registerOnTouched(fn: any) { } //Not currently needed
}
