import { 
  Component, 
  Input, 
  forwardRef, 
  OnInit, 
  ChangeDetectorRef, 
  AfterViewChecked, 
  ViewChild, 
  ElementRef 
} from "@angular/core";
import { ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator } from "@angular/forms";
import { MultiSelectItemModel } from "../input-multi-select/multi-select-item.model";
import * as _ from "lodash";
import { takeUntil } from "rxjs/operators";
import { combineLatest, Subject } from "rxjs";
import { StoreObject } from "../../models/store-object.model";
import { MatAutocompleteTrigger } from "@angular/material/autocomplete";

@Component({
  selector: 'input-autocomplete',
  templateUrl: './input-autocomplete.template.html',
  styleUrls: ['./input-autocomplete.scss'],
  providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => InputAutocompleteComponent),
			multi: true
		},
		{
			provide: NG_VALIDATORS,
			useExisting: forwardRef(() => InputAutocompleteComponent),
			multi: true
		}
	]
})
export class InputAutocompleteComponent implements ControlValueAccessor, OnInit, Validator, AfterViewChecked {
  	value: any[] = [];
	changed: boolean = false;

	@Input() label: string;
	@Input() isRequired: boolean = false;
	@Input() type: string;
	@Input() tooltip: string;
	@Input() placeholderText: string;
	@Input() isDisabled: boolean;
	@Input() isInvalid: boolean;

  	@ViewChild("autocompleteInput") autocompleteInput: ElementRef<HTMLInputElement>;
	@ViewChild(MatAutocompleteTrigger) autocompleteTrigger: MatAutocompleteTrigger;

  	private _options: MultiSelectItemModel[];
	@Input()
	set options(val: MultiSelectItemModel[]) {
		this._options = val;
		this.$options.set(val);
	}
	get options(): MultiSelectItemModel[] {
		return this._options;
	}

	autocompleteCtrl = new FormControl();

	private $options: StoreObject<MultiSelectItemModel[]> = new StoreObject<MultiSelectItemModel[]>([]);
	public $filteredOptions: StoreObject<MultiSelectItemModel[]> = new StoreObject<MultiSelectItemModel[]>([]);
	private $query: StoreObject<string> = new StoreObject<string>("");
	private destroyed: Subject<any> = new Subject<any>();
  	
  	get selectedItems(): MultiSelectItemModel[] {
		let selectedItems = this.$options.get().filter((x: MultiSelectItemModel) => {
			return x.selected;
		});
		return selectedItems;
	}

  	constructor(private cdr: ChangeDetectorRef) { }

  	ngAfterViewChecked(): void {
		this.cdr.detectChanges();
	}

	ngOnInit() {
		this.$options.observable.subscribe((options: MultiSelectItemModel[]) => {
			let selectedItems: MultiSelectItemModel[] = [];

			if (options && options.length > 0) {
				selectedItems = _.filter(options, (o: MultiSelectItemModel) => o.selected);
			}

			this.value = selectedItems;
			this.cdr.detectChanges();

			if (this.isRequired && (!this.value || this.value.length === 0)) {
				this.isInvalid = true;
			} else {
				this.isInvalid = false;
			}
	
			this.changed = true;
			this.propagateChange(this.value);
			this.writeValue(this.value);
		});

		combineLatest([this.$options.observable, this.$query.observable]).subscribe(([options, query]) => {
			if (!options || options.length == 0) {
				this.$filteredOptions.set([]);
				return;
			}

			let uncheckedItems = _.filter(options, (o: MultiSelectItemModel) => !o.selected);

			if (!query || !query.trim || query.trim().length == 0) {
				this.$filteredOptions.set(uncheckedItems);
				return;
			}

			query = query.toLowerCase();

			this.$filteredOptions.set(_.filter(uncheckedItems, (val: MultiSelectItemModel) => (val.text || "").toLowerCase().indexOf(query) !== -1));
		});

		this.autocompleteCtrl.valueChanges.pipe(takeUntil(this.destroyed)).subscribe((val: string) => {
			this.$query.set(val);
		});

		setTimeout(() => {
			if (this.isRequired && (!this.value || this.value.length === 0)) {
				this.isInvalid = true;
			} else {
				this.isInvalid = false;
			}
			this.cdr.detectChanges();
		}, 250);
	}

	propagateChange = (_: any[]) => { };

  	validate(control: FormControl) {
		if (this.isDisabled) {
			// if the control is disabled then we do not want to be validated.
			return null;
		}

		if (this.isInvalid) {
			if (this.changed) {
				this.isInvalid = true;
			}

			return {
				formatError: { valid: false }
			};
		}

		return null;
	}

	writeValue(obj: any[]) {
		this.value = obj;
	}

	registerOnChange(fn: any) {
		this.propagateChange = fn;
	}

	registerOnTouched(fn: any) { } //Not currently needed

  	toggleItem(item: MultiSelectItemModel) {
		if (this.isDisabled) return;
		this.$options.set(_.map(this.$options.get(), (o: MultiSelectItemModel) => {
			if (o.id == item.id) {
				o.selected = !o.selected;
			}
			
			return o;
		}));
		this.itemSelected();
	}

  	removeItem(item: MultiSelectItemModel) {
		if (this.isDisabled) return;

		this.$options.set(_.map(this.$options.get(), (o: MultiSelectItemModel) => {
			if (o.id == item.id) {
				o.selected = false;
			}
			
			return o;
		}));
	}

	itemSelected() {
		const self = this;
		// setTimeout(function () {
		// 	self.autoTrigger.openPanel();
		// }, 0);
		requestAnimationFrame(() => {
			self.autocompleteInput.nativeElement.focus();
			self.autocompleteTrigger.openPanel();
		});
		this.autocompleteCtrl.setValue(null);
		this.autocompleteInput.nativeElement.value = "";
	}
}
