import {Component} from "@angular/core";

@Component({
  selector: 'access-denied',
  templateUrl: "./access-denied.template.html",
  styleUrls: ["./access-denied.css"]
})

export class AccessDeniedComponent {
}
