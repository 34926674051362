import {NgModule} from "@angular/core";

import {SharedModule} from "../shared/shared.module";
import {OrganizationProfileSectionComponent} from "./organization-profile.component";
import {OrganizationProfileRouting} from "./organization-profile.routes";
import {ReactiveFormsModule} from "@angular/forms";

@NgModule({
  imports: [
    SharedModule,
    ReactiveFormsModule,
    OrganizationProfileRouting
  ],
  declarations: [
    OrganizationProfileSectionComponent,
  ],
  providers: [],
  exports: [
    OrganizationProfileSectionComponent,
  ]
})
export class OrganizationProfileModule {
}
