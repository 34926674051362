import {Component, Input, Output, EventEmitter, OnInit} from '@angular/core';
import {ReportingService} from "../../services/reporting.service";
import {UserReportPermissionMatrixModel} from "../../models/user-report-permission-matrix.model";
import {ApiResult} from "../../models/api-result.model";
import {UserReportPermissionModel} from "../../models/user-report-permission.model";
import {StatusMessageService} from "../status-message/status-message.service";
import { UserReportPermissionGroup } from '../../models/user-report-permission-group.model';

@Component({
  selector: 'user-report-permissions',
  templateUrl: './form-user-report-permissions.template.html',
  styleUrls: ['./form-user-report-permissions.scss']
})

export class UserReportPermissionsComponent implements OnInit {
  saving: boolean = false;
  isLoading: boolean = false;
  reportPermissionMatrix: UserReportPermissionMatrixModel = new UserReportPermissionMatrixModel();
  availableCategories: string[] = ['All Categories'];
  selectedCategory: string = 'All Categories';
  nameFilter: string = '';
  filteredReportPermissions: UserReportPermissionModel[];
  selectAll: boolean = false;
  expandedIdx?: number = null;
  expandedCategory: string = null;

  permissionGroups: UserReportPermissionGroup[] = [];
  filteredPermissionGroups: UserReportPermissionGroup[] = [];

  @Input() userId: number;

  @Output() closeModal: EventEmitter<string> = new EventEmitter<string>();
  @Output() saveSuccess: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private _reportingService: ReportingService, private _statusMessageService: StatusMessageService) {
  }

  ngOnInit() {
    this.isLoading = true;
    this._reportingService.getUserReportPermissions(this.userId).subscribe((reportPermissionMatrix: UserReportPermissionMatrixModel) => {
      this.isLoading = false;
      // sort first by category name, then by display name
      reportPermissionMatrix.ReportPermissions.sort(this.sortMethod);

      let reportPermissions = [];
      for (let i: number = 0; i < reportPermissionMatrix.ReportPermissions.length; i++) {
        let reportPermission: UserReportPermissionModel = reportPermissionMatrix.ReportPermissions[i];

        if (this.availableCategories.indexOf(reportPermission.CategoryName) === -1) {
          this.availableCategories.push(reportPermission.CategoryName);
        }

        reportPermissions.push(reportPermission);
      }

      this.reportPermissionMatrix = reportPermissionMatrix;
      this.permissionGroups = this.buildCategoryGroups(reportPermissions);
      this.filter();
    });
  }

  isExpanded(category: string): boolean {
		return this.expandedCategory === category;
	}

	expand(category: string) {
    if (this.expandedCategory === category){
      this.expandedCategory = null;
    } else {
      this.expandedCategory = category;
    }
	}

  buildCategoryGroups(permissions: UserReportPermissionModel[]): UserReportPermissionGroup[] {
    let groups: UserReportPermissionGroup[] = [];

    groups = permissions.reduce((acc: UserReportPermissionGroup[], permission: UserReportPermissionModel) => {
      let idx = acc.findIndex(g => g.categoryName == permission.CategoryName);
      if (idx >= 0){
        acc[idx].reports.push(permission);
      } else {
        let group = new UserReportPermissionGroup();
        group.categoryName = permission.CategoryName;
        group.reports = [permission];
        acc.push(group);
      }

      return acc;
    }, []);


    return groups;
  }

  filter() {
    let permissions: any = this.reportPermissionMatrix.ReportPermissions;
    permissions = Array.prototype.concat.apply([], permissions);

    if (this.nameFilter && this.nameFilter.length > 1){
      permissions = permissions.filter((r: UserReportPermissionModel) => r.DisplayName.toLowerCase().indexOf(this.nameFilter.toLowerCase()) > -1)
    }

    if (this.selectedCategory && this.selectedCategory.length){
      permissions = permissions.filter((r: UserReportPermissionModel) => this.selectedCategory === 'All Categories' || r.CategoryName === this.selectedCategory)
    }

    this.filteredPermissionGroups = this.buildCategoryGroups(permissions);
  }

  sortMethod(a, b) {
    let sorts: string[] = ['IsPermitted', 'CategoryName', 'DisplayName'];
    for (let i: number = 0; i < sorts.length; i++) {
      let leftVal = a[sorts[i]];
      let rightVal = b[sorts[i]];

      leftVal = typeof leftVal === 'string' ? leftVal.toLowerCase().replace(/ /g, "") : (leftVal ? '0' : '1');
      rightVal = typeof rightVal === 'string' ? rightVal.toLowerCase().replace(/ /g, "") : (rightVal ? '0' : '1');

      if (leftVal != rightVal) {
        return leftVal < rightVal ? -1 : 1;
      }
    }
  }

  areAllSelected(): boolean {
    if (!this.permissionGroups) return false;

    return this.permissionGroups.every(g => g.reports.every(r => r.IsPermitted));
  }

  selectAllToggled(toggle: boolean){
    this.permissionGroups.forEach(g => g.reports.forEach(r => r.IsPermitted = toggle));
  }
  
  isParentEnabled(group: UserReportPermissionGroup): boolean {
    return group.reports.every(r => r.IsPermitted);
  }

  toggleParent(group: UserReportPermissionGroup, toggle: boolean){
    group.reports.forEach(r => r.IsPermitted = toggle);
  }

  save() {
    this.saving = true;
    let reportPermissions = this.permissionGroups.reduce((acc: UserReportPermissionModel[], group: UserReportPermissionGroup) => {
      acc.push(...group.reports)
      return acc;
    }, []);

    console.log({reportPermissions, groups: this.permissionGroups})

    this._reportingService.updateUserReportPermissions(this.userId, reportPermissions)
      .subscribe((result: ApiResult) => {
        this.saving = false;
        if (result.Success) {
          this.saveSuccess.emit();
        } else {
          this._statusMessageService.changeStatusMessage('error', result.PublicMessage);
        }
      });
  }
}
