/**
 * Created by danielarandia on 3/8/17.
 */
import {Routes, RouterModule} from "@angular/router";

import {PartnerProfile} from "./partner-profile.component";

export const PartnerProfileRoutes: Routes = [
    {path: "partner-profile", component: PartnerProfile}
];

export const PartnerProfileRouting = RouterModule.forChild(PartnerProfileRoutes);
