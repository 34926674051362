export class Permissions {
	static patientCare: string = "PatientCare";
	static reporting: string = "Reporting";
	static clinicalTools: string = "ClinicalTools";
	static partnerProfile: string = "PartnerProfile";
	static administration: string = "Administration";
	static authorizeMedications: string = "AuthorizeMedications";
	static submitERx: string = "SubmitERx";
	static lockUsers: string = "LockUsers";
	static editCustomer: string = "EditCustomer";
	static orderIntake: string = "OrderIntake";
	static pendingMedications: string = "PendingMedications";
	static addExternalUser: string = "AddExternalUser";
	static viewExternalUserRoles: string = "ViewExternalUserRoles";
	static editExternalUserRoles: string = "EditExternalUserRoles";
	static editExternalUserProfile: string = "EditExternalUserProfile";
	static editOrganization: string = "EditOrganization";
	static editCustomerTeams: string = "EditCustomerTeams";
	static viewEditDoseSpot: string = "ViewEditDoseSpot";
	static viewCustomerRoles: string = "ViewCustomerRoles";
	static viewEditReportPermissions: string = "ViewEditReportPermissions";
	static viewPendingERxAlerts: string = "ViewPendingERxAlerts";
	static viewPriorAuthAlerts: string = "ViewPriorAuthAlerts";
	static viewMessages: string = "ViewMessages";
	static internalNonDispensing: string = "InternalNonDispensing";
	static analytics = "Analytics";
	static modifySystemAlerts = "ModifySystemAlerts";
	static viewCustomerIntegrations = "ViewCustomerIntegrations";
}
