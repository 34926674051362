import {Component, Input, Output, EventEmitter, OnInit} from '@angular/core';
import {CustomerProfileModel} from "../../models/customer-profile.model";
import {CustomerService} from "../../services/customer.service";
import {UserService} from "../../services/user.service";
import {ApiResult} from "../../models/api-result.model";
import {StatusMessageService} from "../status-message/status-message.service";
import states from "../../models/constants/state-list.constant";

@Component({
  selector: 'partner-details',
  templateUrl: './partner-details.template.html',
  styleUrls: ['./partner-details.css']
})

export class PartnerDetails implements OnInit {
  saving: boolean = false;
  stateList = states.STATE_LIST;
  statusList = [
    { text: "Active", value: true }, 
    { text: "Inactive", value: false }
  ];
  ssnOptions: any[] = [
    { text: 'Yes', value: true }, 
    { text: 'No', value: false }
  ];
  vendorList: any[] = [];
  pharmacyList: any[] = [];
  accountManagerList: any[] = [];
  customerUserList: any[] = [];

  @Input() partner: CustomerProfileModel = new CustomerProfileModel();
  @Input() organizationId: number;
  @Input() mode: string;

  @Output() closeModal: EventEmitter<string> = new EventEmitter<string>();
  @Output() saveSuccess: EventEmitter<string> = new EventEmitter<string>();

  constructor(private _customerService: CustomerService,
              private _userService: UserService,
              private _statusMessageService: StatusMessageService) {
  }

  ngOnInit() {
    this._customerService.getVendorList().subscribe((results: any[]) => {
      this.vendorList = results;
    });

    this._customerService.getPharmacyList().subscribe((results: any[]) => {
      this.pharmacyList = results;
    });

    this._userService.getAccountManagerList().subscribe((results: any[]) => {
      this.accountManagerList = results;
    });

    if (this.mode === 'Edit') {
      this._customerService.getCustomerUsers(this.partner.CustomerId).subscribe((results: any[]) => {
        this.customerUserList = results;
      });
    }
  }

  save() {
    this.saving = true;
    if (this.organizationId) {
      this.partner.OrganizationId = this.organizationId;
    }

    if (this.mode === 'New') {
      this._customerService.addCustomerProfile(this.partner).subscribe((result: ApiResult) => {
        this.saving = false;
        if (result.Success) {
          this.saveSuccess.emit(this.partner.Name);
        } else {
          this._statusMessageService.changeStatusMessage('error', result.PublicMessage);
        }
      });
    } else if (this.mode === 'Edit') {
      this._customerService.updateCustomerProfile(this.partner).subscribe((result: ApiResult) => {
        this.saving = false;
        if (result.Success) {
          this.saveSuccess.emit(this.partner.Name);
          this._userService.refreshUserInfo();
        } else {
          this._statusMessageService.changeStatusMessage('error', result.PublicMessage);
        }
      });
    }
  }
}
