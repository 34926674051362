/**
 * Created by dan on 3/29/2017.
 */
export class OptionModel {
  value: any;
  text: string;

  constructor(text:string, value:any) {
    this.value = value;
    this.text = text;
  }
}
