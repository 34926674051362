import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Permissions } from "../shared/enums/permissions";

@Component({
	selector: "reporting-reports",
	templateUrl: "./reporting-reports.template.html",
	styleUrls: ["./reporting.scss"]
})
export class ReportingReportsComponent implements OnInit {
	Permissions: any = Permissions;

	constructor(private _router: Router) {}

	ngOnInit() {}

	goToDashboardTab() {
		this._router.navigate(["reporting/"]);
	}
}
