import {Component, Input, Output, EventEmitter, OnInit, ViewChild} from '@angular/core';
import {FileUploadComponent} from "../../../input-file-upload/file-upload.component";
import {DocumentTypeModel} from '../../../../models/document-type.model';
import {DocumentInfoModel} from '../../../../models/document-info.model';
import {StatusMessageService} from "../../../status-message/status-message.service";
import {UploadFileSuccessModel} from '../../../../models/upload-file-success.model';

@Component({
  selector: 'upload-users',
  templateUrl: './upload-users.template.html',
  styleUrls: ['./upload-users.scss']
})

export class UploadUsersComponent implements OnInit {
  currentStep: number = 1;
  fileUploadUrl: string = "api/user/parsebulkuploadsheet";
  fileSelected: boolean = false;
  fileTypeList: DocumentTypeModel[] = [];
  fileChanged: boolean = false;

  @ViewChild('fileUpload')
  fileUpload: FileUploadComponent;

  @Input() customerId: number;
  @Input() file: DocumentInfoModel = new DocumentInfoModel();
  @Input() stepCompleted: boolean = false;

  @Output() closeModal: EventEmitter<string> = new EventEmitter<string>();
  @Output() goToStepTwo: EventEmitter<any[]> = new EventEmitter<any[]>();

  constructor(private _statusMessageService: StatusMessageService) {
  }

  ngOnInit() {}

  onUploadComplete(results: UploadFileSuccessModel) {
    this.goToStepTwo.emit(results.results)
  }

  onFileSelected(fileName: string) {
    if (fileName.toLowerCase().endsWith('.xls') || fileName.toLowerCase().endsWith('.xlsx')) {
      this.fileChanged = true;
      this.fileSelected = true;
    }
  }

  onUploadError(e: string) {
    this._statusMessageService.changeStatusMessage('error', e);
    this.fileSelected = false;
  }

  uploadFile() {
    this.fileUpload.upload(null);
  }
}
