import { Injectable } from "@angular/core";
import { UserService } from "../services/user.service";
import { StoreObject } from "../models/store-object.model";
import { OrganizationUser } from "../models/organizations/organization-user.model";
import { combineLatest } from "rxjs";
import { CustomerSummaryModel } from "../models/customer-summary.model";
import { debounceTime } from "rxjs/operators";

@Injectable()
export class OrganizationUsersStore {
	private _organizationId: number;

	public readonly activeFilter: StoreObject<boolean> = new StoreObject<boolean>(true);
	public readonly searchText: StoreObject<string> = new StoreObject<string>("");

	public readonly users: StoreObject<OrganizationUser[]> = new StoreObject<OrganizationUser[]>([]);
	public readonly filteredUsers: StoreObject<OrganizationUser[]> = new StoreObject<OrganizationUser[]>([]);

	public readonly customers: StoreObject<CustomerSummaryModel[]> = new StoreObject<CustomerSummaryModel[]>([]);
	public readonly roles: StoreObject<string[]> = new StoreObject<string[]>([]);

	public readonly customerFilter: StoreObject<number[]> = new StoreObject<number[]>([]);
	public readonly roleFilter: StoreObject<string[]> = new StoreObject<string[]>([]);

	constructor(private _userService: UserService) {
		this.users.observable.subscribe(users => {
			if (!users || users.length === 0) {
				this.customers.set([]);
				this.roles.set([]);
				return;
			}

			this.customers.set(
				users
					.reduce((customers, user) => {
						if (!user.Customers || user.Customers.length === 0) return customers;

						user.Customers.forEach(customer => {
							if (!customers.find(c => c.CustomerId === customer.CustomerId)) {
								let model = new CustomerSummaryModel();
								model.CustomerId = customer.CustomerId;
								model.Name = customer.CustomerName;

								customers.push(model);
							}
						});

						return customers;
					}, [])
					.sort((a, b) => a.Name.localeCompare(b.Name))
			);

			this.roles.set(
				users
					.reduce((roles, user) => {
						if (!user.Customers || user.Customers.length === 0) return roles;

						let userRoles = user.Customers.reduce((customerRoles, customer) => {
							if (!customer.Roles || customer.Roles.length === 0) return customerRoles;

							customer.Roles.forEach(role => {
								if (!customerRoles.includes(role)) {
									customerRoles.push(role);
								}
							});

							return customerRoles;
						}, []);

						for (let role of userRoles) {
							if (!roles.includes(role)) {
								roles.push(role);
							}
						}

						return roles;
					}, [])
					.sort()
			);
		});

		combineLatest([this.users.observable, this.searchText.observable, this.activeFilter.observable, this.customerFilter.observable, this.roleFilter.observable])
			.pipe(debounceTime(500))
			.subscribe(([users, searchText, activeOnly, customerFilters, roleFilters]) => {
				let filteredUsers: OrganizationUser[] = [];

				if (users && users.length > 0) {
					filteredUsers = users;
					if (searchText && searchText.trim().length > 0) {
						filteredUsers = filteredUsers.filter((user: OrganizationUser) => {
							let nameMatches: boolean = false;

							if (!searchText) {
								nameMatches = true;
							} else {
								searchText = searchText
									.trim()
									.replace(/\s\s+/g, " ")
									.replace(",", "")
									.toLowerCase();
								let searchNameParts: string[] = searchText.split(" ");
								let matchPartsCount: number = 0;
								searchNameParts.forEach((part: string) => {
									if (user.FirstName.toLowerCase().indexOf(part) > -1 || user.LastName.toLowerCase().indexOf(part) > -1 || user.LoginId.toLowerCase().indexOf(part) > -1) {
										matchPartsCount++;
									}
								});

								if (matchPartsCount === searchNameParts.length) {
									nameMatches = true;
								}
							}

							return nameMatches;
						});
					}

					if (customerFilters && customerFilters.length > 0) {
						filteredUsers = filteredUsers.filter(u => {
							return u.Customers.some(c => customerFilters.includes(c.CustomerId));
						});
					}

					if (roleFilters && roleFilters.length > 0) {
						filteredUsers = filteredUsers.filter(u => {
							return u.Customers.some(c => c.Roles.some(r => roleFilters.includes(r)));
						});
					}

					filteredUsers = filteredUsers.filter(u => (activeOnly ? !u.IsLocked : true));
				}

				this.filteredUsers.set(
					filteredUsers.sort((a, b) => {
						let aName = a.LastName + a.FirstName;
						let bName = b.LastName + b.FirstName;
						return aName.localeCompare(bName);
					})
				);
			});
	}

	loadUsersForOrganization(organizationId: number) {
		if (this._organizationId === organizationId && this.users.get().length > 0) return;

		this._organizationId = organizationId;

		this.fetchUsers();
	}

	refreshUsers() {
		this.fetchUsers();
	}

	private fetchUsers(): Promise<OrganizationUser[]> {
		return new Promise<OrganizationUser[]>(resolve => {
			this._userService.getAllUsersInOrganization(this._organizationId).subscribe((users: OrganizationUser[]) => {
				this.users.set(users);
				resolve(users);
			});
		});
	}

	clearFilters() {
		this.searchText.set("");
		this.activeFilter.set(true);
		this.customerFilter.set([]);
		this.roleFilter.set([]);
	}
}
