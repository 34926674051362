import { BehaviorSubject } from "rxjs";
import { Injectable } from "@angular/core";
import { MobileService } from "./mobile.service";

@Injectable()
export class ApplicationService {
	private _disablePage: BehaviorSubject<boolean> = new BehaviorSubject(false);
	private _hideNavBar: BehaviorSubject<boolean> = new BehaviorSubject(false);
	private _isFullScreenSearchOpen: BehaviorSubject<boolean> = new BehaviorSubject(false);

	constructor(private _mobile: MobileService) {
		this.isMobile = this._mobile.isMobile();
	}

	get disablePage() {
		return this._disablePage.asObservable();
	}

	set disablePage(value: any) {
		this._disablePage.next(value);
	}

	get hideNavBar() {
		return this._hideNavBar.asObservable();
	}

	set hideNavBar(value: any) {
		this._hideNavBar.next(value);
	}

	get isFullScreenSearchOpen() {
		return this._isFullScreenSearchOpen.asObservable();
	}

	set isFullScreenSearchOpen(val: any) {
		if (val === true && this.isMobile) {
			this._isFullScreenSearchOpen.next(true)
		} else {
			this._isFullScreenSearchOpen.next(false);
		}
	}

	isMobile: boolean;
}
