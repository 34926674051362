import {Component, Input, OnInit, EventEmitter, Output} from '@angular/core';
import {RxOrderModel} from "../../../shared/models/rx-order.model";

@Component({
  selector: 'allergy-screening-modal',
  templateUrl: './allergy-screening-modal.template.html'
})

export class AllergyScreeningModalComponent implements OnInit {
  doneScreening: boolean = false;

  @Input() rxOrder: RxOrderModel = new RxOrderModel();
  @Input() patientId: number;

  @Output() goBack: EventEmitter<string> = new EventEmitter<string>();
  @Output() nextStep: EventEmitter<string> = new EventEmitter<string>();

  constructor() {
  }

  ngOnInit() {
  }

  screeningComplete() {
    this.doneScreening = true;
  }
}
