import {Component, Input, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'organization-summary',
  templateUrl: './organization-summary.template.html',
  styleUrls: ['./organization-summary.scss']
})

export class OrganizationSummary {

  @Input() organizationId: string;
  @Input() name: string;
  @Input() city: string;
  @Input() state: string;
  @Input() phone: string;

  @Output() openEditOrg:EventEmitter<string> = new EventEmitter<string>();
  @Output() openOrgProfile:EventEmitter<string> = new EventEmitter<string>();

  
}
