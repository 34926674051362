export class IntegrationSettings {
    CustomerId: number;
    FacilityId: string;
    AdtEnabled: boolean;
    MedEnabled: boolean;
    InterfaceName: string;
    AutoPA: boolean;
    AutoAddressUpdate: boolean;
    PBMOnly: boolean;
    eRxEnabled: boolean;
    DosespotSync: boolean;
    CR8358Frequency: string;
    UsesExternalFTP: boolean;
    CR8358Type: string;

    CalculatedCR8358Type: string;

    deserialize(obj: any): IntegrationSettings {
        let settings: IntegrationSettings = Object.assign(new IntegrationSettings(), obj);

        if (settings.CR8358Type && settings.CR8358Type.trim().length > 0) {
            if (settings.CR8358Type == 'Automated') {
                settings.CalculatedCR8358Type = `${settings.CR8358Type} ${settings.UsesExternalFTP ? 'External' : 'Internal'} FTP`
            } else {
                settings.CalculatedCR8358Type = 'Manual';
            }
        }

        return settings;
    }
}