import { SubscribableReport } from "./subscribable-report.model";

export class SubscriptionSummary {
	ReportSubscriptionId: number;
	SubscriptionName: string;
	IsActive: number;
	Report: SubscribableReport;
	FrequencyType: string;
	Frequency: string;
	EndOfMonthFlag: number;

	get Active(): boolean {
		return this.IsActive === 1;
	}

	get EndOfMonth(): boolean {
		return this.EndOfMonthFlag === 1;
	}

	get FrequencyTypeString(): string {
		switch (this.FrequencyType) {
			case "D":
				return "Daily";
			case "W":
				let days: string[] = [];
				let strDays: string[] = this.Frequency.split(",");

				for (let i = 0; i < strDays.length; i++) {
					let day: number = parseInt(strDays[i]);
					switch (day) {
						case 0:
							days.push("Sun");
							break;
						case 1:
							days.push("Mon");
							break;
						case 2:
							days.push("Tue");
							break;
						case 3:
							days.push("Wed");
							break;
						case 4:
							days.push("Thu");
							break;
						case 5:
							days.push("Fri");
							break;
						case 6:
							days.push("Sat");
							break;
					}
				}

				return "Weekly (" + days.join(", ") + ")";
			case "M":
				let freq: string[] = [];

				freq = this.Frequency.split(",");

				if (this.EndOfMonth) {
					freq.push("End of Month");
				}

				return "Monthly (" + freq.join(", ") + ")";
			default:
				return "Unknown";
		}
	}

	deserialize(input: any): SubscriptionSummary {
		this.ReportSubscriptionId = input.ReportSubscriptionId;
		this.SubscriptionName = input.SubscriptionName;
		this.IsActive = input.IsActive;
		this.Report = new SubscribableReport().deserialize(input.Report);
		this.FrequencyType = input.FrequencyType;
		this.Frequency = input.Frequency;
		this.EndOfMonthFlag = input.EndOfMonthFlag;

		return this;
	}
}
