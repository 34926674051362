export class StatusMessageModel {
  Message: string = '';
  MessageType: string = 'info';
  Duration: number = 5000;
  PushTopic: string = '';

  constructor(messageType: string, message: string, duration: number) {
    this.Message = message;
    this.Duration = duration;
    if (messageType) {
      messageType = messageType.toLowerCase();
    }

    if (messageType.startsWith("push-")) {
      this.MessageType = "pushnotification";
      this.PushTopic = messageType.replace("push-", "");
      return;
    }

    this.PushTopic = "";

    switch (messageType) {
      case 'info':
      case 'success':
      case 'warning':
      case 'error':
      case 'pushnotification':
        this.MessageType = messageType;
        break;
      default:
        this.MessageType = 'info';
        break;
    }
  }
}
