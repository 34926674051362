import { Injectable } from "@angular/core";
import { HttpService } from "./http.service";
import { ApiResult } from "../models/api-result.model";
import { combineLatest } from "rxjs";
import { DashboardPatientInfo } from "../models/dashboard-patient-info.model";
import * as _ from "lodash";
import { CustomerTeamModel } from "../models/customer-team.model";
import { DashboardRxInfo } from "../models/dashboard-rx-info.model";
import { UserService } from "./user.service";
import * as moment from "moment";
import { debounceTime } from "rxjs/operators";
import { DashboardModel, DashboardGroupModel, DashboardMetricModel, DashboardDetailRow, DashboardDetailModel } from "../models/dashboard.models";
import { MultiSelectItemModel } from "../components/input-multi-select/multi-select-item.model";
import { StoreObject } from "../models/store-object.model";

@Injectable()
export class DashboardStore {
	private _monthIdx: number = 0;
	private _customerId: number;

	private _teamId: number;
	private _from: Date;
	private _to: Date;
	private _selectedGroup: number;

	public readonly dateRange: StoreObject<Date[]> = new StoreObject<Date[]>([]);

	public readonly teams: StoreObject<CustomerTeamModel[]> = new StoreObject<CustomerTeamModel[]>([
		new CustomerTeamModel({
			Default: true,
			TeamName: "ALL",
			CustomerTeamId: -1
		})
	]);

	public readonly team: StoreObject<CustomerTeamModel> = new StoreObject<CustomerTeamModel>(null);
	public readonly dashData: StoreObject<DashboardModel> = new StoreObject<DashboardModel>(new DashboardModel());
	public readonly top10Patients: StoreObject<DashboardPatientInfo[]> = new StoreObject<DashboardPatientInfo[]>([]);
	public readonly top10NonFormulary: StoreObject<DashboardRxInfo[]> = new StoreObject<DashboardRxInfo[]>([]);
	public readonly loading: StoreObject<boolean> = new StoreObject<boolean>(true);
	public readonly details: StoreObject<DashboardDetailModel> = new StoreObject<DashboardDetailModel>(new DashboardDetailModel());
	public readonly facilities: StoreObject<MultiSelectItemModel[]> = new StoreObject<MultiSelectItemModel[]>([]);
	public readonly selectedFacilities: StoreObject<number[]> = new StoreObject<number[]>([]);
	public readonly mode: StoreObject<string> = new StoreObject<string>("dashboard");

	private readonly customerId: StoreObject<number> = new StoreObject<number>(null);

	constructor(private _http: HttpService, private _userService: UserService) {
		this._userService.signedOut.subscribe(() => {
			this.reset();
		});

		combineLatest([this.mode.observable, this.selectedFacilities.observable, this.customerId.observable, this.dateRange.observable, this.team.observable])
			.pipe(debounceTime(500))
			.subscribe(([mode, facilities, customerId, dates, team]) => {
				if (mode == "dashboard") {
					if (!dates || dates.length < 2 || !customerId) {
						return;
					}

					this._customerId = customerId;

					this._from = dates[0];
					this._to = dates[1];
					this._teamId = team ? team.CustomerTeamId : null;

					this.getDashboard([customerId], this._from, this._to, this._teamId);
					if (this._selectedGroup >= 0) {
						this.getDetail(this._selectedGroup, this._from, this._to);
					}
				} else {
					if (!dates || dates.length < 2 || !facilities || facilities.length === 0) return;

					this._from = dates[0];
					this._to = dates[1];
					this._teamId = team ? team.CustomerTeamId : null;

					this.getDashboard(facilities, this._from, this._to, this._teamId);
					if (this._selectedGroup >= 0) {
						this.getDetail(this._selectedGroup, this._from, this._to);
					}
				}
			});

		this.setDate(0);
	}

	loadDashboard(customerId: number) {
		this.reset();
		this.setDate(0);
		this.customerId.set(customerId);
	}

	setMode(mode: string) {
		if (mode === this.mode.get()) return;

		this.mode.set(mode);
	}

	loadFacilities(): Promise<void> {
		return new Promise<void>(resolve => {
			this._http.get("api/customer/getmyorganizationcustomerlist").subscribe((result: any[]) => {
				let facilities = result.map(r => {
					let model = new MultiSelectItemModel();
					model.id = r.CustomerId;
					model.text = r.Name;
					model.selected = true;

					return model;
				});

				this.facilities.set(facilities);
				resolve();
			});
		});
	}

	datesAreEqual(dt: Date, otherDt: Date): boolean {
		if (!dt || !otherDt) return false;

		return (
			dt.getFullYear() === otherDt.getFullYear() &&
			dt.getMonth() === otherDt.getMonth() &&
			dt.getDate() === otherDt.getDate() &&
			dt.getHours() === otherDt.getHours() &&
			dt.getMinutes() === otherDt.getMinutes()
		);
	}

	reset() {
		this.dateRange.set([]);
		this.dashData.set(new DashboardModel());
		this.top10Patients.set([]);
		this.top10NonFormulary.set([]);
		this.details.set(new DashboardDetailModel());
		this.loading.set(true);
		this.team.set(null);

		this._customerId = -1;
		this._selectedGroup = -1;
	}

	setDate(idx: number) {
		this._monthIdx = idx;

		if (idx === 0) {
			this.dateRange.set([
				moment()
					.utc()
					.subtract("month", 1)
					.toDate(),
				moment()
					.utc()
					.subtract("days", 1)
					.toDate()
			]);
		} else {
			this.dateRange.set([
				moment()
					.utc()
					.subtract("month", idx)
					.startOf("month")
					.toDate(),
				moment()
					.utc()
					.subtract("month", idx)
					.endOf("month")
					.toDate()
			]);
		}
	}

	selectTeam(teamId: number) {
		if (teamId < 0) {
			this.team.set(null);
		} else {
			this.team.set(
				_.find(this.teams.get(), (team: CustomerTeamModel) => {
					return team.CustomerTeamId === teamId;
				})
			);
		}
	}

	selectFacility(selected: any[]) {
		this.selectedFacilities.set(selected);
	}

	getDetail(groupid: number, startDate: Date, endDate: Date): Promise<any> {
		return new Promise<any>(resolve => {
			this._selectedGroup = groupid;

			let model = new DashboardDetailModel();
			model.rows = [];

			switch (groupid) {
				case 1:
					model.group = "ESTIMATED CPPD";
					break;
				case 2:
					model.group = "FORMULARY CPPD";
					break;
				case 3:
					model.group = "NON-FORMULARY CPPD";
					break;
				case 4:
					model.group = "ANCILLARY CPPD";
					break;
			}

			model.start = startDate;
			model.end = endDate;

			this.details.set(model);

			this.loading.set(true);

			let team: CustomerTeamModel = this.team.get();

			this._http
				.post("api/dashboard/getdashboarddetail?groupId=" + groupid, {
					customerIds: this.mode.get() === "dashboard" ? [] : this.selectedFacilities.get(),
					teamId: team ? team.CustomerTeamId : null,
					startDate,
					endDate
				})
				.subscribe((result: ApiResult) => {
					let data: DashboardDetailRow[] = _.map(result.Result, (obj: any) => {
						let row: DashboardDetailRow = Object.assign(new DashboardDetailRow(), obj);

						return row;
					});

					model.rows = data;

					this.details.set(Object.assign(new DashboardDetailModel(), model));
					this.loading.set(false);
				});
		});
	}

	private getDashboard(customerIds: number[], startDate: Date, endDate: Date, teamId?: number) {
		this.loading.set(true);

		this._http.post("api/dashboard/getDashboard", { customerIds, teamId, startDate, endDate }).subscribe((result: ApiResult) => {
			if (result && result.Success) {
				let currentTitle = "Last 30 Days";
				let dates: Date[] = this.dateRange.get();

				if (this._monthIdx > 0) {
					currentTitle = moment(dates[0])
						.utc()
						.format("MMM YYYY");
				}

				let pastTitle =
					this._monthIdx === 0
						? moment(dates[0])
								.utc()
								.subtract("month", 3)
								.format("MMM YYYY") +
						  " - " +
						  moment(dates[1])
								.utc()
								.subtract("month", 2)
								.format("MMM YYYY")
						: moment(dates[0])
								.utc()
								.subtract("month", 3)
								.format("MMM YYYY") +
						  " - " +
						  moment(dates[1])
								.utc()
								.subtract("month", 1)
								.format("MMM YYYY");

				let model: DashboardModel = _.reduce(
					result.Result.categoriesAndMetrics,
					(model: DashboardModel, item: any) => {
						let group: DashboardGroupModel = _.find(model.groups, (group: DashboardGroupModel) => {
							return group.name === item.categoryName;
						});

						if (!group) {
							group = new DashboardGroupModel();
							group.name = item.categoryName;
							group.id = item.categoryId;

							model.groups.push(group);
						}

						let metric = new DashboardMetricModel();
						metric.value = item.metricAmount;
						metric.id = item.metricId;

						if (item.metricId === 1) {
							metric.label = currentTitle;
							metric.start = dates[0];
							metric.end = dates[1];
						} else {
							metric.label = pastTitle;
							metric.start = moment(dates[0])
								.utc()
								.subtract("month", 3)
								.startOf("month")
								.toDate();
							metric.end = moment(dates[0])
								.utc()
								.subtract("month", 1)
								.endOf("month")
								.toDate();
						}

						group.metrics.push(metric);

						return model;
					},
					new DashboardModel()
				);

				for (let i = 0; i < model.groups.length; i++) {
					model.groups[i].calculateChange();
				}

				model.averagePatientDays = result.Result.dashMetaData?.averageDays ?? 0;

				this.dashData.set(model);

				this.top10NonFormulary.set(result.Result.top10NonFormulary);
				this.top10Patients.set(result.Result.top10Patients);

				let teams: CustomerTeamModel[] = result.Result.customerTeams;
				teams.unshift(
					new CustomerTeamModel({
						Default: true,
						TeamName: "ALL",
						CustomerTeamId: -1
					})
				);
				this.teams.set(teams);
			}

			this.loading.set(false);
		});
	}
}
