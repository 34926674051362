import {Component, ElementRef, HostListener} from '@angular/core';

@Component({
  selector: 'app',
  providers: [],
  templateUrl: "./app.template.html",
  host: {
    '(document:click)': 'documentClick($event)'
  }
})
export class App {

  constructor(private _elementRef: ElementRef) {
  }

  documentClick(event) {
    // IOS 9 related hack to force the keyboard to close if the user clicks on an element that is not
    // an user editable field.
    if (event.target) {
      if (event.target.tagName.toLowerCase() !== "input" && event.target.tagName.toLowerCase() !== "textarea"
        && event.target.tagName.toLowerCase() !== "select") {
        if (this._elementRef.nativeElement.ownerDocument.activeElement) {
          this._elementRef.nativeElement.ownerDocument.activeElement.blur();
        }
      }
    }
  }

  @HostListener('keydown', ['$event'])
  keyPressed(event) {
    if (event && event.target) {
      let keyCode: number = event.which || event.keyCode;

      if (event.target.tagName && event.target.tagName.toLowerCase() === 'select' && keyCode === 8) {
        // ignore the backspace in a select element because they are not editable and we want to stop the
        //  app from navigating to the previous page.
        event.preventDefault();
        return false;
      }
    }
  }
}
