import { Pipe, PipeTransform } from "@angular/core";
import { isBoolean } from "util";

@Pipe({ name: "yesNo" })
export class YesNoPipe implements PipeTransform {
    transform(value: any, ...args: any[]) {
        if (value === true) return "Yes";

        return "No";
    }
}
