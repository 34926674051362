/**
 * Created by John on 2016-05-31.
 */
import {Pipe, PipeTransform} from "@angular/core";

@Pipe({name: 'phoneNumber'})
export class PhonePipe implements PipeTransform{
  transform(value:string, args:string[]):any{
    return this.formatPhoneNumber(value)
  }

  formatPhoneNumber(s) {
    var s2 = (""+s).replace(/\D/g, '');
    var m = s2.match(/^(\d{3})(\d{3})(\d{4})$/);
    return (!m) ? null : "(" + m[1] + ") " + m[2] + "-" + m[3];
  }
}
