import { Component, OnInit, OnDestroy } from "@angular/core";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { ErxOrderStore } from "../shared/services/erx-order.store";
import { RxOrderModel } from "../shared/models/rx-order.model";
import { ActivatedRoute, Router } from "@angular/router";
import { OrderPreferenceDetail } from "../shared/models/erx/order-preference-detail.model";
import { OrderBuilder } from "../shared/models/erx/order.builder";

@Component({
	selector: "ocp-user-preferred-erx",
	templateUrl: "./user-preferred-erx.template.html"
})
export class UserPreferredErxComponent implements OnInit, OnDestroy {
	isLoading: boolean = false;
	preferredErx: RxOrderModel[] = [];

	private _destroyed: Subject<void> = new Subject<void>();

	constructor(public erxStore: ErxOrderStore, private _router: Router, private _activeRoute: ActivatedRoute) {}

	ngOnInit(): void {
		this.initTable();
		this.erxStore.getUserPreferences();
	}

	ngOnDestroy(): void {
		this._destroyed.next();
		this._destroyed.unsubscribe();
	}

	initTable() {
		this.erxStore.userPreferredErx.observable.pipe(takeUntil(this._destroyed)).subscribe((preferred: RxOrderModel[]) => {
			this.preferredErx = preferred;
		});
	}

	loadErxForm(nickname: string = null, preferenceId: number = null) {
		this._router.navigate(["../preferred-erx"], { relativeTo: this._activeRoute, queryParams: { nickname, preferenceId } });
	}

	newPreferred() {
		this.erxStore.resetSelectedErx();
		this.erxStore.mode.set("new");
		this.loadErxForm();
	}

	doAction(action: any) {
		if (action.type === "edit") {
			this.edit(action.rx);
		} else if (action.type === "delete") {
			this.delete(action.rx);
		}
	}

	edit(erx: RxOrderModel) {
		this.erxStore.resetSelectedErx();

		this.erxStore.loadPreference(erx.preferenceId, "user").then((detail: OrderPreferenceDetail) => {
			let rxModel = OrderBuilder.fromOrderPreference(detail);

			if (rxModel.children.length === 0) {
				this.erxStore.selectedErx.set([rxModel]);
			} else {
				this.erxStore.selectedErx.set(rxModel.children);
			}

			this.erxStore.mode.set("edit");
			this.loadErxForm(erx.preferredNickname, erx.preferenceId);
		});
	}

	delete(erx: RxOrderModel) {
		this.erxStore.deleteUserPreference(erx.preferenceId);
	}
}
