import { Component, Output, EventEmitter, OnInit, Input, ViewChild } from "@angular/core";
import { NgForm } from "@angular/forms";
import { SearchPhysiciansComponent } from "../search-physicians/search-physicians.component";
import { RxOrderModel } from "../../../../../shared/models/rx-order.model";
import { PrescriberSearchResultModel } from "../../../../../shared/models/prescriber-search-result.model";
import { RxService } from "../../../../../shared/services/rx.service";
import { AgentModel } from "../../../../models/agent-model";

@Component({
	selector: "delivery-physician-details",
	templateUrl: "./delivery-physician-details.template.html",
	styleUrls: ["./delivery-physician-details.scss"]
})
export class DeliveryPhysicianDetailsComponent implements OnInit {
	isSavingDeliveryPhysician: boolean = false;
	newOrderCreated: boolean = false;

	IsVerified: boolean = true;

	@Input() rxOrderModel: RxOrderModel = new RxOrderModel();
	@Input() agent: AgentModel = new AgentModel();

	@Output() onToggleDetails: EventEmitter<any> = new EventEmitter<any>();
	@Output() onCreateNewOrder: EventEmitter<any> = new EventEmitter<any>();
	@Output() onPrescriberSelected: EventEmitter<PrescriberSearchResultModel> = new EventEmitter<PrescriberSearchResultModel>();

	@ViewChild("deliveryPhysicianForm") deliveryPhysicianForm: NgForm;

	@ViewChild("searchMedications") searchMedications: SearchPhysiciansComponent;

	constructor(public _rxService: RxService) {}

	ngOnInit() {
		this._rxService.Deliver = "";
		this._rxService.DeliveryNotes = "";
	}

	physicianSearchCleared(obj: any) {
		this.rxOrderModel.selectedPrescriber = null;
	}

	physicianSelected(prescriber: PrescriberSearchResultModel) {
		this.onPrescriberSelected.emit(prescriber);
	}

	saveDeliveryPhysicianDetails() {
		this.isSavingDeliveryPhysician = true;

		const self = this;
		setTimeout(function() {
			if (!self.newOrderCreated) {
				self.newOrderCreated = true;
				self.onCreateNewOrder.emit();
			}

			self.onToggleDetails.emit("summary");
			self.isSavingDeliveryPhysician = false;
		}, 1500);
	}

	showFormFields() {
		this.onToggleDetails.emit("form");
	}

	hideFormFields() {
		this.onToggleDetails.emit("summary");
	}
}
