import { Component, Input, OnInit, ViewChild, Output, EventEmitter, OnDestroy, AfterViewInit } from "@angular/core";
import { PatientService } from "../../../shared/services/patient.service";
import { StatusMessageService } from "../../../shared/components/status-message/status-message.service";
import { RxService } from "../../../shared/services/rx.service";
import { FadeInQuickAnimation } from "src/app/ocp/animations";
import { GridViewStore } from "src/app/ocp/shared/services/grid-view.store";
import { ApplicationService } from "src/app/ocp/shared/services/application.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ErxOrderStore } from "../../../shared/services/erx-order.store";
import { RxOrderModel } from "src/app/ocp/shared/models/rx-order.model";
import { ModalComponent } from "../../../shared/components/modal/modal.component";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";

@Component({
	selector: "patient-erx-orders-tab",
	templateUrl: "./patient-erx-orders-tab.template.html",
	styleUrls: ["./patient-details-tabs.scss"],
	animations: [FadeInQuickAnimation]
})
export class PatientErxOrdersTab implements OnInit, OnDestroy, AfterViewInit {
	isLoading: boolean = false;
	erxOrders: RxOrderModel[] = [];
	viewOptions: string[] = ["grid", "table"];
	selectedErx: RxOrderModel = null;
	subHeaderText: string = "";

	@Input() patientId: number;
	@Input() patientAdmitted: boolean = false;
	@Input() allowNewOrders: boolean = false;

	isPrescriber: boolean = false;
	isPrescribingAgent: boolean = false;

	@ViewChild("statusHistoryModal") statusHistoryModal: ModalComponent;
	@ViewChild("completeErxOrderModal") completeErxOrderModal: ModalComponent;

	@Output() onSignErxOrder: EventEmitter<boolean> = new EventEmitter<boolean>();

	constructor(
		private _patientService: PatientService,
		private _statusMessageService: StatusMessageService,
		private _router: Router,
		private _activeRoute: ActivatedRoute,
		public rxService: RxService,
		public gridViewStore: GridViewStore,
		public appService: ApplicationService,
		public erxStore: ErxOrderStore
	) {}

	ngAfterViewInit(): void {
		this.erxStore.showSigning.observable.pipe(takeUntil(this.$destroyed)).subscribe((show: boolean) => {
			if (!show && this.completeErxOrderModal.isOpen) {
				this.completeErxOrderModal.dismiss();
			}

			if (show && !this.completeErxOrderModal.isOpen) {
				this.completeErxOrderModal.open("md");
			}
		});
	}

	ngOnInit() {
		this.erxStore.loadOrders(this.patientId);
		this.erxStore.getLoggedInDosespotUser().then((user: any) => {
			this.isPrescriber = user && user.Roles && user.Roles.indexOf(1) >= 0;
			this.isPrescribingAgent = user && user.Roles && user.Roles.indexOf(5) >= 0;
		});
	}

	private $destroyed: Subject<void> = new Subject<void>();
	ngOnDestroy(): void {
		this.$destroyed.next();
		this.$destroyed.unsubscribe();
	}

	popoverContent(viewType: string): string {
		if (viewType === "grid") {
			return "View as Grid";
		} else if (viewType === "table") {
			return "View as List";
		}
	}

	viewSelected(viewType: string): boolean {
		return this.gridViewStore.erxOrdersViewMode.get() === viewType;
	}

	viewChanged(view: string) {
		this.gridViewStore.erxOrdersViewMode.set(view);
	}

	newErxOrder() {
		this.erxStore.resetSelectedErx();
		this.erxStore.mode.set("new");
		this._router.navigate(["../new-erx-order"], { relativeTo: this._activeRoute });
	}

	doAction(action: any) {
		if (action.type === "view") {
			this.view(action.rx);
		} else if (action.type === "reorder") {
			this.reorder(action.rx);
		} else if (action.type === "addPreferred") {
			this.addPreferred(action.rx);
		} else if (action.type === "statusHistory") {
			this.viewStatusHistory(action.rx);
		} else if (action.type === "signErx" || action.type === "edit") {
			this.onSignErxOrder.emit();
		}
	}

	view(erx: RxOrderModel) {
		console.log("View ", erx);
	}

	reorder(erx: RxOrderModel) {
		this.erxStore.resetSelectedErx();
		this.erxStore.mode.set("new");
		this._router.navigate(["../new-erx-order"], { relativeTo: this._activeRoute, queryParams: { reorder: erx.prescriptionId } });
	}

	addPreferred(erx: RxOrderModel) {
		console.log("Add preferred ", erx);
	}

	viewStatusHistory(erx: RxOrderModel) {
		this.erxStore.loadHistory(this.patientId, erx.prescriptionId);

		this.statusHistoryModal.open("lg");
		this.selectedErx = erx;
		this.subHeaderText = " for " + this.selectedErx.DrugName;
	}

	closeStatusHistory() {
		this.statusHistoryModal.close();
	}

	skipSignature() {
		this.erxStore.dismissSignature();
	}

	goToSignature() {
		this.erxStore.dismissSignature();
		this.onSignErxOrder.emit();
	}
}
