import {Injectable} from "@angular/core";
import {HttpService} from "./http.service";

@Injectable()
export class AllergyService {
  private _searchUrl: string = 'api/allergy/search?searchTerm=';

  constructor(private _http: HttpService) {
  }

  searchAllergies(searchTerm: string) {
    return this._http.get(this._searchUrl + searchTerm);
  }
}
