import { Component, OnInit, Input, Output, EventEmitter, ViewChild, AfterViewInit } from '@angular/core';
import { MultiSelectItemModel } from "../../../shared/components/input-multi-select/multi-select-item.model";
import { PatientCareStore } from '../../services/patient-care.store';

const teamsSample: MultiSelectItemModel[] = [
	{
		id: 0,
		text: "GREEN TEAM", 
		selected: false
	},
	{
		id: 1,
		text: "ORANGE TEAM", 
		selected: false
	},
	{
		id: 2,
		text: "RED TEAM", 
		selected: false
	},
	{
		id: 3,
		text: "YELLOW TEAM", 
		selected: false
	}
];

@Component({
  selector: 'ct-form-filter-patient-search',
  templateUrl: './form-filter-patient-search.component.html',
  styleUrls: ['./form-filter-patient-search.component.scss']
})
export class FormFilterPatientSearchComponent implements OnInit {
    states: string[] = [
		"",
		"AL",
		"AK",
		"AS",
		"AZ",
		"AR",
		"CA",
		"CO",
		"CT",
		"DE",
		"DC",
		"FM",
		"FL",
		"GA",
		"GU",
		"HI",
		"ID",
		"IL",
		"IN",
		"IA",
		"KS",
		"KY",
		"LA",
		"ME",
		"MH",
		"MD",
		"MA",
		"MI",
		"MN",
		"MS",
		"MO",
		"MT",
		"NE",
		"NV",
		"NH",
		"NJ",
		"NM",
		"NY",
		"NC",
		"ND",
		"MP",
		"OH",
		"OK",
		"OR",
		"PW",
		"PA",
		"PR",
		"RI",
		"SC",
		"SD",
		"TN",
		"TX",
		"UT",
		"VT",
		"VI",
		"VA",
		"WA",
		"WV",
		"WI",
		"WY"
	];

 	filteredTeams: MultiSelectItemModel[] = teamsSample;

	@Input() disableFilter: boolean;
	@Input() showTeamFilter: boolean;

  	constructor(public patientStore: PatientCareStore) { }

	ngOnInit() {}

	activeToggle(val: boolean){
		this.patientStore.toggleFilterIsActive(val);
	}
}
