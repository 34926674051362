import {Component, Input, Output, EventEmitter} from '@angular/core';
import {PatientCareService} from '../../services/patient-care.service';

@Component({
  selector: 'step-counter',
  templateUrl: './step-counter.template.html',
  styleUrls: ['./step-counter.scss']
})

export class StepCounter {
  @Input() currentStep: number;
  @Input() mode: string;
  @Input() showBackBtn: boolean = false;

  @Output() onGoBack: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private _patientCareService:PatientCareService){}

  private _complete: boolean = false;
  private _cursor: string = 'not-allowed';

  public emitStep(step){
    if(!this._complete){
      return;
    }
    this._patientCareService.emitStep(step);
  }

  ngOnInit(){
    this._complete = this._patientCareService.getComplete();
    this._cursor = this._complete ? 'pointer' : 'default';
  }
}
