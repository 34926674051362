export class DocumentInfoModel {
  public DocumentId: number;
  public DocumentTypeId: number;
  public OriginalFileName: string;
  public SystemFileName: string;
  public PublicationDate: string;
  public UploadedDateTimeUtc: string;
  public FileDescription: string;
  public ThumbnailPageNumber: number;


  public confirmDelete: boolean = false;
}
