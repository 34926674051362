import {Injectable} from "@angular/core";
import {ISearchService} from "../components/patient-search/search.interface";
import {AllergyService} from "./allergy.service";

@Injectable()
export class AllergySearchService implements ISearchService {
  constructor(private _rxService: AllergyService) {
  }

  search(text: string) {
    return this._rxService.searchAllergies(text);
  }
}
