import {Pipe, PipeTransform} from "@angular/core";
import {RxService} from "../services/rx.service";
import {BehaviorSubject} from "rxjs";

@Pipe({name: "deliveryStatus"})
export class DeliveryStatusPipe implements PipeTransform {
  constructor(private _rxService: RxService) {
  }

  transform(id: number) {
    let deliveryText$: BehaviorSubject<string> = new BehaviorSubject("- - - - -");

    if (!id && id !== 0) {
      deliveryText$.next("- - - - -");
    } else {
      this._rxService.getDeliveryStatusList().subscribe(x => {
        x.forEach(obj => {
          if (obj.DeliveryStatusId === id) {
            deliveryText$.next(obj.Description);
          }
        });
      });
    }
    return deliveryText$.asObservable();
  }
}
