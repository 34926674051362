import { Component, Input, forwardRef, Output, EventEmitter } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
	selector: "input-checkbox",
	templateUrl: "./input-checkbox.template.html",
	styleUrls: ["./input-checkbox.scss"],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => InputCheckboxComponent),
			multi: true
		}
	]
})
export class InputCheckboxComponent implements ControlValueAccessor {
	@Input() id: string;
	@Input() label: string;
	@Input() focus: EventEmitter<any> = new EventEmitter<any>();
	@Input() isDisabled: boolean = false;
	@Input() isAllPharmacy: boolean;
	@Input() isInFooter: boolean = false;
	@Input() isBulkUploadParent: boolean = false;
	@Input() emitChange: boolean = false;
	@Input() hint: string = null;

	@Output() onChange: EventEmitter<boolean> = new EventEmitter<boolean>();

	value: boolean;

	propagateChange = (_: any) => {};

	inputChanged(value: boolean) {
		this.value = value;
		this.propagateChange(this.value);

		if (this.emitChange) {
			this.onChange.emit(this.value);
		}
	}

	writeValue(obj: any) {
		if (obj !== undefined) {
			this.value = obj;
		} else {
			this.value = false;
		}
	}

	registerOnChange(fn: any) {
		this.propagateChange = fn;
	}

	registerOnTouched(fn: any) {} //Not currently needed
}
