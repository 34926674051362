import { Component, Input } from "@angular/core";
import { RxOrderModel } from "../../models/rx-order.model";

@Component({
	selector: "ocp-add-override",
	styleUrls: ["./form-add-override.css"],
	templateUrl: "./form-add-override.template.html"
})
export class FormAddOverrideComponent {
	@Input() rxModel: RxOrderModel;
	@Input() isInternalUser: boolean;
}
