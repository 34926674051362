import {Component, OnInit} from '@angular/core';
import {DocumentTypeModel} from "../../../shared/models/document-type.model";
import {ClinicalResourcesService} from "../../../shared/services/clinical-resources.service";

@Component({
  selector: 'newsletters-tab',
  templateUrl: './newsletters.template.html',
  styleUrls: ['./clinical-resources-tabs.css']
})

export class NewslettersTab implements OnInit {
  private _documentTypeId: number = 3; // NewsLetter
  documents: DocumentTypeModel[] = [];

  constructor(private _clinicalResourcesService: ClinicalResourcesService) {
  }

  ngOnInit() {
    this._clinicalResourcesService.getDocuments(null, this._documentTypeId).subscribe((documents: DocumentTypeModel[]) => {
      this.documents = documents;
    });
  }

  viewNewsletter() {

  }
}
