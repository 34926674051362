import { Component, Input, Output, EventEmitter, OnInit, AfterViewInit } from "@angular/core";
import { PatientInfoModel } from "../../../shared/models/patient.model";
import { PatientAllergyModel } from "../../../shared/models/allergy.model";
import { StatusMessageService } from "../../../shared/components/status-message/status-message.service";
import { PatientService } from "../../../shared/services/patient.service";
import { ApiResult } from "../../../shared/models/api-result.model";
import { UserService } from "../../../shared/services/user.service";

@Component({
	selector: "allergy-details",
	templateUrl: "./form-patient-allergies.template.html",
	styleUrls: ["./form-patient-allergies.scss"]
})
export class FormPatientAllergies implements OnInit, AfterViewInit {
	saving: boolean = false;
	currentStep: number = 4;
	_formDisabled: boolean = false;
	_noKnownAllergies: boolean = false;
	formChanged: boolean = false;
	focusKnownAllergies: EventEmitter<any> = new EventEmitter<any>();
	focusSearch: EventEmitter<any> = new EventEmitter<any>();
	mrnRequired: boolean = false;

	@Input() mode: string;
	@Input() patient: PatientInfoModel = new PatientInfoModel();
	@Input() allergies: PatientAllergyModel[] = [];

	@Output() goBack: EventEmitter<string> = new EventEmitter<string>();
	@Output() closeModal: EventEmitter<string> = new EventEmitter<string>();
	@Output() goToConfirmation: EventEmitter<string> = new EventEmitter<string>();
	@Output() saveSuccess: EventEmitter<string> = new EventEmitter<string>();
	@Output() onUnsavedChanges: EventEmitter<string> = new EventEmitter<string>();

	get needsMrn(): boolean {
		return this.mrnRequired && this.patient.patientStatus !== 0 && !this.patient.Mrn && this.mode !== "Edit";
	}

	constructor(private _statusMessageService: StatusMessageService, private _patientService: PatientService, private _userService: UserService) {}

	ngOnInit() {
		if (this.mode === "Edit") {
			this._noKnownAllergies = !this.allergyList.length;
		}
		this._userService.isMrnRequired.subscribe((isRequired: boolean) => {
			this.mrnRequired = isRequired;
		});
	}

	ngAfterViewInit() {
		if (this.mode !== "Edit") {
			this.focusKnownAllergies.emit();
		}
	}

	get allergyList(): PatientAllergyModel[] {
		if (this.mode !== "Edit") {
			return this.patient.allergies;
		} else {
			return this.allergies;
		}
	}

	get noKnownAllergies(): boolean {
		return this._noKnownAllergies;
	}

	set noKnownAllergies(value: boolean) {
		this.formChanged = true;
		this._noKnownAllergies = value;
	}

	get newPatientSaveDisabled(): boolean {
		return this.patient.patientStatus !== 0 && !this.noKnownAllergies && !this.allergyList.length;
	}

	get editSaveDisabled(): boolean {
		return !this.formChanged || (!this.noKnownAllergies && !this.allergyList.length);
	}

	addAllergy(allergy: PatientAllergyModel) {
		if (allergy) {
			if (this.mode !== "Edit") {
				this.patient.allergies.push(allergy);
			} else {
				this.formChanged = true;
				this.allergies.push(allergy);
			}
		}
	}

	removeAllergy(allergy: PatientAllergyModel) {
		if (this.mode !== "Edit") {
			this.patient.allergies.splice(this.patient.allergies.indexOf(allergy), 1);
		} else {
			this.formChanged = true;
			this.allergies.splice(this.allergies.indexOf(allergy), 1);
		}
	}

	doFocusSearch() {
		if (!this.noKnownAllergies) {
			this.focusSearch.emit();
		}
	}

	saveAllergies() {
		this.saving = true;
		this._patientService.updatePatientAllergies(this.patient.patientId, this.allergies).subscribe((result: ApiResult) => {
			if (result.Success) {
				this.saving = false;
				this._statusMessageService.changeStatusMessage("success", "Patient allergies updated successfully.");
				this.saveSuccess.emit();
			} else {
				this.saving = false;
				this._statusMessageService.changeStatusMessage("error", result.PublicMessage);
			}
		});
	}

	checkIfUnsavedChanges() {
		if (this.formChanged) {
			this.onUnsavedChanges.emit();
		} else {
			this.closeModal.emit();
		}
	}
}
