export class SearchMedicationsModel {
	drugName: string;
	strength: string;
	dosageForm: string;
	ndc: string;

	constructor(obj: any) {
		Object.assign(this, obj);
	}
}
