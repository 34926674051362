import {Injectable} from "@angular/core";
import {HttpService} from "./http.service";
import {DoseSpotCustomerConfigModel} from "../models/dosespot-customer-config.model";

@Injectable()
export class DoseSpotService {
  private _getConfigurationForCustomerUrl: string = 'api/dosespot/getconfigurationforcustomer?customerId=';
  private _setCustomerConfigUrl: string = 'api/dosespot/setcustomerconfig';

  constructor(private _http: HttpService) {
  }

  getConfigurationForCustomer(customerId: number) {
    return this._http.get(this._getConfigurationForCustomerUrl + customerId);
  }

  setConfigurationForCustomer(customerId: number, config: DoseSpotCustomerConfigModel) {
    return this._http.post(this._setCustomerConfigUrl, {
      CustomerId: customerId,
      Config: config
    });
  }
}
