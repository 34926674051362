import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "dosespotStyle" })
export class DosespotStatusStylePipe implements PipeTransform {
	transform(status: string) {
		if (!status || status.trim().length === 0) return "neutral";

		switch (status) {
			// Medication statuses
			case "-1": // active
				return "success";
			case "-2": // inactive
				return "neutral";
			case "-3": // discontinued
				return "informational";
			case "-4": // deleted
				return "warning";
			case "-5": // completed
				return "success";
			case "-6": // cancellation requested
				return "warning";
			case "-7": // cancellation pending
				return "warning";
			case "-8": // cancelled
				return "warning";
			case "-9": // cancellation denied
				return "warning";
			case "-10": // changed
				return "informational";
			case "-11": // full fill
				return "success";
			case "-12": // partial fill
				return "success";
			case "-13": // no fill
				return "warning";
			// Prescription statuses
			case "1": //entered
				return "neutral";
			case "2": //printed
				return "success";
			case "3": //sending
				return "informational";
			case "4": // eRx Sent
				return "informational";
			case "5": //faxed
				return "informational";
			case "6": // error
				return "warning";
			case "7": // deleted
				return "warning";
			case "8": //requested
				return "informational";
			case "9": // edited
				return "informational";
			case "10": // epcs error
				return "warning";
			case "11": // epcs signed
				return "informational";
			case "12": // ready to sign
				return "informational";
			case "13": // pharmacy verified
				return "success";
		}

		return "neutral";
	}
}
