/**
 * Created by John on 2016-03-02.
 */
import {Component, Input} from "@angular/core";
import {ModalComponent} from "./modal.component";

@Component({
    selector:"modal-footer",
    templateUrl: "./modal-footer.template.html"
})
export class ModalFooterComponent {
  isSafari:boolean = false;

    @Input() showDefaultButtons:boolean = false;
    constructor(public modal:ModalComponent){
      this.isSafari = this.checkSafari();
    }

  private checkSafari(): boolean {
    const userAgent = window.navigator.userAgent.toLowerCase();
    let isSafari = !!userAgent.match(/version\/[\d\.]+.*safari/);

    isSafari = isSafari || /iphone|ipod|ipad/.test(userAgent);

    //console.log("Is Safari - " + isSafari);
    return isSafari;
  };
}
