import { Component, OnInit, OnDestroy, Input, AfterViewInit } from "@angular/core";
import { DashboardStore } from "src/app/ocp/shared/services/dashboard.store";
import { UserService } from "src/app/ocp/shared/services/user.service";
import { DashboardPatientInfo } from "src/app/ocp/shared/models/dashboard-patient-info.model";
import { DataTableColumnModel } from "src/app/ocp/shared/components/data-table/columns.model";
import { DataTableModel } from "src/app/ocp/shared/components/data-table/data-table.model";
import { SortModel } from "src/app/ocp/shared/components/data-table/sort.model";
import { DashboardRxInfo } from "src/app/ocp/shared/models/dashboard-rx-info.model";
import * as moment from "moment";
import { DashboardGroupModel, DashboardMetricModel } from "src/app/ocp/shared/models/dashboard.models";
import { AppConfig } from "src/app/ocp/app.config";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
	selector: "dashboard-tab",
	templateUrl: "./dashboard.component.html",
	styleUrls: ["./dashboard.scss"]
})
export class ReportingDashboardComponent implements OnInit, OnDestroy, AfterViewInit {
	monthsList: any[] = [];

	patientsTable: DataTableModel = new DataTableModel([], []);
	nonFormularyTable: DataTableModel = new DataTableModel([], []);

	selectedMonth: number = 0;
	selectedTeam: number = -1;
	selectedFacilities: any[] = [];

	showDetails: boolean = false;
	showFilters: boolean = false;

	@Input() isOrganizationDash: boolean = false;
	@Input() organizationName: string;

	constructor(public _dashStore: DashboardStore, public _userService: UserService, private _config: AppConfig) {}

	private $destroyed: Subject<void> = new Subject<void>();
	ngOnDestroy() {
		this.$destroyed.next();
		this.$destroyed.unsubscribe();
		this._dashStore.reset();
	}

	ngAfterViewInit(): void {
		if (this.isOrganizationDash) {
			this._dashStore.loadFacilities();
		}
	}

	ngOnInit() {
		if (!this.isOrganizationDash) {
			this._userService.$electedCustomer.pipe(takeUntil(this.$destroyed)).subscribe(customer => {
				if (customer && customer.CustomerId > 0) {
					this._dashStore.loadDashboard(customer.CustomerId);
				}
			});
		}

		this._dashStore.setMode(this.isOrganizationDash ? "organization" : "dashboard");

		this.generateMonthList();

		this._dashStore.top10Patients.observable.pipe(takeUntil(this.$destroyed)).subscribe((info: DashboardPatientInfo[]) => {
			this.patientsTable = this.buildTable();
			this.patientsTable.populate(info);
		});

		this._dashStore.top10NonFormulary.observable.pipe(takeUntil(this.$destroyed)).subscribe((info: DashboardRxInfo[]) => {
			this.nonFormularyTable = this.buildFormularyTable();
			this.nonFormularyTable.populate(info);
		});
	}

	private buildTable(): DataTableModel {
		let columns: DataTableColumnModel[] = [];
		columns.push(new DataTableColumnModel("name", "Name", "pl-12px", true));
		columns.push(new DataTableColumnModel("totalRx", "Count", "right-aligned", true));
		columns.push(new DataTableColumnModel("totalSpent", "Dollars", "right-aligned", true));
		if (this.isOrganizationDash) {
			columns.push(new DataTableColumnModel("facility", "Facility", "", true));
		}

		let defaultSort: SortModel[] = [];
		return new DataTableModel(defaultSort, columns);
	}

	private buildFormularyTable(): DataTableModel {
		let columns: DataTableColumnModel[] = [];
		columns.push(new DataTableColumnModel("label", "Name", "pl-12px", true));
		columns.push(new DataTableColumnModel("totalRx", "Count", "right-aligned", true));
		columns.push(new DataTableColumnModel("totalSpent", "Dollars", "right-aligned", true));

		let defaultSort: SortModel[] = [];
		return new DataTableModel(defaultSort, columns);
	}

	monthYearChanged(val: any) {
		this.selectedMonth = val;
		//this._dashStore.reset();
		this._dashStore.setDate(val);
		this._dashStore.selectTeam(this.selectedTeam);
	}

	teamChanged(teamId: number) {
		//this._dashStore.reset();
		this._dashStore.selectTeam(teamId);
		//this._dashStore.setDate(this.selectedMonth);
		this.selectedTeam = teamId;
	}

	facilityChanged(selected: any[]) {
		//this._dashStore.reset();
		this._dashStore.selectFacility(selected);
		//this._dashStore.selectTeam(this.selectedTeam);
		//this._dashStore.setDate(this.selectedMonth);
		this.selectedFacilities = selected;
	}

	goToCppdDetails(group: DashboardGroupModel, metric: DashboardMetricModel) {
		this._dashStore.getDetail(group.id, metric.start, metric.end);

		this.showDetails = true;
	}

	private generateMonthList() {
		this.monthsList.push({
			displayText: "Last 30 Days",
			data: 0
		});
		for (let i = 1; i < this._config.maxDashboardMonths + 1; i++) {
			const dt = moment().subtract("month", i);

			this.monthsList.push({
				displayText: dt.format("MMMM YYYY"),
				data: i
			});
		}
	}

	toggleFilters() {
		this.showFilters = !this.showFilters;
	}
}
