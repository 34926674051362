import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'user-role-tag',
  templateUrl: './user-role-tag.component.html',
  styleUrls: ['./user-role-tag.component.scss']
})
export class UserRoleTagComponent implements OnInit {
  @Input() role: string;

  constructor() { }

  ngOnInit(): void {
  }

  getRoleColor(role: string):string {
    switch (role) {
			case "Administrator":
				return "accent";
			case "Nurse":
				return "blue";
			case "Nurse Practitioner":
				return "blue";
			case "Physician":
				return "blue";
			case "Staff":
				return "blue";
      case "Analytics":
        return "orange";
      case "Enhanced eRx":
        return "orange";
      case "Enhanced ERx":
        return "orange";
      case "Prior Authorizer":
        return "orange";
			default:
				return "";
		}
  }
}
