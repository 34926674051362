import { Component, Input, Output, EventEmitter, ViewChild, OnInit } from "@angular/core";
import { FileUploadComponent } from "../../../shared/components/input-file-upload/file-upload.component";
import { ClinicalResourcesService } from "../../../shared/services/clinical-resources.service";
import { UploadFileSuccessModel } from "../../../shared/models/upload-file-success.model";
import { ApiResult } from "../../../shared/models/api-result.model";
import { DocumentTypeModel } from "../../../shared/models/document-type.model";
import { DocumentInfoModel } from "../../../shared/models/document-info.model";
import { StatusMessageService } from "../../../shared/components/status-message/status-message.service";

@Component({
	selector: "file-details",
	templateUrl: "./file-details.template.html",
	styleUrls: ["./file-details.scss"]
})
export class FileDetails implements OnInit {
	changingThumbnail: boolean = false;
	saving: boolean = false;
	fileUploadUrl: string = "api/clinicalresources/uploaddocument";
	fileSelected: boolean = false;
	fileTypeList: DocumentTypeModel[] = [];
	thumbnailUrl: any;
	fileChanged: boolean = false;

	@ViewChild("fileUpload") fileUpload: FileUploadComponent;

	@Input() mode: string;
	@Input() file: DocumentInfoModel = new DocumentInfoModel();
	@Input() defaultDocTypeId: number = 1;

	@Output() closeModal: EventEmitter<string> = new EventEmitter<string>();
	@Output() uploadComplete: EventEmitter<string> = new EventEmitter<string>();

	constructor(private _clinicalResourcesService: ClinicalResourcesService, private _statusMessageService: StatusMessageService) {}

	ngOnInit() {
		if (this.mode === "Edit") {
			this.fileSelected = true;
			this.updateThumbnail();
		} else {
			if (this.defaultDocTypeId) {
				this.file.DocumentTypeId = this.defaultDocTypeId;
			}
		}

		this._clinicalResourcesService.getDocumentTypes().subscribe((results: DocumentTypeModel[]) => {
			this.fileTypeList = results;
		});
	}

	onUploadComplete(results: UploadFileSuccessModel) {
		this.file.OriginalFileName = results.originalFileName;
		this.file.SystemFileName = results.systemFileName;
		this.updateThumbnail();
	}

	updateThumbnail() {
		this.changingThumbnail = true;
		if (this.file.DocumentId) {
			this._clinicalResourcesService.getThumbnail("", this.file.DocumentId, null, this.file.ThumbnailPageNumber).subscribe((results: any) => {
				this.changingThumbnail = false;
				this.thumbnailUrl = "data:image/jpeg;base64," + results;
			});
		} else {
			this._clinicalResourcesService.getThumbnail(this.file.SystemFileName, null, null, this.file.ThumbnailPageNumber).subscribe((results: any) => {
				this.changingThumbnail = false;
				this.thumbnailUrl = "data:image/jpeg;base64," + results;
			});
		}
	}

	onFileSelected(fileName: string) {
		if (fileName.toLowerCase().endsWith(".pdf") || fileName.toLowerCase().endsWith(".ppt") || fileName.toLowerCase().endsWith(".pptx")) {
			this.fileChanged = true;
			this.fileSelected = true;
		}
	}

	onUploadError(e: string) {
		this._statusMessageService.changeStatusMessage("error", e);
		this.fileSelected = false;
	}

	save() {
		this.saving = true;
		if (this.file.DocumentId) {
			this._clinicalResourcesService.updateDocument(this.file, this.fileChanged).subscribe((result: ApiResult) => {
				this.saving = false;
				if (result.Success) {
					this.uploadComplete.emit(this.file.OriginalFileName);
				} else {
					this._statusMessageService.changeStatusMessage("error", result.PublicMessage);
				}
			});
			if (this.fileChanged) {
				this.fileUpload.upload(null);
			}
		} else {
			this._clinicalResourcesService.addDocument(this.file).subscribe((result: ApiResult) => {
				this.saving = false;
				if (result.Success) {
					this.uploadComplete.emit(this.file.OriginalFileName);
				} else {
					this._statusMessageService.changeStatusMessage("error", result.PublicMessage);
				}
			});
			this.fileUpload.upload(null);
		}
	}
}
