export class PriorAuthRequest {
	PatientId: number;
	Covered: boolean;
	NDC: string;
	EffectiveDate: Date;
	TerminationDate: Date;
	MaxQuanity: number;
	MaxRefills: number;
	MaxDaySupply: number;
	PriorAuthNote: string;
}
