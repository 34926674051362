import { Component, Input, Output, EventEmitter } from "@angular/core";
import { DashboardMetricModel } from "src/app/ocp/shared/models/dashboard.models";

@Component({
	selector: "ct-cppd-block",
	templateUrl: "./cppd-block.component.html",
	styleUrls: ["./cppd-block.component.scss"]
})
export class CppdBlockComponent {
	@Input() data: DashboardMetricModel;

	@Output() onDataClick: EventEmitter<string> = new EventEmitter<string>();

	get showBlock(): boolean {
		return this.data.change && Number.isFinite(this.data.change);
	}

	percentageChange: number;
	trending: string;

	constructor() {}
}
