/**
 * Created by dan on 3/31/2017.
 */
import {Component, Input, Output, EventEmitter} from "@angular/core";

@Component({
  selector: "inline-confirmation",
  templateUrl: "./inline-confirmation.template.html",
  styleUrls: ["./inline-confirmation.css"]
})

export class InlineConfirmationComponent {
  @Input() label:string;
  @Input() isDelete:boolean = false;

  @Output() cancelAction:EventEmitter<string> = new EventEmitter<string>();
  @Output() confirmAction:EventEmitter<string> = new EventEmitter<string>();

}
