export class DosespotItem {
	LexiGenProductId?: number;
	LexiDrugSynId?: number;
	LexiSynonymTypeId?: number;
	DispenseUnitId: number;
	ItemName: string;
	UnitName: string;
	Strength: string;
	ItemTypeId: number; // 1 = Medication, 2 = Supply, 3 = compound
	SupplyId?: number;
	NotControlled: boolean;
	CompoundId?: number;
	DiagnosisId?: number;
}
