import { Component, OnInit, Input } from "@angular/core";
import { CustomerService } from "../../../shared/services/customer.service";
import { PermissionMatrixModel } from "../../models/permission-matrix.model";
import { UserService } from "../../services/user.service";
import { PermissionsService } from "../../services/permisisons.service";

@Component({
	selector: "customer-permission-matrix",
	templateUrl: "./customer-permission-matrix.template.html"
})
export class CustomerPermissionMatrixComponent implements OnInit {
	private _customerId: number;

	permissions: PermissionMatrixModel[] = [];

	@Input() isAdminSection: boolean = false;
	@Input()
	set customerId(customerId: number) {
		this._customerId = customerId;
		this.getPermissionMatrix();
	}

	get customerId() {
		return this._customerId;
	}

	constructor(private _customerService: CustomerService, private _permissionsService: PermissionsService) {}

	ngOnInit() {
		if (!this.isAdminSection) {
			this.getPermissionMatrix();
		} else {
			this.getAdminPermissionMatrix();
		}
	}

	getPermissionMatrix() {
		this._customerService.getCustomerPermissionMatrix(this.customerId).subscribe((results: PermissionMatrixModel[]) => {
			this.permissions = results;
		});
	}

	getAdminPermissionMatrix() {
		this._permissionsService.getInternalPermissionMatrix().subscribe((results: PermissionMatrixModel[]) => {
			this.permissions = results;
		});
	}
}
