import { Component } from "@angular/core";
import { NewVersionCheckerService } from "./new-version-checker.service";

@Component({
	selector: "new-version-checker",
	templateUrl: "./new-version-checker.template.html",
	styleUrls: ["./new-version-checker.styles.scss"]
})
export class NewVersionCheckerComponent {
	constructor(public service: NewVersionCheckerService) {}
}
