import { takeUntil } from "rxjs/operators";
import { Component, ViewChild, OnInit, OnDestroy, ElementRef } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { PatientInfoModel } from "../shared/models/patient.model";
import { ModalComponent } from "../shared/components/modal/modal.component";
import { PatientService } from "../shared/services/patient.service";
import { StatusMessageService } from "../shared/components/status-message/status-message.service";
import { DiagnosisModel } from "../shared/models/diagnosis.model";
import { PatientDiagnosisTab } from "./components/patient-details-tabs/patient-diagnosis-tab.component";
import { RxOrderSuccessModel } from "../shared/models/rx-order-success.model";
import { PatientRxTab } from "./components/patient-details-tabs/patient-rx-tab.component";
import { FormQuickRefill } from "./components/form-quick-refill/form-quick-refill.component";
import { PatientAllergyModel } from "../shared/models/allergy.model";
import { PatientAllergiesTab } from "./components/patient-details-tabs/patient-allergies-tab.component";
import { Permissions } from "../shared/enums/permissions";
import { UserService } from "../shared/services/user.service";
import { AuthService } from "../shared/services/auth.service";
import { AdmitPatientModalComponent } from "./components/admit-patient-modal/admit-patient-modal.component";
import { UserInfoModel } from "../shared/models/user-info.model";
import { PatientCareStore } from "./services/patient-care.store";
import { Subject } from "rxjs";
import { RxService } from "../shared/services/rx.service";
import { ApplicationService } from "../shared/services/application.service";
import { MobileService } from "../shared/services/mobile.service";
import { ErxOrderStore } from "../shared/services/erx-order.store";
import { RoutingRedirectService } from "../shared/services/routing-redirect.service";

@Component({
	selector: "patient-details",
	templateUrl: "./patient-details.template.html",
	styleUrls: ["./patient-details.scss"]
})
export class PatientDetails implements OnInit, OnDestroy {
	isLoading: boolean = false;
	isInternalUser: boolean = false;
	isScreeningEnabled: boolean = true;
	doseSpotEnabled: boolean = false;
	patientId: number;
	age: number;
	tabContent: string = "profile";
	editMode: string;
	createMode: string;
	dialogueMode: string;
	modalTitle: string;
	subHeaderText: string;
	moreActions: boolean = false;
	editAdmissionType: string;
	showingDispenseHistory: boolean = false;
	viewMode: string = "patientDetails";
	clinicalToolsType: string;
	rxOrderSuccessModel: RxOrderSuccessModel;
	permissions: any = Permissions;
	confirmMed: boolean = false;
	overrideRequired: boolean = false;
	profileMedStep: string = "details";
	patient: PatientInfoModel = new PatientInfoModel();
	patientDiagnosis: DiagnosisModel[] = [];
	editPatientModel: PatientInfoModel = new PatientInfoModel();
	diagnosisToManage: DiagnosisModel[] = [];
	allergiesToManage: PatientAllergyModel[] = [];
	saving: boolean = false;
	doseSpotUrl: any;

	isMobile: boolean = false;

	pharmacyPhone: string;

	private destroyed: Subject<any> = new Subject<any>();

	@ViewChild("editModal") editModal: ModalComponent;

	@ViewChild("createModal") createModal: ModalComponent;

	@ViewChild("dialogueModal") dialogueModal: ModalComponent;

	@ViewChild("dispenseHistory") dispenseHistory: ModalComponent;

	@ViewChild("quickRefill") quickRefill: ModalComponent;

	@ViewChild("quickRefillForm") quickRefillForm: FormQuickRefill;

	@ViewChild("confirmQuickRefill") confirmQuickRefill: ModalComponent;

	@ViewChild("rxTab") rxTab: PatientRxTab;

	@ViewChild("diagnosisTab") diagnosisTab: PatientDiagnosisTab;
	@ViewChild("allergiesTab") allergiesTab: PatientAllergiesTab;

	@ViewChild("authorizationModal") authorizationModal: ModalComponent;

	@ViewChild("clinicalTools") clinicalTools: ModalComponent;

	@ViewChild("admitPatientModal") admitPatientModal: AdmitPatientModalComponent;

	@ViewChild("confirmDeletePreAdmit") confirmDeletePreAdmit: ModalComponent;

	@ViewChild("doseSpotLink") doseSpotLink: any;

	@ViewChild("confirmCancelChanges") confirmCancelChanges: ModalComponent;

	private _isDosespotUser: boolean = false;
	get allowNewErx(): boolean {
		return this.doseSpotEnabled && this._isDosespotUser && !this.patientStore.getIsPediatricRestricted();
	}

	get popoverContent(): string {
		if (this.patient.isAdmitted && this.patient.isFacilityCare) {
			return "Currently admitted into facility care.";
		} else if (this.patient.isAdmitted && this.patient.isHomePatient) {
			return "Currently admitted into home care.";
		} else if (this.patient.isAdmitted && this.patient.isIpuGpu) {
			return "Currently admitted into IPU/GPU.";
		} else if (this.patient.isDeceased) {
			return "Patient is Deceased.";
		} else if (this.patient.isPreadmit) {
			return "Patient has not been admitted.";
		} else {
			return "Currently discharged.";
		}
	}

	constructor(
		private _activeRoute: ActivatedRoute,
		private _patientService: PatientService,
		private _userService: UserService,
		private _authService: AuthService,
		private _statusMessageService: StatusMessageService,
		private _rxService: RxService,
		private _router: Router,
		public patientStore: PatientCareStore,
		public appService: ApplicationService,
		private _mobile: MobileService,
		private _erxStore: ErxOrderStore,
		private _redirectService: RoutingRedirectService
	) {
		this.isMobile = this._mobile.isMobile();
		this._redirectService.loadErxTab.pipe(takeUntil(this.destroyed)).subscribe(() => {
			this.loadErxOrdersTab();
		});
	}

	newErxURLError: string = null;
	ngOnInit() {
		this.isLoading = true;
		this.isInternalUser = this._userService.isInternalUser();

		this._userService.doseSpotEnabled.pipe(takeUntil(this.destroyed)).subscribe((enabled: boolean) => {
			this.doseSpotEnabled = enabled;
		});

		this._activeRoute.parent.params.pipe(takeUntil(this.destroyed)).subscribe((params: any) => {
			this.patientId = +params.patientId;
		});

		this.patientStore.Patient.pipe(takeUntil(this.destroyed)).subscribe((patient: PatientInfoModel) => {
			//this is intentionally a double equals
			if (patient && patient.patientId == this.patientId) {
				this.isLoading = false;
				this.patient = patient;
				if (this.doseSpotEnabled && this.isMobile) {
					this._rxService.getDoseSpotURLForNewERX(patient.patientId).then(
						(url: any) => {
							if (url) {
								this.doseSpotUrl = url.changingThisBreaksApplicationSecurity;
								this.doseSpotLink.nativeElement.href = this.doseSpotUrl;
								this.newErxURLError = null;
							} else {
								this.newErxURLError = "Unknown error";
							}
						},
						err => {
							this.newErxURLError = err;
						}
					);
				}
			} else {
				this.patient = new PatientInfoModel();
			}
		});

		this._userService.userInfo.pipe(takeUntil(this.destroyed)).subscribe((userInfo: UserInfoModel) => {
			setTimeout(() => {
				this.pharmacyPhone = userInfo.PharmacyPhone;
				this._isDosespotUser = userInfo.DoseSpotClinicianId > 0;
			});
		});

		this._userService.isScreeningEnabled.pipe(takeUntil(this.destroyed)).subscribe((isScreeningEnabled: boolean) => {
			this.isScreeningEnabled = isScreeningEnabled;
		});
	}

	ngOnDestroy() {
		this.destroyed.next();
		this.destroyed.unsubscribe();
	}

	loadProfileTab() {
		this.tabContent = "profile";
	}

	loadRxTab() {
		this.tabContent = "rx";
	}

	loadErxOrdersTab() {
		this.tabContent = "erxOrders";
	}

	loadAllergiesTab() {
		this.tabContent = "allergies";
	}

	loadDiagnosisTab() {
		this.tabContent = "diagnosis";
	}

	callPharmacy() {
		document.location.href = "tel:" + this.pharmacyPhone;
	}

	toggleFavorite() {
		this.patient.isFavorite = !this.patient.isFavorite;
		let self = this;
		this._patientService.updateFavoritePatient(this.patient.patientId, this.patient.isFavorite).subscribe(function(results: any) {
			if (results.Success) {
				if (self.patient.isFavorite) {
					self._statusMessageService.changeStatusMessage("success", self.patient.fullName() + " has been added to your Favorites list.");
				} else {
					self._statusMessageService.changeStatusMessage("success", self.patient.fullName() + " has been removed from your Favorites list.");
				}
			} else {
				self._statusMessageService.changeStatusMessage("error", results.PublicMessage);
			}
		});
	}

	onPatientDetailsSaveSuccess() {
		this.closeEditModal();
		this.patientStore.loadPatientProfile(this.patientId);
	}

	dischargePatientSuccess() {
		this.patientStore.loadPatientProfile(this.patientId);
	}

	diagnosesSaveSuccess() {
		if (this.tabContent === "diagnosis") {
			this.diagnosisTab.loadPatientDiagnosis();
		}
		this.closeEditModal();
	}

	allergySaveSuccess() {
		if (this.tabContent === "allergies") {
			this.allergiesTab.loadAllergies();
		}
		this.closeEditModal();
	}

	admitPatientSuccess() {
		this.patientStore.loadPatientProfile(this.patientId);

		switch (this.tabContent) {
			case "rx": {
				this.rxTab.loadMedications();
				break;
			}
			case "diagnosis": {
				this.diagnosisTab.loadPatientDiagnosis();
				break;
			}
			case "allergies": {
				this.allergiesTab.loadAllergies();
				break;
			}
		}
	}

	closeEditModal() {
		this.editMode = null;
		this.editModal.dismiss();
	}

	openERX() {
		if (this.isMobile) {
			if (this.doseSpotEnabled) {
				if (this.newErxURLError) {
					this._statusMessageService.changeStatusMessage("error", this.newErxURLError, 15000);
				} else {
					if (this.doseSpotUrl) {
						this.doseSpotLink.nativeElement.click();
					}
				}
			}
		} else {
			this._router.navigate(["../erx"], { relativeTo: this._activeRoute });
		}
	}

	viewPlanOfTreatment() {
		this._router.navigate(["../plan-of-treatment"], { relativeTo: this._activeRoute });
	}

	viewDeprescribingOptions() {
		this._router.navigate(["../deprescribing-options"], { relativeTo: this._activeRoute });
	}

	viewTierReport() {
		this._router.navigate(["../cost-savings-report"], { relativeTo: this._activeRoute });
	}

	viewFormularies() {
		this._router.navigate(["clinical-resources/formularies"]);
	}

	openCurrentAdmissions() {
		this.editModal.open("lg");
		this.editMode = "currentAdmissions";
		this.modalTitle = "Current Admissions";
		this.subHeaderText = " for " + this.patient.firstName + " " + this.patient.lastName;
	}

	addAllergy() {
		this.editModal.open("lg");
		this.editMode = "allergy";
		this.modalTitle = "Add Allergy";
		this.subHeaderText = " to " + this.patient.firstName + " " + this.patient.lastName;
	}

	manageDiagnoses() {
		this._patientService.getPatientDiagnosis(this.patientId).subscribe((diags: DiagnosisModel[]) => {
			this.diagnosisToManage = diags;
			this.editModal.open("lg");
			this.editMode = "diagnosis";
			this.modalTitle = "Manage Diagnoses";
			this.subHeaderText = " for " + this.patient.firstName + " " + this.patient.lastName;
		});
	}

	manageAllergies() {
		this._patientService.getPatientAllergies(this.patientId).subscribe((allergies: PatientAllergyModel[]) => {
			this.allergiesToManage = allergies;
			this.editModal.open("lg");
			this.editMode = "allergy";
			this.modalTitle = "Manage Allergies";
			this.subHeaderText = " for " + this.patient.firstName + " " + this.patient.lastName;
		});
	}

	editProfile() {
		this._authService.checkSession().then((isValid: boolean) => {
			if (isValid) {
				this._patientService.getProfileModel(this.patientId).subscribe((patientInfo: PatientInfoModel) => {
					this.editPatientModel = patientInfo;
					this.editModal.open("lg");
					this.editMode = "profile";
					this.modalTitle = "Edit Profile";
					this.subHeaderText = this.patient.firstName + " " + this.patient.lastName;
				});
			}
		});
	}

	editAdmissions(admissionsType: string) {
		if (admissionsType === "admit") {
			this.admitPatientModal.showDialog(this.patientId);
			this.editMode = "admissions";
		} else {
			this._patientService.getProfileModel(this.patientId).subscribe((patientInfo: PatientInfoModel) => {
				this.editPatientModel = patientInfo;
				this.editAdmissionType = admissionsType;
				this.editMode = "admissions";
				this.modalTitle = "Discharge Patient";
				this.subHeaderText = " " + this.patient.firstName + " " + this.patient.lastName;
				this.editModal.open("xs");
			});
		}
	}

	removePreAdmit() {
		this.modalTitle = "Confirm Delete";
		this.subHeaderText = " " + this.patient.firstName + " " + this.patient.lastName;
		this.confirmDeletePreAdmit.open("xs");
	}

	confirmDelete() {
		this._patientService.removePreAdmitPatient(this.patientId).subscribe((result: boolean) => {
			this.confirmDeletePreAdmit.dismiss();
			this._router.navigate(["patient-care"]);
		});
	}

	cancelDelete() {
		this.modalTitle = "";
		this.subHeaderText = "";
		this.confirmDeletePreAdmit.dismiss();
	}

	newOrderIntake() {
		this._router.navigate(["../order-intake"], { relativeTo: this._activeRoute });
	}

	newProfileMedication() {
		if (this.dialogueModal.isOpen) this.dialogueModal.close();
		this.createModal.open("lg");
	}

	newNonDispensingOrder() {
		this._router.navigate(["../non-dispensing-order"], { relativeTo: this._activeRoute });
	}

	orderSuccess(rxOrderSuccessModel: RxOrderSuccessModel) {
		if (this.tabContent === "rx") {
			this.rxTab.loadMedications();
		}
		this.rxOrderSuccessModel = rxOrderSuccessModel;
		this.createModal.dismiss();
		this.dialogueModal.open("xs");
		this.dialogueMode = "orderConfirmation";
		this.modalTitle = "Profile Order Confirmation";
	}

	viewPatientDispenseHistory() {
		this.dispenseHistory.open("lg");
		this.showingDispenseHistory = true;
		this.modalTitle = "Patient Dispense History";
		this.subHeaderText = " for " + this.patient.firstName + " " + this.patient.lastName;
	}

	closeDispenseHistory() {
		this.showingDispenseHistory = false;
		this.dispenseHistory.dismiss();
	}

	refillRxGroup() {
		if (this.isLoading) return;
		this.quickRefill.open("md");
		this.modalTitle = "Quick Refill";
		this.subHeaderText = " for " + this.patient.firstName + " " + this.patient.lastName;
	}

	quickRefillClose() {
		this.quickRefillForm.reset();
	}

	editRefill() {
		this.confirmQuickRefill.dismiss();
		this.quickRefill.open("md");
		this.modalTitle = "Quick Refill";
		this.subHeaderText = " for " + this.patient.firstName + " " + this.patient.lastName;
	}

	confirmRefill() {
		this.quickRefill.dismiss();
		this.confirmQuickRefill.open("md");
		this.modalTitle = "Confirm Quick Refill";
		this.subHeaderText = " for " + this.patient.firstName + " " + this.patient.lastName;
	}

	toggleMoreActions() {
		if (!this.moreActions) {
			this.moreActions = true;
		} else {
			this.moreActions = false;
		}
	}

	priorAuthRequired() {
		this.dialogueModal.open("xs");
		this.dialogueMode = "authRequired";
		this.modalTitle = "Drug Coverage Override Required";
	}

	authorizeOverride() {
		this.dialogueModal.open("xs");
		this.dialogueMode = "authOverride";
		this.modalTitle = "Drug Coverage Override Required";
	}

	viewPatientClinicalNotes() {
		this.clinicalTools.open("lg");
		this.modalTitle = "Patient Clinical Notes";
		this.subHeaderText = " for " + this.patient.firstName + " " + this.patient.lastName;
		this.clinicalToolsType = "clinicalNotes";
	}

	viewPatientDrugScreening() {
		this.clinicalTools.open("lg");
		this.modalTitle = "Patient Drug Screening";
		this.subHeaderText = " for " + this.patient.firstName + " " + this.patient.lastName;
		this.clinicalToolsType = "drugScreening";
	}

	viewPatientAllergyScreening() {
		this.clinicalTools.open("lg");
		this.modalTitle = "Patient Allergy Screening";
		this.subHeaderText = " for " + this.patient.firstName + " " + this.patient.lastName;
		this.clinicalToolsType = "allergyScreening";
	}

	closeClinicalTools() {
		this.clinicalTools.dismiss();
	}

	createModalClosed() {
		this.createMode = "";
		this.profileMedStep = "";
		this.appService.isFullScreenSearchOpen = false;
	}

	dismissCreateModal() {
		this.createModal.dismiss();
		this.appService.isFullScreenSearchOpen = false;
	}

	clinicalToolsModalClose() {
		this.clinicalToolsType = "";
	}

	confirmCancelChangesModal() {
		this.confirmCancelChanges.open("xs");
	}

	confirmCancel() {
		this.confirmCancelChanges.dismiss();
		this.closeEditModal();
	}

	newErxOrder() {
		this._erxStore.resetSelectedErx();
		this._erxStore.mode.set("new");
		this._router.navigate(["../new-erx-order"], { relativeTo: this._activeRoute });
	}
}
