import {Injectable} from "@angular/core";
import {HttpService} from "./http.service";

@Injectable()
export class VimeoService {
  private _getVideosUrl: string = 'api/vimeo/getvideos';
  private _getEmbeddedHtmlUrl: string = 'api/vimeo/getembeddedhtml?videoId=';

  constructor(private _http: HttpService) {
  }

  getVideos() {
    return this._http.get(this._getVideosUrl);
  }

  getEmbeddedHtml(videoId: string) {
    return this._http.get(this._getEmbeddedHtmlUrl + videoId);
  }
}
