/**
 * Created by John on 2016-03-02.
 */
import { environment } from "../../environments/environment";
import { throwError as observableThrowError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class AppConfig {
	public year: number = new Date().getFullYear();

	public Urls: any = {
		baseApiUrl: ""
	};

	public maxDashboardMonths: number;
	public refreshIntervalMinutes: number;

	public SessionKeys: any = {
		authentication: {
			username: "auth.username",
			accessToken: "auth.accessToken",
			refreshToken: "auth.refreshToken",
			expireDate: "auth.expireDate",
			lastActivity: "auth.lastActivity",
			roles: "auth.roles",
			permissions: "auth.permissions",
			requiresPasswordChange: "auth.requiresPasswordChange",
			isInternalUser: "auth.isInternalUser"
		},
		preferences: {
			showDosespotLogin: "pref.dosespot.login"
		}
	};

	load() {
		return new Promise((resolve, reject) => {
			let url = "assets/config/config.local.json";

			if (environment.production) {
				url = "assets/config/config.deploy.json";
			}

			this.http
				.get(url)
				.pipe(
					catchError((error: any) => {
						console.log("Configuration file could not be read");
						return observableThrowError(error.error || "Server error");
					})
				)
				.subscribe((envResponse: any) => {
					this.Urls.baseApiUrl = envResponse.apiUrl;
					this.maxDashboardMonths = envResponse.maxDashboardMonths ?? 12;
					this.refreshIntervalMinutes = envResponse.refreshIntervalMinutes ?? 5;

					resolve(true);
				});
		});
	}

	constructor(private http: HttpClient) {}
}
