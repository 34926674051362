export class PatientMedicationModel {
  NDC: string;
  OrderNumber: number;
  RxNumber: number;
  CCID: string;
  GPI: string;
  DrugName: string;
  Directions: string;
  StartDate: string;
  WrittenDate: string;
  ExpireDate: string;
  DiscontinueDate: string;
  LastDispenseDate: string;
  TotalQuantityWritten: number;
  TotalQuantityRemaining: number;
  Quantity: number;
  RefillsRemaining: number;
  PrescriberName: string;
  IsEarlyRefill: boolean = false;
  IsActive: boolean;
  IsDispensing: boolean;
  IsCovered: boolean;
  IsScreeningOrder: boolean;
  IsPRN: boolean;
  IsCompound: boolean;
  IsOpenBatch: boolean;
  AllowRefill: boolean;
  DeaClass: number;
  PharmacyNotes: string;
  DispenseUnitId?: number;
  ICD10: string;

  // application only properties
  IsSelected: boolean = false;
  erxType: string = "medication"; // "medication", "compound", "supply", "kit"
  AgentName?: string;
  orderStatus?: string;
  children?: PatientMedicationModel[] = [];
  allowSubstitutions?: boolean = false;
}
