import { distinctUntilChanged, debounceTime } from "rxjs/operators";
import { Component, ViewChild, Output, EventEmitter, OnInit } from "@angular/core";
import { ModalComponent } from "../../shared/components/modal/modal.component";
import { OrganizationProfile } from "../../shared/models/organization-profile.model";
import { CustomerService } from "../..//shared/services/customer.service";
import { OrganizationSummaryModel } from "../../shared/models/organization-summary.model";
import { FormControl } from "@angular/forms";
import { StatusMessageService } from "../..//shared/components/status-message/status-message.service";
import { Router, Route, ActivatedRoute } from "@angular/router";

@Component({
	selector: "organizations-admin",
	templateUrl: "./organizations-admin.template.html",
	styleUrls: ["../administration-tabs.scss", "./organizations-admin.scss"]
})
export class OrganizationsAdminTab implements OnInit {
	organizationProfile: OrganizationProfile = new OrganizationProfile();
	viewMode: string = "all";
	editMode: string;
	createMode: string;
	modalTitle: string;
	subHeaderText: string;

	organizationSearchText: FormControl = new FormControl();
	organizationList: OrganizationSummaryModel[] = [];
	filteredOrganizations: OrganizationSummaryModel[] = [];
	pagedOrganizations: OrganizationSummaryModel[] = [];
	editOrg: OrganizationProfile = null;
	selectedOrganizationId: number;

	// paging properties
	maxDisplayItems: number = 250;
	startCount: number = 0;
	endCount: number = 0;
	currentPage: number = 0;
	pageSize: number = 250;
	pageCount: number = 1;
	pageButtons: number[] = [];

	@ViewChild("editModal") editModal: ModalComponent;

	@ViewChild("createModal") createModal: ModalComponent;

	constructor(private _customerService: CustomerService, private _statusMessageService: StatusMessageService, private _router: Router, private _route: ActivatedRoute) {}

	ngOnInit() {
		this._customerService.getOrganizationList(true).subscribe((organizations: OrganizationSummaryModel[]) => {
			organizations = organizations.sort((a, b) => {
				if (a.Name < b.Name) return -1;

				if (a.Name > b.Name) return 1;

				return 0;
			});
			this.organizationList = organizations;
			this.filteredOrganizations = organizations;
			this.pageOrganizations(0);
		});

		this.organizationSearchText.valueChanges.pipe(debounceTime(400), distinctUntilChanged()).subscribe((term: string) => {
			this.pageSize = 50;
			this.filterOrganizations(term);
			this.pageOrganizations(0);
		});
	}

	updateOrganizationList() {
		this._customerService.getOrganizationList(false).subscribe((organizations: OrganizationSummaryModel[]) => {
			this.organizationList = organizations;
			this.filterOrganizations(this.organizationSearchText.value || "");
			this.pageOrganizations(0);
		});
	}

	updateOrganizationSuccess(organizationName: string) {
		if (this.createMode) {
			this.createModal.dismiss();
			this._statusMessageService.changeStatusMessage("success", organizationName + " has been added.");
		} else if (this.editMode) {
			this.editModal.dismiss();
			this._statusMessageService.changeStatusMessage("success", organizationName + " has been updated.");
		}

		this.createMode = "";
		this.editMode = "";

		this.updateOrganizationList();
	}

	addOrganization() {
		this.editOrg = this.organizationProfile;
		this.createModal.open("md");
		this.createMode = "organization";
		this.modalTitle = "New Organization";
	}

	editOrganization(organizationId: number) {
		this._customerService.getOrganizationProfile(organizationId).subscribe((profile: OrganizationProfile) => {
			this.editOrg = profile;
			this.editModal.open("md");
			this.editMode = "organization";
			this.modalTitle = "Edit Organization";
		});
	}

	closeEditModal() {
		this.editMode = null;
		this.editModal.dismiss();
	}

	viewProfile(organizationId: number) {
		this._router.navigate(["../organizations/" + organizationId], { relativeTo: this._route });
	}

	filterOrganizations(term: string) {
		let filteredOrgs: OrganizationSummaryModel[] = [];
		if (term) {
			filteredOrgs = this.organizationList.filter((x: OrganizationSummaryModel) => {
				return x.Name.toLowerCase().indexOf(term.toLowerCase()) > -1 || !term;
			});
		} else {
			filteredOrgs = this.organizationList;
		}

		filteredOrgs.sort((a, b) => {
			if (a.Name < b.Name) return -1;

			if (a.Name > b.Name) return 1;

			return 0;
		});

		this.filteredOrganizations = filteredOrgs;
	}

	clearSearch() {
		this.organizationSearchText.setValue("");
	}

	pageOrganizations(currentPage: number) {
		// limit the total number of organizations displayed to 250 to save memory in mobile apps.
		this.maxDisplayItems = this.filteredOrganizations.length;
		this.pageSize = this.filteredOrganizations.length;
		this.pageButtons = [];
		this.currentPage = currentPage;
		let start: number = this.pageSize * this.currentPage;
		let end: number = start + this.pageSize;
		this.pagedOrganizations = this.filteredOrganizations.slice(start, end);
		this.startCount = start + 1;
		this.endCount = end > this.maxDisplayItems ? this.maxDisplayItems : end;
		this.pageCount = this.maxDisplayItems ? Math.ceil(this.maxDisplayItems / this.pageSize) : 1;
	}

	showMore() {
		this.pageSize = this.pageSize + 250;
		this.pageOrganizations(0);
	}
}
