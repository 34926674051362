import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Pharmacy } from 'src/app/ocp/shared/models/pharmacy.model';

@Component({
  selector: 'ocp-pharmacy-summary',
  templateUrl: './pharmacy-summary.component.html',
  styleUrls: ['./pharmacy-summary.component.scss']
})
export class PharmacySummaryComponent implements OnInit {
  @Input() pharmacy: Pharmacy;
  @Input() isSelected: boolean;

  @Output() onPharmacySelect: EventEmitter<Pharmacy> = new EventEmitter<Pharmacy>();

  constructor() { }

  ngOnInit(): void {
  }

  pharmacySelected(selected: Pharmacy) {
    this.onPharmacySelect.emit(selected);
  }
}
