/**
 * Created by dan on 12/14/2016.
 */
import { Component, Input, forwardRef } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
	selector: "radio-set",
	templateUrl: "./radio-set.template.html",
	styleUrls: ["./radio-set.scss"],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => RadioSetComponent),
			multi: true
		}
	]
})
export class RadioSetComponent implements ControlValueAccessor {
	@Input() labelText: string;
	@Input() name: string;
	@Input() isRequired: boolean = false;
	@Input() optionA: any;
	@Input() optionB: any;
	@Input() nameValueOptions: any[] = [];

	@Input() invalidAnswer: boolean = false;

	value: any;

	propagateChange = (_: any) => {};

	valueChanged(value: any) {
		this.value = value;
		this.propagateChange(this.value);
	}

	writeValue(obj: any) {
		if (obj !== undefined) this.value = obj;
	}

	registerOnChange(fn: any) {
		this.propagateChange = fn;
	}

	registerOnTouched(fn: any) {} //Not currently needed
}
