import {NgModule} from "@angular/core";

import {SharedModule} from "../shared/shared.module";
import {ClinicalResources} from "./clinical-resources.component";
import {ClinicalResourcesRouting} from "./clinical-resources.routes";
import {WebinarsTab} from "./components/tabs/webinars.component";
import {DocumentationTab} from "./components/tabs/documentation.component";
import {NewslettersTab} from "./components/tabs/newsletters.component";
import {DocumentThumbnail} from "./components/tabs/document-thumbnail.component";
import {SymptomGuideTab} from "./components/tabs/symptom-guide.component";
import {WebinarThumbnail} from "./components/tabs/webinar-thumbnail.component";
import {VideoModal} from "./components/tabs/video-modal/video-modal.component";
import { FormulariesTab } from "./components/tabs/formularies.component";
import { MatTabsModule } from "@angular/material/tabs";

@NgModule({
  imports:[
    SharedModule,
    ClinicalResourcesRouting,
    MatTabsModule
  ],
  declarations:[
    ClinicalResources,
    WebinarsTab,
    DocumentationTab,
    NewslettersTab,
    DocumentThumbnail,
    SymptomGuideTab,
    VideoModal,
    WebinarThumbnail,
    FormulariesTab
  ],
  providers:[],
})
export class ClinicalResourcesModule{}
