import {Component, EventEmitter, Input, Output} from '@angular/core';
import {CustomerPermissionModel} from "../../../../models/customer-permission.model";

@Component({
  selector: 'customer-permission',
  templateUrl: './customer-permission.template.html',
  styleUrls: ['./customer-permission.scss']
})

export class CustomerPermissionComponent {
  @Input() customerPermission: CustomerPermissionModel;

  @Output() permissionChanged: EventEmitter<CustomerPermissionModel> = new EventEmitter<CustomerPermissionModel>();

  constructor() {
  }

  togglePermission() {
    this.customerPermission.Permitted = !this.customerPermission.Permitted;
    this.checkedChanged();
  }

  checkedChanged() {
    this.permissionChanged.emit(this.customerPermission);
  }
}
