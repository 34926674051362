import { Component, Output, EventEmitter, ViewChild, Input, OnInit } from "@angular/core";
import { ISearchService } from "../../../../../shared/components/patient-search/search.interface";
import { RxPrescribersSearchService } from "../../../../../shared/services/rx-prescribers-search.service";
import { PrescriberSearchResultModel } from "../../../../../shared/models/prescriber-search-result.model";
import { AdvancedSearch } from "src/app/ocp/shared/components/advanced-search/advanced-search.component";
import * as _ from "lodash";

@Component({
	selector: "search-physicians",
	providers: [{ provide: ISearchService, useClass: RxPrescribersSearchService }],
	templateUrl: "./search-physicians.template.html",
	styleUrls: ["./search-physicians.scss"]
})
export class SearchPhysiciansComponent implements OnInit {
	@Input() initialText: string;

	@Output() searchCleared: EventEmitter<any> = new EventEmitter<any>();
	@Output() onItemSelect: EventEmitter<PrescriberSearchResultModel> = new EventEmitter<PrescriberSearchResultModel>();

	city: string;
	state: string;
	firstName: string;
	lastName: string;
	tableHeaderLabels: string[] = ["Name", "Address", "NPI", "DEA", "Phone"];

	@ViewChild("searchControl") private searchControl: AdvancedSearch;

	get filtering(): boolean {
		return this.numFiltersApplied > 0;
	}

	get numFiltersApplied(): number {
		return [this.city, this.state, this.firstName, this.lastName].reduce((acc, val) => {
			return val && val.length > 0 ? ++acc : acc;
		}, 0);
	}

	constructor() {}

	ngOnInit() {
		if (this.initialText) {
			this.setText(this.initialText);
		}
	}

	setText(text: string) {
		this.searchControl.searchText.reset(text, { emitEvent: false });
	}

	clear() {
		this.searchControl.clearSearch();
	}

	filterResults() {
		this.searchControl.filteredAutoCompleteResults = _.filter(this.searchControl.autocompleteResults, r => {
			return (
				(!this.city || (r.City && r.City.toLowerCase().indexOf(this.city.toLowerCase()) !== -1)) &&
				(!this.firstName || (r.FirstName && r.FirstName.toLowerCase().indexOf(this.firstName.toLowerCase()) !== -1)) &&
				(!this.lastName || (r.LastName && r.LastName.toLowerCase().indexOf(this.lastName.toLowerCase()) !== -1)) &&
				(!this.state || this.state === "" || (r.State && r.State.toLowerCase() === this.state.toLowerCase()))
			);
		});
	}

	clearFilters(parentSearchCleared: boolean = false) {
		this.city = this.state = this.firstName = this.lastName = null;
		if (parentSearchCleared) {
			this.searchCleared.emit();
		}
		this.filterResults();
	}

	states: string[] = [
		"",
		"AL",
		"AK",
		"AS",
		"AZ",
		"AR",
		"CA",
		"CO",
		"CT",
		"DE",
		"DC",
		"FM",
		"FL",
		"GA",
		"GU",
		"HI",
		"ID",
		"IL",
		"IN",
		"IA",
		"KS",
		"KY",
		"LA",
		"ME",
		"MH",
		"MD",
		"MA",
		"MI",
		"MN",
		"MS",
		"MO",
		"MT",
		"NE",
		"NV",
		"NH",
		"NJ",
		"NM",
		"NY",
		"NC",
		"ND",
		"MP",
		"OH",
		"OK",
		"OR",
		"PW",
		"PA",
		"PR",
		"RI",
		"SC",
		"SD",
		"TN",
		"TX",
		"UT",
		"VT",
		"VI",
		"VA",
		"WA",
		"WV",
		"WI",
		"WY"
	];
}
