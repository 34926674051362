import { Routes, RouterModule } from "@angular/router";

import { Administration } from "./administration.component";
import { OrganizationsAdminTab } from "./organizations-admin/organizations-admin.component";
import { UsersTab } from "../partner-profile/tabs/users.component";
import { RolesTab } from "../partner-profile/tabs/roles.component";
import { FileManagerTab } from "./file-manager/file-manager.component";
import { OrganizationProfileComponent } from "../shared/components/organization-profile/organization-profile.component";
import { CustomerProfileComponent } from "../shared/components/customer-profile/customer-profile.component";
import { SystemAlertsAdminTab } from "./system-alerts/system-alerts.component";
import { SystemAlertGuard } from "../shared/gaurds/system-alert.guard";
import { PharmaciesAdminTab } from "./pharmacies-admin/pharmacies-admin.component";
import { ReportsAdminTab } from "./reports-admin/reports-admin.component";
import { AdminPharmaciesGuard } from "../shared/gaurds/admin-pharmacies.guard";
import { AdminReportsGuard } from "../shared/gaurds/admin-reports.guard";
import { ErxNewOrderComponent } from "../shared/components/erx-components/erx-new-order/erx-new-order.component";
import { CreateEditSubscriptionComponent } from "../shared/components/report-subscriptions/create-edit-subscription/create-edit-subscription.component";

export const AdministrationRoutes: Routes = [
	{
		path: "administration",
		redirectTo: "administration/organizations",
		pathMatch: "full"
	},
	{
		path: "administration",
		component: Administration,
		children: [
			{ path: "organizations", component: OrganizationsAdminTab },
			{ path: "users", component: UsersTab },
			{ path: "roles", component: RolesTab },
			{ path: "files", component: FileManagerTab },
			{ path: "system-alerts", component: SystemAlertsAdminTab, canActivate: [SystemAlertGuard] },
			{ path: "pharmacies", component: PharmaciesAdminTab, canActivate: [AdminPharmaciesGuard] },
			{ path: "reports", component: ReportsAdminTab, canActivate: [AdminReportsGuard] }
		]
	},
	{ path: "administration/organizations/:id", component: OrganizationProfileComponent },
	{ path: "administration/organizations/:id/partners/:partnerId", component: CustomerProfileComponent },
	{ path: "administration/organizations/:id/partners/:partnerId/preferred-erx", component: ErxNewOrderComponent, data: {type: "preferred-erx"}},
	{ path: "administration/organizations/:id/create-subscription", component: CreateEditSubscriptionComponent },
	{ path: "administration/organizations/:id/edit-subscription", component: CreateEditSubscriptionComponent }
];

export const AdministrationRouting = RouterModule.forChild(AdministrationRoutes);
