import { LineItemResult } from "./line-item-result.model";

export class NewOrderResult {
	results: LineItemResult[] = [];

	deserialize(input: any): NewOrderResult {
		this.results = input.Results.map((result: any) => new LineItemResult().deserialize(result));
		return this;
	}
}
