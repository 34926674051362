export class DispenseUnitType {
    id: number;
    name: string;

    value: number;
    text: string;

    deserialize(o): DispenseUnitType {
        let obj = Object.assign(this, o);

        obj.value = obj.id;
        obj.text= obj.name;

        return obj;
    }
}