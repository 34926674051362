import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { Permissions } from "../../enums/permissions";
import { UserService } from "../../services/user.service";
import { StatusMessageService } from "../status-message/status-message.service";
import { AuthService } from "../../services/auth.service";
import { UserListItemModel } from "../../models/user-list-item.model";
import { ApiResult } from "../../models/api-result.model";

@Component({
	selector: "user-summary",
	templateUrl: "./user-summary.template.html",
	styleUrls: ["./user-summary.scss"]
})
export class UserSummary implements OnInit {
	showDetails: boolean = false;
	popoverContent: string;

	permissions: any = Permissions;

	@Input() customerId: number;
	@Input() user: UserListItemModel;
	@Input() isInternal: boolean = false;
	@Input() viewingAllUsers: boolean = false;
	@Input() showEditRoles: boolean;
	@Input() showOrgSettings: boolean = false;

	get editUserPermissions(): string[] {
		return [Permissions.editExternalUserProfile, Permissions.administration];
	}

	get canLockUsers(): any {
		let hasPermission = this._authService.hasPermission(Permissions.lockUsers) || this._authService.hasPermission(Permissions.administration);

		return hasPermission && this._authService.getUserName().toLowerCase() !== this.user.LoginId.toLowerCase();
	}

	get canDeleteUser(): boolean {
		let hasPermission = this._authService.hasPermission(Permissions.editExternalUserProfile) || this._authService.hasPermission(Permissions.administration);

		return hasPermission && this._authService.getUserName().toLowerCase() !== this.user.LoginId.toLowerCase() && !this.isInternal;
	}

	@Output() statusUpdated: EventEmitter<string> = new EventEmitter<string>();
	@Output() editUser: EventEmitter<number> = new EventEmitter<number>();
	@Output() editUserRole: EventEmitter<UserListItemModel> = new EventEmitter<UserListItemModel>();
	@Output() editUserReportPermissions: EventEmitter<UserListItemModel> = new EventEmitter<UserListItemModel>();
	@Output() editLoginId: EventEmitter<UserListItemModel> = new EventEmitter<UserListItemModel>();
	@Output() changeUserLock: EventEmitter<UserListItemModel> = new EventEmitter<UserListItemModel>();
	@Output() changeUserOrg: EventEmitter<UserListItemModel> = new EventEmitter<UserListItemModel>();
	@Output() editOrgSettings: EventEmitter<UserListItemModel> = new EventEmitter<UserListItemModel>();

	constructor(private _userService: UserService, private _authService: AuthService, private _statusMessageService: StatusMessageService) {}

	ngOnInit() {
		if (this.user.IsLocked) {
			if (!this.canLockUsers) {
				this.popoverContent = "User is locked.";
			} else {
				this.popoverContent = "Click to Unlock this User";
			}
		} else if (this.user.IsLocked === false) {
			if (this.canLockUsers) {
				this.popoverContent = "Click to Lock this User";
			}
		}
	}

	deleteUser() {
		if (this.canDeleteUser) {
			this._userService.setUserCustomer(this.user.UserId, this.customerId, false).subscribe((results: ApiResult) => {
				if (results.Success) {
					this._statusMessageService.changeStatusMessage("success", 'Login for "' + this.user.LastName + ", " + this.user.FirstName + '" has been removed.');
					this.statusUpdated.emit();
				} else {
					this._statusMessageService.changeStatusMessage("error", results.PublicMessage);
				}
			});
		}
	}

	showMore() {
		if (!this.showDetails) {
			this.showDetails = true;
		} else {
			this.showDetails = false;
		}
	}

	editRolesClick() {
		this.editUserRole.emit(this.user);
	}

	editOrgClick() {
		this.editOrgSettings.emit(this.user);
	}

	editReportPermissions() {
		this.editUserReportPermissions.emit(this.user);
	}

	transferUser(user) {
		if (!this.canMoveUser()) {
			return;
		} else {
			this.changeUserOrg.emit(user);
		}
	}

	canMoveUser(): boolean {
		let hasPermission = this._authService.hasPermission(Permissions.editExternalUserProfile) || this._authService.hasPermission(Permissions.administration);

		return hasPermission && this._authService.getUserName().toLowerCase() !== this.user.LoginId.toLowerCase() && !this.isInternal;
	}
}
