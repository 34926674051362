
import { takeUntil } from 'rxjs/operators';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { PatientService } from "../../../shared/services/patient.service";
import { TierReportModel } from "../../models/TierReport.model";
import { ActivatedRoute, Router } from "@angular/router";
import { PatientCareStore } from "../../services/patient-care.store";
import { PatientInfoModel } from "../../../shared/models/patient.model";
import { Subject } from "rxjs";

@Component({
  selector: 'tier-report',
  templateUrl: './tier-report.template.html',
  styleUrls: ["./tier-report.scss"]
})

export class TierReportComponent implements OnInit, OnDestroy {
  loading: boolean = false;
  selectedRow: number;
  reportData: TierReportModel[] = [];

  patientId: number;
  firstName: string;
  lastName: string;

  private destroyed: Subject<any> = new Subject<any>();

  constructor(
    private _patientService: PatientService,
    private _router: Router,
    private _route: ActivatedRoute,
    private _store: PatientCareStore) {
  }

  ngOnInit() {
    this.loading = true;

    this._store.Patient.pipe(takeUntil(this.destroyed)).subscribe((patient: PatientInfoModel) => {
      if (!patient.patientId) return;

      this.patientId = patient.patientId;
      this.firstName = patient.firstName;
      this.lastName = patient.lastName;

      this._patientService.getCostSavingAlternativesReport(this.patientId).subscribe((options: TierReportModel[]) => {
        this.loading = false;
        this.reportData = options;
      });
    });
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.unsubscribe();
  }

  closePage() {
    this._router.navigate(["../detail"], { relativeTo: this._route });
  }

  rowClick(index) {
    this.selectedRow = index;
  }
}
