export class DateOnly {
	private _year: number;
	get year(): number {
		return this._year;
	}

	private _month: number;
	get month(): number {
		return this._month;
	}

	private _day: number;
	get day(): number {
		return this._day;
	}

	constructor(year?: number, month?: number, day?: number) {
		let dt = new Date();

		if (year !== undefined) {
			this._year = year;
		} else {
			this._year = dt.getFullYear();
		}

		if (month !== undefined) {
			this._month = month;
		} else {
			this._month = dt.getMonth() + 1;
		}

		if (day !== undefined) {
			this._day = day;
		} else {
			this._day = dt.getDate();
		}
	}

	compareTo(other: DateOnly): number {
		if (this.year !== other.year) {
			return this.year - other.year;
		}
		if (this.month !== other.month) {
			return this.month - other.month;
		}
		return this.day - other.day;
	}

	toString(): string {
		const month = String(this.month).padStart(2, "0");
		const day = String(this.day).padStart(2, "0");

		return `${this._year}-${month}-${day}`;
	}

	isBefore(other: DateOnly): boolean {
		return this.compareTo(other) < 0;
	}

	isAfter(other: DateOnly): boolean {
		return this.compareTo(other) > 0;
	}

	setDate(dt: Date): void {
		this._year = dt.getFullYear();
		this._month = dt.getMonth() + 1;
		this._day = dt.getDate();
	}
}
