import { Component, OnInit, Input } from "@angular/core";
import { DataTableModel } from "../../data-table/data-table.model";
import { SortModel } from "../../data-table/sort.model";
import { DataTableColumnModel } from "../../data-table/columns.model";
import { DashboardStore } from "../../../services/dashboard.store";
import { DashboardDetailRow, DashboardDetailModel } from "../../../models/dashboard.models";
import { UserService } from "../../../services/user.service";

@Component({
	selector: "cppd-details",
	templateUrl: "./cppd-details.template.html",
	styleUrls: ["./cppd-details.scss"]
})
export class CppdDetailsComponent implements OnInit {
	cppdItems: DataTableModel = new DataTableModel([], []);

	private _data: DashboardDetailModel;
	@Input()
	get data(): DashboardDetailModel {
		return this._data;
	}
	set data(d: DashboardDetailModel) {
		this._data = d;
		this.initTable();
	}
	@Input() isOrganizationDash: boolean = false;

	constructor(public _dashStore: DashboardStore, public _userService: UserService) {}

	ngOnInit() {}

	initTable() {
		if (!this.data || !this.data.rows) return;

		let defaultSorting: SortModel[] = [];

		let columns: DataTableColumnModel[] = [];
		columns.push(new DataTableColumnModel("dateFilled", "Fill Date", "date-column", true));
		columns.push(new DataTableColumnModel("drugLabel", "Drug Label", "", true));
		columns.push(new DataTableColumnModel("quantity", "Qty", "right-aligned", true));
		columns.push(new DataTableColumnModel("deaClass", "DEA Class", "", true));
		columns.push(new DataTableColumnModel("prescriberName", "Prescriber", "", true));
		if (this.isOrganizationDash) {
			columns.push(new DataTableColumnModel("facility", "Facility", "", true));
		}
		columns.push(new DataTableColumnModel("amountPaid", "Paid", "right-aligned", true));

		this.cppdItems = new DataTableModel(defaultSorting, columns);
		this.cppdItems.populate(this.data.rows);
	}
}
