import { Injectable, EventEmitter } from "@angular/core";
import { StatusMessageService } from "../components/status-message/status-message.service";
import { StoreObject } from "../models/store-object.model";
import { Pharmacy } from "../models/pharmacy.model";
import { RxOrderModel } from "../models/rx-order.model";
import { HttpService } from "./http.service";
import { UserPreference } from "../models/erx/user-preference.model";
import { DosespotItem } from "../models/erx/dosespot-item.model";
import { DosespotPharmacy } from "../models/erx/dosespot-pharmacy.model";
import { LineItemSummary } from "../models/erx/line-item-summary.model";
import { LineItem } from "../models/erx/line-item.model";
import { UserPreferenceSummary } from "../models/erx/user-preference-summary.model";
import { MedicationSearchResultModel } from "../models/medication-search-result.model";
import { FacilityPreferenceSummary } from "../models/erx/facility-preference-summary.model";
import { FacilityPreference } from "../models/erx/facility-preference.model";
import { OrderPreferenceSummary } from "../models/erx/order-preference-summary.model";
import { OrderPreferenceDetail } from "../models/erx/order-preference-detail.model";
import { PharmacySearchFiltersModel } from "../models/pharmacy-search-filters.model";
import { PharmacySearchResult } from "../models/pharmacy-search-result.model";
import { NewOrder } from "../models/erx/new-order.model";
import { eRxSummary } from "../models/erx/erx-summary.model";
import { PrescriberSearchResultModel } from "../models/prescriber-search-result.model";
import { StatusEntry } from "../models/erx/status-entry.model";
import { OrderBuilder } from "../models/erx/order.builder";
import { ErxSearchModel } from "../models/erx/erx-search.model";
import { NewOrderResult } from "../models/erx/results/new-order-result.model";
import { PatientUpdateResult } from "../models/erx/results/patient-update-result.model";

@Injectable()
export class ErxOrderStore {
	public readonly erxOrders: StoreObject<RxOrderModel[]> = new StoreObject<RxOrderModel[]>([]);
	public readonly defaultPharmacy: StoreObject<PharmacySearchResult> = new StoreObject<PharmacySearchResult>(null);

	public readonly userPreferredErx: StoreObject<RxOrderModel[]> = new StoreObject<RxOrderModel[]>([]);
	public readonly customerPreferredErx: StoreObject<RxOrderModel[]> = new StoreObject<RxOrderModel[]>([]);
	public readonly orderPreferredErx: StoreObject<RxOrderModel[]> = new StoreObject<RxOrderModel[]>([]);

	public readonly mode: StoreObject<"new" | "edit" | "view"> = new StoreObject<"new" | "edit" | "view">(null);
	public readonly selectedErx: StoreObject<RxOrderModel[]> = new StoreObject<RxOrderModel[]>([]);
	public readonly scrollToFormStart: EventEmitter<any> = new EventEmitter<any>();
	public readonly erxStatusHistory: StoreObject<StatusEntry[]> = new StoreObject<StatusEntry[]>([]);

	public readonly units: StoreObject<any[]> = new StoreObject<any[]>([]);

	public readonly pharmacySearchResults: StoreObject<PharmacySearchResult[]> = new StoreObject<PharmacySearchResult[]>([]);
	public readonly preferredPharmacies: StoreObject<PharmacySearchResult[]> = new StoreObject<PharmacySearchResult[]>([]);
	public pharmacySearchFilter: StoreObject<PharmacySearchFiltersModel> = new StoreObject<PharmacySearchFiltersModel>(new PharmacySearchFiltersModel());

	public readonly isPrescriber: StoreObject<boolean> = new StoreObject<boolean>(false);
	public readonly isPrescribingAgent: StoreObject<boolean> = new StoreObject<boolean>(false);

	public readonly searchModels: StoreObject<ErxSearchModel[]> = new StoreObject<ErxSearchModel[]>([]);

	constructor(private _status: StatusMessageService, private _http: HttpService) {}

	getUnits() {
		let url = "api/dosespot/getunits";

		if (this._customerId && this._customerId > 0) {
			url += "?customerId=" + this._customerId;
		}

		this._http.get(url).subscribe((result: any) => {
			let items = result.Items.filter(i => i.IsActive).sort((a, b) => a.Singular.localeCompare(b.Singular));

			this.units.set(items);
		});
	}

	private _customerId?: number;
	setCustomer(id: number) {
		this._customerId = id;
	}
	clearCustomer() {
		this._customerId = null;
	}

	getTooltipContent(erxType: string, preferredType?: string) {
		let rxType: string;
		let preferred: string;

		switch (erxType) {
			case "medication":
				rxType = "Medication";
				break;
			case "compound":
				rxType = "Compound";
				break;
			case "supply":
				rxType = "Supply";
				break;
			case "kit":
				rxType = "eRx Kit";
				break;
			default:
				rxType = "Medication";
		}

		if (preferredType) {
			if (preferredType === "userPreferred") {
				preferred = "My Preferred";
			} else if (preferredType === "customerPreferred") {
				preferred = "Hospice Preferred";
			}
			return preferred + " " + rxType;
		}

		return rxType;
	}

	getErxIcon(erxType: string) {
		switch (erxType) {
			case "medication":
				return "medication";
			case "compound":
				return "medication_liquid";
			case "supply":
				return "vaccines";
			case "kit":
				return "medical_services";
			default:
				return "medication";
		}
	}

	getStatusCss(orderStatus: string) {
		switch (orderStatus.toLowerCase()) {
			case "error":
			case "epcs error":
			case "deleted":
				return "warning";
			case "pharmacy verified":
			case "printed":
				return "success";
			case "sending":
			case "erx sent":
			case "faxed":
			case "requested":
			case "edited":
			case "epcs signed":
			case "ready to sign":
				return "informational";
			default:
				return "neutral";
		}
	}

	resetSelectedErx() {
		this.selectedErx.set([]);
	}

	searchMedications(name: string): Promise<any[]> {
		return new Promise<any[]>(resolve => {
			let url = "/api/dosespot/medicationsearch?name=" + encodeURIComponent(name);

			if (this._customerId && this._customerId > 0) {
				url += "&customerId=" + this._customerId;
			}

			this._http.get(url).subscribe((result: any) => {
				resolve(result.Items);
			});
		});
	}

	searchSupply(name: string): Promise<any[]> {
		return new Promise<any[]>(resolve => {
			let url = "/api/dosespot/supplysearch?name=" + encodeURIComponent(name);

			if (this._customerId && this._customerId > 0) {
				url += "&customerId=" + this._customerId;
			}

			this._http.get(url).subscribe((result: any) => {
				resolve(result.Items);
			});
		});
	}

	searchCompounds(name: string): Promise<any[]> {
		return new Promise<any[]>(resolve => {
			let url = "api/dosespot/compoundsearch?name=" + encodeURIComponent(name);

			if (this._customerId && this._customerId > 0) {
				url += "&customerId=" + this._customerId;
			}

			this._http.get(url).subscribe((result: any) => {
				resolve(result.Items);
			});
		});
	}

	getMedDetail(name: string, strength: string): Promise<any> {
		return new Promise<any>(resolve => {
			let url = "/api/dosespot/getmedication?name=" + encodeURIComponent(name) + "&strength=" + encodeURIComponent(strength);

			if (this._customerId && this._customerId > 0) {
				url += "&customerId=" + this._customerId;
			}

			this._http.get(url).subscribe((result: any) => {
				resolve(result);
			});
		});
	}

	getDosespotDiagnosis(icd10: string): Promise<number> {
		return new Promise<number>(resolve => {
			this._http.get("api/dosespot/SearchIcd?icd10=" + encodeURIComponent(icd10)).subscribe((result: any) => {
				resolve(result);
			});
		});
	}

	getLoggedInDosespotUser(): Promise<any> {
		return new Promise<any>(resolve => {
			this._http.get("api/dosespot/GetLoggedInClinician").subscribe((result: any) => {
				this.isPrescriber.set(result && result.Roles && result.Roles.indexOf(1) >= 0);
				this.isPrescribingAgent.set(result && result.Roles && result.Roles.indexOf(5) >= 0);
				resolve(result);
			});
		});
	}

	getCustomerPreferences(): Promise<FacilityPreferenceSummary[]> {
		return new Promise<FacilityPreferenceSummary[]>(resolve => {
			this._http.get("/api/erx/facilitypreferences?customerId=" + this._customerId).subscribe((result: any) => {
				let preferences: RxOrderModel[] = result.map((item: FacilityPreferenceSummary) => {
					return OrderBuilder.fromFacilityPreference(item);
				});

				this.customerPreferredErx.set(preferences);

				resolve(result);
			});
		});
	}

	getUserPreferences(): Promise<UserPreferenceSummary[]> {
		return new Promise<UserPreferenceSummary[]>(resolve => {
			this._http.get("/api/erx/userpreferences").subscribe((result: any) => {
				let preferences: RxOrderModel[] = result.map((item: UserPreferenceSummary) => {
					let rxModel = new RxOrderModel();
					rxModel.DrugName = item.Nickname;
					rxModel.preferredNickname = item.Nickname;
					rxModel.preferredType = "userPreferred";
					rxModel.preferenceId = item.UserPreferenceId;

					if (item.LineItems.length === 1) {
						let med = new MedicationSearchResultModel();
						med.DosageForm = item.LineItems[0].UnitName;
						med.Strength = item.LineItems[0].Strength;
						med.DrugName = item.LineItems[0].ItemName;

						rxModel.selectedMedication = med;

						rxModel.Quantity = OrderBuilder.parseInput(+item.LineItems[0].Quantity);
						rxModel.daysSupply = item.LineItems[0].DaysSupply;
						rxModel.dispenseUnitName = item.LineItems[0].UnitName;
						rxModel.erxType = OrderBuilder.toRxType(item.LineItems[0].ItemTypeId);
						rxModel.sortedMedStrength = OrderBuilder.parseMedStrength(item.LineItems[0].Strength);
					} else {
						rxModel.Quantity = null;
						rxModel.daysSupply = null;
						rxModel.dispenseUnitName = "BUNDLE";
						rxModel.erxType = "kit";
						rxModel.sortedMedStrength = 0;

						let kitMed = new MedicationSearchResultModel();
						kitMed.DosageForm = "BUNDLE";
						kitMed.Strength = "";

						rxModel.selectedMedication = kitMed;

						rxModel.children = item.LineItems.map((lineItem: LineItemSummary) => {
							let child = new RxOrderModel();
							child.DrugName = lineItem.ItemName;
							child.erxType = OrderBuilder.toRxType(lineItem.ItemTypeId);
							child.preferredType = "userPreferred";

							let childMed = new MedicationSearchResultModel();
							childMed.DosageForm = lineItem.UnitName;
							childMed.Strength = lineItem.Strength;

							child.selectedMedication = childMed;

							child.Quantity = +lineItem.Quantity;
							child.daysSupply = lineItem.DaysSupply;

							return child;
						});
					}

					return rxModel;
				});

				this.userPreferredErx.set(preferences);
				resolve(result);
			});
		});
	}

	getOrderPreferences(): Promise<void> {
		return new Promise<void>(resolve => {
			let url = "api/erx/OrderPreferences";

			if (this._customerId && this._customerId > 0) {
				url += "?customerId=" + this._customerId;
			}

			this._http.get(url).subscribe((result: any) => {
				let preferences: RxOrderModel[] = result.map((item: OrderPreferenceSummary) => {
					let rxModel = new RxOrderModel();
					rxModel.DrugName = item.Nickname;
					rxModel.preferredNickname = item.Nickname;
					rxModel.preferredType = item.PreferenceType == "user" ? "userPreferred" : "customerPreferred";
					rxModel.preferenceId = item.PreferenceId;
					rxModel.preferenceType = item.PreferenceType;

					if (item.LineItems.length === 1) {
						let med = new MedicationSearchResultModel();
						med.DosageForm = item.LineItems[0].UnitName;
						med.Strength = item.LineItems[0].Strength;
						med.DrugName = item.LineItems[0].ItemName;

						rxModel.selectedMedication = med;
						rxModel.erxType = OrderBuilder.toRxType(item.LineItems[0].ItemTypeId);

						rxModel.Quantity = OrderBuilder.parseInput(+item.LineItems[0].Quantity);
						rxModel.daysSupply = item.LineItems[0].DaysSupply;
						rxModel.dispenseUnitName = item.LineItems[0].UnitName;
						rxModel.sortedMedStrength = OrderBuilder.parseMedStrength(item.LineItems[0].Strength);
					} else {
						rxModel.Quantity = null;
						rxModel.daysSupply = null;
						rxModel.dispenseUnitName = "BUNDLE";
						rxModel.erxType = "kit";
						rxModel.sortedMedStrength = 0;

						let kitMed = new MedicationSearchResultModel();
						kitMed.DosageForm = "BUNDLE";
						kitMed.Strength = "";

						rxModel.selectedMedication = kitMed;

						rxModel.children = item.LineItems.map((lineItem: LineItemSummary) => {
							let child = new RxOrderModel();
							child.DrugName = lineItem.ItemName;
							child.erxType = OrderBuilder.toRxType(lineItem.ItemTypeId);
							child.preferredType = item.PreferenceType == "user" ? "userPreferred" : "customerPreferred";

							let childMed = new MedicationSearchResultModel();
							childMed.DosageForm = lineItem.UnitName;
							childMed.Strength = lineItem.Strength;

							child.selectedMedication = childMed;

							child.Quantity = +lineItem.Quantity;
							child.daysSupply = lineItem.DaysSupply;

							return child;
						});
					}

					return rxModel;
				});

				this.orderPreferredErx.set(preferences);

				resolve();
			});
		});
	}

	addUserPreference(preference: UserPreference): Promise<void> {
		return new Promise<void>(resolve => {
			this._http.post("api/erx/userpreferences", preference).subscribe((result: any) => {
				resolve();
			});
		});
	}

	updateUserPreference(preferenceId: number, preference: UserPreference): Promise<void> {
		return new Promise<void>(resolve => {
			this._http.put("api/erx/userpreferences?preferenceId=" + preferenceId, preference).subscribe((result: any) => {
				resolve();
			});
		});
	}

	addCustomerPreference(preference: FacilityPreference): Promise<void> {
		return new Promise<void>(resolve => {
			this._http.post("api/erx/facilitypreferences", preference).subscribe((result: any) => {
				resolve();
			});
		});
	}

	updateCustomerPreference(preferenceId: number, preference: FacilityPreference): Promise<void> {
		return new Promise<void>(resolve => {
			this._http.put("api/erx/facilitypreferences?preferenceId=" + preferenceId + "&customerId=" + this._customerId, preference).subscribe((result: any) => {
				resolve();
			});
		});
	}

	loadPreference(id: number, type: string): Promise<OrderPreferenceDetail> {
		return new Promise<OrderPreferenceDetail>(resolve => {
			let url = `api/erx/preferences?type=${type}&id=${id}`;

			if (this._customerId && this._customerId > 0) {
				url += `&customerId=${this._customerId}`;
			}

			this._http.get(url).subscribe((result: any) => {
				resolve(result);
			});
		});
	}

	loadPrescription(id: number): Promise<OrderPreferenceDetail> {
		return new Promise<OrderPreferenceDetail>(resolve => {
			this._http.get(`api/erx/prescription?id=${id}`).subscribe((result: any) => {
				resolve(result);
			});
		});
	}

	getPatientPharmacies(patientId: number, defaultOPPC: boolean) {
		this.defaultPharmacy.set(null);
		this.preferredPharmacies.set([]);

		Promise.all([this.getPatientPreferredPharmacies(patientId), this.getPatientOPPCPharmacy()]).then(([preferredPharmacies, oppcPharmacy]) => {
			if (!preferredPharmacies.some(p => p.id == oppcPharmacy.id) && defaultOPPC) {
				preferredPharmacies.push(oppcPharmacy);
			}

			this.defaultPharmacy.set(preferredPharmacies.find(p => p.isDefault) || preferredPharmacies[0] || null);
			this.preferredPharmacies.set(preferredPharmacies);
		});
	}

	private getPatientPreferredPharmacies(patientId: number): Promise<PharmacySearchResult[]> {
		return new Promise<PharmacySearchResult[]>(resolve => {
			this._http.get("api/dosespot/patientpharmacies?patientId=" + patientId).subscribe((result: any) => {
				let pharmacies = result.Items.map(p => new PharmacySearchResult().deserialize(p));
				resolve(pharmacies);
			});
		});
	}

	private getPatientOPPCPharmacy(): Promise<PharmacySearchResult> {
		return new Promise<PharmacySearchResult>(resolve => {
			this._http.get("api/customer/getpharmacy").subscribe((result: any) => {
				let pharmacy: PharmacySearchResult = new PharmacySearchResult();

				pharmacy.id = result.DosespotPharmacyId;
				pharmacy.pharmacyName = "OnePoint Pharmacy - " + result.Name;
				pharmacy.street1 = result.Street1;
				pharmacy.street2 = result.Street2;
				pharmacy.city = result.City;
				pharmacy.state = result.State;
				pharmacy.zip = result.Zip;
				pharmacy.phone = result.Phone;
				pharmacy.fax = result.Fax;
				pharmacy.isDefault = false;
				pharmacy.isPreferred = true;

				resolve(pharmacy);
			});
		});
	}

	applySearchFilter() {
		let filter = this.pharmacySearchFilter.get();
		this.searchPharmacies(filter.name, filter.address, filter.city, filter.state, filter.zipCode, filter.phone, filter.isEpcs, filter.ncpdpid);
	}

	clearSearchFilter() {
		let f = new PharmacySearchFiltersModel();
		this.pharmacySearchFilter.set(f);

		// ToDo: remove this once filters are functional
		this.pharmacySearchResults.set([]);
	}

	searchPharmacies(name: string, address: string, city: string, state: string, zip: string, phone: string, isEpcs: boolean, ncpdpid: string) {
		let url = "api/dosespot/searchpharmacy";

		// add the parameters that have value to the query string
		let params = "?";
		if (name && name.trim().length > 0) params += "name=" + name + "&";
		if (address && address.trim().length > 0) params += "address=" + address + "&";
		if (city && city.trim().length > 0) params += "city=" + city + "&";
		if (state && state.trim().length > 0) params += "state=" + state + "&";
		if (zip && zip.trim().length > 0) params += "zip=" + zip + "&";
		if (phone && phone.trim().length > 0) params += "phoneOrFax=" + phone + "&";
		if (isEpcs) params += "specialty[0]=2048&";
		if (ncpdpid && ncpdpid.trim().length > 0) params += "ncpdpId=" + ncpdpid + "&";
		if (params === "?") {
			params = "";
		}

		this._http.get(url + params).subscribe((result: any) => {
			this.pharmacySearchResults.set(result.Items.map(p => new PharmacySearchResult().deserialize(p)));
		});
	}

	addOrder(patientId: number, order: NewOrder): Promise<NewOrderResult> {
		return new Promise<NewOrderResult>(resolve => {
			this._http.post("api/erx/prescriptions?patientId=" + patientId, order).subscribe((result: any) => {
				resolve(new NewOrderResult().deserialize(result.Result));
			});
		});
	}

	setupPatient(patientId: number): Promise<PatientUpdateResult> {
		return new Promise<PatientUpdateResult>((resolve, reject) => {
			this._http.put("api/erx/patientsetup?patientId=" + patientId, {}).subscribe((result: any) => {
				if (!result || !result.Success) {
					reject(result.PublicMessage);
				} else {
					resolve(new PatientUpdateResult().deserialize(result.Result));
				}
			});
		});
	}

	deleteUserPreference(id: number): Promise<void> {
		return new Promise<void>(resolve => {
			this._http.delete("api/erx/userpreference?preferenceId=" + id).subscribe(() => {
				this.userPreferredErx.set(this.userPreferredErx.get().filter(p => p.preferenceId !== id));

				resolve();
			});
		});
	}

	deleteFacilityPreference(customerId: number, id: number): Promise<void> {
		return new Promise<void>(resolve => {
			this._http.delete(`api/erx/facilitypreference?customerId=${customerId}&preferenceId=${id}`).subscribe(() => {
				this.customerPreferredErx.set(this.customerPreferredErx.get().filter(p => p.preferenceId !== id));
				resolve();
			});
		});
	}

	loadOrders(patientId: number) {
		this._http.get("api/erx/patientorders?patientId=" + patientId).subscribe((result: eRxSummary[]) => {
			this.erxOrders.set(
				result.map(r => {
					let rxOrder = new RxOrderModel();
					rxOrder.prescriptionId = r.DosespotPrescriptionId;

					rxOrder.erxType = OrderBuilder.toRxType(r.ItemTypeId);
					rxOrder.DrugName = r.ItemName;

					rxOrder.selectedMedication = new MedicationSearchResultModel();
					rxOrder.selectedMedication.DosageForm = r.UnitName;
					rxOrder.selectedMedication.Strength = r.Strength;

					rxOrder.Quantity = +r.Quantity;
					rxOrder.orderStatus = r.StatusText ?? "";
					rxOrder.notControlled = r.NotControlled;
					rxOrder.IsControl = !r.NotControlled;

					rxOrder.StartDate = r.DateSubmitted;
					rxOrder.Refills = r.Refills;
					rxOrder.pharmacyName = r.PharmacyName;
					rxOrder.isOppcPharmacy = r.PharmacyName.toLowerCase().indexOf("onepoint") >= 0;

					rxOrder.selectedPrescriber = new PrescriberSearchResultModel();
					rxOrder.selectedPrescriber.Name = r.Prescriber;
					rxOrder.sortedPrescriberName = r.Prescriber;

					rxOrder.AgentName = r.Submitter;

					return rxOrder;
				})
			);
		});
	}

	addPharmacy(patientId: number, pharmacyId: number, isDefault: boolean): Promise<void> {
		return new Promise<void>(resolve => {
			this._http.post("api/dosespot/addpharmacy?patientId=" + patientId + "&pharmacyId=" + pharmacyId + "&setAsPrimary=" + isDefault, {}).subscribe(() => {
				resolve();
			});
		});
	}

	removePharmacy(patientId: number, pharmacyId: number): Promise<void> {
		return new Promise<void>(resolve => {
			this._http.delete("api/dosespot/deletepharmacy?patientId=" + patientId + "&pharmacyId=" + pharmacyId).subscribe(() => {
				resolve();
			});
		});
	}

	loadHistory(patientId: number, prescriptionId: number): Promise<void> {
		this.erxStatusHistory.set([]);

		return new Promise<void>(resolve => {
			this._http.get("api/erx/PrescriptionHistory?patientId=" + patientId + "&prescriptionId=" + prescriptionId).subscribe((result: StatusEntry[]) => {
				this.erxStatusHistory.set(result);
				console.log(result);
				resolve();
			});
		});
	}

	matchMed(drugName: string, ndc: string): Promise<any> {
		return new Promise<void>(resolve => {
			this._http.get(`api/erx/GetProfileMedMatch?drugName=${drugName}&ndc=${ndc}`).subscribe((result: any) => {
				console.log(result);
				resolve(result);
			});
		});
	}

	public showSigning: StoreObject<boolean> = new StoreObject<boolean>(false);
	promptToSign() {
		this.showSigning.set(true);
	}

	dismissSignature() {
		this.showSigning.set(false);
	}

	getPreferredCssClass(type: string): string {
		if (!type) return;
		if (type === "userPreferred") {
			return "erx--user-preferred";
		} else if (type === "customerPreferred") {
			return "erx--customer-preferred";
		}
	}

	getPreferredItemMetadata(preference: RxOrderModel): string {
		let metadata: string;

		if (preference.children?.length > 0) {
			metadata = preference.children.length + " items included";
		} else {
			metadata = preference.selectedMedication.DrugName;
		}

		return metadata;
	}

	setSearchModels(models: ErxSearchModel[]) {
		this.searchModels.set(models);
	}

	getSearchModels(): ErxSearchModel[] {
		let models: ErxSearchModel[] = this.searchModels.get();

		this.searchModels.set([]);

		return models;
	}
}
