export class ReportServer {
	ReportServerId: number;
	ServerName: string;
	ServiceType: string;
	ServerUrl: string;
	ServiceUrl: string;

	deserialize(input: any): ReportServer {
		this.ReportServerId = input.ReportServerId;
		this.ServerName = input.ServerName;
		this.ServiceType = input.ServiceType;
		this.ServerUrl = input.ServerUrl;
		this.ServiceUrl = input.ServiceUrl;

		return this;
	}
}
