import { MultiSelectItemModel } from "../../shared/components/input-multi-select/multi-select-item.model";

export class PatientSearchFiltersModel {
  	firstName: string;
	lastName: string;
	state: string;
	city: string;
	teamNames: string;
	teams: number[];
	showOnlyActive: boolean;
	setPreferred: boolean;

	deserialize(obj: any): PatientSearchFiltersModel {
		return Object.assign(this, obj)
	}
}

export class PatientSearchFilterChip {
	type: string;
	isPreferred: boolean;
	value: any;

	deserialize(obj: any): PatientSearchFilterChip {
		var chip = Object.assign(this, obj);

		if (chip.type == "teams")
			chip.type = "team"

		return chip;
	}
}