import {Component, OnInit, ViewChild, Output, EventEmitter, Input} from '@angular/core';
import { BulkUploadUser } from '../../models/bulk-upload-user.model';
import {ModalComponent} from "../modal/modal.component";

@Component({
  selector: 'bulk-user-upload-modal',
  templateUrl: './bulk-user-upload-modal.template.html'
})

export class BulkUserUploadModalComponent implements OnInit {
  loading: boolean = false;
  headerText: string;
  subHeaderText: string;
  step: number = 1;
  userUploadCompleted: boolean = false;
  userRolesSelected: boolean = false;
  uploadResults: BulkUploadUser[] = [];

  @ViewChild('bulkUserUploadModal')
  bulkUserUploadModal: ModalComponent;

  @Input() customerId: number;

  @Output() modalClosed: EventEmitter<any> = new EventEmitter<any>();

  constructor() {}

  ngOnInit() {}

  goToStep(step: number) {
    this.step = step;

    switch (step) {
      case 1:
        this.subHeaderText = ' - Upload Users List';
        break;
      case 2:
        this.subHeaderText = ' - Select Roles';
        break;
      case 3:
        this.subHeaderText = ' - Confirm Users';
        break;
      default:
        this.step = 1;
        this.subHeaderText = '';
        break;
    }
  }

  uploadComplete(results: BulkUploadUser[]) {
    this.uploadResults = results;
    this.goToStep(2);
  }

  rolesSelected(results: any[]) {
    this.goToStep(3);
  }

  open() {
    this.headerText = "Bulk User Upload";
    this.subHeaderText = "";
    this.bulkUserUploadModal.open('lg');
    this.goToStep(1);
  }

  showDialog() {
    this.open();
  }

  closeBulkUserUploadModal() {
    this.bulkUserUploadModal.dismiss();
  }

  onModalClose() {
    this.headerText = "";
    this.subHeaderText = "";
    this.step = 1;
    this.modalClosed.emit();
  }
}
