import {Injectable} from "@angular/core";
import { DiagnosisSearchResultModel } from "src/app/ocp/shared/models/diagnosis-search-results.model";
import {HttpService} from "../../../shared/services/http.service";

@Injectable()
export class DiagnosisService {
  private _searchUrl: string = "api/diagnosis/search?searchTerm=";
  private _getTopFiveUrl: string = "api/diagnosis/PrimaryIcdCodes";

  constructor(private http: HttpService) {
  }

  search(searchText: string){
    return this.http.get(this._searchUrl + searchText);
  }

  getTopFiveDiagnosis(): Promise<DiagnosisSearchResultModel[]> {
    return new Promise<DiagnosisSearchResultModel[]> (resolve => {
      this.http.get(this._getTopFiveUrl).subscribe((results: DiagnosisSearchResultModel[]) => {
        resolve(results);
      })
    })
  }
}
