import { Injectable, EventEmitter } from "@angular/core";
import { Observable, BehaviorSubject, combineLatest } from "rxjs";
import { combineAll, debounceTime, filter } from "rxjs/operators";
import { MultiSelectItemModel } from "../../shared/components/input-multi-select/multi-select-item.model";
import { CustomerTeamModel } from "../../shared/models/customer-team.model";
import { PatientResult } from "../../shared/models/patient-result.model";
import { PatientInfoModel } from "../../shared/models/patient.model";
import { StoreObject } from "../../shared/models/store-object.model";
import { UserInfoModel } from "../../shared/models/user-info.model";
import { CustomerService } from "../../shared/services/customer.service";
import { HttpService } from "../../shared/services/http.service";
import { PatientService } from "../../shared/services/patient.service";
import { UserService } from "../../shared/services/user.service";
import { UtilitiesService } from "../../shared/services/utilities.service";
import { PatientSearchFilterChip, PatientSearchFiltersModel } from "../models/patient-search-filters.model";
import SetPreferredTeamModel from "../models/set-preferred-team.model";
import { SelectionModel } from '@angular/cdk/collections';
import { PatientMedicationModel } from "../../shared/models/patient-medication.model";

@Injectable()
export class PatientCareStore {
	private _popoverContent: BehaviorSubject<string> = new BehaviorSubject<string>("");
	public readonly PopoverContent: Observable<string> = this._popoverContent.asObservable();

	private _patient: BehaviorSubject<PatientInfoModel> = new BehaviorSubject<PatientInfoModel>(new PatientInfoModel());
	public readonly Patient: Observable<PatientInfoModel> = this._patient.asObservable();

	private _createMode: BehaviorSubject<string> = new BehaviorSubject<string>("");
	public readonly CreateMode: Observable<string> = this._createMode.asObservable();

	private _pediatricRestricted: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
	public readonly PediatricRestricted: Observable<boolean> = this._pediatricRestricted.asObservable();

	public patientSearchResults: StoreObject<PatientResult[]> = new StoreObject([]);
	public displayedPatients: StoreObject<PatientResult[]> = new StoreObject([]);
	public patientSearchFilter: StoreObject<PatientSearchFiltersModel> = new StoreObject<PatientSearchFiltersModel>(new PatientSearchFiltersModel());

	public teams: StoreObject<CustomerTeamModel[]> = new StoreObject<CustomerTeamModel[]>([]);
	public teamSelectItems: StoreObject<MultiSelectItemModel[]> = new StoreObject<MultiSelectItemModel[]>([]);

	public filtersApplied: string[] = [];
	public filterChips: PatientSearchFilterChip[] = [];
	private _selectedCustomerId?: number = 0;
	private _preferredTeams: number[] = [];
	public realignTabInkBar: EventEmitter<any> = new EventEmitter<any>();
	public selectedMedications = new SelectionModel<PatientMedicationModel>(true, []);

	public get numFiltersApplied(): number {
		return (this.filterChips || []).length;
	}

	constructor(private _service: PatientService, private _util: UtilitiesService, private _http: HttpService, private _userService: UserService) {
		this.Patient.subscribe(p => {
			this._pediatricRestricted.next(false);
			if (p.patientId != this._patient.getValue().patientId) return;

			if (p && p.birthDate && this._util.calcAge(p.birthDate) < 18) {
				if (!p.weightLbs || !p.heightIn) {
					this._pediatricRestricted.next(true);
					return;
				}
			}
		});

		this.patientSearchResults.observable.subscribe(searchResults => {
			this.applySearchFilter();
		});

		this.patientSearchFilter.observable.subscribe((searchFilter: PatientSearchFiltersModel) => {
			this.applySearchFilter();
		});

		combineLatest(this._userService.selectedCustomer)
			.pipe(debounceTime(0))
			.subscribe((c: any) => {
				this._selectedCustomerId = c[0] ? c[0].CustomerId : null;
				this.refreshTeams();
			});

		this._userService.userInfo.subscribe((u: UserInfoModel) => {
			if (u && u.UserSearchTeamList) {
				this._preferredTeams = (u.UserSearchTeamList || "").split(",").map(t => +t);
			} else {
				this._preferredTeams = [];
			}
		});

		this.teams.observable.subscribe((teams: CustomerTeamModel[]) => {
			this.teamSelectItems.set(
				(teams || []).map(t => {
					return { id: t.CustomerTeamId, text: t.TeamName } as MultiSelectItemModel;
				})
			);
			let f = this.patientSearchFilter.get();
			f.teams = this._preferredTeams || [];
		});
	}

	refreshTeams() {
		this._http.get(`api/customer/getcustomerteamlist?includeInactive=true&customerId=${this._selectedCustomerId}`).subscribe((teams: CustomerTeamModel[]) => {
			this.teams.set(teams || []);
		});
	}

	setPreferredSearchTeams(): Promise<number[]> {
		let filter = this.patientSearchFilter.get();

		if (!filter.setPreferred) return;

		let selectedTeams = filter.teams || [];

		let teams = (this.teams.get() || []).map(t => {
			let enabled = selectedTeams.indexOf(t.CustomerTeamId) > -1;
			return new SetPreferredTeamModel(t.CustomerTeamId, enabled);
		});

		return new Promise<number[]>(resolve => {
			this._http.post("api/user/setuserpreferredSearchTeams", teams).subscribe((result: any) => {
				this._preferredTeams = result.Result;
				this.applySearchFilter();
				resolve(result);
			});
		});
	}

	search(searchText: string): Promise<PatientResult[]> {
		return new Promise<PatientResult[]>(resolve => {
			this._http.get("api/patient/search?searchTerm=" + searchText).subscribe(
				(searchResults: PatientResult[]) => {
					this.patientSearchResults.set(searchResults);
					resolve(searchResults);
				},
				() => {
					resolve([]);
				}
			);
		});
	}

	applySearchFilter() {
		let filter = this.patientSearchFilter.get();
		let searchResults = this.patientSearchResults.get();

		if (!filter) {
			this.displayedPatients.set(searchResults);
			this.filtersApplied = [];
			this.filterChips = [];
			return;
		}

		this.filterChips = Object.keys(filter).reduce((acc: PatientSearchFilterChip[], key: string) => {
			if (key == "teams") {
				let teams = this.teams.get() || [];
				let teamChips = (filter[key] || []).map(t => {
					return new PatientSearchFilterChip().deserialize({
						isPreferred: (this._preferredTeams || []).indexOf(t) > -1,
						value: (teams.find(a => a.CustomerTeamId == +t) || { TeamName: "" }).TeamName,
						type: key
					});
				});

				return [...acc, ...teamChips];
			} else {
				return !!filter[key] && key !== "setPreferred"
					? [
							...acc,
							new PatientSearchFilterChip().deserialize({
								isPreferred: false,
								value: filter[key],
								type: key
							})
					  ]
					: acc;
			}
		}, []);

		this.teamSelectItems.set(
			(this.teams.get() || []).map(t => {
				return { id: t.CustomerTeamId, text: t.TeamName, selected: (filter.teams || []).indexOf(t.CustomerTeamId) > -1 } as MultiSelectItemModel;
			})
		);

		if (this.numFiltersApplied === 0) {
			this.filtersApplied = [];
			this.filterChips = [];
			this.displayedPatients.set(searchResults);
			return;
		}

		let partMatch = (value: string, propFilter: string) => {
			if (!propFilter) return true;
			if (!value && propFilter) return false;

			return (value || "").toLowerCase().indexOf((propFilter || "").toLowerCase()) > -1;
		};

		let teamNames = (this.teams.get() || []).filter(t => (filter.teams || []).indexOf(t.CustomerTeamId) > -1).map(t => t.TeamName);

		let results = searchResults.filter((p: PatientResult) => {
			return (
				partMatch(p.FirstName, filter.firstName) &&
				partMatch(p.LastName, filter.lastName) &&
				partMatch(p.State, filter.state) &&
				partMatch(p.City, filter.city) &&
				(p.AdmissionStatusCode === 0 || p.AdmissionStatusCode === 1 || !filter.showOnlyActive) &&
				(teamNames.length == 0 || teamNames.indexOf(p.TeamName) > -1)
			);
		});

		this.displayedPatients.set(results);
	}

	removeFilterValue(key: string, value?: any) {
		let filter = this.patientSearchFilter.get();

		if (key != "team") delete filter[key];
		else {
			let id = (this.teams.get() || []).find(t => t.TeamName == value).CustomerTeamId;
			filter.teams = (filter.teams || []).filter(t => t != +id);
		}

		this.patientSearchFilter.set(filter);
	}

	clearSearchFilter() {
		let f = new PatientSearchFiltersModel();
		f.showOnlyActive = true;
		f.teams = this._preferredTeams;
		this.patientSearchFilter.set(f);
	}

	toggleFilterIsActive(val: boolean) {
		let f = this.patientSearchFilter.get();
		f.showOnlyActive = val;
	}

	loadPatientProfile(patientId: number) {
		this._pediatricRestricted.next(false);
		this._service.getProfileModel(patientId).subscribe((patient: PatientInfoModel) => {
			// Set the tooltip content for each Patient's Icon
			if (patient.isAdmitted && patient.isFacilityCare) {
				this._popoverContent.next("Currently admitted into facility care.");
			} else if (patient.isAdmitted && patient.isHomePatient) {
				this._popoverContent.next("Currently admitted into home care.");
			} else if (patient.isAdmitted && patient.isIpuGpu) {
				this._popoverContent.next("Currently admitted into IPU/GPU.");
			} else if (patient.isPreadmit) {
				this._popoverContent.next("Patient has not been admitted.");
			} else if (patient.isDeceased) {
				this._popoverContent.next("Patient is Deceased.");
			} else {
				this._popoverContent.next("Currently discharged.");
			}

			this._patient.next(patient);
		});
	}

	clearPatient() {
		this._patient.next(new PatientInfoModel());
	}

	getIsPediatricRestricted(): boolean {
		return this._pediatricRestricted.getValue();
	}

	getOppcBillingInfo(): string[] {
		let pat = this._patient.getValue();
		return [pat.memberId || "", pat.bin || "", pat.pcn || ""];
	}
}
