import { throwError as observableThrowError, Observable, of } from "rxjs";

import { catchError, tap } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AuthService } from "./auth.service";
import { AppConfig } from "../../app.config";
import { CacheService } from "./cache.service";
import { StatusMessageService } from "../components/status-message/status-message.service";

@Injectable()
export class HttpService {
	externalGet(url: string) {
		return this.http.get(url);
	}

	get(url: string) {
		if (!this.authService.isAuthenticated()) {
			this.authService.doLogout("");
		}

		this._cacheService.set(this._config.SessionKeys.authentication.lastActivity, new Date().toISOString());

		return this.http.get(this._config.Urls.baseApiUrl + url, { headers: this.getHeaders() }).pipe(
			catchError(error => {
				return this.handleError(error, this);
			})
		);
	}

	makeFileRequest(url: string, id: number, files: File[]) {
		if (!this.authService.isAuthenticated()) {
			this.authService.doLogout("");
		}

		const accessToken = this._cacheService.get(this._config.SessionKeys.authentication.accessToken);
		this._cacheService.set(this._config.SessionKeys.authentication.lastActivity, new Date().toISOString());
		return new Promise((resolve, reject) => {
			url = this._config.Urls.baseApiUrl + url + "?id=" + id;

			let formData = new FormData();
			let xhr = new XMLHttpRequest();

			for (let i = 0; i < files.length; i++) {
				formData.append("uploads", files[i], files[i].name);
			}

			xhr.onreadystatechange = function() {
				if (xhr.readyState == 4) {
					if (xhr.status == 200) {
						resolve(JSON.parse(xhr.response));
					} else {
						reject(xhr.response);
					}
				}
			};

			xhr.open("POST", url, true);
			xhr.setRequestHeader("Authorization", "Bearer " + accessToken);
			xhr.send(formData);
		});
	}

	downloadFile(url: string) {
		if (!this.authService.isAuthenticated()) {
			this.authService.doLogout("");
		}

		const accessToken = this._cacheService.get(this._config.SessionKeys.authentication.accessToken);
		this._cacheService.set(this._config.SessionKeys.authentication.lastActivity, new Date().toISOString());

		let self = this;
		return new Promise((resolve, reject) => {
			let xhr = new XMLHttpRequest();

			xhr.onreadystatechange = function() {
				if (xhr.readyState == 4) {
					if (xhr.status == 200) {
						resolve(xhr.response);
					} else if (xhr.status == 404) {
						self._statusMsgService.changeStatusMessage("error", "Requested resource file is no longer available.");
						reject(xhr.response);
					} else {
						reject(xhr.response);
					}
				}
			};

			xhr.open("GET", this._config.Urls.baseApiUrl + url, true);
			xhr.responseType = "blob";
			xhr.setRequestHeader("Authorization", "Bearer " + accessToken);
			xhr.send();
		});
	}

	cachedGet(allowCache: boolean, url: string) {
		if (allowCache && this._cacheService.exists(url)) {
			var self = this;

			return Observable.create(function(o) {
				var val = self._cacheService.get(url);

				o._next(val);
				o._complete();
			});
		} else {
			return this.get(url).pipe(tap((data: string) => this._cacheService.set(url, data)));
		}
	}

	post(url: string, body: any) {
		if (!this.authService.isAuthenticated()) {
			this.authService.doLogout("");
			return of(null);
		}

		let jBody = JSON.stringify(body);

		this._cacheService.set(this._config.SessionKeys.authentication.lastActivity, new Date().toISOString());
		return this.http.post(this._config.Urls.baseApiUrl + url, jBody, { headers: this.postHeaders() }).pipe(
			catchError(error => {
				return this.handleError(error, this);
			})
		);
	}

	put(url: string, body: any) {
		if (!this.authService.isAuthenticated()) {
			this.authService.doLogout("");
			return;
		}

		let jBody = JSON.stringify(body);

		this._cacheService.set(this._config.SessionKeys.authentication.lastActivity, new Date().toISOString());
		return this.http.put(this._config.Urls.baseApiUrl + url, jBody, { headers: this.postHeaders() }).pipe(
			catchError(error => {
				return this.handleError(error, this);
			})
		);
	}

	delete(url: string) {
		if (!this.authService.isAuthenticated()) {
			this.authService.doLogout("");
			return;
		}

		this._cacheService.set(this._config.SessionKeys.authentication.lastActivity, new Date().toISOString());
		return this.http.delete(this._config.Urls.baseApiUrl + url, { headers: this.postHeaders() }).pipe(
			catchError(error => {
				return this.handleError(error, this);
			})
		);
	}

	private getHeaders(): HttpHeaders {
		const accessToken = this._cacheService.get(this._config.SessionKeys.authentication.accessToken);

		let authHeaders = new HttpHeaders();
		if (accessToken) {
			authHeaders = new HttpHeaders({ Authorization: "Bearer " + accessToken, Accept: "application/json", "ngsw-bypass": "true" });
		}

		return authHeaders;
	}

	private postHeaders(): HttpHeaders {
		const accessToken = this._cacheService.get(this._config.SessionKeys.authentication.accessToken);

		let authHeaders = new HttpHeaders();
		if (accessToken) {
			authHeaders = new HttpHeaders({
				Authorization: "Bearer " + accessToken,
				"Content-Type": "application/json",
				Accept: "application/json",
				"ngsw-bypass": "true"
			});
		}

		return authHeaders;
	}

	private handleError(error: any, self: any) {
		console.log(error);

		if (error.statusText) {
			if (error.statusText === "Invalid Session Key") {
				self.authService.doLogout("Your session has expired.");
			}

			if (error.statusText === "Unauthorized" || error.statusText === "Authorization has been denied for this request.") {
				self.authService.doLogout("Unauthorized access.");
			}

			if (error._body) {
				let parseErrorBody: any = JSON.parse(error._body);

				if (parseErrorBody.ExceptionMessage) {
					console.log(parseErrorBody.ExceptionMessage);
					return observableThrowError(parseErrorBody.ExceptionMessage);
				}
			}

			return observableThrowError(error.statusText || "Server Error");
		}

		return observableThrowError(error);
	}

	constructor(private http: HttpClient, private authService: AuthService, private _cacheService: CacheService, private _config: AppConfig, private _statusMsgService: StatusMessageService) {}
}
