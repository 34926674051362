import { Injectable } from "@angular/core";
import { HttpService } from "./http.service";
import { UserService } from "./user.service";
import { OrganizationProfile } from "../models/organization-profile.model";
import { CustomerProfileModel } from "../models/customer-profile.model";
import { CustomerTeamModel } from "../models/customer-team.model";
import { StoreObject } from "../models/store-object.model";

@Injectable()
export class CustomerService {
	private _cachedCustomerId: number;

	private _customersUrl: string = "api/user/getcustomers";
	private _getCustomerTeamListUrl: string = "api/customer/getcustomerteamlist?includeInactive=";
	private _getCustomerTeamDetailUrl: string = "api/customer/GetCustomerTeamDetail?includeInactive=";
	private _setCustomerTeamUrl: string = "api/customer/setcustomerteam";
	//private _addTeamUrl: string = 'api/customer/addteam';
	//private _updateTeamInfoUrl: string = 'api/customer/updateteaminfo';
	private _updateCustomerTeamStatusUrl: string = "api/customer/updatecustomerteamstatus";
	private _getOrganizationProfileUrl: string = "api/customer/getorganizationprofile?organizationId=";
	private _getOrganizationCustomerListUrl: string = "api/customer/getorganizationcustomerlist?organizationId=";
	private _getCustomerProfileUrl: string = "api/customer/getcustomerprofile?customerId=";
	private _getCustomerUsersUrl: string = "api/customer/getcustomerusers?customerId=";
	private _updateOrganizationProfileUrl: string = "api/customer/updateorganizationprofile";
	private _addOrganizationProfileUrl: string = "api/customer/addorganizationprofile";
	private _updateCustomerProfileUrl: string = "api/customer/updatecustomerprofile";
	private _addCustomerProfileUrl: string = "api/customer/addcustomerprofile";
	private _updateCustomerStatusUrl: string = "api/customer/updatecustomerstatus";
	private _getVendorListUrl: string = "api/customer/getvendorlist";
	private _getPharmacyListUrl: string = "api/customer/getpharmacylist";
	private _getOrganizationListUrl: string = "api/customer/getorganizationlist";
	private _getCustomerPermissionMatrixUrl: string = "api/customer/getcustomerpermissionmatrix?customerId=";
	private _getCustomerRolesUrl: string = "api/customer/GetCustomerRoles";
	private _getCustomerCliniciansUrl: string = "api/customer/GetCustomerClinicians?customerId=";
	private _getIntegrationSettingsUrl: string = "api/customer/GetIntegrationSettings?customerId=";

	public readonly customers: StoreObject<any[]> = new StoreObject<any[]>([]);

	constructor(private _http: HttpService, private _userService: UserService) {}

	getOrganizationProfile(organizationId: number) {
		return this._http.get(this._getOrganizationProfileUrl + organizationId);
	}

	getMyOrganizationProfile() {
		return this._http.get("api/customer/getmyorganizationprofile");
	}

	addOrganizationProfile(orgProfile: OrganizationProfile) {
		return this._http.post(this._addOrganizationProfileUrl, orgProfile);
	}

	updateOrganizationProfile(orgProfile: OrganizationProfile) {
		return this._http.post(this._updateOrganizationProfileUrl, orgProfile);
	}

	addCustomerProfile(customer: CustomerProfileModel) {
		return this._http.post(this._addCustomerProfileUrl, customer);
	}

	updateCustomerProfile(customer: CustomerProfileModel) {
		return this._http.post(this._updateCustomerProfileUrl, customer);
	}

	getCustomerProfile(customerId: number) {
		return this._http.get(this._getCustomerProfileUrl + customerId);
	}

	getOrganizationCustomerList(organizationId: number) {
		return this._http.get(this._getOrganizationCustomerListUrl + organizationId);
	}

	getCustomers(): Promise<any[]> {
		return new Promise<any[]>((resolve, reject) => {
			this._http.get(this._customersUrl).subscribe(
				(results: any[]) => {
					this.customers.set(results);
					resolve(results);
				},
				(error: any) => {
					reject(error);
				}
			);
		});
	}

	getCustomerTeamList(customerId: number, includeInactive: boolean, disableCache: boolean) {
		let useCache = true;

		if (this._cachedCustomerId !== this._userService.selectedCustomerId) {
			this._cachedCustomerId = this._userService.selectedCustomerId;
			useCache = false;
		}

		if (disableCache) {
			useCache = false;
		}

		return this._http.cachedGet(useCache, this._getCustomerTeamListUrl + includeInactive + "&customerId=" + customerId);
	}

	getCustomerTeamDetails(customerId: number, includeInactive: boolean, disableCache: boolean) {
		let useCache = true;

		if (this._cachedCustomerId !== this._userService.selectedCustomerId) {
			this._cachedCustomerId = this._userService.selectedCustomerId;
			useCache = false;
		}

		if (disableCache) {
			useCache = false;
		}

		return this._http.cachedGet(useCache, this._getCustomerTeamDetailUrl + includeInactive + "&customerId=" + customerId);
	}

	setCustomerTeam(team: CustomerTeamModel, customerId: number) {
		return this._http.post(this._setCustomerTeamUrl, {
			Team: team,
			CustomerId: customerId
		});
	}

	// addTeam(team: CustomerTeamModel, customerId: number) {
	//   return this._http.post(this._addTeamUrl, {Team: team, CustomerId: customerId});
	// }
	//
	// updateTeamInfo(team: CustomerTeamModel) {
	//   return this._http.post(this._updateTeamInfoUrl, team);
	// }

	updateCustomerTeamStatus(customerTeamId: number, isActive: boolean) {
		return this._http.post(this._updateCustomerTeamStatusUrl, { CustomerTeamId: customerTeamId, IsActive: isActive });
	}

	getCustomerUsers(customerId: number) {
		return this._http.get(this._getCustomerUsersUrl + customerId);
	}

	getVendorList() {
		return this._http.get(this._getVendorListUrl);
	}

	getPharmacyList() {
		return this._http.get(this._getPharmacyListUrl);
	}

	getOrganizationList(allowCache: boolean) {
		return this._http.cachedGet(allowCache, this._getOrganizationListUrl);
	}

	updateCustomerStatus(customerId: number, isActive) {
		return this._http.post(this._updateCustomerStatusUrl, { CustomerId: customerId, IsActive: isActive });
	}

	getCustomerPermissionMatrix(customerId: number) {
		return this._http.get(this._getCustomerPermissionMatrixUrl + customerId);
	}

	getCustomerRoles() {
		return this._http.get(this._getCustomerRolesUrl);
	}

	getCustomerClinicians(customerId: number) {
		return this._http.get(this._getCustomerCliniciansUrl + customerId);
	}

	getCustomerIntegrations(customerId: number) {
		return this._http.get(this._getIntegrationSettingsUrl + customerId);
	}
}
