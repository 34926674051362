import {Component, Input, OnInit} from "@angular/core";

@Component({
  selector: "active-inactive-status-text",
  templateUrl: "./active-inactive-status-text.template.html"
})

export class ActiveInactiveStatusTextComponent implements OnInit {
  private _startDate: string;
  private _endDate: string;

  statusText: string = "";

  @Input() set startDate(val: string) {
    this._startDate = val;
    this.evaluateStatus();
  }

  @Input() set endDate(val: string) {
    this._endDate = val;
    this.evaluateStatus();
  }

  ngOnInit() {
    this.evaluateStatus();
  }

  private evaluateStatus() {
    this.statusText = "Inactive";
    let evalStartDate = null;
    let evalEndDate = null;
    let dateNow = new Date();

    let currentDate = new Date(dateNow.getFullYear(), dateNow.getMonth(), dateNow.getDate());

    if (this._startDate) {
      evalStartDate = new Date(this._startDate);
    }

    if (this._endDate) {
      evalEndDate = new Date(this._endDate);
    }

    if (evalStartDate && evalEndDate) {
      if (currentDate >= evalStartDate && currentDate <= evalEndDate) {
        this.statusText = "Active";
      }
    } else if (evalStartDate && currentDate >= evalStartDate) {
      this.statusText = "Active";
    } else if (currentDate <= evalEndDate) {
      this.statusText = "Active";
    }
  }
}
