import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { ActivatedRoute, Router, Params } from "@angular/router";
import { PatientInfoModel } from "../../../models/patient.model";
import { PatientCareStore } from "../../../../patient-care/services/patient-care.store";
import { Subject, combineLatest } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ErxOrderStore } from "../../../services/erx-order.store";
import { ModalComponent } from "../../../components/modal/modal.component";
import { UserService } from "../../../services/user.service";
import * as _ from "lodash";
import { RxOrderModel } from "../../../models/rx-order.model";
import { StatusMessageService } from "../../status-message/status-message.service";
import { UserInfoModel } from "../../../models/user-info.model";
import { CustomerService } from "../../../services/customer.service";
import { UserPreference } from "../../../models/erx/user-preference.model";
import { LineItem } from "../../../models/erx/line-item.model";
import { DosespotItem } from "../../../models/erx/dosespot-item.model";
import { FacilityPreference } from "../../../models/erx/facility-preference.model";
import { PharmacySearchResult } from "../../../models/pharmacy-search-result.model";
import { NewOrder } from "../../../models/erx/new-order.model";
import { DosespotPharmacy } from "../../../models/erx/dosespot-pharmacy.model";
import { Clinician } from "../../../models/clinician-model";
import { StoreObject } from "../../../models/store-object.model";
import { MedicationSearchResultModel } from "../../../models/medication-search-result.model";
import { OrderBuilder } from "../../../models/erx/order.builder";
import { FadeInAnimation } from "src/app/ocp/animations";
import { OrderPreferenceDetail } from "../../../models/erx/order-preference-detail.model";
import { CustomerProfileModel } from "../../../models/customer-profile.model";
import { NewOrderResult } from "../../../models/erx/results/new-order-result.model";
import { error } from "@angular/compiler/src/util";
import { PatientUpdateResult } from "../../../models/erx/results/patient-update-result.model";
import { DateOnly } from "../../../models/date-only.model";
import { RoutingRedirectService } from "../../../services/routing-redirect.service";

@Component({
	selector: "ocp-new-erx-order",
	templateUrl: "./erx-new-order.component.html",
	styleUrls: ["./erx-new-order.component.scss"],
	animations: [FadeInAnimation]
})
export class ErxNewOrderComponent implements OnInit {
	type: string; // "patientErx", "preferredErx"
	mode: string; // "new", "edit"
	isCustomerPreferred: boolean = false;

	isLoading: boolean = false;
	saving: boolean = false;
	saveAsPreferred: boolean = false;
	preferredNickname: string;
	stepOneCompleted: boolean = false;

	isPrescriber: boolean = false;
	isPrescribingAgent: boolean = false;

	user: UserInfoModel;
	patient: PatientInfoModel;
	customerId: number;
	customer: any;
	rxOrders: RxOrderModel[] = [];
	selectedOrder: RxOrderModel;

	private _effectiveDt: DateOnly;
	get effectiveDate(): string {
		return this._effectiveDt.toString();
	}
	set effectiveDate(dt: string) {
		if (!dt) return;

		if (typeof dt != "object") {
			this._effectiveDt.setDate(new Date(dt));
		} else {
			this._effectiveDt.setDate(dt);
		}
	}

	selectedPrescriber: Clinician;
	selectedPharmacy: PharmacySearchResult;
	preferredItems: RxOrderModel[] = [];

	dosespotPatientId?: number;

	showIcdSelect: boolean = false;
	defaultOPPC: boolean = false;
	signatureActionSelected: string; // "skipForNow", "proceedToSignature"
	includesRejectedRx: boolean = false;

	@ViewChild("changePharmacyModal") changePharmacyModal: ModalComponent;
	@ViewChild("editOrderSettingsModal") editOrderSettingsModal: ModalComponent;
	@ViewChild("confirmCancelModal") confirmCancelModal: ModalComponent;
	@ViewChild("orderFormStartMobile") orderFormStartMobile: ElementRef;
	@ViewChild("orderFormStartDesktop") orderFormStartDesktop: ElementRef;
	@ViewChild("confirmSaveAsPreferredModal") confirmSaveAsPreferredModal: ModalComponent;

	private validateForm(): string[] {
		let validations: string[] = [];

		if (this.rxOrders.length === 1 && !this.rxOrders[0].selectedMedication) {
			validations.push("Please select a medication.");
			return validations;
		}

		if (this.type === "patientErx") {
			let today = new DateOnly();

			if (!this._effectiveDt || this._effectiveDt.isBefore(today)) {
				validations.push("Please select a valid effective date.");
			}

			if (!this.selectedClinicianId || this.selectedClinicianId <= 0) {
				validations.push("Please select a prescriber.");
			}

			if (!this.selectedPharmacy) {
				validations.push("Please select a pharmacy.");
			}
		}

		for (let i = 0; i < this.rxOrders.length; i++) {
			let order: RxOrderModel = this.rxOrders[i];

			let itemValidations = order.validate(this.showIcdSelect, this.type, i);
			if (itemValidations.length > 0) {
				validations = validations.concat(itemValidations);
			}
		}

		return validations;
	}

	private destroyed: Subject<any> = new Subject<any>();

	readonly ready: StoreObject<boolean> = new StoreObject<boolean>(false);

	private _fromMedList: boolean = false;

	private _preferenceId: number;
	private _prescriptionId: number;

	constructor(
		private _router: Router,
		private _route: ActivatedRoute,
		private _patientStore: PatientCareStore,
		private _userService: UserService,
		public store: ErxOrderStore,
		private _statusMessageService: StatusMessageService,
		private _customerService: CustomerService,
		private _el: ElementRef,
		private _redirectService: RoutingRedirectService
	) {
		this.type = this._route.snapshot.data["type"] == "patient-erx" ? "patientErx" : "preferredErx";

		// Using this to redirect back to eRx Orders listing if New Order screen is refreshed and no customerId is set
		if (this.type === "patientErx" && !this._userService.selectedCustomerId) {
			this.backToErxOrders();
		}

		this._fromMedList = this._route.snapshot.queryParams["fromMedList"] === "true";

		let name = this._route.snapshot.queryParams["nickname"];
		if (name && name.trim().length > 0 && name !== "undefined") {
			this.preferredNickname = name;
		}

		let id = this._route.snapshot.queryParams["preferenceId"];
		if (id && id.trim().length > 0 && id !== "undefined") {
			this._preferenceId = +id;
		}

		let prescriptionId = this._route.snapshot.queryParams["reorder"];
		if (prescriptionId && prescriptionId.trim().length > 0 && prescriptionId !== "undefined") {
			this._prescriptionId = +prescriptionId;
		}
	}

	ngOnInit(): void {
		this.store.isPrescriber.observable.pipe(takeUntil(this.destroyed)).subscribe((isPrescriber: boolean) => {
			this.isPrescriber = isPrescriber;
		});

		this.store.isPrescribingAgent.observable.pipe(takeUntil(this.destroyed)).subscribe((isPrescribingAgent: boolean) => {
			this.isPrescribingAgent = isPrescribingAgent;
		});

		this.store.mode.observable.pipe(takeUntil(this.destroyed)).subscribe((mode: string) => {
			this.mode = mode;
		});

		this._userService.userInfo.pipe(takeUntil(this.destroyed)).subscribe((loggedInUser: UserInfoModel) => {
			this.user = loggedInUser;
		});

		this.store.defaultPharmacy.observable.pipe(takeUntil(this.destroyed)).subscribe((pharmacy: PharmacySearchResult) => {
			setTimeout(() => {
				this.selectedPharmacy = pharmacy;
			});
		});

		this.store.orderPreferredErx.observable.pipe(takeUntil(this.destroyed)).subscribe((preferred: RxOrderModel[]) => {
			this.preferredItems = preferred;
		});

		this.store.selectedErx.observable.pipe(takeUntil(this.destroyed)).subscribe((selectedItems: RxOrderModel[]) => {
			this.rxOrders = selectedItems;
		});

		this._userService.selectedCustomer.pipe(takeUntil(this.destroyed)).subscribe((customer: CustomerProfileModel) => {
			if (customer) {
				this.showIcdSelect = customer.DxReqForErx;
				this.defaultOPPC = customer.ErxCustomerConfig.DefaultOPPCPharmacy;
			}
		});

		this.ready.observable.pipe(takeUntil(this.destroyed)).subscribe((ready: boolean) => {
			if (ready) {
				if (this._prescriptionId > 0) {
					this.isLoading = true;
					this.store.loadPrescription(this._prescriptionId).then((detail: OrderPreferenceDetail) => {
						let rxModel = OrderBuilder.fromOrderPreference(detail);

						this.rxOrders.push(rxModel);
						this.stepOneCompleted = true;
						this.selectedOrder = this.rxOrders[0];
						this.isLoading = false;
					});
				} else {
					if (this.mode === "edit" || this.mode === "view") {
						this.stepOneCompleted = true;
					} else if (this.mode === "new") {
						this.addNewItem();
					}

					this.selectedOrder = this.rxOrders[0];
					if (this._fromMedList) {
						this.initializeOrderFromMedList();
					}
				}
			}
		});

		this.init();
	}

	onUnitsChanged(id: number) {
		this.selectedOrder.dispenseUnitName = this.store.units.get().find((unit: any) => unit.StandardDispenseUnitTypeID === id).SingularOrPlural;
	}

	ngOnDestroy() {
		this.destroyed.next();
		this.destroyed.unsubscribe();
	}

	initializeOrderFromMedList() {
		let searchModels = this.store.getSearchModels();

		if (searchModels.length === 0) return;

		this.isLoading = true;

		let promises: Promise<RxOrderModel>[] = [];

		for (let i = 0; i < searchModels.length; i++) {
			let model = searchModels[i];

			promises.push(
				this.store.matchMed(model.drugName, model.ndc).then((result: any) => {
					let orderItem: RxOrderModel = new RxOrderModel();
					orderItem.erxType = "medication";

					if (!result) {
						orderItem.selectedMedication = new MedicationSearchResultModel();
						orderItem.selectedMedication.DrugName = model.drugName;

						orderItem.Directions = model.directions;
					} else {
						this.stepOneCompleted = true;

						orderItem.selectedMedication = new MedicationSearchResultModel();
						orderItem.selectedMedication.DrugName = result.DisplayName;
						orderItem.selectedMedication.Strength = result.Strength;

						orderItem.Directions = model.directions;

						orderItem.IsControl = result.Schedule !== "0";
						orderItem.notControlled = !this.selectedOrder.IsControl;
						orderItem.DrugName = result.DisplayName;
						orderItem.Ndc = result.NDC;
						orderItem.DispenseUnitId = result.DispenseUnitId;
						orderItem.lexiDrugSynId = result.LexiDrugSynId;
						orderItem.lexiGenProductId = result.LexiGenProductId;
						orderItem.lexiSynonymId = result.LexiSynonymTypeId;
						orderItem.dispenseUnitName = this.store.units.get().find((unit: any) => unit.StandardDispenseUnitTypeID === result.DispenseUnitId).SingularOrPlural;
					}

					return orderItem;
				})
			);
		}

		Promise.all(promises).then((items: RxOrderModel[]) => {
			this.rxOrders = items;
			this.selectedOrder = this.rxOrders[0];

			if (!this.selectedOrder.lexiDrugSynId) {
				this.stepOneCompleted = false;
			}

			this.isLoading = false;
		});
	}

	init() {
		if (this.type === "patientErx") {
			this.store.setCustomer(null);
			this.store.getOrderPreferences();
			this.store.getUnits();

			this._patientStore.Patient.pipe(takeUntil(this.destroyed)).subscribe((patient: PatientInfoModel) => {
				this.dosespotPatientId = null;

				if (!patient.patientId) return;

				this.store.setupPatient(patient.patientId).then(
					(result: PatientUpdateResult) => {
						this.patient = patient;
						this.loadPrescribers();

						if (result.errors && result.errors.length > 0) {
							let errorMessage = "Patient updates required:\n";

							result.errors.forEach((itemError, index) => {
								errorMessage += `${itemError.propertyName}: \n`;
								itemError.errors.forEach(error => {
									errorMessage += ` - ${error.propertyName}: ${error.errorMessage}\n`;
								});
							});

							this._statusMessageService.changeStatusMessage("error", errorMessage, 10000);
							this.doGoBackRouting();
						} else {
							this.dosespotPatientId = result.dosespotPatientId;
							this.ready.set(true);
						}
					},
					(error: string) => {
						this._statusMessageService.changeStatusMessage("error", "Unable to configure patient in Dosespot - " + error, 10000);
						this.doGoBackRouting();
					}
				);
			});

			this._effectiveDt = new DateOnly();
		} else if (this.type === "preferredErx") {
			// If this is a Preferred eRx, temporarily set this.patient to be a 'fake' patient,
			// so that <ocp-form-erx-step-one> doesn't throw an error for a missing patient
			let fakePatient = new PatientInfoModel();
			this.patient = fakePatient;

			this._route.params.pipe(takeUntil(this.destroyed)).subscribe((p: Params) => {
				this.customerId = +p["partnerId"];

				if (this.customerId) {
					this.isCustomerPreferred = true;

					this._customerService.getCustomerProfile(this.customerId).subscribe((customer: any) => {
						if (!customer.CustomerId) return;
						this.customer = customer;
						this.store.setCustomer(this.customerId);
						this.store.getOrderPreferences();
						this.store.getUnits();
						this.ready.set(true);
					});
				} else if (this.mode) {
					this.store.setCustomer(null);
					this.store.getOrderPreferences();
					this.store.getUnits();
					this.ready.set(true);
				}
			});
		}
	}

	prescribers: any[] = [];
	selectedClinicianId: number;
	prescribersLoading: boolean = true;

	private loadPrescribers() {
		this._customerService.getCustomerClinicians(this._userService.selectedCustomerId).subscribe(
			(results: Clinician[]) => {
				const options: any[] = [];
				for (let i = 0; i < results.length; i++) {
					options.push({ text: results[i].lastName + ", " + results[i].firstName, value: results[i].clinicianId });
				}

				this.prescribers = _.orderBy(options, ["text"]);
				this.prescribersLoading = false;

				if (this.user?.UserId > 0) {
					let prescriberUser = results.find(p => +p.userId == this.user.UserId);

					if (prescriberUser) {
						this.selectedClinicianId = prescriberUser.clinicianId;
						this.selectedPrescriber = this.prescribers.find(p => p.value == this.selectedClinicianId);
					}
				}
			},
			err => (this.prescribersLoading = false),
			() => (this.prescribersLoading = false)
		);
	}

	getUserFullName(firstName: string, lastName: string) {
		return firstName + " " + lastName;
	}

	getSubmitButtonText(type: string) {
		if (this.mode === "edit") {
			return "Save Changes";
		} else if (type === "patientErx") {
			return "Submit Order";
		} else if (type === "preferredErx") {
			return "Save eRx";
		}
	}

	savePreferredChanged(bool: boolean) {
		this.saveAsPreferred = bool;
	}

	editOrderSettings() {
		this.editOrderSettingsModal.open("xs");
	}

	changePharmacy() {
		this.changePharmacyModal.open("lg");
	}

	updatePharmacy(pharmacy: PharmacySearchResult) {
		this.selectedPharmacy = pharmacy;
	}

	updateEffectiveDate(date: any) {
		if (!date) return;

		if (typeof date != "object") {
			this._effectiveDt.setDate(new Date(date));
		} else {
			this._effectiveDt.setDate(date);
		}
	}

	updateSelectedPrescriber(clinicianId: string) {
		this.selectedClinicianId = +clinicianId;
		this.selectedPrescriber = this.prescribers.find(p => p.value == clinicianId);
	}

	updateSettings(date: Date) {
		this.updateEffectiveDate(date);
		this.dismissSettingsModal();
		this._statusMessageService.changeStatusMessage("success", "Order settings successfully updated.");
	}

	dismissSettingsModal() {
		this.editOrderSettingsModal.dismiss();
	}

	backToStepOne() {
		this.stepOneCompleted = false;

		if (!this.selectedOrder.selectedMedication) return;

		this.selectedOrder.selectedMedication.Strength = "";

		if (this.selectedOrder.tempMedicationName && this.selectedOrder.tempMedicationName.trim().length > 0) {
			this.selectedOrder.selectedMedication.DrugName = this.selectedOrder.tempMedicationName;
		} else {
			this.selectedOrder.selectedMedication.DrugName = this.selectedOrder.DrugName.split(" ")[0];
		}

		this.scrollToFormTop();
	}

	moveToStepTwo() {
		this.stepOneCompleted = true;
		this.scrollToFormTop();
	}

	addNewItem() {
		let newRx = new RxOrderModel();
		newRx.PatientId = this.patient.patientId;
		newRx.erxType = "medication";

		this.rxOrders.push(newRx);
	}

	selectRx(rx: RxOrderModel) {
		this.selectedOrder = rx;

		if (!this.selectedOrder.DrugName) {
			this.stepOneCompleted = false;
		} else {
			this.stepOneCompleted = true;
		}

		this.scrollToFormTop();
	}

	loadPreferredRx(rx: RxOrderModel) {
		if (rx.children.length === 0) {
			this.rxOrders.push(rx);
			this.selectRx(rx);
		} else {
			this.rxOrders = [...this.rxOrders, ...rx.children];
			this.selectRx(rx.children[0]);
		}

		this.rxOrders = this.rxOrders.filter(rx => rx.DrugName && rx.DrugName.trim().length > 0);

		this.scrollToFormTop();
	}

	remove(idx: number) {
		this.rxOrders.splice(idx, 1);
	}

	backToErxOrders(showSigningModal: boolean = false) {
		this._router.navigate(["../detail"], { relativeTo: this._route });

		setTimeout(() => {
			if (showSigningModal) {
				this.store.promptToSign();
			}
			this._redirectService.loadErxTab.emit();
		}, 300);
	}

	backToUserPreferredErx() {
		this._router.navigate(["../manage-preferred-erx"], { relativeTo: this._route });
	}

	backToCustomerPreferredErx() {
		this._router.navigate(["../"], { relativeTo: this._route });

		setTimeout(() => {
			this._redirectService.loadErxTab.emit();
		}, 300);
	}

	cancelErx() {
		this.confirmCancelModal.open("xs");
	}

	confirmCancelErx() {
		this.confirmCancelModal.dismiss();
		this.doGoBackRouting();
	}

	doGoBackRouting(showSigning: boolean = false) {
		if (this.type === "patientErx") {
			this.backToErxOrders(showSigning);
		} else if (this.type === "preferredErx" && this.isCustomerPreferred) {
			this.backToCustomerPreferredErx();
		} else {
			this.backToUserPreferredErx();
		}
	}

	handleSaveButtonClick(fromPreference: boolean = false) {
		var validations = this.validateForm();

		if (validations.length > 0) {
			this._statusMessageService.changeStatusMessage("error", validations.join("\n"), 10000);
			return;
		}

		if (this.type === "preferredErx") {
			this.saveErx().then(
				() => {
					this.doGoBackRouting();
				},
				(error: string) => {
					this._statusMessageService.changeStatusMessage("error", error, 15000);
				}
			);
		} else if (this.saveAsPreferred && !fromPreference) {
			this.confirmSaveAsPreferredModal.open("md");
		} else {
			if (this.confirmSaveAsPreferredModal.isOpen) {
				this.confirmSaveAsPreferredModal.dismiss();
			}

			this.saveErx().then(
				() => {
					let canAuthorize: boolean = this.isPrescriber || (this.isPrescribingAgent && this.rxOrders.some(rx => rx.notControlled));

					if (!canAuthorize) {
						this._statusMessageService.changeStatusMessage("success", "Order submitted successfully. Please ask your prescriber to authorize the order.", 5000);
					}

					this.doGoBackRouting(canAuthorize);
				},
				(error: string) => {
					this._statusMessageService.changeStatusMessage("error", error, 15000);
				}
			);
		}
	}

	saveErx(): Promise<void> {
		return new Promise<void>((resolve, reject) => {
			this.rxOrders = this.rxOrders.map(rx => {
				if (rx.erxType !== "medication") {
					rx.selectedMedication.Strength = "";
					rx.dispenseUnitName = this.store.units.get().find((unit: any) => unit.StandardDispenseUnitTypeID === rx.DispenseUnitId)?.SingularOrPlural ?? "";
				}

				return rx;
			});

			let lineItems: LineItem[] = OrderBuilder.fromOrders(this.rxOrders);

			console.log(lineItems);

			if (lineItems.length === 0) {
				reject("No line items found for order");
				return;
			}

			if (this.type == "patientErx") {
				var order = new NewOrder();
				order.ClinicianId = +this.selectedClinicianId;
				order.EffectiveDate = this.effectiveDate;
				order.LineItems = lineItems;

				let pharmacy = new DosespotPharmacy();
				pharmacy.Address = this.selectedPharmacy.street1 + " " + this.selectedPharmacy.street2;
				pharmacy.City = this.selectedPharmacy.city;
				pharmacy.Name = this.selectedPharmacy.pharmacyName;
				pharmacy.PhoneOrFax = this.selectedPharmacy.phone;
				pharmacy.State = this.selectedPharmacy.state;
				pharmacy.Zip = this.selectedPharmacy.zip;
				pharmacy.UniquePharmacyId = this.selectedPharmacy.id;

				order.Pharmacy = pharmacy;

				if (this.saveAsPreferred && this.preferredNickname && this.preferredNickname.trim().length > 0) {
					order.Nickname = this.preferredNickname;
				}

				this.saving = true;
				this.store.addOrder(this.patient.patientId, order).then((result: NewOrderResult) => {
					this.saving = false;

					let errors: string[] = [];
					for (let i = 0; i < result.results.length; i++) {
						if (result.results[i].success) {
							this.rxOrders[i].prescriptionId = result.results[i].prescriptionId;
							this.rxOrders[i].isSent = true;
							this.rxOrders[i].isRejected = false;
							this.rxOrders[i].dosespotError = "";
						} else {
							let errorMessage = result.results[i].errors.map(e => `${e.propertyName}: ${e.errorMessage}`).join("\n");
							errors.push(errorMessage);

							this.rxOrders[i].isSent = false;
							this.rxOrders[i].isRejected = true;
							this.rxOrders[i].dosespotError = errorMessage;
							this.includesRejectedRx = true;
						}
					}

					if (errors.length > 0) {
						let errorMessage = "Some items failed to process:\n" + errors.join("\n");
						reject(errorMessage);
					} else {
						resolve();
					}
				});
			} else if (this.type == "preferredErx") {
				if (this.isCustomerPreferred) {
					let facPreference: FacilityPreference = new FacilityPreference();
					facPreference.CustomerId = this.customerId;
					facPreference.Nickname = this.preferredNickname;
					facPreference.LineItems = lineItems;

					this.saving = true;
					if (this.mode == "edit") {
						this.store.updateCustomerPreference(this._preferenceId, facPreference).then((result: any) => {
							this.saving = false;
							resolve();
						});
					} else {
						this.store.addCustomerPreference(facPreference).then((result: any) => {
							this.saving = false;
							resolve();
						});
					}
				} else {
					let preference: UserPreference = new UserPreference();
					preference.nickname = this.preferredNickname;
					preference.lineItems = lineItems;

					this.saving = true;
					if (this.mode == "edit") {
						this.store.updateUserPreference(this._preferenceId, preference).then((result: any) => {
							this.saving = false;
							resolve();
						});
					} else {
						this.store.addUserPreference(preference).then((result: any) => {
							this.saving = false;
							resolve();
						});
					}
				}
			}
		});
	}

	scrollToFormTop() {
		const elMobile = this.orderFormStartMobile.nativeElement;
		const elDesktop = this.orderFormStartDesktop.nativeElement;

		if (window.innerWidth < 768) {
			elMobile.scrollIntoView({ behavior: "smooth" });
		} else {
			elDesktop.scrollIntoView({ behavior: "smooth" });
		}
	}

	realignTabInk() {
		// Used to notify mat-tab-group in FormChangePharmacy to realignInkBar()
		this._patientStore.realignTabInkBar.emit();
	}
}
