import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";

@Component({
	selector: "select-menu",
	templateUrl: "./select-menu.template.html",
	styleUrls: ["./select-menu.scss"]
})
export class SelectMenuComponent implements OnInit {
	@Input() selectedItem: any = null;
	@Input() controlName: string;
	@Input() options: any[];
	@Input() placeholderText: string;
	@Input() isRequired: boolean;
	@Input() focus: EventEmitter<any> = new EventEmitter<any>();
	@Input() disabled: boolean = false;

	isInvalid: boolean = false;

	ngOnInit() {
		if (!this.placeholderText) {
			this.placeholderText = "Select...";
		}

		setTimeout(() => {
			let found = this.options.map(v => v.value).some(i => i == this.selectedItem);
			if (this.isRequired && !found) {
				this.isInvalid = true;
			} else {
				this.isInvalid = false;
			}
		}, 200)
	}

	valueChanged() {
		this.onSelect.emit(this.selectedItem);
		this.isInvalid = this.isRequired && !this.selectedItem && this.selectedItem != 0;
	}

	@Output() onSelect: EventEmitter<string> = new EventEmitter<string>();

	constructor() {}
}
