/**
 * Created by John on 2017-01-20.
 */
import { Injectable } from "@angular/core";
import { ISearchService } from "../shared/components/patient-search/search.interface";
import { PatientCareStore } from "./services/patient-care.store";

@Injectable()
export class PatientSearchService implements ISearchService {
	constructor(private _patientService: PatientCareStore) {}

	search(searchText: string) {
		return this._patientService.search(searchText);
	}
}
