export class SystemAlert {
    id: number;
    pharmacyIds: number[];
    enableAllPharmacies: boolean;

    endDateUTC: Date;
    startDateUTC: Date;

    startDateStringUTC: string;
    endDateStringUTC: string; 

    endDate: Date;
    startDate: Date;

    startTime: string;
    endTime: string;

    text: string;
    pharmacies: PharmacyItem[];

    status: string;

    deserialize(obj: any): SystemAlert {
        let a: SystemAlert = Object.assign(this, obj);
        if (a.endDateStringUTC){
            a.endDate = new Date(a.endDateStringUTC + " UTC");
            let parts = a.endDate.toTimeString().split(" ")[0].split(":");
            a.endTime = parts[0] + ":" + parts[1];
        }

        if (a.startDateStringUTC){
            a.startDate = new Date(a.startDateStringUTC + " UTC");
            let parts = a.startDate.toTimeString().split(" ")[0].split(":");
            a.startTime = parts[0] + ":" + parts[1];
        }
        
        return a;
    }
}


export class PharmacyItem {
    PharmacyId: number;
    Name: string;
}