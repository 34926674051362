import { Component, Output, EventEmitter, Input, OnInit } from "@angular/core";
import { UserService } from "../../services/user.service";
import { RoleModel } from "../../models/role.model";
import { ApiResult } from "../../models/api-result.model";
import { UserListItemModel } from "../../models/user-list-item.model";
import { Permissions } from "../../enums/permissions";
import { AuthService } from "../../services/auth.service";
import { StatusMessageService } from "../status-message/status-message.service";
import { MultiSelectItemModel } from "../input-multi-select/multi-select-item.model";
import { CustomerTeamModel } from "../../models/customer-team.model";
import { UserTeamModel } from "../../models/user-team.model";
import { CustomerService } from "../../services/customer.service";

@Component({
	selector: "user-role",
	templateUrl: "./user-role.template.html",
	styleUrls: ["./user-role.css"]
})
export class UserRole implements OnInit {
	saving: boolean = false;
	userRoles: MultiSelectItemModel[] = [];
	selectedUserRoles: number[] = [];
	permissions: any = Permissions;

	userTeams: MultiSelectItemModel[] = [];
	selectedTeams: number[] = [];

	@Input() userInfo: UserListItemModel;
	@Input() customerId: number;
	@Input() isInternal: boolean = false;
	@Input() showTeams: boolean = false;

	@Output() closeModal: EventEmitter<string> = new EventEmitter<string>();
	@Output() saveSuccess: EventEmitter<string> = new EventEmitter<string>();

	get canEdit(): boolean {
		return this._authService.hasPermission(this.permissions.editExternalUserRoles) || this._authService.hasPermission(this.permissions.administration);
	}

	constructor(private _userService: UserService, private _authService: AuthService, private _statusMessageService: StatusMessageService, private _customerService: CustomerService) {}

	ngOnInit() {
		if (!this.isInternal) this.initCustomerTeamList();

		this._userService.getUserRoles(this.userInfo.UserId, this.customerId).subscribe((roles: RoleModel[]) => {
			this.userRoles = this.getUserRolesMultiSelect(roles);
		});
	}

	save() {
		this.saving = true;

		this.saveRoles().then((result: boolean) => {
			if (result && !this.isInternal && this.showTeams) {
				this.saveTeams().then((result2: boolean) => {
					this.saving = false;

					if (result2) {
						this.saveSuccess.emit();
					}
				});
			} else if (result && (this.isInternal || !this.showTeams)) {
				this.saving = false;
				this.saveSuccess.emit();
			} else {
				this.saving = false;
			}
		});
	}

	private saveRoles(): Promise<boolean> {
		return new Promise<boolean>(resolve => {
			this._userService.setUserRoles(this.userInfo.UserId, this.getRoleModels()).subscribe((result: ApiResult) => {
				if (result.Success) {
					resolve(true);
				} else {
					this._statusMessageService.changeStatusMessage("error", result.PublicMessage);
					resolve(false);
				}
			});
		});
	}

	private saveTeams(): Promise<boolean> {
		return new Promise<boolean>(resolve => {
			this._userService.setUserTeams(this.userInfo.UserId, this.getUserTeamModels()).subscribe((result: ApiResult) => {
				if (result.Success) resolve(true);
				else {
					this._statusMessageService.changeStatusMessage("error", result.PublicMessage);
					resolve(false);
				}
			});
		});
	}

	private getUserRolesMultiSelect(roles: RoleModel[]) {
		let userRoles: MultiSelectItemModel[] = [];
		roles.forEach((x: RoleModel) => {
			userRoles.push({
				id: x.roleId,
				text: x.roleName,
				selected: x.isEnabled
			});
		});
		return userRoles;
	}

	private getRoleModels() {
		let userRoles: RoleModel[] = [];
		this.userRoles.forEach((x: MultiSelectItemModel) => {
			userRoles.push({
				roleId: x.id,
				roleName: x.text,
				isEnabled: x.selected,
				id: null
			});
		});
		return userRoles;
	}

	private initCustomerTeamList() {
		if (this.userInfo.UserId) {
			this._userService.getUserTeams(this.userInfo.UserId, this.customerId).subscribe((teams: UserTeamModel[]) => {
				this.userTeams = this.getUserTeamsMultiSelect(teams);
			});
		} else {
			this._customerService.getCustomerTeamList(this.customerId, false, false).subscribe((teams: CustomerTeamModel[]) => {
				this.userTeams = this.getCustomerTeamsMultiSelect(teams);
			});
		}
	}

	private getCustomerTeamsMultiSelect(teams: CustomerTeamModel[]) {
		let userTeams: MultiSelectItemModel[] = [];
		teams.forEach((x: CustomerTeamModel) => {
			userTeams.push({
				id: x.CustomerTeamId,
				text: x.TeamName,
				selected: false
			});
		});
		return userTeams;
	}

	private getUserTeamsMultiSelect(teams: UserTeamModel[]) {
		let userTeams: MultiSelectItemModel[] = [];
		if (teams && teams.length) {
			teams.forEach((x: UserTeamModel) => {
				if (x.Permitted) {
					this.selectedTeams.push(x.CustomerTeamId);
				}
				userTeams.push({
					id: x.CustomerTeamId,
					text: x.TeamName,
					selected: x.Permitted
				});
			});
		}
		return userTeams;
	}

	private getUserTeamModels() {
		let userTeams: UserTeamModel[] = [];
		this.userTeams.forEach((x: MultiSelectItemModel) => {
			userTeams.push({
				CustomerTeamId: x.id,
				TeamName: x.text,
				Permitted: x.selected,
				CustomerId: null,
				CustomerName: null,
				Location: null
			});
		});
		return userTeams;
	}
}
