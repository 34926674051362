import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { ErxOrderStore } from "src/app/ocp/shared/services/erx-order.store";
import { RxOrderModel } from "../../../shared/models/rx-order.model";

@Component({
	selector: "ct-erx-summary",
	templateUrl: "./erx-summary.component.html",
	styleUrls: ["./erx-summary.component.scss"]
})
export class ErxSummaryComponent implements OnInit {
	showDetails: boolean = false;

	@Input() erx: RxOrderModel;
	@Input() isPrescriber: boolean = false;
	@Input() isPrescribingAgent: boolean = false;
	@Input() allowErx: boolean = false;

	@Output() onMenuAction: EventEmitter<any> = new EventEmitter<any>();

	constructor(public erxStore: ErxOrderStore) {}

	ngOnInit(): void {}

	showMore() {
		this.showDetails = !this.showDetails;
	}

	showRefillButton(erxStatus: string): boolean {
		if (!this.allowErx) return false;

		if (+erxStatus < 0) return true;

		var terminalStatuses: number[] = [6, 7, 10, 13];

		return terminalStatuses.includes(+erxStatus);
	}

	showEditButton(erxStatus: string): boolean {
		if (!this.allowErx) return false;

		if (+erxStatus < 0) return false;

		var allowEditStatuses: number[] = [1, 2, 5, 6, 8, 9, 10, 12];

		return allowEditStatuses.includes(+erxStatus);
	}

	actionClick(type: string, rx: RxOrderModel) {
		this.onMenuAction.emit({ type, rx });
	}

	isReadyToSign(erxStatus: string): boolean {
		if (+erxStatus < 0 || (!this.isPrescriber && !this.isPrescribingAgent)) return false;

		if (this.erx.IsControl && !this.isPrescriber) return false;

		var signingStatuses: number[] = [1, 2, 8, 9, 12];

		return signingStatuses.includes(+erxStatus);
	}
}
