import {Injectable} from "@angular/core";
import {HttpService} from "./http.service";
import {UserReportPermissionModel} from "../models/user-report-permission.model";

@Injectable()
export class ReportingService {
  private _getUserReportsUrl: string = 'api/reporting/getuserreports';
  private _getUserReportPermissionsUrl: string = 'api/reporting/getuserreportpermissions?userId=';
  private _updateUserReportPermissionsUrl: string = 'api/reporting/setreportpermissions';
  private _setReportViewer: string = 'api/reporting/setreportviewer';

  constructor(private _http: HttpService) {
  }

  getUserReports() {
    return this._http.get(this._getUserReportsUrl);
  }

  getUserReportPermissions(userId: number) {
    return this._http.get(this._getUserReportPermissionsUrl + userId);
  }

  updateUserReportPermissions(userId: number, reportPermissions: UserReportPermissionModel[]) {
    return this._http.post(this._updateUserReportPermissionsUrl, {
      UserId: userId, ReportPermissions: reportPermissions
    });
  }

  setReportViewer(reportId: number) {
    return this._http.post(this._setReportViewer, reportId);
  }
}
