import { MultiSelectItemModel } from "../../components/input-multi-select/multi-select-item.model";
import { ReportParameterValue } from "./report-parameter-value.model";

export class ReportParameter {
    Name: string;
    Type: string;
    Nullable: boolean;
    AllowBlank: boolean;
    MultiValue: boolean;
    QueryParameter: boolean;
    Prompt: string;
    PromptUser: boolean;
    Dependencies: string[];
    ValidValues: ReportParameterValue[];
    DefaultValues: string[];
    State: number;

    options: MultiSelectItemModel[];
    value: string;
    label: string;

    deserialize(input: any): ReportParameter {
        Object.assign(this, input);

        if (this.ValidValues && this.ValidValues.length > 0) {
            this.options = this.ValidValues.map(v => {
                let item: MultiSelectItemModel = new MultiSelectItemModel();
                item.id = v.Value;
                item.text = v.Label;
                item.selected = this.DefaultValues && this.DefaultValues.length > 0 ? this.DefaultValues.indexOf(v.Value) >= 0 : false;
                return item;
            });
        }

        if (this.DefaultValues && this.DefaultValues.length === 1) {
            this.value = this.DefaultValues[0];
        }

        this.label = this.Prompt && this.Prompt.trim().length > 0 ? this.Prompt : this.Name;

        return this;
    }
}