import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Clinician } from "../../../models/clinician-model";

@Component({
	selector: "ocp-modal-order-settings",
	templateUrl: "./modal-order-settings.component.html"
})
export class ModalOrderSettingsComponent implements OnInit {
	@Input() effectiveDate: Date;
	@Input() selectedPrescriber: number;
	@Input() prescribers: any[] = [];

	@Output() onCancelSaveSettings: EventEmitter<any> = new EventEmitter<any>();
	@Output() onSaveEffectiveDate: EventEmitter<Date> = new EventEmitter<Date>();
	@Output() onSaveSelectedPrescriber: EventEmitter<number> = new EventEmitter<number>();

	constructor() {}

	ngOnInit(): void {}

	cancelChanges() {
		this.onCancelSaveSettings.emit();
	}

	confirmChanges() {
		this.onSaveEffectiveDate.emit(this.effectiveDate);
		this.onSaveSelectedPrescriber.emit(this.selectedPrescriber);
	}
}
