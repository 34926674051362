import {Injectable} from "@angular/core";
import { DiagnosisSearchResultModel } from "src/app/ocp/shared/models/diagnosis-search-results.model";
import {ISearchService} from "../../../shared/components/patient-search/search.interface";
import {DiagnosisService} from "./diagnosis.service";

@Injectable()
export class DiagnosisSearchService implements ISearchService{
  constructor(private _diagnosisService:DiagnosisService){}

  search(text:string){
    return this._diagnosisService.search(text);
  }

  getTopFiveDiagnosis(): Promise<DiagnosisSearchResultModel[]> {
    return new Promise<DiagnosisSearchResultModel[]> (resolve => {
      this._diagnosisService.getTopFiveDiagnosis().then((results: DiagnosisSearchResultModel[]) => {
        resolve(results);
      })
    })
  }
}
