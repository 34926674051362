import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Pharmacy } from '../../../models/pharmacy.model';

@Component({
  selector: 'ct-selected-pharmacy-card',
  templateUrl: './selected-pharmacy-card.component.html',
  styleUrls: ['./selected-pharmacy-card.component.scss']
})
export class SelectedPharmacyCardComponent implements OnInit {
  @Input() pharmacy: Pharmacy;
  @Input() disableChange: boolean = false;

  @Output() onChangePharmacy: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor() { }

  ngOnInit(): void {
  }

  changePharmacy() {
    this.onChangePharmacy.emit();
  }
}
