import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'multi-select-user-role',
  templateUrl: './multi-select-user-role.template.html',
  styleUrls: ['./multi-select-user-role.css']
})

export class MultiSelectUserRole implements OnInit {
  @Input() fieldName: string;
  @Input() labelText: string;
  @Input() placeholderText: string;
  @Input() itemList: any[];
  @Input() ngModelValue: any[];

  constructor(){}

  ngOnInit(){}
}
