import {Component, OnInit, Input,} from '@angular/core';
import {PatientService} from "../../../shared/services/patient.service";
import {ClinicalNotesModel} from "../../../shared/models/clinical-notes.model";

@Component({
  selector: 'clinical-notes',
  templateUrl: './clinical-notes.template.html',
  styleUrls: ['./clinical-notes.scss']
})

export class ClinicalNotesComponent implements OnInit {
  loading: boolean = false;
  selectedRow: number;
  setClickedRow: Function;

  @Input() clinicalNotes: ClinicalNotesModel[] = [];

  @Input() patientId: number;

  constructor(private _patientService: PatientService) {
  }

  ngOnInit() {
    if(this.patientId) {
      this.loading = true;
      this._patientService.getClinicalNotesForPatient(this.patientId).subscribe((clinicalNotes: ClinicalNotesModel[]) => {
        this.loading = false;
        this.clinicalNotes = clinicalNotes;
      });
    }

    this.setClickedRow = function(index){
      this.selectedRow = index;
    }
  }
}
