import { ReportServer } from "./report-server.model";

export class SubscribableReport {
	ReportServerId: number;
	ReportId: number;
	ReportName: string;
	ReportPath: string;
	ReportDesc: string;
	IsActive: number;
	ReportServer: ReportServer;
	OcpReportId: number;

	deserialize(input: any): SubscribableReport {
		this.ReportServerId = input.ReportServerId;
		this.ReportId = input.ReportId;
		this.ReportName = input.ReportName;
		this.ReportPath = input.ReportPath;
		this.ReportDesc = input.ReportDesc;
		this.IsActive = input.IsActive;

		if (input.ReportServer) this.ReportServer = new ReportServer().deserialize(input.ReportServer);

		this.OcpReportId = input.OcpReportId;

		return this;
	}
}
