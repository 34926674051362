/**
 * Created by John on 2016-03-08.
 */
import { Injectable } from "@angular/core";

@Injectable({
  providedIn: "root"
})
export class CacheService {
  private _cacheSeparator: string = "__cacheService_";

  get(key: string, durable: boolean = false): any {
    var obj = durable ? localStorage.getItem(this._cacheSeparator + key) : sessionStorage.getItem(this._cacheSeparator + key);

    if (obj) {
      var objVal = JSON.parse(obj);
      return objVal.d;
    }

    return null;
  }

  set(key: string, data: string, durable: boolean = false) {
    var jData = JSON.stringify({ d: data });

    if (durable) {
      localStorage.setItem(this._cacheSeparator + key, jData);
    } else {
      sessionStorage.setItem(this._cacheSeparator + key, jData);
    }
  }

  remove(key: string, durable: boolean = false) {
    if (durable) {
      localStorage.removeItem(this._cacheSeparator + key);
    } else {
      sessionStorage.removeItem(this._cacheSeparator + key);
    }
  }

  clear() {
    var arr = [];

    for (var i = 0; i < sessionStorage.length; i++) {
      if (sessionStorage.key(i).substring(0, this._cacheSeparator.length) === this._cacheSeparator) {
        arr.push(sessionStorage.key(i));
      }
    }

    for (var j = 0; j < arr.length; j++) {
      sessionStorage.removeItem(arr[j]);
    }
  }

  exists(key: string, durable: boolean = false): boolean {
    var obj = durable ? localStorage.getItem(this._cacheSeparator + key) : sessionStorage.getItem(this._cacheSeparator + key);

    if (obj)
      return true;

    return false;
  }
}
