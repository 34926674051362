import { Component, Input, Output, EventEmitter, ViewChild, OnInit } from "@angular/core";
import { ModalComponent } from "../modal/modal";
import { CustomerAuthenticationModel } from "../../models/customer-authentication.model";

@Component({
	selector: "form-customer-authentication",
	templateUrl: "./form-customer-authentication.template.html",
	styleUrls: ["./form-customer-authentication.css"]
})
export class FormCustomerAuthenticationComponent implements OnInit {
	@Input() step: number;
	@Input() customerAuth: CustomerAuthenticationModel;
	@Input() editMode: string;

	@Output() onClose: EventEmitter<string> = new EventEmitter<string>();
	@Output() saveSuccess: EventEmitter<CustomerAuthenticationModel> = new EventEmitter<CustomerAuthenticationModel>();
	@Output() certFileUploadSuccess: EventEmitter<string> = new EventEmitter<string>();

	@ViewChild("authEditModal") authEditModal: ModalComponent;

	constructor() {}

	ngOnInit() {}

	goToStep(step: number) {
		this.step = step;

		switch (step) {
			case 1:
				break;
			case 2:
				break;
			default:
				this.step = 1;
				break;
		}
	}

	showDialog(authModel: CustomerAuthenticationModel, step?: number) {
		if (step) {
			this.step = step;
		}
		this.customerAuth = authModel;
		this.authEditModal.open("md");
	}

	closeDialog() {
		this.authEditModal.dismiss();

		let self = this;
		setTimeout(function() {
			self.step = 1;
		}, 300);
	}
}
