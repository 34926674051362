export class AdminReport {
    id: number;
    categoryId: number;
    category: string;
    name: string;
    description: string;

    deserialize(obj: any): AdminReport {
        return Object.assign(this, obj);
    }
}