import { NgModule } from "@angular/core";

import { SharedModule } from "../shared/shared.module";
import { PatientCare } from "./patient-care.component";
import { PatientDetails } from "./patient-details.component";
import { patientCareRouting } from "./patient-care.routes";
import { AdmitPatientModalComponent } from "./components/admit-patient-modal/admit-patient-modal.component";
import { AdmissionsDetailsComponent } from "./components/admit-patient-modal/admissions-details/admissions-details.component";
import { FormPatientProfile } from "./components/form-patient-profile/form-patient-profile.component";
import { FormPatientConfirmation } from "./components/admit-patient-modal/form-patient-confirmation/form-patient-confirmation.component";
import { PatientDiagnosisTab } from "./components/patient-details-tabs/patient-diagnosis-tab.component";
import { PatientProfileTab } from "./components/patient-details-tabs/patient-profile-tab.component";
import { PatientRxTab } from "./components/patient-details-tabs/patient-rx-tab.component";
import { PatientAllergiesTab } from "./components/patient-details-tabs/patient-allergies-tab.component";
import { AllergyScreeningModalComponent } from "./profile-order/allergy-screening-modal/allergy-screening-modal.component";
import { DrugScreeningModalComponent } from "./profile-order/drug-screening-modal/drug-screening-modal.component";
import { InputTextBoxMrnComponent } from "./components/form-patient-profile/input-textbox-mrn/input-textbox-mrn.component";
import { FormAdmissionDetails } from "./components/form-admission-details/form-admission-details.component";
import { FormEditAdmissionsComponent } from "./components/form-edit-admissions/form-edit-admissions.component";
import { FormQuickRefill } from "./components/form-quick-refill/form-quick-refill.component";
import { FormProfileMedication } from "./profile-order/form-profile-medication/form-profile-medication.component";
import { FormMultiProfileMedication } from "./components/form-multi-profile-medication/form-multi-profile-medication.component";
import { FormPatientAllergies } from "./components/form-patient-allergies/form-patient-allergies.component";
import { FormPatientDiagnosis } from "./components/form-patient-diagnosis/form-patient-diagnosis.component";
import { FormResolveDiagnosis } from "./components/patient-details-tabs/form-resolve-diagnosis/form-resolve-diagnosis.component";
import { DiscontinueRxComponent } from "./components/patient-details-tabs/form-discontinue-rx/form-discontinue-rx.component";
import { FormRefillRxComponent } from "./components/patient-details-tabs/form-refill-rx/form-refill-rx.component";
import { FormEditAllergyComponent } from "./components/form-edit-allergy/form-edit-allergy.component";
import { AllergyScreeningComponent } from "./components/allergy-screening/allergy-screening.component";
import { ErxIframeComponent } from "./components/erx-iframe/erx-iframe.component";
import { ClinicalNotesComponent } from "./components/clinical-notes/clinical-notes.component";
import { PlanOfTreatmentComponent } from "./components/plan-of-treatment/plan-of-treatment.component";
import { DiagnosisSummary } from "./components/diagnosis-summary/diagnosis-summary.component";
import { DrugScreeningComponent } from "./components/drug-screening/drug-screening.component";
import { AllergySummary } from "./components/allergy-summary/allergy-summary.component";
import { PatientSummary } from "./components/patient-summary/patient-summary.component";
import { LabNotesComponent } from "./components/plan-of-treatment/tabs/lab-notes.component";
import { DispenseHistoryComponent } from "./components/table-dispense-history/table-dispense-history.component";
import { MrnOverrideComponent } from "./components/mrn-override-text/mrn-override-text.component";
import { PrescriptionSummary } from "./components/prescription-summary/prescription-summary.component";
import { SelectHospiceComponent } from "./components/select-hospice/select-hospice.component";
import { SelectCustomerTeamComponent } from "./components/select-customer-team/select-customer-team.component";
import { ActiveInactiveStatusTextComponent } from "./components/form-edit-admissions/active-inactive-status-text/active-inactive-status-text.component";
import { NoRelatedDiagnosisWarningComponent } from "./components/form-patient-diagnosis/no-related-diagnosis-warning/no-related-diagnosis-warning.component";
import { DeprescribingOptionsComponent } from "./components/deprescribing-options/deprescribing-options.component";
import { TierReportComponent } from "./components/tier-report/tier-report.component";
import { NewProfileMedicationComponent } from "./components/new-profile-medication/new-profile-medication.component";
import { DeliveryPhysicianDetailsComponent } from "./components/new-profile-medication/components/delivery-physician-details/delivery-physician-details.component";
import { SearchPhysiciansComponent } from "./components/new-profile-medication/components/search-physicians/search-physicians.component";
import { OrderFormPrintoutComponent } from "./components/new-profile-medication/components/order-form-printout/order-form-printout.component";
import { PatientLayoutComponent } from "./patient-layout.component";
import { PatientCareStore } from "./services/patient-care.store";
import { ProfileOrderComponent } from "./profile-order/profile-order.component";
import { FormAddErxComponent } from "./components/patient-details-tabs/form-add-erx/form-add-erx.component";
import { FormEditInstructionsComponent } from "./components/patient-details-tabs/form-edit-instructions/form-edit-instructions.component";
import { AdmissionSummaryComponent } from "./components/admission-summary/admission-summary.component";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatFormFieldModule } from "@angular/material/form-field";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatInputModule } from "@angular/material/input";
import { MatOptionModule } from "@angular/material/core";
import { MatExpansionModule } from "@angular/material/expansion";
import { FormFilterPatientSearchComponent } from './components/form-filter-patient-search/form-filter-patient-search.component';
import { OverlayModule } from "@angular/cdk/overlay";
import { PrescriptionSummaryTableComponent } from './components/prescription-summary-table/prescription-summary-table.component';
import { PatientErxOrdersTab } from './components/patient-details-tabs/patient-erx-orders-tab.component';
import { ErxSummaryTableComponent } from './components/erx-summary-table/erx-summary-table.component';
import { ErxSummaryComponent } from './components/erx-summary/erx-summary.component';
import { ErxStatusHistoryComponent } from './components/erx-status-history/erx-status-history.component';

@NgModule({
	imports: [
		SharedModule, 
		patientCareRouting, 
		MatExpansionModule, 
		MatInputModule, 
		MatAutocompleteModule, 
		MatOptionModule, 
		MatFormFieldModule, 
		FormsModule, 
		ReactiveFormsModule,
		OverlayModule
	],
	declarations: [
		ActiveInactiveStatusTextComponent,
		AdmissionsDetailsComponent,
		AdmitPatientModalComponent,
		AllergyScreeningModalComponent,
		AllergyScreeningComponent,
		AllergySummary,
		ClinicalNotesComponent,
		DeprescribingOptionsComponent,
		DiagnosisSummary,
		DiscontinueRxComponent,
		DispenseHistoryComponent,
		DrugScreeningComponent,
		DrugScreeningModalComponent,
		ErxIframeComponent,
		FormPatientProfile,
		FormPatientConfirmation,
		FormAdmissionDetails,
		FormEditAdmissionsComponent,
		FormQuickRefill,
		FormProfileMedication,
		FormMultiProfileMedication,
		FormPatientAllergies,
		FormPatientDiagnosis,
		FormEditAllergyComponent,
		FormRefillRxComponent,
		FormResolveDiagnosis,
		InputTextBoxMrnComponent,
		LabNotesComponent,
		MrnOverrideComponent,
		NoRelatedDiagnosisWarningComponent,
		PatientProfileTab,
		PatientRxTab,
		PatientAllergiesTab,
		PatientDiagnosisTab,
		PatientCare,
		PatientDetails,
		PatientSummary,
		PlanOfTreatmentComponent,
		PrescriptionSummary,
		SelectCustomerTeamComponent,
		SelectHospiceComponent,
		TierReportComponent,
		NewProfileMedicationComponent,
		DeliveryPhysicianDetailsComponent,
		SearchPhysiciansComponent,
		OrderFormPrintoutComponent,
		PatientLayoutComponent,
		ProfileOrderComponent,
		FormAddErxComponent,
		FormEditInstructionsComponent,
		AdmissionSummaryComponent,
		FormFilterPatientSearchComponent,
		PrescriptionSummaryTableComponent,
		PatientErxOrdersTab,
		ErxSummaryTableComponent,
		ErxSummaryComponent,
		ErxStatusHistoryComponent
	],
	providers: [PatientCareStore]
})
export class PatientCareModule {}
