import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";
import { ClinicalResources } from "./clinical-resources.component";
import { FormulariesTab } from "./components/tabs/formularies.component";
import { NewslettersTab } from "./components/tabs/newsletters.component";
import { SymptomGuideTab } from "./components/tabs/symptom-guide.component";
import { WebinarsTab } from "./components/tabs/webinars.component";

export const routes: Routes = [
  {
    path: "clinical-resources", 
    component: ClinicalResources,
    children: [
      { path: "", redirectTo: "formularies", pathMatch: "full" },
      {
        path: "formularies",
        component: FormulariesTab
      },
      {
        path: "newsletters",
        component: NewslettersTab
      },
      {
        path: "symptom-guide",
        component: SymptomGuideTab
      },
      {
        path: "webinars",
        component: WebinarsTab
      }
    ]
  }
];

@NgModule({
	imports: [RouterModule.forChild(routes)],
	exports: [RouterModule]
})
export class ClinicalResourcesRouting {}