import { Component, OnInit, ViewChild } from "@angular/core";
import { ReportingService } from "../shared/services/reporting.service";
import { ModalComponent } from "../shared/components/modal/modal.component";
import { DomSanitizer } from "@angular/platform-browser";
import { StatusMessageService } from "../shared/components/status-message/status-message.service";
import { ApiResult } from "../shared/models/api-result.model";
import { ActivatedRoute } from "@angular/router";
import { AppConfig } from "../app.config";
import { Permissions } from "../shared/enums/permissions";

@Component({
	selector: "reporting",
	templateUrl: "./view-report.template.html",
	styleUrls: ["./view-report.scss"]
})
export class ViewReportComponent implements OnInit {
	Permissions: any = Permissions;
	isLoading: boolean = false;
	sourceUrlSet: boolean = false;
	reportId: number;
	reportTitle: string = "";

	src: any;

	@ViewChild("reportModal") reportModal: ModalComponent;

	constructor(
		private _activeRoute: ActivatedRoute,
		private _reportingService: ReportingService,
		private _domSanitizer: DomSanitizer,
		private _statusMessageService: StatusMessageService,
		private _config: AppConfig
	) {}

	ngOnInit() {
		this.src = this._domSanitizer.bypassSecurityTrustResourceUrl("");
		this.isLoading = true;
		this._activeRoute.params.subscribe((x: any) => {
			document.body.scrollTop = document.documentElement.scrollTop = 0;
			this.reportId = +x.reportId;
			this.reportTitle = x.reportTitle;
			this.viewReport();
		});
	}

	viewReport() {
		this._reportingService.setReportViewer(this.reportId).subscribe((result: ApiResult) => {
			if (result.Success) {
				this.src = this._domSanitizer.bypassSecurityTrustResourceUrl(this._config.Urls.baseApiUrl + "reportviewer.aspx?key=" + result.Result);
				this.sourceUrlSet = true;
			} else {
				this._statusMessageService.changeStatusMessage("error", result.PublicMessage);
			}
		});
	}

	onLoad() {
		if (this.sourceUrlSet) {
			this.isLoading = false;
		}
	}
}
