import { Routes, RouterModule } from "@angular/router";

import { ReportingComponent } from "./reporting.component";
import { ReportingReportsComponent } from "./reporting-reports.component";
import { ViewReportComponent } from "./view-report.component";
import { CppdDetailsComponent } from "../shared/components/reporting-dashboard/cppd-details/cppd-details.component";

export const ReportingRoutes: Routes = [
	{ path: "reporting", component: ReportingComponent },
	{ path: "reporting/reports", component: ReportingReportsComponent },
	{ path: "reporting/view-report/:reportId/:reportTitle", component: ViewReportComponent },
	{ path: "reporting/cppd-details", component: CppdDetailsComponent }
];

export const ReportingRouting = RouterModule.forChild(ReportingRoutes);
