export class OrganizationProfile {
  OrganizationId: number;
  Name: string;
  AddressLine1: string;
  AddressLine2: string;
  City: string;
  State: string;
  Zip: string;
  Telephone: string;
  Fax: string;
  ContactName: string;
  ContactEmail: string;
  ContactPhone: string;
}
