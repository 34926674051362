import { Component, OnInit, ViewChild } from "@angular/core";
import { DataTableModel } from "../../shared/components/data-table/data-table.model";
import { DataTableColumnModel } from "../../shared/components/data-table/columns.model";
import { ReportItemModel } from "../../shared/models/report-item.model";
import { ModalComponent } from "../../shared/components/modal/modal.component";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { AdminReportStore } from "../../shared/services/admin-report.store";
import { AdminReport } from "../../shared/models/admin-report.model";

@Component({
  selector: 'ct-reports-admin',
  templateUrl: './reports-admin.component.html',
  styleUrls: ['./reports-admin.component.scss']
})
export class ReportsAdminTab implements OnInit {
  allReports: AdminReport[] = [];
  filteredReports: DataTableModel = new DataTableModel([], []);
  selectedReportTab: string = "management";
  reportTabs: any[] = [
		{ value: "management", name: "Report Management" }, 
		{ value: "settings", name: "Permission Settings" }
	];

  private _destroyed: Subject<any> = new Subject<any>();

  @ViewChild("editModal") editModal: ModalComponent;
  @ViewChild("confirmDeleteModal") confirmDeleteModal: ModalComponent;

  constructor(public store: AdminReportStore) {
    this.store.reports.observable
      .pipe(takeUntil(this._destroyed))
      .subscribe((reports: AdminReport[]) => {
        this.allReports = reports;
      });
  }

  ngOnInit() {
    this.store.refresh();
    this.initTable();
  }

  ngOnDestroy(){
    this._destroyed.next();
    this._destroyed.unsubscribe();
  }

  initTable() {
		let columns: DataTableColumnModel[] = [];
    columns.push(new DataTableColumnModel("DisplayName", "Report Name / Description", "", false));
    columns.push(new DataTableColumnModel("Description", "Server Path", "server-path", false));
    columns.push(new DataTableColumnModel("edit", "", "edit-buttons", false));
    
		this.filteredReports = new DataTableModel([], columns);
    this.filteredReports.pageLength = 9999;

    this.store.filteredReports.observable
      .pipe(takeUntil(this._destroyed))
      .subscribe((reports: AdminReport[]) => {
        this.filteredReports.populate(reports)
      });
  }

  editReport(report: ReportItemModel) {
    this.store.mode.set("edit");

    this.store.selected.set(new AdminReport().deserialize(report));
		this.editModal.open("md");
	}

  newReport(){
    this.store.mode.set("new");

    this.store.selected.set(new AdminReport());
    this.editModal.open("md");
  }

  deleteReport(report: ReportItemModel){
    this.store.selected.set(new AdminReport().deserialize(report));
    this.confirmDeleteModal.open("xs");
  }

  confirmDelete(){
    this.store.delete().then(() => {
      this.store.selected.set(new AdminReport());
      this.confirmDeleteModal.close();
    });
  }

  tabChanged(tab: string) {
		this.selectedReportTab = tab;
	}

  categoryChanged(category: string) {
		this.store.setCategoryFilter(category);
	}
}
