import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { SubscribableReport } from 'src/app/ocp/shared/models/report-subscription/subscribable-report.model';
import { DataTableModel } from '../../../../data-table/data-table.model';
import { SortModel } from '../../../../data-table/sort.model';
import { DataTableColumnModel } from '../../../../data-table/columns.model';

@Component({
  selector: 'ocp-form-select-report',
  templateUrl: './form-select-report.component.html',
  styleUrls: ['./form-select-report.component.scss']
})
export class FormSelectReportComponent implements OnInit {
  reports: DataTableModel = new DataTableModel([], []);

  private _data: SubscribableReport[];
	@Input()
	get data(): SubscribableReport[] {
		return this._data;
	}
	set data(d: SubscribableReport[]) {
		this._data = d;

		setTimeout(() => {
			this.reports.populate(d);
		}, 0);
	}
  @Input() selectedReport: SubscribableReport;

  @Output() onSelect: EventEmitter<SubscribableReport> = new EventEmitter<SubscribableReport>();

  constructor() { }

  ngOnInit(): void {
    this.initTable();
  }

  initTable() {
		if (!this.data || !this.data) return;

		let defaultSorting: SortModel[] = [];

		defaultSorting.push({
			column: "ReportName",
			direction: "asc"
		});

		let columns: DataTableColumnModel[] = [];
		columns.push(new DataTableColumnModel("select", "", "", false));
		columns.push(new DataTableColumnModel("ReportName", "Report Name", "col-report-name", true));
		columns.push(new DataTableColumnModel("ReportDesc", "Description", "", true));

		this.reports = new DataTableModel(defaultSorting, columns);
		this.reports.pageLength = 9999;
	}

  reportChanged(selected: SubscribableReport) {
		this.onSelect.emit(selected);
	}
}
