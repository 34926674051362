import {Pipe, PipeTransform} from "@angular/core";
import {CustomerService} from "../services/customer.service";
import {CustomerTeamModel} from "../models/customer-team.model";
import {BehaviorSubject} from "rxjs";

@Pipe({name: "customerTeamName"})
export class CustomerTeamNamePipe implements PipeTransform {
  constructor(private _customerService: CustomerService) {
  }

  transform(teamId: number) {
    let teamName$: BehaviorSubject<string> = new BehaviorSubject("- - - - -");
    this._customerService.getCustomerTeamList(null, false, false).subscribe((teams: CustomerTeamModel[]) => {
      let team = teams.find(x => {
        return x.CustomerTeamId === teamId;
      });
      if (team) {
        teamName$.next(team.TeamName);
      } else {
        teamName$.next("- - - - -")
      }
    });
    return teamName$.asObservable();
  }
}
