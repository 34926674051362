export class PatientAllergyModel {
  PatientAllergyID: number;
  PatientID: number;
  AllergyName: string;
  Reaction: string;
  MedispanClassID: string;
  MedispanDrugID: string;
  HasReaction_Rash: boolean = false;
  HasReaction_Shock: boolean = false;
  HasReaction_Asthma: boolean = false;
  HasReaction_Nausea: boolean = false;
  HasReaction_Anemia: boolean = false;
  HasReaction_Other: boolean = false;
  OtherReactionDescription: string;
  IsActive: boolean;

  constructor() {

  }
}
