import { Injectable } from "@angular/core";
import { HttpService } from "./http.service";
import { StoreObject } from "../models/store-object.model";
import { MessageItemModel } from "../models/message-item.model";

@Injectable()
export class MessagesService {
	private _getUnreadMessageCountForUserUrl: string = "api/messages/getunreadmessagecountforuser";
	private _getMessageListUrl: string = "api/messages/getmessagelist?includeRead=";
	private _markMessagesAsReadUrl: string = "api/messages/markmessagesasread";

	public readonly unreadMessageCount: StoreObject<number> = new StoreObject<number>(0);

	constructor(private _http: HttpService) {}

	refreshUnreadMessagesCount() {
		return this._http.get(this._getUnreadMessageCountForUserUrl).subscribe((x: number) => {
			this.unreadMessageCount.set(x || 0);
		});
	}

	getMessageList(includeRead: boolean): Promise<MessageItemModel[]> {
		return new Promise<MessageItemModel[]>(resolve => {
			this._http.get(this._getMessageListUrl + (includeRead || false)).subscribe((results: MessageItemModel[]) => {
				this.unreadMessageCount.set(results.filter(x => !x.IsRead).length);
				resolve(results);
			});
		});
	}

	markMessagesAsRead(messageIds: number[]) {
		return this._http.post(this._markMessagesAsReadUrl, { MessageIds: messageIds });
	}
}
