import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { AdminReportRole } from 'src/app/ocp/shared/models/admin-report-role.model';
import { RoleModel } from 'src/app/ocp/shared/models/role.model';
import { AdminReportRoleStore } from 'src/app/ocp/shared/services/admin-report-role.store';

@Component({
  selector: 'ct-report-permission-settings',
  templateUrl: './permission-settings.component.html',
  styleUrls: ['./permission-settings.component.scss']
})
export class PermissionSettingsComponent implements OnInit {
  constructor(public store: AdminReportRoleStore) { }

  ngOnInit() {}

  isLarge(name: string): boolean {
    return (name || "").indexOf(" ") > -1 || (name || "").length >= 13
  }

  isChildChecked(roleId: number, enabledRoles: RoleModel[]): boolean {
    return (enabledRoles || []).some(r => r.id == roleId)
  }

  isParentChecked(roleId: number, group): boolean {
    return ((group || {}).reports || []).every(r => (r.enabledRoles || []).some(er => er.id == roleId));
  }
}
