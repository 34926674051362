import {Pipe, PipeTransform} from "@angular/core";
import {PatientService} from "../services/patient.service";
import {BehaviorSubject} from "rxjs";

@Pipe({name: "packagingPreferenceTypeName"})
export class PackagingPreferenceTypeNamePipe implements PipeTransform {
  constructor(private _patientService: PatientService) {
  }

  transform(packagingTypeId: number) {
    let preferenceText$: BehaviorSubject<string> = new BehaviorSubject("- - - - -");

    if (!packagingTypeId && packagingTypeId !== 0) {
      preferenceText$.next("- - - - -");
    } else {
      this._patientService.getPackingPreferenceList().subscribe((packagingPreferences: any[]) => {
        let preference = packagingPreferences.find(x => {
          return x.PackagingPreferenceId === packagingTypeId;
        });

        if (preference) {
          preferenceText$.next(preference.Description);
        } else {
          preferenceText$.next("- - - - -");
        }
      });
    }
    return preferenceText$.asObservable();
  }
}
