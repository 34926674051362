import { throwError as observableThrowError, Observable, Observer, BehaviorSubject } from "rxjs";

import { share, catchError } from "rxjs/operators";
import { Injectable, EventEmitter } from "@angular/core";
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { LoginResult } from "../models/LoginResult";
import { AppConfig } from "../../app.config";
import { Router } from "@angular/router";
import { ResetPasswordModel } from "../models/reset-password.model";
import { ApiResult } from "../models/api-result.model";
import { CacheService } from "./cache.service";
import { AppStore } from "src/app/app.store";
import { StoreObject } from "../models/store-object.model";

@Injectable()
export class AuthService {
	private expiresTimerId: any = null;
	private checkSessionInterval: any = null;

	authChange$: Observable<boolean>;
	private _observer: Observer<boolean>;

	public authorized: StoreObject<boolean> = new StoreObject<boolean>(false);

	private seconds: number = 1800;

	private _logoutReason: BehaviorSubject<any> = new BehaviorSubject("");

	public onAuthenticated: EventEmitter<boolean> = new EventEmitter<boolean>();
	public onSessionInvalid: EventEmitter<string> = new EventEmitter<string>();
	public user: any;

	get logoutReason() {
		return this._logoutReason.asObservable();
	}

	set logoutReason(reason: any) {
		this._logoutReason.next(reason);
	}

	bioLogin = (username: string, authToken: string, deviceId: string, gToken: string) =>
		this.doLogin(username, `grant_type=password&username=${username}&password=${encodeURIComponent(authToken)}&bioStatus=token&deviceId=${encodeURIComponent(deviceId)}&gToken=${gToken}`);

	setupBioLogin = (username: string, password: string, deviceId: string, gToken: string) =>
		this.doLogin(username, `grant_type=password&username=${username}&password=${password}&bioStatus=setup&deviceId=${encodeURIComponent(deviceId)}&gToken=${gToken}`);

	login = (username: string, password: string, gToken: string) =>
		this.doLogin(username, "grant_type=password&username=" + username + "&password=" + encodeURIComponent(password) + "&gToken=" + gToken);

	otpLogin = (username: string, password: string, otp: string) => this.doLogin(username, "grant_type=password&username=" + username + "&password=" + encodeURIComponent(password) + "&otpCode=" + otp);

	private doLogin(username: string, body: string) {
		return new Promise<any>((resolve, reject) => {
			let headers = new HttpHeaders({ "Content-Type": "application/x-www-form-urlencoded" });
			this.http
				.post(this._config.Urls.baseApiUrl + "TOKEN", body, { headers: headers })
				.pipe(catchError(error => this.handleError(error, true)))
				.subscribe(
					(result: LoginResult) => {
						this.processLoginResult(username, result);
						resolve(result);
					},
					error => {
						reject(error);
						console.log(error);
					}
				);
		});
	}

	isUserSessionActive() {
		return this.http.get(this._config.Urls.baseApiUrl + "api/account/isusersessionactive", { headers: this.getHeaders() }).pipe(catchError(error => this.handleError(error)));
	}

	private processLoginResult(username: string, result: LoginResult) {
		this._cache.set(this._config.SessionKeys.authentication.username, username);
		this._cache.set(this._config.SessionKeys.authentication.accessToken, result.access_token);
		this._cache.set(this._config.SessionKeys.authentication.refreshToken, result.refresh_token);
		this._cache.set(this._config.SessionKeys.authentication.requiresPasswordChange, result.requiresPasswordChange);
		this._cache.set(this._config.SessionKeys.authentication.isInternalUser, result.isInternalUser);

		if (result.roles && result.roles.trim().length > 0) {
			this._cache.set(this._config.SessionKeys.authentication.roles, result.roles);
		}

		if (result.permissions && result.permissions.trim().length > 0) {
			this._cache.set(this._config.SessionKeys.authentication.permissions, result.permissions);
		}

		const d = new Date();
		d.setSeconds(d.getSeconds() + result.expires_in - 30);
		this._cache.set(this._config.SessionKeys.authentication.expireDate, d.toISOString());

		this.startExpiresTimer(result.expires_in);
		this.startSessionCheckInterval();

		this.onAuthenticated.emit(true);
		this.authorized.set(true);
	}

	isUserInternal(): boolean {
		return (this._cache.get(this._config.SessionKeys.authentication.isInternalUser) || "").toLowerCase() == "true" ? true : false;
	}

	checkSession() {
		return new Promise<any>(resolve => {
			this.isUserSessionActive().subscribe((result: ApiResult) => {
				if (!result) {
					resolve(false);
					clearInterval(this.checkSessionInterval);
					this.checkSessionInterval = null;

					if (this.isAuthenticated()) {
						let reason: string = "You have been automatically logged out. ";
						this.onSessionInvalid.emit(reason);
					}
				} else {
					resolve(true);
				}
			});
		});
	}

	startSessionCheckInterval() {
		if (!this.checkSessionInterval) {
			this.checkSessionInterval = setInterval(() => {
				this.checkSession();
			}, 45000);
		}
	}

	forgotPassword(email: string) {
		var jBody = JSON.stringify(email);

		return this.http.post(this._config.Urls.baseApiUrl + "api/account/ForgotPassword", jBody, { headers: this.postHeaders() }).pipe(catchError(error => this.handleError(error)));
	}

	validateToken(token: string) {
		var jBody = JSON.stringify(token);

		return this.http.post(this._config.Urls.baseApiUrl + "api/account/validatetoken", jBody, { headers: this.postHeaders() }).pipe(catchError(error => this.handleError(error)));
	}

	resetPassword(model: any) {
		var jBody = JSON.stringify(model);

		return this.http.post(this._config.Urls.baseApiUrl + "api/account/forgotpasswordreset", jBody, { headers: this.postHeaders() }).pipe(catchError(error => this.handleError(error)));
	}

	changePassword(model: ResetPasswordModel) {
		var jBody = JSON.stringify(model);

		return this.http.post(this._config.Urls.baseApiUrl + "api/account/changepassword", jBody, { headers: this.postAuthHeaders() }).pipe(catchError(error => this.handleError(error)));
	}

	private postHeaders(): HttpHeaders {
		return new HttpHeaders({ "Content-Type": "application/json" });
	}

	private getHeaders(): HttpHeaders {
		const accessToken = this._cache.get(this._config.SessionKeys.authentication.accessToken);

		let authHeaders = new HttpHeaders();
		if (accessToken) {
			authHeaders = new HttpHeaders({ Authorization: "Bearer " + accessToken, Accept: "application/json" });
		}

		return authHeaders;
	}

	private postAuthHeaders(): HttpHeaders {
		const accessToken = this._cache.get(this._config.SessionKeys.authentication.accessToken);

		let authHeaders = new HttpHeaders();
		if (accessToken) {
			authHeaders = new HttpHeaders({
				Authorization: "Bearer " + accessToken,
				"Content-Type": "application/json",
				Accept: "application/json"
			});
		}

		return authHeaders;
	}

	doLogout(logoutReason: string) {
		this.logoutReason = logoutReason || "";
		this._cache.clear();
		this.expiresTimerId = null;
		this.emitAuthStatus();
		console.log("Logging Out because " + logoutReason);
		this._router.navigate(["/login"]);
		this._app.bioCheck();
	}

	doRefresh() {
		const refreshToken = this._cache.get(this._config.SessionKeys.authentication.refreshToken);

		if (!refreshToken) {
			console.log("no refresh, logout");
			return this.doLogout("Your account has been logged out due to inactivity.");
		}

		let body = "grant_type=refresh_token&refresh_token=" + refreshToken;
		let headers = new HttpHeaders({ "Content-Type": "application/x-www-form-urlencoded" });

		this.http
			.post(this._config.Urls.baseApiUrl + "TOKEN", body, { headers: headers })
			.pipe(catchError(error => this.handleError(error)))
			.subscribe((result: LoginResult) => this.processRefresh(result));
	}

	private handleError(error: any, isLogin: boolean = false) {
		if (!isLogin) {
			if (error.error.error_description) {
				this.doLogout(error.error.error_description);
				return observableThrowError(error.error.error_description);
			}

			this.doLogout("");
		} else {
			if (error.error.error_description) {
				return observableThrowError(error.error.error_description);
			}
		}

		if (error && error._body) {
			error = JSON.parse(error._body);
		} else if (error) {
			error = JSON.parse(error);
		}

		return observableThrowError(error || "Server Error");
	}

	private processRefresh(result: LoginResult) {
		this._cache.set(this._config.SessionKeys.authentication.accessToken, result.access_token);
		this._cache.set(this._config.SessionKeys.authentication.refreshToken, result.refresh_token);

		const d = new Date();
		d.setSeconds(d.getSeconds() + result.expires_in);
		this._cache.set(this._config.SessionKeys.authentication.expireDate, d.toISOString());

		this.startExpiresTimer(result.expires_in - 60);
	}

	isAuthenticated() {
		var accessToken = this._cache.get(this._config.SessionKeys.authentication.accessToken);
		let requiresPasswordChange = this._cache.get(this._config.SessionKeys.authentication.requiresPasswordChange);

		if (!accessToken || requiresPasswordChange.toLowerCase() == "true") {
			return false;
		}

		var expireDate = new Date(this._cache.get(this._config.SessionKeys.authentication.expireDate));

		var now = new Date();

		if (now < expireDate) {
			return true;
		}

		return false;
	}

	getUserName() {
		return this._cache.get(this._config.SessionKeys.authentication.username);
	}

	isInRole(role: string) {
		let roles: string = this._cache.get(this._config.SessionKeys.authentication.roles);

		let rolesArr: string[] = [];

		if (roles) {
			rolesArr = roles.split(",");
		}
		return rolesArr ? rolesArr.indexOf(role) > -1 : false;
	}

	hasPermission(permission: string) {
		let permissions: string = this._cache.get(this._config.SessionKeys.authentication.permissions);

		let permissionArr: string[] = [];

		if (permissions) {
			permissionArr = permissions.split(",");
		}
		return permissionArr ? permissionArr.indexOf(permission) > -1 : false;
	}

	hasRole(role: string) {
		let roles: string = this._cache.get(this._config.SessionKeys.authentication.roles);

		let rolesArr: string[] = [];

		if (roles) {
			rolesArr = roles.split(",");
		}
		return rolesArr ? rolesArr.indexOf(role) > -1 : false;
	}

	updatePermissions(permissions: string) {
		if (permissions && permissions.trim().length > 0) {
			this._cache.set(this._config.SessionKeys.authentication.permissions, permissions);
		} else {
			this._cache.set(this._config.SessionKeys.authentication.permissions, "");
		}
	}

	updateRoles(roles: string) {
		if (roles && roles.trim().length > 0) {
			this._cache.set(this._config.SessionKeys.authentication.roles, roles);
		} else {
			this._cache.set(this._config.SessionKeys.authentication.roles, "");
		}
	}

	startExpiresTimer(seconds: number) {
		console.log("Starting timer " + seconds + " seconds");
		this.seconds = seconds;

		if (this.expiresTimerId != null) {
			clearTimeout(this.expiresTimerId);
		}

		this.expiresTimerId = setTimeout(() => {
			const lastActivity = this._cache.get(this._config.SessionKeys.authentication.lastActivity);
			const expire = new Date();
			expire.setSeconds(expire.getSeconds() - 15 * 60);

			if (!lastActivity || new Date(lastActivity) < expire) {
				console.log("Logging out lastActive = " + new Date(lastActivity) + " expired on " + expire);
				this.doLogout("Your account has been logged out due to inactivity.");
			} else {
				console.log("attempting refresh");
				this.doRefresh();
			}
		}, seconds * 1000);
	}

	private emitAuthStatus() {
		if (this._observer) {
			this._observer.next(this.isAuthenticated());
		}

		this.authorized.set(this.isAuthenticated());
	}

	constructor(private http: HttpClient, private _router: Router, private _config: AppConfig, private _cache: CacheService, private _app: AppStore) {
		this.authChange$ = new Observable<boolean>(observer => {
			this._observer = observer;
		}).pipe(share());

		if (this.isAuthenticated()) {
			const expireDate = new Date(this._cache.get(this._config.SessionKeys.authentication.expireDate));
			const now = new Date();

			const diff = expireDate.getTime() - now.getTime();

			this.startExpiresTimer(diff / 1000 - 30);
			this.authorized.set(true);
		} else {
			this.authorized.set(false);
		}
	}
}
