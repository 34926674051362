import { Component } from "@angular/core";
import { AppStore } from "src/app/app.store";
import { LoginResult } from "../shared/models/LoginResult";
import { AuthService } from "../shared/services/auth.service";
import { Router } from "@angular/router";
import { UserService } from "../shared/services/user.service";
import { UserInfoModel } from "../shared/models/user-info.model";
import { CustomerService } from "../shared/services/customer.service";
import { CustomerProfileModel } from "../shared/models/customer-profile.model";
import { StatusMessageService } from "../shared/components/status-message/status-message.service";
import { PermissionsService } from "../shared/services/permisisons.service";

@Component({
	selector: "verify-account",
	templateUrl: "./verify-account.template.html",
	styleUrls: ["./verify-account.styles.scss"]
})
export class VerifyAccountComponent {
	loading: boolean = false;
	code: string;
	username: string;
	password: string;

	loginResult: LoginResult;

	constructor(
		public appStore: AppStore,
		private _auth: AuthService,
		private _router: Router,
		private _userService: UserService,
		private _customerService: CustomerService,
		private _statusMessageService: StatusMessageService,
		private _permissionsService: PermissionsService
	) {}

	login() {
		this.loading = true;

		this._auth.otpLogin(this.username, this.password, this.code.trim()).then(
			(result: LoginResult) => {
				this.finishLogin(result);
			},
			error => {
				this.loading = false;
				this._statusMessageService.changeStatusMessage("error", error || "The password does not match the Username provided. Please retry or click 'Forgot Password' to reset.");
			}
		);
	}

	private finishLogin(result: LoginResult) {
		if (result.requiresPasswordChange.toLowerCase() === "true") {
			this.loading = false;
			this._router.navigate(["/login/change-password"]);
			return;
		}
		this._userService.getUserInfo().subscribe(
			(userInfo: UserInfoModel) => {
				this.loading = false;
				if (userInfo) {
					this._userService.userInfo = userInfo;
					this._userService.updateCustomerInfo(
						userInfo.CustomerMrnNameOverride,
						userInfo.CustomerMrnFormat,
						userInfo.CustomerMrnFormatDescription,
						userInfo.IsMrnRequired,
						userInfo.IsSsnRequired,
						userInfo.IsScreeningEnabled,
						userInfo.HasFormulary,
						userInfo.AllowMultipleFormularies,
						userInfo.DoseSpotEnabled,
						userInfo.OrganizationProfileOn,
						userInfo.IsPrescriber,
						userInfo.DoseSpotClinicianId
					);
				}

				this._customerService.getCustomerProfile(userInfo.CustomerId).subscribe((customer: CustomerProfileModel) => {
					this._userService.selectedCustomer = customer;
				});

				if (!this.redirect()) {
					this._auth.doLogout("");
				}
			},
			error => {
				this.loading = false;
				this._statusMessageService.changeStatusMessage("error", error.error_description || "The password does not match the Username provided. Please retry or click 'Forgot Password' to reset.");
			}
		);
	}

	private redirect(): boolean {
		if (this._permissionsService.canNavigateToSection("patient-care")) {
			this._router.navigate(["/patient-care"]);
			return true;
		} else if (this._permissionsService.canNavigateToSection("reporting")) {
			this._router.navigate(["/reporting"]);
			return true;
		} else if (this._permissionsService.canNavigateToSection("clinical-resources")) {
			this._router.navigate(["/clinical-resources"]);
			return true;
		} else if (this._permissionsService.canNavigateToSection("partner-profile")) {
			this._router.navigate(["/customer-profile"]);
			return true;
		} else if (this._permissionsService.canNavigateToSection("administration")) {
			this._router.navigate(["/administration"]);
			return true;
		}

		return false;
	}
}
