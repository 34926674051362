import { Injectable } from "@angular/core";
import { StoreObject } from "../models/store-object.model";
import { FormularyModel } from "../models/formulary.model";
import { HttpService } from "./http.service";
import { DocumentInfoModel } from "../models/document-info.model";
import { StatusMessageService } from "../components/status-message/status-message.service";

@Injectable()
export class FormulariesStore {
  public readonly formularies: StoreObject<DocumentInfoModel[]> = new StoreObject<DocumentInfoModel[]>([]);
  public readonly selected: StoreObject<DocumentInfoModel> = new StoreObject<DocumentInfoModel>(new DocumentInfoModel());

  public loading: boolean = false;
  public mode: StoreObject<'new' | 'edit'> = new StoreObject<'new' | 'edit'>(null);

  constructor(
    private _http: HttpService,
    private _status: StatusMessageService
  ) {}

  refresh(){
    this.loading = true;
    this._http.get("api/clinicalresources/getdocuments?documentId=null&documentTypeId=7").subscribe((result: DocumentInfoModel[]) => {
      this.loading = false;
      if (result){
          this.formularies.set(result);
      } else {
          this.formularies.set([]);
      }
    });
  }
}