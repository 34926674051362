import { Injectable } from "@angular/core";
import { HttpService } from "./http.service";
import { PatientInfoModel } from "../models/patient.model";
import { Observable, Observer } from "rxjs";
import { DiagnosisModel } from "../models/diagnosis.model";
import { GenerateRefillRequestModel } from "../models/generate-refill-request.model";
import { PatientAllergyModel } from "../models/allergy.model";
import { PatientMedicationModel } from "../models/patient-medication.model";
import { AdmitPatientProfileResultModel } from "../models/admit-patient-profile-result.model";
import { PatientSearchModel } from "../models/patient-search.model";

@Injectable()
export class PatientService {
	private _patientProfileUrl: string = "api/patient/getpatientprofile?patientId=";
	private _getAdmitPatientProfileUrl: string = "api/patient/getadmitpatientprofile?patientId=";
	private _addPatientUrl: string = "api/patient/addpatient";
	private _admitPatientUrl: string = "api/patient/admitpatient";
	private _updatePatientProfileUrl: string = "api/patient/updatepatientprofile";
	private _updateFavoritePatientUrl: string = "api/patient/updatefavoritepatient";
	private _dischargePatientUrl: string = "api/patient/dischargepatient";
	private _getPatientDiagnosisUrl: string = "api/patient/getpatientdiagnosis?patientId=";
	private _updatePatientDiagnosisUrl: string = "api/patient/setpatientdiagnosis";
	private _updatePatientDiagnosisListUrl: string = "api/patient/updatepatientdiagnosis";
	private _removePatientDiagnosisUrl: string = "api/patient/removediagnosis";
	private _getPackagingPreferenceListUrl: string = "api/patient/getpackagingpreferencelist";
	private _getLocationTypesUrl: string = "api/patient/getlocationtypes";
	private _getPatientMedicationsUrl: string = "api/patient/getpatientmedications?patientId=";
	private _discontinueMedicationUrl: string = "api/patient/discontinuemedication";
	private _getPatientDispenseHistoryUrl: string = "api/patient/getpatientdispensehistory?";
	private _getMedicationDispenseHistoryUrl: string = "api/patient/getmedicationdispensehistory?";
	private _resolveDiagnosisUrl: string = "api/patient/resolvediagnosis";
	private _createRefillRequestUrl: string = "api/patient/generaterefillrequest";
	private _getPatientAllergiesUrl: string = "api/patient/getpatientallergies?patientid=";
	private _updatePatientAllergiesUrl: string = "api/patient/updatepatientallergies";
	private _removeAllergyUrl: string = "api/patient/removeallergy";
	private _updateAllergyUrl: string = "api/patient/setpatientallergy";
	private _getAllergyScreeningUrl: string = "api/patient/getallergyscreening?patientId=";
	private _getDrugScreeningUrl: string = "api/patient/getdrugscreening?patientId=";
	private _getClinicalNotesForPatientUrl: string = "api/patient/getclinicalnotesforpatient?patientId=";
	private _getPlanOfTreatmentForPatientUrl: string = "api/patient/getplanoftreatmentforpatient?patientId=";
	private _getDeprescribingOptions: string = "api/patient/getdeprescribingoptions?patientId=";
	private _getCostSavingAlternativesReport: string = "api/patient/GetCostSavingAlternativesReport?patientId=";
	private _getAdmissionsUrl: string = "api/patient/getadmissions?patientId=";
	private _updateAdmissionsUrl: string = "api/patient/updateadmissions";
	private _updatePatientsMedicationDirectionUrl: string = "api/patient/updatepatientmedicationdirections";
	private _removePatientUrl: string = "api/patient/removepatient";
	private _deleteAdmissionUrl: string = "api/patient/deleteAdmission";

	private _patient$: Observable<PatientInfoModel>;
	private _admitPatientProfile$: Observable<PatientInfoModel>;

	private _patientObserver: Observer<PatientInfoModel>;
	private _admitPatientProfileObserver: Observer<PatientInfoModel>;

	constructor(private _http: HttpService) {
		this._patient$ = new Observable<PatientInfoModel>(observer => (this._patientObserver = observer));
		this._admitPatientProfile$ = new Observable<PatientInfoModel>(observer => (this._admitPatientProfileObserver = observer));
	}

	getProfileModel(patientId: number) {
		this.getProfile(patientId).subscribe((result: any) => {
			if (result) {
				this._patientObserver.next(this.createPatientProfileModel(patientId, result));
			} else {
				this._patientObserver.next(new PatientInfoModel());
			}
		});
		return this._patient$;
	}

	getAdmitPatientProfile(patientId: number) {
		this._http.get(this._getAdmitPatientProfileUrl + patientId).subscribe((result: AdmitPatientProfileResultModel) => {
			let patientProfile: PatientInfoModel = this.createPatientProfileModel(patientId, result.Profile);

			patientProfile.diagnosis = result.Diagnosis || [];
			patientProfile.allergies = result.Allergies || [];

			this._admitPatientProfileObserver.next(patientProfile);
		});
		return this._admitPatientProfile$;
	}

	addPatient(patientInfo: PatientInfoModel, dischargeDate: string) {
		let data = {
			PatientProfile: {
				Sex: patientInfo.sex,
				LocationTypeId: patientInfo.hospiceType,
				FirstName: patientInfo.firstName,
				MiddleName: patientInfo.middleName,
				LastName: patientInfo.lastName,
				BirthDate: patientInfo.birthDate,
				Ssn: patientInfo.ssn,
				MedicalRecordNumber: patientInfo.Mrn,
				Street1: patientInfo.homeAddress,
				Street2: patientInfo.homeAddress2,
				City: patientInfo.city,
				State: patientInfo.state,
				Zip: patientInfo.zipCode,
				Phone: patientInfo.phone,
				AlternatePhone: patientInfo.altPhone,
				CustomerTeamID: patientInfo.customerTeamId,
				AdmitDate: patientInfo.dateAdmitted,
				LocationName: patientInfo.facilityName,
				PharmacyName: patientInfo.pharmacyName,
				PharmacyFax: patientInfo.pharmacyFax,
				DeliveryNotes: patientInfo.deliveryDetails,
				PackagingPreferenceId: patientInfo.packaging,
				PackagingNarcSheets: patientInfo.packagingNarcSheets,
				PackagingPrefilledSyringes: patientInfo.packagingPrefilledSyringes,
				WeightLbs: patientInfo.weightLbs,
				HeightIn: patientInfo.heightIn,
				EpisodeId: patientInfo.episodeId
			},
			Admissions: {
				DischargeDate: patientInfo.patientStatus === 2 || patientInfo.patientStatus === 3 ? dischargeDate : null
			},
			Diagnosis: patientInfo.diagnosis || [],
			Allergies: patientInfo.allergies || [],
			IsFavorite: patientInfo.isFavorite,
			AdmissionStatusCode: patientInfo.patientStatus
		};

		return this._http.post(this._addPatientUrl, data);
	}

	updatePatientProfile(patientId: number, patientInfo: PatientInfoModel) {
		let data = {
			PatientId: patientId,
			PatientProfile: {
				Sex: patientInfo.sex,
				LocationTypeId: patientInfo.hospiceType,
				FirstName: patientInfo.firstName,
				MiddleName: patientInfo.middleName,
				LastName: patientInfo.lastName,
				BirthDate: patientInfo.birthDate,
				Ssn: patientInfo.ssn,
				MedicalRecordNumber: patientInfo.Mrn,
				Street1: patientInfo.homeAddress,
				Street2: patientInfo.homeAddress2,
				City: patientInfo.city,
				State: patientInfo.state,
				Zip: patientInfo.zipCode,
				Phone: patientInfo.phone,
				AlternatePhone: patientInfo.altPhone,
				CustomerTeamID: patientInfo.customerTeamId,
				AdmitDate: patientInfo.dateAdmitted,
				LocationName: patientInfo.facilityName,
				PharmacyName: patientInfo.pharmacyName,
				PharmacyFax: patientInfo.pharmacyFax,
				DischargeDate: patientInfo.dischargeDate,
				DeliveryNotes: patientInfo.deliveryDetails,
				PackagingPreferenceId: patientInfo.packaging,
				DeliveryDesignationCode: patientInfo.deliveryDesignationCode,
				PackagingNarcSheets: patientInfo.packagingNarcSheets,
				PackagingPrefilledSyringes: patientInfo.packagingPrefilledSyringes,
				RelayHealthMemberId: patientInfo.memberId,
				WeightLbs: patientInfo.weightLbs,
				HeightIn: patientInfo.heightIn,
				EpisodeId: patientInfo.episodeId
			}
		};

		return this._http.post(this._updatePatientProfileUrl, data);
	}

	updateFavoritePatient(patientId: number, isFavorite: boolean) {
		return this._http.post(this._updateFavoritePatientUrl, { PatientId: patientId, IsFavorite: isFavorite });
	}

	admitPatient(patientId: number, patientInfo: PatientInfoModel) {
		//patientId: number, admitDate: string, dischargeDate: string) {
		let data = {
			PatientId: patientId,
			PatientProfile: {
				Sex: patientInfo.sex,
				LocationTypeId: patientInfo.hospiceType,
				FirstName: patientInfo.firstName,
				MiddleName: patientInfo.middleName,
				LastName: patientInfo.lastName,
				BirthDate: patientInfo.birthDate,
				Ssn: patientInfo.ssn,
				MedicalRecordNumber: patientInfo.Mrn,
				Street1: patientInfo.homeAddress,
				Street2: patientInfo.homeAddress2,
				City: patientInfo.city,
				State: patientInfo.state,
				Zip: patientInfo.zipCode,
				Phone: patientInfo.phone,
				AlternatePhone: patientInfo.altPhone,
				CustomerTeamID: patientInfo.customerTeamId,
				AdmitDate: patientInfo.dateAdmitted,
				LocationName: patientInfo.facilityName,
				PharmacyName: patientInfo.pharmacyName,
				PharmacyFax: patientInfo.pharmacyFax,
				DeliveryNotes: patientInfo.deliveryDetails,
				PackagingPreferenceId: patientInfo.packaging,
				PackagingNarcSheets: patientInfo.packagingNarcSheets,
				PackagingPrefilledSyringes: patientInfo.packagingPrefilledSyringes,
				WeightLbs: patientInfo.weightLbs,
				HeightIn: patientInfo.heightIn,
				EpisodeId: patientInfo.episodeId
			},
			Admissions: {
				DischargeDate: null
			},
			Diagnosis: patientInfo.diagnosis || [],
			Allergies: patientInfo.allergies || [],
			IsFavorite: patientInfo.isFavorite,
			AdmissionStatusCode: patientInfo.patientStatus
		};

		return this._http.post(this._admitPatientUrl, data);
	}

	dischargePatient(patientId: number, dischargeDate: string, isDeceased: boolean) {
		return this._http.post(this._dischargePatientUrl, {
			PatientId: patientId,
			DischargeDate: dischargeDate,
			IsDeceased: isDeceased
		});
	}

	removePreAdmitPatient(patientId: number) {
		return this._http.post(this._removePatientUrl, patientId);
	}

	getPatientDiagnosis(patientId: number) {
		return this._http.get(this._getPatientDiagnosisUrl + patientId);
	}

	updateDiagnosis(patientId: number, diagnosis: DiagnosisModel) {
		return this._http.post(this._updatePatientDiagnosisUrl, { PatientId: patientId, Diagnosis: diagnosis });
	}

	updateDiagnosisList(patientId: number, diagnosis: DiagnosisModel[]) {
		return this._http.post(this._updatePatientDiagnosisListUrl, {
			PatientId: patientId,
			Diagnosis: diagnosis
		});
	}

	removePatientDiagnosis(patientId: number, patientDxId: number) {
		return this._http.post(this._removePatientDiagnosisUrl, { patientId: patientId, patientDxId: patientDxId });
	}

	resolveDiagnosis(patientId: number, patientDxId: number, resolvedDate: string) {
		let data = {
			PatientId: patientId,
			PatientDxId: patientDxId,
			ResolvedDate: resolvedDate
		};

		return this._http.post(this._resolveDiagnosisUrl, data);
	}

	getPatientAllergies(patientId: number) {
		return this._http.get(this._getPatientAllergiesUrl + patientId);
	}

	updatePatientAllergies(patientId: number, allergies: PatientAllergyModel[]) {
		return this._http.post(this._updatePatientAllergiesUrl, { PatientId: patientId, Allergies: allergies });
	}

	updateAllergy(patientId: number, allergy: PatientAllergyModel) {
		// patientID does not current come back from the service so it is being set here to make sure it persists on edit.
		allergy.PatientID = patientId;
		allergy.IsActive = true;
		return this._http.post(this._updateAllergyUrl, allergy);
	}

	removeAllergy(patientId: number, patientAllergyId: number) {
		return this._http.post(this._removeAllergyUrl, { PatientId: patientId, PatientAllergyId: patientAllergyId });
	}

	getPatientMedications(patientId: number): Observable<any> {
		return this._http.get(this._getPatientMedicationsUrl + patientId);
	}

	updatePatientMedicationDirections(patientId: number, orderId: number, directions: string, isprn: boolean) {
		return this._http.post(this._updatePatientsMedicationDirectionUrl, {
			PatientId: patientId,
			OrderNumber: orderId,
			Directions: directions,
			IsPRN: isprn
		});
	}

	discontinueMedication(patientId: number, orderNumber: number, discontinueDate: string) {
		return this._http.post(this._discontinueMedicationUrl, {
			PatientId: patientId,
			OrderNumber: orderNumber,
			DiscontinueDate: discontinueDate
		});
	}

	createRefillRequest(refillRequest: GenerateRefillRequestModel) {
		return this._http.post(this._createRefillRequestUrl, refillRequest);
	}

	getPatientDispenseHistory(patientId: number) {
		return this._http.get(this._getPatientDispenseHistoryUrl + "patientId=" + patientId + "&dayOffset=");
	}

	getMedicationDispenseHistory(patientId: number, ndc: string) {
		return this._http.get(this._getMedicationDispenseHistoryUrl + "patientId=" + patientId + "&ndc=" + ndc);
	}

	getPackingPreferenceList() {
		return this._http.cachedGet(true, this._getPackagingPreferenceListUrl);
	}

	getLocationTypes() {
		return this._http.cachedGet(true, this._getLocationTypesUrl);
	}

	getAllergyScreening(patientId: number) {
		return this._http.get(this._getAllergyScreeningUrl + patientId);
	}

	getDrugScreening(patientId: number) {
		return this._http.get(this._getDrugScreeningUrl + patientId);
	}

	getClinicalNotesForPatient(patientId: number) {
		return this._http.get(this._getClinicalNotesForPatientUrl + patientId);
	}

	getPlanOfTreatmentForPatientUrl(patientId: number) {
		return this._http.get(this._getPlanOfTreatmentForPatientUrl + patientId);
	}

	getDeprescribingOptions(patientId: number) {
		return this._http.get(this._getDeprescribingOptions + patientId);
	}

	getCostSavingAlternativesReport(patientId: number) {
		return this._http.get(this._getCostSavingAlternativesReport + patientId);
	}

	getAdmissions(patientId: number) {
		return this._http.get(this._getAdmissionsUrl + patientId);
	}

	updateAdmissions(patientId: number, admission: any) {
		return this._http.post(this._updateAdmissionsUrl, {
			PatientId: patientId,
			Admissions: admission
		});
	}

	deleteAdmission(patientId: number, admissionId: number) {
		return this._http.post(this._deleteAdmissionUrl, {
			PatientId: patientId,
			AdmissionId: admissionId
		});
	}

	getPatientStatuses() {
		return [
			{ text: "Preadmit", value: 0 },
			{ text: "Active", value: 1 },
			{ text: "Discharged", value: 2 },
			{ text: "Deceased", value: 3 }
		];
	}

	isPreadmit(statusCode: number) {
		return statusCode === 0;
	}

	isAdmitted(statusCode: number) {
		return statusCode === 1;
	}

	isDischarged(statusCode: number) {
		return statusCode === 2;
	}

	isDeceased(statusCode: number) {
		return statusCode === 3;
	}

	getReactionsList(allergy: PatientAllergyModel) {
		let reactions: string[] = [];

		if (allergy.HasReaction_Anemia) {
			reactions.push("Anemia");
		}

		if (allergy.HasReaction_Asthma) {
			reactions.push("Asthma");
		}

		if (allergy.HasReaction_Nausea) {
			reactions.push("Nausea");
		}

		if (allergy.HasReaction_Rash) {
			reactions.push("Rash");
		}

		if (allergy.HasReaction_Shock) {
			reactions.push("Shock");
		}

		if (allergy.HasReaction_Other) {
			reactions.push(allergy.OtherReactionDescription);
		}
	}

	createPatientProfileModel(patientId: number, result: any): PatientInfoModel {
		let patient = new PatientInfoModel();
		patient.patientId = patientId;
		patient.sex = result.Sex;
		patient.isPreadmit = this.isPreadmit(result.AdmissionStatusCode);
		patient.isAdmitted = this.isAdmitted(result.AdmissionStatusCode);
		patient.isDischarged = this.isDischarged(result.AdmissionStatusCode);
		patient.isDeceased = this.isDeceased(result.AdmissionStatusCode);
		patient.hospiceType = result.LocationTypeId;
		patient.firstName = result.FirstName;
		patient.middleName = result.MiddleName;
		patient.lastName = result.LastName;
		patient.birthDate = result.BirthDate;
		patient.dischargeDate = result.DischargeDate;
		patient.isFavorite = result.IsFavorite;
		patient.ssn = result.Ssn;
		patient.Mrn = result.MedicalRecordNumber;
		patient.homeAddress = result.Street1;
		patient.homeAddress2 = result.Street2;
		patient.city = result.City;
		patient.state = result.State;
		patient.zipCode = result.Zip;
		patient.phone = result.Phone;
		patient.altPhone = result.AlternatePhone;
		patient.teamName = result.TeamName;
		patient.customerTeamId = result.CustomerTeamID;
		patient.dateAdmitted = result.AdmitDate;
		patient.facilityName = result.LocationName;
		patient.pharmacyName = result.PharmacyName;
		patient.pharmacyFax = result.PharmacyFax;
		patient.patientStatus = result.AdmissionStatusCode;
		patient.deliveryDetails = result.DeliveryNotes;
		patient.packaging = result.PackagingPreferenceId || 0;
		patient.packagingNarcSheets = result.PackagingNarcSheets;
		patient.packagingPrefilledSyringes = result.PackagingPrefilledSyringes;
		patient.deliveryDesignationCode = result.DeliveryDesignationCode;

		patient.isHomePatient = result.IsHomePatient;
		patient.isFacilityCare = result.IsFacilityCare;
		patient.isIpuGpu = result.IsIpuGpu;

		patient.frameworkPatId = result.FrameworkPatId;
		patient.frameworkFacId = result.FrameworkFacId;
		patient.doseSpotPatientId = result.DoseSpotPatientId;

		patient.weightLbs = result.WeightLbs;
		patient.heightIn = result.HeightIn;
		patient.episodeId = result.EpisodeId;

		patient.memberId = result.RelayHealthMemberId;
		patient.pcn = result.RelayHealthPCN;
		patient.bin = result.RelayHealthBIN;

		return patient;
	}

	private getProfile(patientId: number) {
		return this._http.get(this._patientProfileUrl + patientId);
	}
}
