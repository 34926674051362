import { Component, Output, EventEmitter, OnInit, OnDestroy } from "@angular/core";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { UserService } from "../../services/user.service";
import { RxService } from "../../services/rx.service";
import { Permissions } from "../../enums/permissions";
import { StatusMessageService } from "../status-message/status-message.service";
import { FadeInAnimation } from "../../../animations";
import { DoseSpotNotificationCountInfo } from "../../models/dosespot-notification-count-info.model";
import { Router } from "@angular/router";
import { AlertCounts } from "../../models/alert-counts.model";
import { AppStore } from "src/app/app.store";

@Component({
	selector: "erx-alerts-listing",
	templateUrl: "./erx-alerts-listing.template.html",
	styleUrls: ["./alerts-messages.scss", "./alert.scss"],
	animations: [FadeInAnimation]
})
export class ErxAlertsListingComponent implements OnInit, OnDestroy {
	subHeaderText: string;
	doseSpotNotificationCountInfo: DoseSpotNotificationCountInfo[] = [];
	permissions: any = Permissions;
	eRxUrl: string = "";
	numberOfDsAlerts: number = 0;
	isLoading: boolean = true;
	isMobile: boolean = false;
	doseSpotUrlError: string = null;

	private destroyed: Subject<any> = new Subject<any>();

	@Output() closePanel: EventEmitter<string> = new EventEmitter<string>();

	constructor(private _userService: UserService, private _rxService: RxService, private _statusMessageService: StatusMessageService, private _router: Router, private _app: AppStore) {}

	ngOnInit() {
		this.isMobile = this._app.IsMobile.get();
		this._userService.userAlerts.observable.pipe(takeUntil(this.destroyed)).subscribe((result: AlertCounts) => {
			if (result) {
				this.numberOfDsAlerts = result.dsAlertCount;
			} else {
				this.numberOfDsAlerts = 0;
			}
		});

		this._userService.customerChanged.pipe(takeUntil(this.destroyed)).subscribe(() => {
			this.load();
		});

		this.load();
	}

	load() {
		this.isLoading = true;
		this.loadPendingErx().then(() => {
			this.isLoading = false;
		});
	}

	ngOnDestroy() {
		this.destroyed.next();
		this.destroyed.unsubscribe();
	}

	loadPendingErx(): Promise<void> {
		return new Promise<void>(resolve => {
			this._rxService.getDoseSpotNotificationCountInfoForUser().subscribe((results: any[]) => {
				this.doseSpotNotificationCountInfo = results;
				resolve();
			});

			if (this.isMobile) {
				this._rxService.getCustomerERxUrl().then(
					(val: any) => {
						this.eRxUrl = val.changingThisBreaksApplicationSecurity;
					},
					(error: any) => {
						this.doseSpotUrlError = error;
					}
				);
			}
		});
	}

	erxErrorMobile() {
		this._statusMessageService.changeStatusMessage("error", this.doseSpotUrlError);
	}

	goToERx() {
		if (this.isMobile) {
		} else {
			this.closePanel.emit();
			this._router.navigate(["/erx-alerts"]);
		}
	}

	refreshUserAlertCount() {
		this._userService.refreshUserAlertCount();
	}
}
