export class AdminReportRole {
    displayName: string;
    categoryName: string;
    roleReportId: number;
    roleId: number;
    roleName: string;
    isInternal: boolean;
    isActive: boolean;
    isEnabled: boolean;
    reportId: number;
}