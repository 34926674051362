export const environment = {
	production: false,
	mobile: false,
	firebaseConfig: {
		apiKey: "AIzaSyBmbkcz7Zg3zW6FleebUjJ2TQAg4kfiFpw",
		authDomain: "oppc-ds-push.firebaseapp.com",
		projectId: "oppc-ds-push",
		storageBucket: "oppc-ds-push.appspot.com",
		messagingSenderId: "1001979241204",
		appId: "1:1001979241204:web:e04631df6792d6f48774a1"
	},
	googleSiteId: "6LfvhCsqAAAAAF53-ySNCwtW74WapbPLjj7YI7Hx"
};
