import { Component, Input, Output, EventEmitter } from '@angular/core';
import { PatientAdmissionModel } from 'src/app/ocp/shared/models/patient-admission.model';

@Component({
  selector: 'ct-admission-summary',
  templateUrl: './admission-summary.component.html',
  styleUrls: ['./admission-summary.component.scss']
})
export class AdmissionSummaryComponent {

  @Input() index: number;
  @Input() admission: PatientAdmissionModel;
  @Input() editing: boolean;
  @Input() unsaved: boolean;

  @Output() delete: EventEmitter<any> = new EventEmitter<any>();
  @Output() edit:   EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

}