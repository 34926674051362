import { EventEmitter, Injectable } from "@angular/core";
import { HttpService } from "./http.service";
import { BehaviorSubject, Observable, Observer } from "rxjs";
import { UserProfile } from "../models/user-profile.model";
import { RoleModel } from "../models/role.model";
import { AppConfig } from "../../app.config";
import { PharmacyPermissionModel } from "../models/pharmacy-permission.model";
import { CustomerPermissionModel } from "../models/customer-permission.model";
import { UserInfoModel } from "../models/user-info.model";
import { AuthService } from "./auth.service";
import { UserTeamModel } from "../models/user-team.model";
import { CacheService } from "./cache.service";
import { MappedClaimModel } from "../models/mapped-claim.model";
import * as _ from "lodash";
import { AlertCounts } from "../models/alert-counts.model";
import { StoreObject } from "../models/store-object.model";
import { BulkUploadUser } from "../models/bulk-upload-user.model";
import { ApiResult } from "../models/api-result.model";
import { ErxCustomerConfig } from "../models/erx/customer-config.model";

@Injectable()
export class UserService {
	private _getUserProfileUrl: string = "api/user/getuserprofile?userId=";
	private _favoritesUrl: string = "api/user/getfavorites";
	private _changeCurrentCustomerUrl: string = "api/user/changecurrentcustomer";
	private _getAlertCountForUserUrl: string = "api/user/getalertcountforuser";
	private _getSystemAlertsForUserUrl: string = "api/user/getsystemalertsforuser";
	private _getPendingAuthorizationsForUserUrl: string = "api/user/getpendingauthorizationsforuser";
	private _getAccountManagerListUrl: string = "api/user/getaccountmanagerlist";
	private _changeUserStatusUrl: string = "api/user/changeuserstatus";
	private _getUserRolesUrl: string = "api/user/getuserroles?userId=";
	private _getUserTeams: string = "api/user/getuserteams?userId=";
	private _setUserRolesUrl: string = "api/user/setuserroles";
	private _getInternalUsersUrl: string = "api/user/getinternaluserlist";
	private _getUserPharmacyPermissionsUrl: string = "api/user/getuserpharmacypermissions?userId=";
	private _getUserCustomerPermissionsUrl: string = "api/user/getusercustomerpermissions?userId=";
	private _getAllUsersInOrganizationUrl: string = "api/user/GetAllOrganizationsUsers";
	private _transferUserUrl: string = "api/user/transferuser";
	private _checkIfClinicianIdExistsUrl = "api/user/CheckClinicianIdExists";
	private _uploadBulkUsersUrl = "api/user/uploadbulkusers?customerId=";

	private _selectedCustomer: BehaviorSubject<any> = new BehaviorSubject(null);
	public readonly $electedCustomer: Observable<any> = this._selectedCustomer.asObservable();

	private _mrnNameOverride: BehaviorSubject<string> = new BehaviorSubject("MRN");
	private _mrnFormatDescription: BehaviorSubject<string> = new BehaviorSubject("");
	private _customMrnFormat: BehaviorSubject<string> = new BehaviorSubject("");
	private _isMrnRequired: BehaviorSubject<boolean> = new BehaviorSubject(false);
	private _isSsnRequired: BehaviorSubject<boolean> = new BehaviorSubject(false);
	private _isScreeningEnabled: BehaviorSubject<boolean> = new BehaviorSubject(false);
	private _hasFormulary: BehaviorSubject<boolean> = new BehaviorSubject(false);
	private _allowMultipleFormularies: BehaviorSubject<boolean> = new BehaviorSubject(false);
	private _doseSpotEnabled: BehaviorSubject<boolean> = new BehaviorSubject(false);
	private _hasClinicianId: BehaviorSubject<boolean> = new BehaviorSubject(false);
	private _isPrescriber: BehaviorSubject<boolean> = new BehaviorSubject(false);

	private _userInfo: BehaviorSubject<UserInfoModel> = new BehaviorSubject(new UserInfoModel());

	public signedOut: EventEmitter<void> = new EventEmitter<void>();
	public customerChanged: EventEmitter<void> = new EventEmitter<void>();

	public userAlerts: StoreObject<AlertCounts> = new StoreObject<AlertCounts>(new AlertCounts());

	public selectedCustomerId: number;

	public organizationProfileOn: StoreObject<boolean> = new StoreObject<boolean>(false);

	constructor(private _http: HttpService, private _authService: AuthService, private _config: AppConfig, private _cache: CacheService) {}

	get selectedCustomer() {
		return this._selectedCustomer.asObservable();
	}

	set selectedCustomer(customer: any) {
		if (!customer) {
			return;
		}

		let refreshUser: boolean = false;
		if (!this._selectedCustomer.value || this._selectedCustomer.value.CustomerId !== customer.CustomerId) {
			refreshUser = true;
		}

		if (this.selectedCustomerId !== customer.CustomerId) {
			this.selectedCustomerId = customer.CustomerId;
			this._selectedCustomer.next(customer);
		}

		if (refreshUser) {
			this.refreshUserInfo();
		}
	}

	updateCustomerInfo(
		mrnName: string,
		mrnFormatRegex: string,
		mrnFormatDescription: string,
		isMrnRequired: boolean,
		isSsnRequired: boolean,
		isScreeningEnabled: boolean,
		hasFormulary: boolean,
		allowMultipleFormularies: boolean,
		doseSpotEnabled: boolean,
		organizationProfileOn: boolean,
		isPrescriber: boolean,
		clinicianId?: number
	) {
		this._mrnNameOverride.next(mrnName || "MRN");
		this._customMrnFormat.next(mrnFormatRegex || "");
		this._mrnFormatDescription.next(mrnFormatDescription || "Enter a valid " + this._mrnNameOverride.value + " format.");
		this._isMrnRequired.next(isMrnRequired || false);
		this._isSsnRequired.next(isSsnRequired || false);
		this._isScreeningEnabled.next(isScreeningEnabled || false);
		this._hasFormulary.next(hasFormulary || false);
		this._allowMultipleFormularies.next(allowMultipleFormularies || false);
		this._doseSpotEnabled.next(doseSpotEnabled || false);
		this._hasClinicianId.next(clinicianId > 0);
		this._isPrescriber.next(isPrescriber || false);
		this.organizationProfileOn.set(organizationProfileOn);
	}

	get mrnOverride() {
		return this._mrnNameOverride.asObservable();
	}

	get mrnFormatDescription() {
		return this._mrnFormatDescription.asObservable();
	}

	get customMrnFormat() {
		return this._customMrnFormat.asObservable();
	}

	get isMrnRequired() {
		return this._isMrnRequired.asObservable();
	}

	get isSsnRequired() {
		return this._isSsnRequired.asObservable();
	}

	get isScreeningEnabled() {
		return this._isScreeningEnabled.asObservable();
	}

	get hasFormulary() {
		return this._hasFormulary.asObservable();
	}

	get allowMultipleFormularies() {
		return this._allowMultipleFormularies.asObservable();
	}

	get doseSpotEnabled() {
		return this._doseSpotEnabled.asObservable();
	}

	get hasClinicianId() {
		return this._hasClinicianId.asObservable();
	}

	get isPrescriber() {
		return this._isPrescriber.asObservable();
	}

	set userInfo(user: any) {
		this._userInfo.next(user);
	}
	get userInfo() {
		return this._userInfo.asObservable();
	}

	isInternalUser(): boolean {
		return this._cache.get(this._config.SessionKeys.authentication.isInternalUser).toLowerCase() === "true";
	}

	getUserProfile(userId: number, customerId: number) {
		return this._http.get(this._getUserProfileUrl + userId + "&customerId=" + customerId);
	}

	addInternalUser(userProfile: UserProfile, pharmacyPermissions: PharmacyPermissionModel[]) {
		return this._http.post("api/user/addinternaluser", {
			Profile: userProfile,
			PharmacyPermissions: pharmacyPermissions
		});
	}

	addExternalUser(userProfile: UserProfile, customerId: number, customerPermissions: CustomerPermissionModel[]) {
		return this._http.post("api/user/addexternaluser", {
			Profile: userProfile,
			CustomerId: customerId,
			CustomerPermissions: customerPermissions
		});
	}

	getAllUsersInOrganization(orgId: number) {
		return this._http.get(this._getAllUsersInOrganizationUrl + "?orgId=" + orgId);
	}

	getUserPharmacyPermissions(userId: number) {
		return this._http.get(this._getUserPharmacyPermissionsUrl + userId);
	}

	getUserCustomerPermissions(userId: number) {
		return this._http.get(this._getUserCustomerPermissionsUrl + userId);
	}

	setUserLogin(userProfile: UserProfile, userId: number) {
		return this._http.post("api/user/setuserloginid", {
			Profile: userProfile,
			UserId: userId
		});
	}

	setUserProfile(userProfile: UserProfile, userId: number, pharmacyPermissions: PharmacyPermissionModel[], customerPermissions: CustomerPermissionModel[], customerId: number) {
		return this._http.post("api/user/setuserprofile", {
			Profile: userProfile,
			UserId: userId,
			CustomerId: customerId,
			PharmacyPermissions: pharmacyPermissions,
			CustomerPermissions: customerPermissions
		});
	}

	setUserCustomer(userId: number, customerId: number, isEnabled: boolean) {
		return this._http.post("api/user/setusercustomer", {
			UserId: userId,
			CustomerId: customerId,
			IsEnabled: isEnabled
		});
	}

	getFavorites() {
		return this._http.get(this._favoritesUrl);
	}

	logout() {
		this.signedOut.emit();
		return this._http.post("api/account/logout", {});
	}

	getUserInfo() {
		return this._http.get("api/user/getuser");
	}

	changeCurrentCustomer(customerId: number): Promise<ApiResult> {
		return new Promise<ApiResult>(resolve => {
			this._http.post(this._changeCurrentCustomerUrl, customerId).subscribe((result: ApiResult) => {
				this.customerChanged.emit();
				resolve(result);
			});
		});
	}

	changeUserStatus(userId: number, isLocked: boolean) {
		return this._http.post(this._changeUserStatusUrl, {
			TargetUserId: userId,
			IsLocked: isLocked
		});
	}

	refreshUserAlertCount() {
		this._http.get(this._getAlertCountForUserUrl).subscribe((x: AlertCounts) => {
			this.userAlerts.set(x);
		});
	}

	getSystemAlertsForUser() {
		return this._http.get(this._getSystemAlertsForUserUrl);
	}

	getPendingAuthorizationsForUser() {
		return this._http.get(this._getPendingAuthorizationsForUserUrl);
	}

	getAccountManagerList() {
		return this._http.get(this._getAccountManagerListUrl);
	}

	getUserRoles(userId: number, customerId: number) {
		return this._http.get(this._getUserRolesUrl + userId + "&customerId=" + customerId);
	}

	getUserTeams(userId: number, customerId: number) {
		return this._http.get(this._getUserTeams + userId + "&customerId=" + customerId);
	}

	setUserRoles(userId: number, roles: RoleModel[]) {
		return this._http.post(this._setUserRolesUrl, {
			UserId: userId,
			Roles: roles
		});
	}

	setUserTeams(userId: number, teams: UserTeamModel[]) {
		return this._http.post("api/user/" + userId + "/teams", teams);
	}

	getInternalUsers() {
		return this._http.get(this._getInternalUsersUrl);
	}

	transferUser(customerId: number, userId: number) {
		return this._http.post(this._transferUserUrl, { CustomerId: customerId, UserId: userId });
	}

	checkClinicianIdExists(clinicianId: number, userId: number) {
		return this._http.get(this._checkIfClinicianIdExistsUrl + "?clinicianId=" + clinicianId + "&userId=" + userId);
	}

	refreshUserInfo() {
		this.getUserInfo().subscribe((userInfo: UserInfoModel) => {
			if (userInfo.UserId) {
				this.userInfo = userInfo;
				this.updateCustomerInfo(
					userInfo.CustomerMrnNameOverride,
					userInfo.CustomerMrnFormat,
					userInfo.CustomerMrnFormatDescription,
					userInfo.IsMrnRequired,
					userInfo.IsSsnRequired,
					userInfo.IsScreeningEnabled,
					userInfo.HasFormulary,
					userInfo.AllowMultipleFormularies,
					userInfo.DoseSpotEnabled,
					userInfo.OrganizationProfileOn,
					userInfo.IsPrescriber,
					userInfo.DoseSpotClinicianId
				);
			} else {
				this._authService.doLogout("");
			}
		});
	}

	uploadBulkUsers(users: BulkUploadUser[], customerId?: number): Promise<any> {
		return new Promise<any>(resolve => {
			users.forEach(u => {
				u.roleIds = (u.roles || []).map(r => r.RoleId);
			});
			this._http.post(this._uploadBulkUsersUrl + customerId, users).subscribe(result => {
				resolve(result);
			});
		});
	}

	setOrganizationRole(userId: number, organizationId: number, isAdmin: boolean): Promise<void> {
		return new Promise<void>(resolve => {
			this._http.put("/api/user/setuserorganizationrole?orgId=" + organizationId + "&userId=" + userId + "&isAdmin=" + isAdmin, {}).subscribe(() => {
				resolve();
			});
		});
	}

	setCustomerConfig(customerId: number, config: ErxCustomerConfig): Promise<void> {
		return new Promise<void>(resolve => {
			this._http.put("/api/erx/CustomerConfig?customerId=" + customerId, config).subscribe(() => {
				resolve();
			});
		});
	}
}
