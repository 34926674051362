import {Component, Output, EventEmitter, ViewChild, Input, OnInit} from '@angular/core';
import {NgForm} from "@angular/forms";
import {PatientService} from "../../../../shared/services/patient.service";
import * as moment from "moment";

@Component({
  selector: 'discontinue-rx',
  templateUrl: './form-discontinue-rx.template.html',
  styles: [(`
    .profile-detail label {
      display: block;
    }
  `)]
})

export class DiscontinueRxComponent implements OnInit {
  saving: boolean = false;
  private _formValid: boolean = false;

  private get formValid(): boolean {
    return this._formValid;
  }

  private set formValid(value: boolean) {
    if (value !== this._formValid) {
      this._formValid = value;
      this.formValidChanged.emit(this._formValid);
    }
  }

  discontinueDate: string;

  @Input() patientId: number;
  @Input() orderNumber: number;
  @Input() drugName: string;

  @ViewChild('discontinueRxForm')
  discontinueRxForm: NgForm;

  @Output() discontinueSuccess: EventEmitter<any> = new EventEmitter<any>();
  @Output() discontinueRxFail: EventEmitter<string> = new EventEmitter<string>();
  @Output() formValidChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private _pateintService: PatientService) {
  }

  ngOnInit() {
    this.formValid = true;
    this.discontinueDate = moment(new Date()).format("YYYY-MM-DD");
    this.discontinueRxForm.form.reset();
    this.discontinueRxForm.form.valueChanges.subscribe(x => {
      this.formValid = this.discontinueRxForm.form.valid;
    });
  }

  confirm() {
    this._pateintService.discontinueMedication(this.patientId, this.orderNumber, this.discontinueDate).subscribe((results:any) => {
      if (results.Success) {
        this.discontinueSuccess.emit({orderNumber: this.orderNumber, drugName: this.drugName});
      } else {
        this.discontinueRxFail.emit(results.PublicMessage);
      }
    });
  }
}
