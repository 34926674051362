import { Injectable } from "@angular/core";
import { HttpService } from "./http.service";
import { MappedClaimModel } from "../models/mapped-claim.model";
import * as _ from "lodash";
import { CustomerAuthenticationModel } from "../models/customer-authentication.model";

@Injectable()
export class SamlService {
	private _addMappedClaimUrl: string = "api/samlauth/addmappedclaim";
	private _getRolesUrl: string = "api/samlauth/getRoles";
	private _deleteMappedClaimUrl: string = "api/samlauth/deletemappedclaim";
	private _getAuthUrl: string = "api/samlauth/getauth";
	private _updateAuthUrl: string = "api/samlauth/updateauth";

	constructor(private _http: HttpService) {}

	addMappedClaim(claim: MappedClaimModel, providerId: number) {
		return this._http.post(this._addMappedClaimUrl, { ProviderId: providerId, RoleIds: claim.roleIds, ClaimValue: claim.claimValue });
	}

	deleteMappedClaim(claimValue: string) {
		return this._http.get(this._deleteMappedClaimUrl + "?claimValue=" + claimValue);
	}

	getRoles(subdomain: string) {
		return this._http.get(this._getRolesUrl + "?subdomain=" + subdomain);
	}

	getAuth(orgId?: number, cusId?: number) {
		return this._http.get(this._getAuthUrl + "?orgId=" + orgId + "&cusId=" + cusId);
	}

	updateAuth(auth: CustomerAuthenticationModel) {
		return this._http.post(this._updateAuthUrl, auth);
	}
}
