import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { FileUploadComponent } from "../../input-file-upload/file-upload.component";
import { DocumentInfoModel } from "../../../models/document-info.model";
import { FormulariesStore } from '../../../services/formularies.store';
import { UploadFileSuccessModel } from "../../../models/upload-file-success.model";
import { StatusMessageService } from '../../status-message/status-message.service';
import { ClinicalResourcesService } from '../../../services/clinical-resources.service';
import { ApiResult } from '../../../models/api-result.model';

@Component({
  selector: 'ct-form-formulary-details',
  templateUrl: './form-formulary-details.component.html'
})
export class FormFormularyDetailsComponent implements OnInit {
  saving: boolean = false;
  fileUploadUrl: string = "api/clinicalresources/uploaddocument";
  fileSelected: boolean = false;
  fileChanged: boolean = false;

  @ViewChild("fileUpload") fileUpload: FileUploadComponent;

  @Input() mode: string;
  @Input() file: DocumentInfoModel = new DocumentInfoModel();

  @Output() closeModal: EventEmitter<string> = new EventEmitter<string>();
  @Output() uploadComplete: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    public formulariesStore: FormulariesStore,
    private _statusMessageService: StatusMessageService,
    private _clinicalResourcesService: ClinicalResourcesService
  ) { }

  ngOnInit() {
    this.file.DocumentTypeId = 7;
    
    if (this.mode === "edit") {
		this.fileSelected = true;
	}
  }

  onUploadComplete(results: UploadFileSuccessModel) {
		this.file.OriginalFileName = results.originalFileName;
		this.file.SystemFileName = results.systemFileName;
	}

  onFileSelected(fileName: string) {
		if (fileName.toLowerCase().endsWith(".pdf")) {
			this.fileChanged = true;
			this.fileSelected = true;
		}
	}

  onUploadError(e: string) {
		this._statusMessageService.changeStatusMessage("error", e);
		this.fileSelected = false;
	}

  save() {
		this.saving = true;
		this.file.DocumentTypeId = 7; // formulary
		if (this.mode === "edit"){
			this._clinicalResourcesService.updateDocument(this.file, false).subscribe((result: ApiResult) => {
				if (result && result.Success){
					this.uploadComplete.emit(this.file.OriginalFileName);
				} else {
					this._statusMessageService.changeStatusMessage("error", "Error uploading formulary document");
				}
				this.saving = false;
			}, e => {
				this.saving = false;
				this._statusMessageService.changeStatusMessage("error", "Error uploading formulary document: " + e);
			}, () => {
				this.saving = false;
			})
		} else {
			this._clinicalResourcesService.addDocument(this.file).subscribe((result: ApiResult) => {
				if (result && result.Success){
					this.uploadComplete.emit(this.file.OriginalFileName);
				} else {
					this._statusMessageService.changeStatusMessage("error", "Error uploading formulary document");
				}
				this.saving = false;
			})
		}
	}
}
