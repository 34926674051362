import { Component, EventEmitter, Output, ViewChild, AfterViewInit, AfterContentChecked } from '@angular/core';
import { AdminReport } from 'src/app/ocp/shared/models/admin-report.model';
import { AdminReportStore } from 'src/app/ocp/shared/services/admin-report.store';

@Component({
  selector: 'ct-form-report-details',
  templateUrl: './form-report-details.component.html',
  styleUrls: ['./form-report-details.component.scss']
})
export class FormReportDetailsComponent {
  @Output() closeModal: EventEmitter<string> = new EventEmitter<string>();

  constructor(public store: AdminReportStore) { }

  save() {
    let mode = this.store.mode.get();
    if (mode == "edit"){
      this.store.update().then(() => {
        this.closeModal.emit();
        this.store.selected.set(new AdminReport());
      });
    } else if (mode == "new"){
      this.store.create().then(() => {
        this.closeModal.emit();
        this.store.selected.set(new AdminReport());
      });
    }
	}
}
