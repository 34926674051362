import { Component, Output, EventEmitter, OnInit, Input, OnDestroy } from "@angular/core";
import { UserService } from "../../services/user.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { ErxCustomerConfig } from "../../models/erx/customer-config.model";

@Component({
	selector: "form-customer-erx-settings",
	templateUrl: "./form-customer-erx-settings.component.html"
})
export class FormCustomerErxSettingsComponent implements OnInit, OnDestroy {
	saving: boolean = false;

	@Input() isAdminSection: boolean;
	@Input() customerId: number;
	@Input() config: ErxCustomerConfig = new ErxCustomerConfig();
	@Input() pharmacy: string;

	@Output() closeModal: EventEmitter<string> = new EventEmitter<string>();
	@Output() saveSuccess: EventEmitter<string> = new EventEmitter<string>();

	constructor(private _userService: UserService) {}

	ngOnInit(): void {}

	private $destroyed: Subject<void> = new Subject<void>();
	ngOnDestroy(): void {
		this.$destroyed.next();
		this.$destroyed.unsubscribe();
	}

	save() {
		this.saving = true;

		this._userService.setCustomerConfig(this.customerId, this.config).then(() => {
			this.saving = false;
			this.saveSuccess.emit();
		});
	}
}
