import { Component, OnInit, EventEmitter, ViewChild, Output, Input } from "@angular/core";
import { NgForm } from "@angular/forms";
import { PatientService } from "../../../../shared/services/patient.service";

@Component({
	selector: "ct-form-edit-instructions",
	templateUrl: "./form-edit-instructions.component.html",
	styles: [
		`
			.profile-detail {
				margin-bottom: 1.5rem;
			}
			.profile-detail label {
				display: block;
			}
		`
	]
})
export class FormEditInstructionsComponent implements OnInit {
	constructor(private _pateintService: PatientService) {}

	@ViewChild("editInstructionsForm") editInstructionsForm: NgForm;

	@Input() drugName: string;
	@Input() patientId: number;
	@Input() directions: string;
	@Input() selectedMedicationIsPRN: boolean;

	isPRN: boolean;

	@Output() onSaveDirections: EventEmitter<string> = new EventEmitter<string>();
	@Output() onCancelSaveDirections: EventEmitter<any> = new EventEmitter<any>();
	@Output() onChangeIsPRN: EventEmitter<boolean> = new EventEmitter<boolean>();

	ngOnInit() {
		this.isPRN = this.selectedMedicationIsPRN;
	}

	confirmDirections() {
		this.onSaveDirections.emit(this.directions);
		this.onChangeIsPRN.emit(this.selectedMedicationIsPRN);
	}

	cancelEditingDirections() {
		this.onCancelSaveDirections.emit();
	}

	onChange(val: boolean) {
		this.selectedMedicationIsPRN = this.isPRN;
		this.onChangeIsPRN.emit(this.selectedMedicationIsPRN);
	}
}
