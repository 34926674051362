export class DosespotPharmacy {
	UniquePharmacyId: number;
	Name: string;
	City: string;
	State: string;
	Zip: string;
	Address: string;
	PhoneOrFax: string;
	NcpdpId: string;
}
