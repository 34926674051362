import { Injectable } from "@angular/core";

@Injectable()
export class MobileService {
    isMobile(): boolean {
        const userAgent = window.navigator.userAgent.toLowerCase();
        const mobileDevicePattern = /mobi|android|ios|iphone|ipad|ipod|windows phone|iemobile|blackberry|opera mini|mobile safari|silk/i;
        return mobileDevicePattern.test(userAgent) || this.iOS() || this.ipad();
    }

    private iOS(): boolean {
        return [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod'
        ].includes(window.navigator.platform)
            // iPad on iOS 13 detection
            || (window.navigator.userAgent.includes("Mac") && "ontouchend" in document)
    }

    private ipad(): boolean {
        return window.navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1;
    }

}