import { Injectable, NgZone } from '@angular/core';
import 'firebase/messaging';
import { StoreObject } from '../models/store-object.model';
import { HttpService } from './http.service';
import { PushRegistration } from "../models/push-registration.model";
import { CacheService } from './cache.service';
import { DeviceIdentifier } from "./device-identifier";
import { Subject, combineLatest } from 'rxjs';
import { StatusMessageService } from '../components/status-message/status-message.service';
import { AppStore } from 'src/app/app.store';
import { MobilePushConfig } from '../models/mobile-push-config.model';
import { AuthService } from './auth.service';
import { FirebaseService } from './firebase.service';
import { PushPreferences } from "../models/push-preferences.model";
import { debounceTime } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class FcmService {

    public readonly supported: StoreObject<boolean> = new StoreObject<boolean>(false);

    public pushEnabled: StoreObject<boolean> = new StoreObject<boolean>(false);
    public registrations: StoreObject<PushRegistration[]> = new StoreObject<PushRegistration[]>([]);
    public mismatch: StoreObject<boolean> = new StoreObject<boolean>(false);

    public preferences: StoreObject<PushPreferences> = new StoreObject<PushPreferences>(new PushPreferences());

    private _thisDevice: StoreObject<string> = new StoreObject<string>("");

    private _deviceName: string;

    constructor(
        private _http: HttpService,
        private _cacheService: CacheService,
        private _statusMessageService: StatusMessageService,
        private _appStore: AppStore,
        private _auth: AuthService,
        private _firebase: FirebaseService,
        private _zone: NgZone
    ) {
        this.supported.set(this._firebase.supported);

        this._appStore.OnNotification.subscribe((message: any) => {
            this._zone.run(() => {
                this._statusMessageService.changeStatusMessage(`push-${message.title}`, message.body, 0);
            });
        });

        this._appStore.OnNotificationsChanged.subscribe((config: MobilePushConfig) => {
            this._zone.run(() => {
                DeviceIdentifier.setDeviceId(config.deviceId);
                console.log("Received notification changed event - " + config.enabled);
                if (config.enabled) {
                    let registration = new PushRegistration();
                    registration.DeviceId = config.deviceId;
                    registration.DeviceName = this._deviceName;
                    registration.FcmToken = config.token;

                    console.log(`Registering device ${registration.DeviceName} for ${registration.DeviceId}`);
                    this.register(registration);
                }
                else {
                    this.clearRegistration();
                }
            });
        });

        this._auth.authorized.observable.subscribe((isAuthenticated: boolean) => {
            if (isAuthenticated) {
                this.refreshToken();
            }
        });

        combineLatest([this._thisDevice.observable, this.registrations.observable, this.pushEnabled.observable]).subscribe(([token, registrations, enabled]) => {
            if (!token || token.trim().length === 0) {
                this.mismatch.set(false);
                console.log("No mismatch because there's no token");
                return;
            }

            if (token && registrations && registrations.length === 0) {
                this.mismatch.set(true);
                console.log(`The token ${token} is present, but there are no registrations for this user.`);
                return;
            }

            let registrationMismatch = registrations.filter(x => x.FcmToken === token).length === 0
            this.mismatch.set(registrationMismatch);

            if (registrationMismatch) {
                console.log(`The token ${token} does not match any registration.`);
            } else {
                console.log(`The token ${token} matches a registration.`);
            }
        });

        this._$updateSubject.pipe(debounceTime(1500)).subscribe(() => this.savePreferences());
    }

    refreshToken() {
        var messaging = this._firebase.messaging();

        let enabled = this._cacheService.get("pushEnabled", true);
        if (!enabled || enabled == "false") {
            console.log("CACHE - Push disabled");
            this.pushEnabled.set(false);
            return;
        }

        let enabledFor = this._cacheService.get("pushEnabledFor", true);
        if (this._auth.getUserName() !== enabledFor) {
            if (enabledFor && enabledFor.trim().length > 0) {
                this._thisDevice.set("UNKNOWN");
            }

            this.pushEnabled.set(false);
            return;
        }

        if (!messaging) {
            console.log("Dispatching notification check from refresh token");
            this._appStore.notificationCheck();
            return;
        }

        messaging.getToken().then(token => {
            console.log(token);

            let registration = new PushRegistration();
            registration.DeviceId = DeviceIdentifier.getDeviceId();
            registration.DeviceName = "";
            registration.FcmToken = token;

            this.register(registration);
        }, err => {
            this.pushEnabled.set(false);
        });
    }

    getPermission(deviceName: string): Promise<void> {
        var messaging = this._firebase.messaging();
        return new Promise<void>((resolve, reject) => {
            if (!messaging) {
                this._deviceName = deviceName;
                console.log("Dispatching enable notifications from get permission");
                this._appStore.enableNotifications();
                resolve();
            } else {
                messaging.requestPermission()
                    .then(() => {
                        return messaging.getToken();
                    })
                    .then(token => {
                        console.log("FCM Token:", token);

                        let registration = new PushRegistration();
                        registration.DeviceId = DeviceIdentifier.getDeviceId();
                        registration.DeviceName = deviceName;
                        registration.FcmToken = token;

                        this.register(registration).then(() => resolve());
                    })
                    .catch(error => {
                        this._statusMessageService.changeStatusMessage("error", "Unable to register for push notifications. Please verify your browser settings allow notifications for this site.");
                        this.pushEnabled.set(false);
                        resolve();
                    });
            }
        });
    }

    private register(registration: PushRegistration): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this._http.post("api/push/register", registration).subscribe(x => {
                this.getDevices();
                this._cacheService.set("pushEnabled", "true", true);
                this._cacheService.set("pushEnabledFor", this._auth.getUserName(), true);

                this._thisDevice.set(registration.FcmToken);
                this.pushEnabled.set(true);

                resolve();
            });
        });
    }

    private clearRegistration() {
        this._cacheService.set("pushEnabled", "false", true);
        this._cacheService.set("pushEnabledFor", "", true);
        this._thisDevice.set("");
        this.pushEnabled.set(false);
    }

    receiveMessages() {
        const messaging = this._firebase.messaging();

        if (!messaging) return;

        messaging.onMessage(payload => {
            this._statusMessageService.changeStatusMessage(`push-${payload.notification.title}`, payload.notification.body, 0);
        });
    }

    disable() {
        const messaging = this._firebase.messaging();
        if (!messaging) {
            console.log("Dispatching disable notifications from disable");
            this._appStore.disableNotifications();
        } else {
            messaging.deleteToken().then(() => {
                this.clearRegistration();
            });
        }
    }

    getDevices() {
        this._http.get("api/push/get").subscribe(x => {
            this.registrations.set(x as PushRegistration[]);
        });
    }

    getPreferences() {
        this._http.get("api/push/preferences").subscribe(x => {
            console.log(x);
            this.preferences.set(x as PushPreferences);
        });
    }

    savePreferences() {
        this._http.post("api/push/updatepushpreferences", this.preferences.get()).subscribe(x => { });
    }

    private _$updateSubject: Subject<void> = new Subject<void>();
    updatePreference(key: string, value: boolean) {
        this.preferences.get()[key] = value;
        this._$updateSubject.next();
    }

    disableAllForDevice() {
        let deviceId = DeviceIdentifier.getDeviceId();

        console.log("Disabling all notifications for device " + deviceId);
        this._http.delete("api/push/deletedevice?deviceId=" + deviceId).subscribe(x => {
            this.getDevices();
            this.disable();
            this.mismatch.set(false);
            this.pushEnabled.set(false);
            this._thisDevice.set("");
        });
    }

    deleteDevice(registration: PushRegistration) {
        if (registration.FcmToken === this._thisDevice.get()) {
            this.disable();
        }

        this._http.delete("api/push/delete?pushRegistrationId=" + registration.PushRegistrationId).subscribe(x => {
            this.getDevices();
        });
    }
}
