import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit } from "@angular/core";
import { Permissions } from "../../enums/permissions";
import { AuthService } from "../../services/auth.service";
import { RxOrderModel } from "../../models/rx-order.model";
import { RxOrderSuccessModel } from "../../models/rx-order-success.model";
import { RxService } from "../../services/rx.service";
import { ApiResult } from "../../models/api-result.model";
import { UserService } from "../../services/user.service";
import { StatusMessageService } from "../status-message/status-message.service";

@Component({
	selector: "confirm-medication",
	templateUrl: "./confirm-medication.template.html",
	styleUrls: ["./confirm-medication.scss"]
})
export class ConfirmMedicationComponent implements OnInit {
	saving: boolean = false;
	loading: boolean = false;
	permissions: any = Permissions;
	isInternalUser: boolean = false;
	priorAuthOptions: any[] = [];
	overrideOptions: any[] = [];
	requestOverride: boolean;
	overrideDrugCoverage: boolean;
	showOverrideForm: boolean;
	originalIsCovered: boolean = false;

	@Input() rxModel: RxOrderModel;
	@Input() overrideRequired: boolean = false;
	@Input() isPriorAuthApprove: boolean = false;

	@Output() closeModal: EventEmitter<string> = new EventEmitter<string>();
	@Output() goBack: EventEmitter<any> = new EventEmitter<any>();
	@Output() orderSuccess: EventEmitter<any> = new EventEmitter<any>();
	@Output() priorAuthSuccess: EventEmitter<string> = new EventEmitter<string>();

	get canAuthorize(): boolean {
		return this._authService.hasPermission(Permissions.authorizeMedications);
	}

	get submitAsStatusText() {
		if (this.canAuthorize) {
			if (!this.overrideDrugCoverage) {
				if (!this.isCovered) {
					return "Submit as Covered";
				} else {
					return "Submit as Non-Covered";
				}
			} else {
				return "Save with Override";
			}
		} else {
			if (!this.isCovered) {
				return "Submit as Covered";
			} else {
				return "Submit as Non-Covered";
			}
		}
	}

	get coverageText(): string {
		if (this.rxModel.NewCoverageText) return this.rxModel.NewCoverageText.replace(/"/g, "");
		else {
			if (this.originalIsCovered) return "COVERED BY HOSPICE";
			else return "NOT COVERED BY HOSPICE";
		}
	}

	get isCovered(): boolean {
		return this.coverageText.indexOf("NOT") === -1;
	}

	constructor(private _authService: AuthService, private _rxService: RxService, private _userService: UserService, private _statusMessageService: StatusMessageService) {}

	ngOnInit() {
		this.isInternalUser = this._userService.isInternalUser();

		this.originalIsCovered = this.rxModel.IsCovered;

		if (!this.isCovered) {
			this.priorAuthOptions = [{ text: "Request an Override", value: true }, { text: "Add as Covered", value: false }];
			this.overrideOptions = [{ text: "Add an Override", value: true }, { text: "Add as Covered", value: false }];
		} else {
			this.priorAuthOptions = [{ text: "Request an Override", value: true }, { text: "Add as Non-Covered", value: false }];
			this.overrideOptions = [{ text: "Add an Override", value: true }, { text: "Add as Non-Covered", value: false }];
		}
	}

	priorAuthChanged() {
		if (this.requestOverride) {
			this.rxModel.IsCovered = this.originalIsCovered;
		} else {
			this.rxModel.IsCovered = !this.originalIsCovered;
		}
	}

	overrideChanged() {
		let overrideType = this.overrideDrugCoverage;

		this.rxModel.overrideChanged(this.canAuthorize, overrideType, this.originalIsCovered);

		if (overrideType) {
			this.showOverrideForm = true;
		} else {
			this.showOverrideForm = false;
		}
	}

	backToOrder() {
		this.goBack.emit();
	}

	cancel() {
		this.closeModal.emit();
	}

	submitOrder(submitForReview) {
		this.saving = true;
		if (this.isPriorAuthApprove) {
			this._rxService.submitPriorAuth(this.originalIsCovered, this.rxModel).then(
				(result: string) => {
					this.saving = false;
					this.priorAuthSuccess.emit(result);
				},
				(error: string) => {
					this.saving = false;
					this._statusMessageService.changeStatusMessage("error", error);
				}
			);
		} else {
			this._rxService.submitOrder(submitForReview, this.originalIsCovered, this.canAuthorize, this.overrideRequired, this.overrideDrugCoverage, this.rxModel).then(
				(result: RxOrderSuccessModel) => {
					this.saving = false;
					this.orderSuccess.emit(result);
				},
				(error: string) => {
					this.saving = false;
					this._statusMessageService.changeStatusMessage("error", error);
				}
			);
		}
	}

	submitAsOppositeCoverage() {
		this.saving = true;
		this.rxModel.SubmitForReview = false;
		this.rxModel.IsCovered = !this.originalIsCovered;

		this._rxService.addOrder(this.rxModel).then(
			(result: RxOrderSuccessModel) => {
				this.saving = false;
				this.orderSuccess.emit(result);
			},
			(error: string) => {
				this.saving = false;
				this._statusMessageService.changeStatusMessage("error", error);
			}
		);
	}
}
