import { Injectable } from "@angular/core";
import { StatusMessageService } from "../components/status-message/status-message.service";
import { ApiResult } from "../models/api-result.model";
import { Pharmacy } from "../models/pharmacy.model";
import { StoreObject } from "../models/store-object.model";
import { HttpService } from "./http.service";
import { PharmacySearchFiltersModel } from "../models/pharmacy-search-filters.model";
import { PharmacySearchResult } from "../models/pharmacy-search-result.model";

@Injectable()
export class PharmacyStore {
	public readonly pharmacies: StoreObject<Pharmacy[]> = new StoreObject<Pharmacy[]>([]);
	public readonly selected: StoreObject<Pharmacy> = new StoreObject<Pharmacy>(new Pharmacy());
	public pharmacySearchResults: StoreObject<Pharmacy[]> = new StoreObject([]);
	public pharmacySearchFilter: StoreObject<PharmacySearchFiltersModel> = new StoreObject<PharmacySearchFiltersModel>(new PharmacySearchFiltersModel());

	public loading: boolean = false;

	constructor(private _http: HttpService, private _status: StatusMessageService) {}

	refresh() {
		this.loading = true;
		this._http.get("api/admin/pharmacy").subscribe((result: Pharmacy[]) => {
			this.loading = false;
			if (result) {
				result.forEach(p => {
					if (p.refillThreshold) {
						p.refillThreshold = +(p.refillThreshold * 100).toFixed(2);
					}
				});
				this.pharmacies.set(result);
			} else {
				this.pharmacies.set([]);
				this._status.changeStatusMessage("error", "Error retrieving pharmacy list.");
			}
		});
	}

	update(): Promise<boolean> {
		return new Promise<boolean>(resolve => {
			this.loading = true;
			let pharmacy = this.selected.get();
			if (pharmacy.refillThreshold) {
				pharmacy.refillThreshold = +(pharmacy.refillThreshold * 0.01).toFixed(2);
			}
			this._http.post("api/admin/pharmacy/" + pharmacy.id, pharmacy).subscribe((result: ApiResult) => {
				this.loading = false;
				if (result.Success) {
					this._status.changeStatusMessage("success", "Pharmacy successfully saved.");
				} else {
					this._status.changeStatusMessage("error", "Error saving pharmacy.");
				}

				this.refresh();
				resolve(result.Success);
			});
		});
	}

	applySearchFilter() {
		let filter = this.pharmacySearchFilter.get();
		this.searchPharmacies(filter.name, filter.address, filter.city, filter.state, filter.zipCode, filter.phone, filter.isEpcs);

		//let results = this.pharmacies.get();
		//this.pharmacySearchResults.set(results);
	}

	clearSearchFilter() {
		let f = new PharmacySearchFiltersModel();
		this.pharmacySearchFilter.set(f);

		// ToDo: remove this once filters are functional
		this.pharmacySearchResults.set([]);
	}

	searchPharmacies(name: string, address: string, city: string, state: string, zip: string, phone: string, isEpcs: boolean) {
		let url = "api/dosespot/searchpharmacy";

		// add the parameters that have value to the query string
		let params = "?";
		if (name && name.trim().length > 0) params += "name=" + name + "&";
		if (address && address.trim().length > 0) params += "address=" + address + "&";
		if (city && city.trim().length > 0) params += "city=" + city + "&";
		if (state && state.trim().length > 0) params += "state=" + state + "&";
		if (zip && zip.trim().length > 0) params += "zip=" + zip + "&";
		if (phone && phone.trim().length > 0) params += "phoneOrFax=" + phone + "&";
		if (isEpcs) params += "specialty[0]=2048&";
		if (params === "?") {
			params = "";
		}

		this.loading = true;
		this._http.get(url + params).subscribe((result: any) => {
			this.loading = false;
			this.pharmacySearchResults.set(result.Items.map(p => new PharmacySearchResult().deserialize(p)));
		});
	}
}
