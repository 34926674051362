import { Component, Input, Output, EventEmitter, OnInit, AfterViewInit } from "@angular/core";
import { PatientInfoModel } from "../../../../shared/models/patient.model";
import { PatientService } from "../../../../shared/services/patient.service";
import { UserService } from "../../../../shared/services/user.service";
import { CustomerProfileModel } from "../../../../shared/models/customer-profile.model";
import * as moment from "moment";

@Component({
	selector: "admissions-details",
	templateUrl: "./admissions-details.template.html",
	styleUrls: ["./admissions-details.scss"]
})
export class AdmissionsDetailsComponent implements OnInit, AfterViewInit {
	isInternalUser: boolean = false;
	currentStep: number = 2;
	focusPatientStatus: EventEmitter<any> = new EventEmitter<any>();
	hasTeams: boolean = false;
	mrnRequired: boolean = false;
	preferenceList: any[] = [];
	statusList = this._patientService.getPatientStatuses();

	@Input() patient: PatientInfoModel = new PatientInfoModel();
	@Input() mode: string;
	@Input() admissionsType: string;

	@Output() goBack: EventEmitter<string> = new EventEmitter<string>();
	@Output() goToStepThree: EventEmitter<string> = new EventEmitter<string>();
	@Output() closeModal: EventEmitter<string> = new EventEmitter<string>();
	@Output() saveAdmissions: EventEmitter<any> = new EventEmitter<any>();

	get needsMrn(): boolean {
		return this.mrnRequired && this.patient.patientStatus !== 0 && !this.patient.Mrn;
	}

	constructor(private _patientService: PatientService, private _userService: UserService) {}

	ngOnInit() {
		this._patientService.getPackingPreferenceList().subscribe(preferenceList => {
			this.preferenceList = preferenceList;
		});

		this._userService.selectedCustomer.subscribe((customer: CustomerProfileModel) => {
			this.hasTeams = customer.HasTeams;
		});

		this._userService.isMrnRequired.subscribe((isRequired: boolean) => {
			this.mrnRequired = isRequired;
		});

		this.isInternalUser = this._userService.isInternalUser();
	}

	ngAfterViewInit() {
		if (this.mode === "New") {
			this.focusPatientStatus.emit();
		}
	}

	deceasedChanged(bool: boolean) {
		this.patient.dischargeDate = this.patient.dischargeDate ? this.patient.dischargeDate : moment(new Date()).format("YYYY-MM-DD");
		this.patient.isDeceased = bool;
	}

	patientStatusChanged(patientStatusId: number) {
		if (patientStatusId) {
			if (!this.patient.dateAdmitted) {
				this.patient.dateAdmitted = moment(new Date()).format("YYYY-MM-DD");
			}

			if (patientStatusId === 2 || (patientStatusId === 3 && !this.patient.dischargeDate)) {
				this.patient.dischargeDate = moment(new Date()).format("YYYY-MM-DD");
			}
		} else {
			this.patient.dateAdmitted = null;
			this.patient.dischargeDate = null;
		}
	}

	save() {
		this.saveAdmissions.emit({ admissionsType: this.admissionsType, patient: this.patient });
	}
}
