import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from "@angular/core";
import { PatientService } from "../../../shared/services/patient.service";
import { RefillRequestModel } from "../../../shared/models/refill-request.model";
import { GenerateRefillRequestModel } from "../../../shared/models/generate-refill-request.model";
import { StatusMessageService } from "../../../shared/components/status-message/status-message.service";
import { PatientMedicationModel } from "../../../shared/models/patient-medication.model";
import { RxService } from "../../../shared/services/rx.service";
import { ApiResult } from "../../../shared/models/api-result.model";
import { UserService } from "src/app/ocp/shared/services/user.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { DeliveryTimeframe } from "../../models/delivery-timeframe.model";

@Component({
	selector: "form-quick-refill",
	templateUrl: "./form-quick-refill.template.html",
	styleUrls: ["../../patient-details.scss", "./form-quick-refill.scss"]
})
export class FormQuickRefill implements OnInit, OnDestroy {
	saving: boolean = false;
	isLoading: boolean = false;
	private _medications: PatientMedicationModel[] = [];
	showConfirmation: boolean = false;
	filteredMedications: PatientMedicationModel[] = [];
	selectedMedications: PatientMedicationModel[] = [];
	orderComments: string;
	callbackPhone: string;
	phoneInit: boolean = false;
	deliveryTimeframes: DeliveryTimeframe[] = [];
	selectedTimeframe: string = "Standard";

	@Input() patientId: number;

	@Output() closeModal: EventEmitter<any> = new EventEmitter<any>();

	focusSelectAll: EventEmitter<any> = new EventEmitter<any>();

	private _destroyed: Subject<any> = new Subject<any>();

	get isAllMedsSelected(): boolean {
		return this.filteredMedications.length && this.filteredMedications.length === this.selectedMedications.length;
	}

	get showEarlyRefillWarning(): boolean {
		return (
			this.filteredMedications.filter((med: PatientMedicationModel) => {
				return med.IsSelected && med.IsEarlyRefill;
			}).length > 0
		);
	}

	get showAdditionalChargeWarning(): boolean {
		const selectedTimeframeItem = this.deliveryTimeframes.find(a => a.TimeframeName == this.selectedTimeframe);
		return selectedTimeframeItem && selectedTimeframeItem.HasAdditionalCharge;
	}

	constructor(private _patientService: PatientService, private _statusService: StatusMessageService, private _rxService: RxService, private _userService: UserService) { }

	ngOnInit() {
		this._rxService.getDeliveryTimeframes().then(result => (this.deliveryTimeframes = result));
		this._userService.userInfo.pipe(takeUntil(this._destroyed)).subscribe(u => {
			if (!this.phoneInit) {
				this.callbackPhone = u.UserPhone;
				this.phoneInit = true;
			}
		});
		this.getMedications();
	}

	ngOnDestroy() {
		this._destroyed.next();
		this._destroyed.unsubscribe();
	}

	filterMedications() {
		this.filteredMedications = [];
		for (let i = 0; i < this._medications.length; i++) {
			let medication = this._medications[i];
			if (medication.AllowRefill) {
				this.filteredMedications.push(medication);
				medication.IsSelected = false;
			}
		}
	}

	getMedications() {
		this.isLoading = true;
		this._patientService.getPatientMedications(this.patientId).subscribe((medications: PatientMedicationModel[]) => {
			medications.sort((a, b) => {
				if (a.DrugName < b.DrugName) return -1;

				if (a.DrugName > b.DrugName) return 1;

				return 0;
			});
			this._medications = medications;

			this.filterMedications();
			this.isLoading = false;
		});
	}

	selectMed(med: PatientMedicationModel) {
		if (this._rxService.getUnableToRefillMessage(med).length) return;

		if (med.IsSelected) {
			med.IsSelected = false;
		} else {
			med.IsSelected = true;
		}

		this.selectedMedications = this.filteredMedications.filter((med: PatientMedicationModel) => {
			return med.IsSelected;
		});
	}

	selectAllChanged(value: boolean) {
		this.filteredMedications.forEach((med: PatientMedicationModel) => {
			let unableToRefill: boolean = this._rxService.getUnableToRefillMessage(med).length > 0;

			if (!unableToRefill) {
				med.IsSelected = value;
			}
		});

		this.selectedMedications = this.filteredMedications.filter((med: PatientMedicationModel) => {
			return med.IsSelected;
		});
	}

	createRefillRequest(med) {
		let refillRequest = new RefillRequestModel();
		refillRequest.Comments = this.orderComments;
		refillRequest.CallbackPhone = this.callbackPhone;
		refillRequest.PatientId = this.patientId;
		refillRequest.RxNumber = med.RxNumber;
		refillRequest.LabelName = med.DrugName;
		refillRequest.DeliveryTimeframe = this.selectedTimeframe;

		return refillRequest;
	}

	confirmRefill() {
		if (!this.showConfirmation) {
			this.selectedMedications = this.filteredMedications.filter((med: PatientMedicationModel) => {
				return med.IsSelected;
			});

			// hack for IOS to move the screen to the top of the dialog before showing confirmation
			//  due to a rendering bug on IOS iPhone 6s
			this.focusSelectAll.emit();
			setTimeout(() => {
				this.showConfirmation = true;
			}, 50);
		} else {
			this.saving = true;
			let refillRequests: any[] = [];
			this.selectedMedications.forEach((med: any) => {
				refillRequests.push(this.createRefillRequest(med));
			});
			let refillRequest = new GenerateRefillRequestModel();
			refillRequest.RefillRequests = refillRequests;
			this._patientService.createRefillRequest(refillRequest).subscribe((result: ApiResult) => {
				this.saving = false;
				if (!result.Success) {
					let message = "";
					if (result.Result) {
						for (let i = 0; i < result.Result.length; i++) {
							let refillResult = result.Result[i];
							if (!refillResult.Success) {
								message += refillResult.PublicMessage + " ";
							}
						}
						if (message) {
							this._statusService.changeStatusMessage("error", message);
						}
					} else {
						this._statusService.changeStatusMessage("error", result.PublicMessage);
					}
				} else {
					this.closeQuickRefill();
					this._statusService.changeStatusMessage("success", "Refill request complete");
				}
			});
		}
	}

	reset() {
		this.filterMedications();
		this.orderComments = null;
		this.showConfirmation = false;
		this.selectAllChanged(false);
	}

	closeQuickRefill() {
		this.reset();
		this.closeModal.emit("close");
	}

	goBack() {
		this.showConfirmation = false;
	}
}
