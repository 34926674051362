import { Component, OnInit, Input, OnDestroy } from "@angular/core";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { IntegrationSettings } from "../../models/integration-settings.model";
import { CustomerService } from "../../services/customer.service";
import { UserService } from "../../services/user.service";

@Component({
    selector: "customer-integrations",
    templateUrl: "customer-integrations.template.html",
    styleUrls: ["customer-integrations.scss"]
})
export class CustomerIntegrationsComponent implements OnInit, OnDestroy {
    customerId: number;
    integration: IntegrationSettings;

    constructor(
        private _customerService: CustomerService,
        private _userService: UserService) { }

    ngOnInit(): void {
        this._userService.$electedCustomer.pipe(takeUntil(this.$destroyed)).subscribe((customer: any) => {
            if (customer && customer.CustomerId !== this.customerId) {
                this.customerId = customer.CustomerId;
                this._customerService.getCustomerIntegrations(this.customerId).subscribe((result: any) => {
                    this.integration = new IntegrationSettings().deserialize(result);
                });
            }
        });


    }

    private $destroyed: Subject<void> = new Subject<void>();
    ngOnDestroy(): void {
        this.$destroyed.next();
        this.$destroyed.unsubscribe();
    }
}