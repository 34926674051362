import { Component, Input, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { Permissions } from "../shared/enums/permissions";

@Component({
	selector: "administration",
	templateUrl: "./administration.template.html",
	styleUrls: ["./administration.scss"]
})
export class Administration implements OnInit {
	permissions: any = Permissions;

	tabContent: string = "organizations";
	@Input() showAdminTabButtons: boolean;

	ngOnInit() {
		this.showAdminTabButtons = true;
		this.setTab(this._router.url);
	}

	private setTab(url: string) {
		if (url.indexOf("/administration/users") >= 0) {
			this.tabContent = "users";
		} else if (url.indexOf("/administration/roles") >= 0) {
			this.tabContent = "internalRoles";
		} else if (url.indexOf("/administration/files") >= 0) {
			this.tabContent = "fileManager";
		} else if (url.indexOf("/administration/system-alerts") >= 0){
			this.tabContent = "systemAlerts";
		} else if (url.indexOf("/administration/pharmacies") >= 0){
			this.tabContent = "pharmacies";
		} else if (url.indexOf("/administration/reports") >= 0){
			this.tabContent = "reports";
		} else {
			this.tabContent = "organizations";
		}
	}

	constructor(private _router: Router, private _route: ActivatedRoute) {}

	loadOrganizationsTab() {
		this._router.navigate(["./organizations"], { relativeTo: this._route }).then(() => {
			this.tabContent = "organizations";
		});
	}

	loadUsersTab() {
		this._router.navigate(["./users"], { relativeTo: this._route }).then(() => {
			this.tabContent = "users";
		});
	}

	loadInternalRolesTab() {
		this._router.navigate(["./roles"], { relativeTo: this._route }).then(() => {
			this.tabContent = "internalRoles";
		});
	}

	loadFileManagerTab() {
		this._router.navigate(["./files"], { relativeTo: this._route }).then(() => {
			this.tabContent = "fileManager";
		});
	}

	loadSystemAlertsTab(){
		this._router.navigate(["./system-alerts"], { relativeTo: this._route }).then(() => {
			this.tabContent = "systemAlerts";
		});
	}

	loadPharmaciesTab() {
		this._router.navigate(["./pharmacies"], { relativeTo: this._route }).then(() => {
			this.tabContent = "pharmacies";
		});
	}

	loadReportsTab() {
		this._router.navigate(["./reports"], { relativeTo: this._route }).then(() => {
			this.tabContent = "reports";
		});
	}
}
