import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { Clinician } from "../../../models/clinician-model";

@Component({
	selector: "ocp-order-settings-card",
	templateUrl: "./order-settings-card.component.html",
	styleUrls: ["./order-settings-card.component.scss"]
})
export class OrderSettingsCardComponent implements OnInit {
	@Input() effectiveDate: Date;
	@Input() selectedPrescriber: any;
	@Input() disableEdit: boolean = false;

	@Output() onEditSettings: EventEmitter<boolean> = new EventEmitter<boolean>();

	constructor() {}

	ngOnInit(): void {}

	editSettings() {
		this.onEditSettings.emit();
	}
}
