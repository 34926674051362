import { Component, Input, Output, EventEmitter, OnInit, ViewChild } from "@angular/core";
import { MedicationSearchResultModel } from "../../../shared/models/medication-search-result.model";
import { RxService } from "../../../shared/services/rx.service";
import { RxOrderModel } from "../../../shared/models/rx-order.model";
import { SearchMedicationsComponent } from "../../../shared/components/search-medications/search-medications.component";
import { NgForm } from "@angular/forms";
import { ApiResult } from "../../../shared/models/api-result.model";
import { UserService } from "../../../shared/services/user.service";
import { StatusMessageService } from "../../../shared/components/status-message/status-message.service";
import { Permissions } from "../../../shared/enums/permissions";
import { AuthService } from "../../../shared/services/auth.service";
import { PriorAuthRequest } from "../../../shared/models/prior-auth-request.model";

@Component({
	selector: "form-multi-profile-medication",
	templateUrl: "./form-multi-profile-medication.template.html",
	styleUrls: ["./form-multi-profile-medication.scss"]
})
export class FormMultiProfileMedication implements OnInit {
	isInternalUser: boolean = false;
	hasFormulary: boolean = false;
	checkingCoverage: boolean = false;

	coveredOptions: any[] = [
		{ text: "Covered by Hospice", value: true },
		{ text: "Non-covered", value: false }
	];
	originalCoverage: boolean;

	coverageStatus: string;
	verifyCoverageSuccess: boolean = false;
	coverageMessage: string = "";
	isProfileOnly: boolean;
	approvedByName: string;
	viewMode: string = "create"; // 'create', 'edit', 'summary'
	isVerifying: boolean = false;

	overrideDrugCoverage: boolean;
	overrideOptions: any[] = [];
	overrideType: string = "";

	hasDrugInteraction: boolean;
	hasPatientAllergy: boolean;

	@ViewChild("addOrderForm") addOrderForm: NgForm;

	@ViewChild("searchMedications") searchMedications: SearchMedicationsComponent;

	@Input() patientId: number;
	@Input() rxOrderModel: RxOrderModel = new RxOrderModel();
	@Input() createMode: string;

	@Output() onAddNewMedicationToOrder: EventEmitter<any> = new EventEmitter<any>();
	@Output() onRemoveMedication: EventEmitter<any> = new EventEmitter<any>();
	@Output() onRemovePrimaryMedForm: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() onSaveMedicationOnly: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() onShowScreening: EventEmitter<any> = new EventEmitter<any>();
	@Output() onEditing: EventEmitter<boolean> = new EventEmitter<boolean>();

	get canSave(): boolean {
		return (this.rxOrderModel.IsCompound || this.rxOrderModel.selectedMedication) && this.addOrderForm.form.valid && this.verifyCoverageSuccess;
	}

	get needsCoverageCheck(): boolean {
		return !this.verifyCoverageSuccess && !this.rxOrderModel.IsCompound && this.hasFormulary;
	}

	get drugInteractionPopover(): string {
		if (this.hasDrugInteraction) {
			return "Drug interaction found.";
		} else {
			return "No drug interactions found.";
		}
	}

	get drugInteractionMessage(): string {
		if (this.hasDrugInteraction) {
			return "Drug interaction found.";
		} else {
			return "No drug to drug interactions found.";
		}
	}

	get patientAllergyPopover(): string {
		if (this.hasPatientAllergy) {
			return "Patient allergy found.";
		} else {
			return "No patient allergies found.";
		}
	}

	get patientAllergyMessage(): string {
		if (this.hasPatientAllergy) {
			return "Patient allergy detected (Nausea).";
		} else {
			return "No patient allergies found.";
		}
	}

	get priorAuthPopover(): string {
		if (this.rxOrderModel.overrideRequired) {
			return "Prior Authorization required.";
		} else {
			return "No prior authorization required.";
		}
	}

	get priorAuthMessage(): string {
		if (this.rxOrderModel.overrideRequired) {
			return "Prior Authorization required.";
		} else {
			return "No prior authorization required.";
		}
	}

	get orderStatusCss(): string {
		if (!this.rxOrderModel.orderProcessed) {
			return "warning";
		} else if (this.rxOrderModel.orderProcessed) {
			if (this.rxOrderModel.orderSuccess) {
				return "success";
			} else {
				return "error";
			}
		}
	}

	get orderStatusIcon(): string {
		if (!this.rxOrderModel.orderProcessed) {
			return "lens";
		} else if (this.rxOrderModel.orderProcessed) {
			if (this.rxOrderModel.orderSuccess) {
				return "check_circle";
			} else {
				return "error";
			}
		}
	}

	get orderSuccessPopover(): string {
		if (!this.rxOrderModel.orderProcessed) {
			return "Waiting to process order.";
		} else if (this.rxOrderModel.orderProcessed) {
			if (this.rxOrderModel.orderSuccess) {
				return "Order processed.";
			} else {
				return "Error processing order.";
			}
		}
	}

	constructor(private _authService: AuthService, private _rxService: RxService, private _userService: UserService, private _statusMessageService: StatusMessageService) {}

	private setCoverageAsVerified() {
		this.verifyCoverageSuccess = true;
		this.rxOrderModel.overrideRequired = false;
		this.coverageMessage = "";
	}

	private setCoverageAsNotReviewed() {
		this.verifyCoverageSuccess = false;
		this.coverageMessage = "COVERAGE NOT REVIEWED";
	}

	private updateCoverageOverride() {
		if (this.rxOrderModel.IsCompound || !this.hasFormulary) {
			this.setCoverageAsVerified();
			return true;
		}
		this.setCoverageAsNotReviewed();
		return false;
	}

	ngOnInit() {
		this.isInternalUser = this._userService.isInternalUser();
		this._userService.hasFormulary.subscribe((hasFormulary: boolean) => {
			this.hasFormulary = hasFormulary;
			this.updateCoverageOverride();
		});
	}

	reset() {
		if (this.searchMedications) {
			this.searchMedications.clear();
		}
		this.addOrderForm.form.reset();
		this.rxOrderModel.Ndc = null;
		this.rxOrderModel.DrugName = null;
		this.rxOrderModel.FrameworkName = null;
		this.rxOrderModel.Directions = null;
		this.rxOrderModel.StartDate = new Date();
		this.rxOrderModel.DiscontinueDate = null;
		this.rxOrderModel.IsCompound = null;
		this.rxOrderModel.IsCovered = null;
		this.rxOrderModel.IsPrn = null;
		this.rxOrderModel.AuthorizationStatusId = null;
		this.rxOrderModel.PriorAuthNote = null;
		this.rxOrderModel.DcoStartDate = null;
		this.rxOrderModel.DcoEndDate = null;
		this.rxOrderModel.DcoMaxQuantity = null;
		this.rxOrderModel.DcoMaxDaysSupply = null;
		this.rxOrderModel.DcoMaxRefills = null;
		this.rxOrderModel.selectedMedication = null;
		this.coverageStatus = null;
		this.verifyCoverageSuccess = false;
		this.hasDrugInteraction = false;
		this.hasPatientAllergy = false;
	}

	cancel() {
		this.reset();
	}

	medicationSearchCleared(obj: any) {
		this.rxOrderModel.selectedMedication = null;
		if (!this.rxOrderModel.IsCompound && this.hasFormulary) {
			this.setCoverageAsNotReviewed();
		}
	}

	medicationSelected(medication: MedicationSearchResultModel) {
		this.rxOrderModel.selectedMedication = medication;
		this.rxOrderModel.Ndc = medication.Ndc;
		this.rxOrderModel.DrugName = medication.DrugName;
		this.rxOrderModel.FrameworkName = medication.DrugName;
		this.rxOrderModel.IsControl = medication.IsControl;
		if (!this.rxOrderModel.IsCompound && this.hasFormulary) {
			this.setCoverageAsNotReviewed();
		}
		this._rxService.screenDrugInteractions(this.patientId, medication.Gpi).subscribe((result: ApiResult) => {
			if (result.Success) {
				this.hasDrugInteraction = result.Result.length > 0;
			} else {
				this._statusMessageService.changeStatusMessage("error", result.PublicMessage);
			}
		});
		this._rxService.screenAllergies(this.patientId, medication.Gpi).subscribe((result: ApiResult) => {
			if (result.Success) {
				this.hasPatientAllergy = result.Result.length > 0;
			} else {
				this._statusMessageService.changeStatusMessage("error", result.PublicMessage);
			}
		});
	}

	openDrugScreen() {
		if (!this.hasDrugInteraction) return;

		this.onShowScreening.emit({ type: "drug-interaction", order: this.rxOrderModel, gpi: this.rxOrderModel.selectedMedication.Gpi });
	}

	openAllergyScreen() {
		if (!this.hasPatientAllergy) return;

		this.onShowScreening.emit({ type: "allergy", order: this.rxOrderModel, gpi: this.rxOrderModel.selectedMedication.Gpi });
	}

	openFailureReason() {
		if (this.rxOrderModel.orderSuccess || !this.rxOrderModel.orderProcessed) return;

		this.onShowScreening.emit({ type: "order-failure", gpi: this.rxOrderModel.orderFailureReason });
	}

	verifyCoverage() {
		if (this.updateCoverageOverride()) {
			return;
		}

		this.setCoverageAsNotReviewed();
		this.originalCoverage = this.rxOrderModel.IsCovered;

		if (this.rxOrderModel.selectedMedication && typeof this.rxOrderModel.IsCovered !== "undefined") {
			this.checkingCoverage = true;

			this._rxService.verifyCoverage(this.patientId, this.rxOrderModel.Ndc, this.rxOrderModel.IsCovered, this.rxOrderModel.StartDate).subscribe((result: ApiResult) => {
				this.checkingCoverage = false;

				if (this.updateCoverageOverride()) {
					return;
				}

				if (result.Success) {
					if (result.Result.Result === "Error") {
						this._statusMessageService.changeStatusMessage("error", result.Result.CustomMessage);
					} else {
						this.verifyCoverageSuccess = true;
						if (result.Result.Result === "OverrideRequired") {
							this.rxOrderModel.overrideRequired = true;
							this.rxOrderModel.DcoStartDate = new Date();

							if (this.createMode === "nonDispensingOrder") {
								if (this.rxOrderModel.IsCovered) {
									this.overrideOptions = [
										{ text: "Add an Override", value: "override" },
										{ text: "Add as Non-Covered", value: "noncovered" },
										{ text: "Send to Hospice for Approval", value: "send" }
									];
								} else {
									this.overrideOptions = [
										{ text: "Add an Override", value: "override" },
										{ text: "Add as Covered", value: "covered" },
										{ text: "Send to Hospice for Approval", value: "send" }
									];
								}
							} else {
								this.overrideOptions = [
									{ text: "Add an Override", value: "override" },
									{ text: "Add a Note", value: "note" }
								];
							}
						} else {
							this.rxOrderModel.overrideRequired = false;
						}

						this.coverageMessage = result.Result.StatusMessage;
					}
				} else {
					this._statusMessageService.changeStatusMessage("error", result.PublicMessage);
				}
			});
		}
	}

	overrideChanged(e: any) {
		this.overrideType = e;

		if (this.rxOrderModel && this.createMode === "nonDispensingOrder") {
			this.rxOrderModel.overrideChanged(this._authService.hasPermission(Permissions.authorizeMedications), this.overrideType === "override", this.originalCoverage);
		}

		if (this.overrideType === "send") {
			this.rxOrderModel.IsCovered = this.originalCoverage;
		}
	}

	compoundChanged() {
		this.updateCoverageOverride();

		if (this.rxOrderModel.IsCompound) {
			this.rxOrderModel.Ndc = null;
		} else {
			if (this.rxOrderModel.selectedMedication) {
				this.rxOrderModel.Ndc = this.rxOrderModel.selectedMedication.Ndc;
				this.rxOrderModel.DrugName = this.rxOrderModel.selectedMedication.DrugName;
				this.rxOrderModel.FrameworkName = this.rxOrderModel.selectedMedication.DrugName;
			}
		}
	}

	medicationStartDateChanged() {
		if (this.rxOrderModel.StartDate && this.rxOrderModel.DiscontinueDate) {
			let startDate = new Date(this.rxOrderModel.StartDate);
			let discontinueDate = new Date(this.rxOrderModel.DiscontinueDate);

			if (startDate.getTime() - discontinueDate.getTime() >= 0) {
				// admit date is after discharge date
				this.rxOrderModel.DiscontinueDate = this.rxOrderModel.StartDate;
			}
		}
	}

	coverageChanged() {
		this.rxOrderModel.overrideRequired = false;
		this.updateCoverageOverride();

		if (!this.rxOrderModel.IsCovered) {
			this.rxOrderModel.Quantity = 0;
			this.rxOrderModel.Refills = 0;
			this.rxOrderModel.IsProfileOnly = true;
		} else if (this.rxOrderModel.IsCovered) {
			this.rxOrderModel.IsProfileOnly = false;
		}
	}

	saveAndAddNew() {
		this.onAddNewMedicationToOrder.emit();
		this.save(false);
	}

	saveOnly() {
		this.onRemovePrimaryMedForm.emit();
		this.save(true);
	}

	save(doneEditing: boolean) {
		if (doneEditing) {
			this.onEditing.emit(false);
		}

		if (this.overrideType === "covered") {
			this.rxOrderModel.IsCovered = true;
			this.rxOrderModel.overrideRequired = false;
			this.coverageMessage = "";
		}

		if (this.overrideType === "noncovered") {
			this.rxOrderModel.IsCovered = false;
			this.rxOrderModel.overrideRequired = false;
			this.coverageMessage = "";
		}

		if (this.overrideType === "send") {
			this.rxOrderModel.SubmitForReview = true;
		}

		this.viewMode = "summary";
		this.isVerifying = true;

		if (this.overrideType === "override" && this.createMode !== "nonDispensingOrder") {
			// Need to submit PA request

			const requestModel = new PriorAuthRequest();
			requestModel.PatientId = this.patientId;
			requestModel.Covered = this.rxOrderModel.IsCovered;
			requestModel.NDC = this.rxOrderModel.Ndc;
			requestModel.EffectiveDate = this.rxOrderModel.DcoStartDate;
			requestModel.TerminationDate = this.rxOrderModel.DcoEndDate;
			requestModel.MaxQuanity = this.rxOrderModel.DcoMaxQuantity;
			requestModel.MaxRefills = this.rxOrderModel.DcoMaxRefills;
			requestModel.PriorAuthNote = this.rxOrderModel.PriorAuthNote;
			requestModel.MaxDaySupply = this.rxOrderModel.DcoMaxDaysSupply;

			this._rxService.addPriorAuthRequest(requestModel).subscribe((result: any) => {
				this.finishSave();
			});
		} else {
			this.finishSave();
		}
	}

	private finishSave() {
		let self = this;

		setTimeout(function() {
			self.isVerifying = false;
			if (self.rxOrderModel.overrideRequired || self.hasPatientAllergy || self.hasDrugInteraction) {
				self._statusMessageService.changeStatusMessage("error", "One or more of the added medications contain warnings that should be addressed before continuing.");
			}
		}, 1500);
	}

	editMedication() {
		this.viewMode = "edit";
		this.onEditing.emit(true);
	}

	cancelEdit() {
		this.onEditing.emit(false);
		this.viewMode = "summary";
	}

	removeMedicationFromOrder() {
		this.onEditing.emit(false);
		if (this.viewMode === "create") {
			this.onRemovePrimaryMedForm.emit();
			this.onRemoveMedication.emit();
		} else {
			this.onRemoveMedication.emit();
		}
	}
}
