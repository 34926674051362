import { Injectable } from "@angular/core";
import { ISearchService } from "../components/patient-search/search.interface";
import { ErxOrderStore } from "./erx-order.store";

@Injectable()
export class DosespotMedSearchService implements ISearchService {
	constructor(private eRxStore: ErxOrderStore) {}

	search(params: string[]) {
		return this.eRxStore.searchMedications(params[0]);
	}
}
