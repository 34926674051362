import {Component, OnInit, Input} from '@angular/core';
import {PermissionMatrixModel} from "../../models/permission-matrix.model";

@Component({
  selector: 'permission-matrix',
  templateUrl: './permission-matrix.template.html',
  styleUrls: ['./permission-matrix.scss']
})

export class PermissionMatrixComponent implements OnInit {
  permissionTableRows: any[] = [];
  roles: any[] = [];

  @Input() permissions: PermissionMatrixModel[] = [];

  constructor() {
  }

  ngOnInit() {
    this.initPermissionMatrix();
  }

  initPermissionMatrix() {
    let permissions: any[] = [];
    let self = this;
    this.permissions.forEach((permissionMatrix: PermissionMatrixModel) => {
      let permission = permissions.find(x => {
        return x.permissionId === permissionMatrix.PermissionId;
      });

      if (!self.roles.find(x => {
          return x.id === permissionMatrix.RoleId
        })) {
        self.roles.push({name: permissionMatrix.RoleName, id: permissionMatrix.RoleId});
      }

      if (!permission) {
        permission = {
          permissionId: permissionMatrix.PermissionId,
          parentPermissionId: permissionMatrix.ParentPermissionId,
          name: permissionMatrix.PermissionName,
          roles: [{
            roleId: permissionMatrix.RoleId,
            name: permissionMatrix.RoleName,
            hasRole: true,
            isEnabled: permissionMatrix.IsEnabled
          }]
        };

        permissions.push(permission);
      } else {
        permission.roles.push({
          roleId: permissionMatrix.RoleId,
          name: permissionMatrix.RoleName,
          hasRole: true,
          isEnabled: permissionMatrix.IsEnabled
        });
      }
    });

    this.roles.sort((a, b) => {
      if (a.id < b.id) {
        return -1;
      } else if (a.id > b.id) {
        return 1;
      } else {
        return 0;
      }
    });

    this.permissionTableRows = permissions;

    this.permissionTableRows.forEach(permission => {
      self.roles.forEach(role => {
        let missingRole = !permission.roles.find(x => {
          return x.roleId === role.id
        });
        if (missingRole) {
          permission.roles.push({
            roleId: role.id,
            name: role.name,
            hasRole: false
          });
        }

        permission.roles.sort((a, b) => {
          if (a.roleId < b.roleId) {
            return -1;
          } else if (a.roleId > b.roleId) {
            return 1;
          } else {
            return 0;
          }
        });
      });
    });
  }
}
