import { Routes, RouterModule } from "@angular/router";

import { AuthGuard } from "../shared/gaurds/auth.gaurd";
import { ChangePasswordComponent } from "./change-password.component";
import { AccountSettingsComponent } from "./account-settings.component";
import { ChangeLoginIdComponent } from "./change-login-id.component";
import { NotificationSettingsComponent } from "./notification-settings.component";
import { AccountLayoutComponent } from "./account-layout.component";
import { UserPreferredErxComponent } from "./user-preferred-erx.component";
import { ErxNewOrderComponent } from "../shared/components/erx-components/erx-new-order/erx-new-order.component";

export const AccountRoutes: Routes = [
  {
    path: "account", component: AccountLayoutComponent, canActivate: [AuthGuard], children: [
      { path: "change-password", component: ChangePasswordComponent },
      { path: "settings", component: AccountSettingsComponent },
      { path: "change-login", component: ChangeLoginIdComponent },
      { path: "notification-settings", component: NotificationSettingsComponent },
      { path: "manage-preferred-erx", component: UserPreferredErxComponent },
      { path: "preferred-erx", component: ErxNewOrderComponent, data: { type: "preferred-erx" } }
    ]
  }
];

export const accountRouting = RouterModule.forChild(AccountRoutes);
