import { Injectable, Injector } from '@angular/core';
import { Overlay, ConnectionPositionPair, PositionStrategy, OverlayConfig } from '@angular/cdk/overlay';
import { PortalInjector, ComponentPortal } from '@angular/cdk/portal';
import { ContextualPopupRef, PopoverContent } from '../components/contextual-popup/contextual-popup-ref';
import { ContextualPopupComponent } from '../components/contextual-popup/contextual-popup.component';

export type PopoverParams<T> = {
  width?: string | number;
  height?: string | number;
  origin: HTMLElement;
  content: PopoverContent;
  data?: T;
  positionBefore?: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class ContextualPopupService {
  constructor(private overlay: Overlay, private injector: Injector) { }

  open<T>({ origin, content, data, width, height, positionBefore }: PopoverParams<T>): ContextualPopupRef<T> {
    const overlayRef = this.overlay.create(this.getOverlayConfig({ origin, width, height, positionBefore }));
    const popoverRef = new ContextualPopupRef<T>(overlayRef, content, data);

    const injector = this.createInjector(popoverRef, this.injector);
    overlayRef.attach(new ComponentPortal(ContextualPopupComponent, null, injector));

    return popoverRef;
  }

  private getOverlayConfig({ origin, width, height, positionBefore }): OverlayConfig {
    return new OverlayConfig({
      hasBackdrop: true,
      width,
      height,
      backdropClass: 'popover-backdrop',
      panelClass: 'popover-panel',
      positionStrategy: this.getOverlayPosition(origin, positionBefore),
      scrollStrategy: this.overlay.scrollStrategies.reposition()
    });
  }

  private getOverlayPosition(origin: HTMLElement, positionBefore: boolean): PositionStrategy {
    let positionType: string = "after";
    
    if (positionBefore) {
      positionType = "before";
    }

    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo(origin)
      .withPositions(this.getPositions(positionType))
      .withFlexibleDimensions(false)
      .withPush(false);

    return positionStrategy;
  }

  createInjector(popoverRef: ContextualPopupRef, injector: Injector) {
    const tokens = new WeakMap([[ContextualPopupRef, popoverRef]]);
    return new PortalInjector(injector, tokens);
  }

  private getPositions(positionType: string): ConnectionPositionPair[] {
    if (positionType === 'before') {
      return [
        {
          originX: 'end',
          originY: 'bottom',
          overlayX: 'end',
          overlayY: 'top'
        }
      ];
    } else {
      return [
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
        }
      ];
    }
  }
}
