import { PrescriberSearchResultModel } from "./prescriber-search-result.model";
import { MedicationSearchResultModel } from "./medication-search-result.model";

export class RxOrderModel {
	public PatientId: number;
	public Ndc: string;
	public DrugName: string;
	public FrameworkName: string;
	public Directions: string;
	public StartDate: Date = new Date();
	public DiscontinueDate: Date;
	public IsCompound: boolean;
	public IsCovered: boolean;
	public IsPrn: boolean;
	public IsControl: boolean;
	public PrescriberNpi: string;
	public Quantity: number;
	public Refills: number;
	public IsProfileOnly: boolean;
	public ApprovalNotes: string;

	public MedicationId: number;
	public PatientName: string;
	public TeamName: string;
	public ProductDescription: string;
	public RequestingUser: string;
	public NewCoverageText: string;
	public AuthorizationStatusId: number;
	public SubmitUserId: number;
	public SubmitUserName: string;
	public SubmitDate: string;
	public OverrideExpires: string;
	public PharmacyId: number;
	public CustomerName: string;

	public PriorAuthNote: string;
	public DcoStartDate: Date;
	public DcoEndDate: Date;
	public DcoMaxQuantity: number;
	public DcoMaxDaysSupply: number;
	public DcoMaxRefills: number;

	public SubmitForReview: boolean = false;

	public selectedMedication: MedicationSearchResultModel;
	public selectedPrescriber: PrescriberSearchResultModel;
	public overrideRequired: boolean;
	public orderProcessed: boolean = false;
	public orderSuccess: boolean = false;
	public orderFailureReason: string;
	public allergyNotes: string;

	public erxType?: string; // "medication", "compound", "supply"
	public daysSupply?: number;
	public PharmacyNotes?: string;
	public DispenseUnitId?: number;
	public allowSubstitutions?: boolean = false;
	public orderStatus?: string;
	public AgentName?: string;
	public children?: RxOrderModel[] = [];
	public preferredNickname?: string;
	public preferredType?: string; // "userPreferred", "customerPreferred"
	public preferredByUser?: string;
	public sortedPrescriberName?: string;
	public sortedMedStrength?: any;
	public isOppcPharmacy?: boolean;

	// dosespot items
	lexiDrugSynId?: number;
	lexiGenProductId?: number;
	lexiSynonymId?: number;
	dispenseUnitName?: string;
	prescriptionId?: number;
	supplyId?: number;
	notControlled?: boolean;
	compoundId?: number;
	diagnosisId?: number;
	diagnosisName?: string;
	tempMedicationName?: string;

	// pref info
	preferenceId?: number;
	preferenceType?: string;
	pharmacyName?: string;
	isUrgent?: boolean = false;
	noSubstitutions?: boolean = false;
	retainPreference?: boolean;
	sourcePreference?: boolean = false;

	//result info
	isSent: boolean = false;
	isRejected: boolean = false;
	dosespotError: string = "";

	ICD10?: string;

	overrideChanged(canAuthorize: boolean, override: boolean, originalCovered: boolean) {
		if (override) {
			if (canAuthorize) {
				this.DcoStartDate = this.StartDate;
				this.DcoEndDate = this.DiscontinueDate;
				this.PriorAuthNote = null;
				this.DcoMaxQuantity = null;
				this.DcoMaxDaysSupply = null;
				this.DcoMaxRefills = null;
			}
			this.IsCovered = originalCovered;
		} else {
			this.PriorAuthNote = null;
			this.DcoStartDate = null;
			this.DcoEndDate = null;
			this.DcoMaxQuantity = null;
			this.DcoMaxDaysSupply = null;
			this.DcoMaxRefills = null;
			this.IsCovered = !originalCovered;
		}
	}

	validate(icdRequired: boolean, type: string, i: number): string[] {
		let validations: string[] = [];

		if (!this.selectedMedication) {
			validations.push("Please select a " + this.erxType + " for item " + (i + 1));
			return validations;
		}

		if (this.erxType === "medication" && (!this.lexiDrugSynId || !this.lexiSynonymId || !this.lexiGenProductId || this.lexiDrugSynId <= 0 || this.lexiSynonymId <= 0 || this.lexiGenProductId <= 0)) {
			validations.push("Please select a valid medication for item " + (i + 1));
		}

		if (this.erxType === "compound" && (!this.DrugName || this.DrugName.trim().length === 0)) {
			validations.push("Please enter a valid compound name for item " + (i + 1));
		}

		if (this.erxType === "supply" && (!this.supplyId || this.supplyId <= 0)) {
			validations.push("Please select a valid supply for item " + (i + 1));
		}

		if (this.erxType === "medication" && (!this.selectedMedication.Strength || this.selectedMedication.Strength.trim().length === 0)) {
			if (this.selectedMedication && this.selectedMedication.DrugName) {
				validations.push("Please enter a valid strength for " + this.selectedMedication.DrugName);
			} else {
				validations.push("Please enter a valid strength for item " + (i + 1));
			}
		}

		if (type !== "patientErx") {
			return validations;
		}

		if (icdRequired && this.IsControl && (!this.diagnosisId || this.diagnosisId <= 0)) {
			validations.push("A valid diagnosis is required for " + this.selectedMedication.DrugName);
		}

		if (!this.Quantity || this.Quantity <= 0) {
			if (this.selectedMedication && this.selectedMedication.DrugName) {
				validations.push("Please enter a valid quantity for " + this.selectedMedication.DrugName);
			} else {
				validations.push("Please enter a valid quantity for item " + (i + 1));
			}
		}

		if (!this.daysSupply || this.daysSupply <= 0 || this.daysSupply > 365) {
			if (this.selectedMedication && this.selectedMedication.DrugName) {
				validations.push("Please enter a valid days supply for " + this.selectedMedication.DrugName);
			} else {
				validations.push("Please enter a valid days supply for item " + (i + 1));
			}
		}

		if (this.Refills !== 0 && (!this.Refills || this.Refills < 0 || this.Refills > 99)) {
			if (this.selectedMedication && this.selectedMedication.DrugName) {
				validations.push("Please enter a valid refill amount for " + this.selectedMedication.DrugName);
			} else {
				validations.push("Please enter a valid refill amount for item " + (i + 1));
			}
		}

		if (!this.DispenseUnitId || this.DispenseUnitId <= 0) {
			if (this.selectedMedication && this.selectedMedication.DrugName) {
				validations.push("Please select a valid dispense unit for " + this.selectedMedication.DrugName);
			} else {
				validations.push("Please select a valid dispense unit for item " + (i + 1));
			}
		}

		if (!this.Directions || this.Directions.trim().length === 0 || this.Directions.trim().length > 1000) {
			if (this.selectedMedication && this.selectedMedication.DrugName) {
				validations.push("Please enter valid directions for " + this.selectedMedication.DrugName);
			} else {
				validations.push("Please enter valid directions for item " + (i + 1));
			}
		}

		if (this.PharmacyNotes && this.PharmacyNotes.trim().length > 150) {
			validations.push("Pharmacy notes cannot exceed 150 characters for " + this.selectedMedication.DrugName);
		}

		return validations;
	}
}
