/**
 * Created by John on 2017-06-28.
 */
import { NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";

import { routing } from "./ocp.routes";

import { SharedModule } from "./shared/shared.module";
import { ErrorModule } from "./error/error.module";
import { LoginModule } from "./login/login.module";
import { PatientCareModule } from "./patient-care/patient-care.module";
import { AccountModule } from "./account/account.module";
import { PartnerProfileModule } from "./partner-profile/partner-profile.module";
import { ClinicalResourcesModule } from "./clinical-resources/clinical-resources.module";
import { ReportingModule } from "./reporting/reporting.module";
import { AdministrationModule } from "./administration/administration.module";
import { OrganizationProfileModule } from "./organization-profile/organization-profile.module";

import { MessagesService } from "./shared/services/messages.service";
import { ReportingService } from "./shared/services/reporting.service";
import { ClinicalResourcesService } from "./shared/services/clinical-resources.service";
import { VimeoService } from "./shared/services/vimeo.service";
import { DoseSpotService } from "./shared/services/dosespot.service";
import { ApplicationService } from "./shared/services/application.service";
import { HttpService } from "./shared/services/http.service";
import { CacheService } from "./shared/services/cache.service";
import { AccountService } from "./shared/services/account.service";
import { CustomerService } from "./shared/services/customer.service";
import { PatientService } from "./shared/services/patient.service";
import { UserService } from "./shared/services/user.service";
import { PatientCareService } from "./shared/services/patient-care.service";
import { DiagnosisService } from "./patient-care/components/form-patient-diagnosis/diagnosis.service";
import { RxService } from "./shared/services/rx.service";
import { AllergyService } from "./shared/services/allergy.service";
import { PermissionsService } from "./shared/services/permisisons.service";
import { UtilitiesService } from "./shared/services/utilities.service";
import { DashboardStore } from "./shared/services/dashboard.store";

import { OcpComponent } from "./ocp.component";
import { SamlService } from "./shared/services/saml.service";
import { AdminSystemAlertStore } from "./shared/services/admin-system-alert.store";
import { GridViewStore } from "./shared/services/grid-view.store";
import { PharmacyStore } from "./shared/services/pharmacy.store";
import { AdminReportStore } from "./shared/services/admin-report.store";
import { FormulariesStore } from "./shared/services/formularies.store";
import { AdminReportRoleStore } from "./shared/services/admin-report-role.store";
import { ErxOrderStore } from "./shared/services/erx-order.store";
import { PreferenceManagementStore } from "./shared/services/preference-management.store";
import { RoutingRedirectService } from "./shared/services/routing-redirect.service";
import { NewVersionCheckerComponent } from "./new-version-checker/new-version-checker.component";
import { NewVersionCheckerService } from "./new-version-checker/new-version-checker.service";

import { OrganizationUsersStore } from "./shared/store/organization-users.store";

@NgModule({
	imports: [
		BrowserModule,
		FormsModule,
		HttpClientModule,
		routing,
		LoginModule,
		SharedModule,
		PatientCareModule,
		ErrorModule,
		AccountModule,
		PartnerProfileModule,
		ReportingModule,
		ClinicalResourcesModule,
		AdministrationModule,
		OrganizationProfileModule
	],
	declarations: [OcpComponent, NewVersionCheckerComponent],
	providers: [
		ApplicationService,
		DoseSpotService,
		MessagesService,
		ReportingService,
		ClinicalResourcesService,
		VimeoService,
		HttpService,
		AccountService,
		CustomerService,
		PatientService,
		UserService,
		PharmacyStore,
		AdminReportStore,
		AdminReportRoleStore,
		DiagnosisService,
		PatientCareService,
		RxService,
		AllergyService,
		PermissionsService,
		UtilitiesService,
		SamlService,
		DashboardStore,
		AdminSystemAlertStore,
		GridViewStore,
		FormulariesStore,
		ErxOrderStore,
		PreferenceManagementStore,
		RoutingRedirectService,
		NewVersionCheckerService,
		OrganizationUsersStore
	],
	exports: [OcpComponent]
})
export class OcpModule {}
