export class Subscriber {
    email: string;
    firstName: string;
    lastName: string;
    externalSubscriberId: string;

    deserialize(json: any): Subscriber {
        let sub: Subscriber = Object.assign(new Subscriber(), json);
        return sub;
    }
}