import {Component, OnInit} from '@angular/core';
import {UserService} from "../../../shared/services/user.service";

@Component({
  selector: 'mrn-override-component',
  templateUrl: './mrn-override-text.template.html'
})

export class MrnOverrideComponent implements OnInit {

  mrnTextName: string = "";

  ngOnInit() {
    this._userService.mrnOverride.subscribe((mrn: string) => {
      this.mrnTextName = mrn;
    });
  }

  constructor(private _userService: UserService) {
  }
}
