import { Injectable } from "@angular/core";
import firebase from "firebase/app";
import { environment } from "../../../../environments/environment";

@Injectable({
    providedIn: "root"
})
export class FirebaseService {
    public supported: boolean = true;

    private _isWebview: boolean = false;
    private _messaging: firebase.messaging.Messaging | null = null;

    constructor() {
        this.init();
    }

    private init() {
        this._isWebview = navigator.userAgent.includes('mobile-wrapper=true');

        if (!this._isWebview && !firebase.apps.length && firebase.messaging.isSupported()) {
            firebase.initializeApp(environment.firebaseConfig);
            this._messaging = firebase.messaging();
        }

        if (!this._isWebview && !firebase.messaging.isSupported()) {
            this.supported = false;
            console.log("Firebase messaging is not supported on this device");
        }
    }

    messaging(): firebase.messaging.Messaging | null {
        return this._messaging;
    }
}