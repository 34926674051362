import { DosespotError } from "./dosespot-error.model";

export class LineItemResult {
	get success(): boolean {
		return this.errors.length === 0 && this.prescriptionId != null && this.prescriptionId > 0;
	}

	itemName: string;
	errors: DosespotError[] = [];
	prescriptionId?: number;

	deserialize(input: any): LineItemResult {
		this.itemName = input.ItemName;
		this.errors = input.Errors.map((error: any) => new DosespotError().deserialize(error));
		this.prescriptionId = input.PrescriptionId;

		return this;
	}
}
