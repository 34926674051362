import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {RxService} from "../../../shared/services/rx.service";
import {AllergyScreeningResult} from "../../../shared/models/allergy-screening-result.model";
import {ApiResult} from "../../../shared/models/api-result.model";
import {PatientService} from "../../../shared/services/patient.service";
import {StatusMessageService} from "../../../shared/components/status-message/status-message.service";

@Component({
  selector: 'allergy-screening',
  templateUrl: './allergy-screening.template.html',
  styleUrls: ['./allergy-screening.css']
})

export class AllergyScreeningComponent implements OnInit {
  loading: boolean = false;
  screeningResults: AllergyScreeningResult[] = [];

  @Input() patientId: number;
  @Input() gpi: string;
  @Input() isClinicalTools: boolean = false;

  @Output() doneScreening: EventEmitter<string> = new EventEmitter<string>();

  constructor(private _rxService: RxService,
              private _patientService: PatientService,
              private _statusMessageService: StatusMessageService) {
  }

  ngOnInit() {
    this.loading = true;

    if (this.isClinicalTools) {
      this._patientService.getAllergyScreening(this.patientId).subscribe((results: AllergyScreeningResult[]) => {
        this.loading = false;
        this.screeningResults = results;
        this.doneScreening.emit();
      });
    } else {
      this._rxService.screenAllergies(this.patientId, this.gpi).subscribe((apiResult: ApiResult) => {
        this.loading = false;
        if (apiResult.Success) {
          this.doneScreening.emit();
          this.screeningResults = apiResult.Result;
        } else {
          this._statusMessageService.changeStatusMessage('error', apiResult.PublicMessage);
        }
      });
    }
  }
}
