import { ErxCustomerConfig } from "./erx/customer-config.model";

export class CustomerProfileModel {
	CustomerId: number;
	OrganizationId: number;
	Name: string;
	AddressLine1: string;
	AddressLine2: string;
	City: string;
	State: string;
	Zip: string;
	Telephone: string;
	ClinicId: number;
	ClinicKey: string;
	AuthorizationValidHours: number;
	FrameworkFacId: string;
	IsActive: boolean = true;
	PharmacyId: number;
	PharmacyName: string;
	VendorId: number;
	VendorName: string;
	IsRelayCustomer: boolean;
	IsSsnRequired: boolean = false;
	AllowMultipleFormularies: boolean = false;
	IsScreeningEnabled: boolean = false;
	AccountManagerUserId: number;
	AccountManagerName: string;
	ContactUserId: number;
	ContactName: string;
	HasFormulary: boolean;
	HasTeams: boolean;
	IsDoseSpotActive: boolean;
	IsUserTeamEnabled: boolean;
	FrameworkInsId: string;
	IsMrnRequired: boolean;
	BillingMrnRequired: boolean;
	DxReqForErx: boolean;
	AutoSyncDoseSpot: boolean;
	AllowReportSubscriptions: boolean;
	ErxCustomerConfig: ErxCustomerConfig;
}
