/**
 * Created by mverry on 2/18/2017.
 */


import {Injectable, EventEmitter} from '@angular/core';

@Injectable()

export class PatientCareService {

  private _formComplete: boolean;

  private _goToStep: EventEmitter<string> = new EventEmitter<string>();

  setComplete(bool: boolean){
    this._formComplete = bool;
  }

  getComplete(){
    return this._formComplete;
  }

  emitStep(step: string){
    this._goToStep.emit(step);
  }

  subscribeToStep(){
    return this._goToStep;
  }

}
