import {Injectable} from "@angular/core";
import {HttpService} from "./http.service";
import {DocumentInfoModel} from "../models/document-info.model";

@Injectable()
export class ClinicalResourcesService {
  private _addDocumentUrl: string = 'api/clinicalresources/adddocument';
  private _updateDocumentUrl: string = 'api/clinicalresources/updatedocument';
  private _removeDocumentUrl: string = 'api/clinicalresources/removedocument';
  private _getDocumentTypesUrl: string = 'api/clinicalresources/getdocumenttypes';
  private _getDocumentsUrl: string = 'api/clinicalresources/getdocuments';
  private _getThumbnailUrl: string = 'api/image/getthumbnail';
  private _downloadDocumentUrl: string = 'api/clinicalresources/downloaddocument';

  constructor(private _http: HttpService) {
  }

  addDocument(document: DocumentInfoModel) {
    return this._http.post(this._addDocumentUrl, document);
  }

  updateDocument(document: DocumentInfoModel, fileUpdated: boolean) {
    return this._http.post(this._updateDocumentUrl, {Document: document, FileUpdated: fileUpdated});
  }

  removeDocument(documentId: number) {
    return this._http.post(this._removeDocumentUrl, documentId);
  }

  getDocuments(documentId: number, documentTypeId: number) {
    return this._http.get(this._getDocumentsUrl + '?documentId=' + documentId + '&documentTypeId=' + documentTypeId);
  }

  getDocumentTypes() {
    return this._http.get(this._getDocumentTypesUrl);
  }

  getThumbnail(tempFileName: string, documentId: number, size: number, page: number) {
    return this._http.get(this._getThumbnailUrl + '?tempFileName=' + (tempFileName || '') + '&documentId='
      + documentId + '&size=' + size + '&page=' + page)
  }

  downloadDocument(documentId: number, download: boolean) {
    return this._http.downloadFile(this._downloadDocumentUrl + '?documentId=' + documentId + '&displayInline=' + !download)
  }

  getMimeType(fileName: string) {
    let extension = fileName.split(/[. ]+/).pop();

    switch (extension.toLowerCase()) {
      case 'ppt':
        return 'application/vnd.ms-powerpoint';
      case 'pptx':
        return 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
      case 'pdf':
        return 'application/pdf';
      default:
        return 'application/octet-stream';

    }
  }
}
