import {Pipe, PipeTransform} from "@angular/core";
import { CPPDCategories } from "../enums/cppd-categories";

@Pipe({name: "cppdGroupName"})
export class cppdGroupNamePipe implements PipeTransform{
  transform(cppdType: string){
    if (!cppdType || cppdType.trim().length === 0){
      return "";
    }

    cppdType = cppdType.toLowerCase().trim();

    switch (cppdType){
      case CPPDCategories.Estimated.toLowerCase(): return "Total";
      case CPPDCategories.Formulary.toLowerCase(): return "Formulary";
      case CPPDCategories.Non_Formulary.toLowerCase(): return "Non-Formulary";
      default: return "Ancillary";
    }
  }
}
