import { Injectable } from "@angular/core";
import { StatusMessageService } from "../components/status-message/status-message.service";
import { AdminReportCategory } from "../models/admin-report-category.model";
import { AdminReport } from "../models/admin-report.model";
import { ApiResult } from "../models/api-result.model";
import { StoreObject } from "../models/store-object.model";
import { HttpService } from "./http.service";
import { combineLatest } from "rxjs";
import * as _ from "lodash";

@Injectable()
export class AdminReportStore {
  public readonly reports: StoreObject<AdminReport[]> = new StoreObject<AdminReport[]>([]);
  public readonly filteredReports: StoreObject<AdminReport[]> = new StoreObject<AdminReport[]>([]);
  public readonly selected: StoreObject<AdminReport> = new StoreObject<AdminReport>(new AdminReport());
  public readonly selectedCategory: StoreObject<string> = new StoreObject<string>("all");
  public categories: StoreObject<AdminReportCategory[]> = new StoreObject<AdminReportCategory[]>([]);

  public loading: boolean = false;
  public mode: StoreObject<'new' | 'edit'> = new StoreObject<'new' | 'edit'>(null);
  public filter: StoreObject<string> = new StoreObject<string>("");

  constructor(private _http: HttpService, private _status: StatusMessageService) {
    combineLatest([this.reports.observable, this.filter.observable])
    .subscribe(([allReports, filter]) => {
        let items: AdminReport[] = allReports.slice();

        if (filter !== 'all') {
            var q = filter.toLowerCase();
            items = _.filter(items, (t: AdminReport) => {
                return (
                    t.category.toLowerCase().indexOf(q) >= 0 
                );
            });
        }

        this.filteredReports.set(items);
    });
  }

  setCategoryFilter(category: string) {
    let filter = this.filter.get();
    filter = category;
    this.filter.set(filter);
    this.selectedCategory.set(filter);
  }

  refresh(){
      this.loading = true;
      this._http.get("api/admin/report").subscribe((result: AdminReport[]) => {
        this.loading = false;
        if (result){
            this.reports.set(result);
        } else {
            this.reports.set([]);
            this._status.changeStatusMessage("error", "Error retrieving report list.");
        }
      });

      this._http.get("api/admin/report/category").subscribe((result: AdminReportCategory[]) => {
        if (result){
            this.categories.set(result);
        } else {
            this.categories.set([]);
            this._status.changeStatusMessage("error", "Error retrieving report category list.");
        }
      });
  }

  update(): Promise<boolean> {
    return new Promise<boolean> (resolve => {
        this.loading = true;
        let report = this.selected.get();
        this._http.post("api/admin/report/" + report.id, report).subscribe((result: ApiResult) => {
            this.loading = false;
            if (result.Success){
                this._status.changeStatusMessage("success", "Report successfully saved.");
            } else {
                this._status.changeStatusMessage("error", "Error saving report.");
            }

            this.refresh();
            resolve(result.Success);
        })
    });
  }

  create(): Promise<boolean> {
    return new Promise<boolean> (resolve => {
        this.loading = true;
        let report = this.selected.get();
        this._http.post("api/admin/report/", report).subscribe((result: ApiResult) => {
            this.loading = false;
            if (result.Success){
                this._status.changeStatusMessage("success", "Report successfully created.");
            } else {
                this._status.changeStatusMessage("error", "Error creating report.");
            }

            this.refresh();
            resolve(result.Success);
        })
    });
  }

  delete(): Promise<boolean> {
    return new Promise<boolean> (resolve => {
        this.loading = true;
        let reportId = this.selected.get().id;
        this._http.post("api/admin/report/delete", reportId).subscribe((result: ApiResult) => {
            this.loading = false;
            if (result.Success){
                this._status.changeStatusMessage("success", "Report successfully deleted.");
            } else {
                this._status.changeStatusMessage("error", "Error deleting report.");
            }

            this.refresh();
            resolve(result.Success);
        })
    })
  }
}