import { Component, OnInit, Input, ViewChild, ElementRef, TemplateRef, OnDestroy, Output, EventEmitter } from "@angular/core";
import { Subscription } from "src/app/ocp/shared/models/report-subscription/subscription.model";
import { MultiSelectItemModel } from "../../../../input-multi-select/multi-select-item.model";
import { SelectionModel } from "@angular/cdk/collections";
import { DataTableModel } from "../../../../data-table/data-table.model";
import { DataTableColumnModel } from "../../../../data-table/columns.model";
import { CustomerSummaryModel } from "src/app/ocp/shared/models/customer-summary.model";
import { ContextualPopupService } from "src/app/ocp/shared/services/contextual-popup.service";
import { combineLatest, Subject } from "rxjs";
import * as _ from "lodash";
import { SortModel } from "../../../../data-table/sort.model";
import { SubscribableReport } from "src/app/ocp/shared/models/report-subscription/subscribable-report.model";
import { ReportSubscriptionStore } from "src/app/ocp/shared/services/report-subscription.store";
import { OrganizationUsersStore } from "src/app/ocp/shared/store/organization-users.store";
import { OrganizationUser } from "src/app/ocp/shared/models/organizations/organization-user.model";
import { debounceTime, takeUntil } from "rxjs/operators";
import { SubscriberFiltersModel } from "src/app/ocp/shared/models/report-subscription/subscriber-filters.model";
import { Subscriber } from "src/app/ocp/shared/models/report-subscription/subscriber.model";

@Component({
	selector: "ocp-form-select-subscribers",
	templateUrl: "./form-select-subscribers.component.html",
	styleUrls: ["./form-select-subscribers.component.scss"]
})
export class FormSelectSubscribersComponent implements OnInit, OnDestroy {
	subscribers: DataTableModel = new DataTableModel([], []);
	expandedIdx?: number = null;

	filter: SubscriberFiltersModel = new SubscriberFiltersModel();
	filteredUsers: MultiSelectItemModel[] = [];
	filterPartnerOptions: MultiSelectItemModel[] = [];
	filterRoleOptions: MultiSelectItemModel[] = [];

	selectedItems: SelectionModel<OrganizationUser> = new SelectionModel<OrganizationUser>(true, []);
	isAllSelected: boolean;

	@Input() subscription: Subscription;
	@Input() selectedReport: SubscribableReport;
	@Input() mode: string;
	@ViewChild("dataSearchInput") dataSearchInput: ElementRef;

	get filtering(): boolean {
		return this.filter.partnerId.length > 0 || this.filter.roleId.length > 0;
	}

	get filterBtnText(): string {
		if (this.filtering) {
			return "Filter Applied";
		}
		return "Add Filter";
	}

	private _destroyed: Subject<any> = new Subject<any>();

	constructor(private _popupService: ContextualPopupService, public store: ReportSubscriptionStore, public userStore: OrganizationUsersStore) {}

	private _init: boolean = false;
	ngOnInit(): void {
		this.initTable();

		this.userStore.filteredUsers.observable.pipe(takeUntil(this._destroyed)).subscribe((users: OrganizationUser[]) => {
			this.subscribers.populate(users);
		});

		this.userStore.customers.observable.pipe(takeUntil(this._destroyed)).subscribe((customers: CustomerSummaryModel[]) => {
			this.filterPartnerOptions = _.map(customers, (p: CustomerSummaryModel) => {
				let model: MultiSelectItemModel = new MultiSelectItemModel();
				model.id = p.CustomerId;
				model.text = p.Name;

				return model;
			});
		});

		this.userStore.roles.observable.pipe(takeUntil(this._destroyed)).subscribe((roles: string[]) => {
			this.filterRoleOptions = _.map(roles, (r: string) => {
				let model: MultiSelectItemModel = new MultiSelectItemModel();
				model.id = r;
				model.text = r;

				return model;
			});
		});

		this.selectedItems.changed.pipe(takeUntil(this._destroyed)).subscribe(e => {
			this.store.customerSubscribers.set(this.selectedItems.selected);
		});

		if (this.mode === "edit") {
			combineLatest([this.userStore.users.observable, this.store.subscribers.observable])
				.pipe(debounceTime(400))
				.subscribe(([users, subscribers]) => {
					if (this._init) return;

					if (!users || users.length === 0) return;

					if (!subscribers || subscribers.length === 0) return;

					this._init = true;

					let selectedUsers: OrganizationUser[] = users.filter((u: OrganizationUser) => {
						return subscribers.some((s: Subscriber) => {
							return parseInt(s.externalSubscriberId) === u.UserId;
						});
					});

					this.selectedItems.select(...selectedUsers);
				});
		}
	}

	ngOnDestroy() {
		this._destroyed.next();
		this._destroyed.unsubscribe();
	}

	initTable() {
		let defaultSorting: SortModel[] = [];

		defaultSorting.push({
			column: "text",
			direction: "asc"
		});

		let columns: DataTableColumnModel[] = [];
		columns.push(new DataTableColumnModel("select", "", "col-select", false));
		columns.push(new DataTableColumnModel("expander", "", "", false));
		columns.push(new DataTableColumnModel("text", "Name", "col-full-name", true));
		columns.push(new DataTableColumnModel("LoginId", "Partner", "col-partners", true));
		columns.push(new DataTableColumnModel("RoleList", "Roles", "", true));

		this.subscribers = new DataTableModel(defaultSorting, columns);
		this.subscribers.pageLength = 9999;
	}

	getDayOfWeek(value: string): string {
		switch (value) {
			case "1":
				return "Sunday";
			case "2":
				return "Monday";
			case "3":
				return "Tuesday";
			case "4":
				return "Wednesday";
			case "5":
				return "Thursday";
			case "6":
				return "Friday";
			case "7":
				return "Saturday";
		}
	}

	isExpanded(idx: number): boolean {
		return this.expandedIdx === idx;
	}

	expand(idx: number) {
		if (this.expandedIdx === idx) {
			this.expandedIdx = null;
		} else {
			this.expandedIdx = idx;
		}
	}

	getRoles(text: string): string[] {
		return text.split(",").map(t => t.trim());
	}

	clearSearch() {
		this.isAllSelected = false;
		const searchInput = this.dataSearchInput.nativeElement;
		searchInput.value = "";
		this.subscribers.filter("", "");
	}

	search(event: Event) {
		this.isAllSelected = false;
		const filterValue = (event.target as HTMLInputElement).value;
		this.subscribers.filter(filterValue.trim().toLowerCase(), "");
	}

	private _filterRef: any;
	openFilters(content: TemplateRef<any>, origin: any) {
		this._filterRef = this._popupService.open<{}>({
			content,
			origin,
			width: "320px",
			data: {},
			positionBefore: true
		});
	}

	partnerFilterChanged(e: number[]) {
		this.isAllSelected = false;
		this.filter.partnerId = e;
		this.userStore.customerFilter.set(e);
	}

	roleFilterChanged(e: string[]) {
		this.isAllSelected = false;
		this.filter.roleId = e;
		this.userStore.roleFilter.set(e);
	}

	resetFilters() {
		this.isAllSelected = false;
		this.userStore.clearFilters();
		this.filter.partnerId = [];
		this.filter.roleId = [];
		if (this._filterRef) {
			this._filterRef.close();
		}
	}

	hasMultipleCustomerAccess(sub: OrganizationUser): boolean {
		return sub.Customers && sub.Customers.length > 1;
	}

	toggleAll() {
		if (this.isAllSelected) {
			this.selectedItems.clear();
			this.isAllSelected = false;
		} else {
			var displayed = this.subscribers.data;
			var filteredUsers = this.userStore.filteredUsers.get();

			let filtered: OrganizationUser[] = filteredUsers.filter((u: OrganizationUser) => {
				return displayed.some((d: OrganizationUser) => {
					return d.UserId === u.UserId;
				});
			});

			this.selectedItems.select(...filtered);
			this.isAllSelected = true;
		}
	}
}
