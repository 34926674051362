/**
 * Created by John on 2016-03-02.
 */
import {ModalComponent} from "./modal.component";
import {ModalBodyComponent} from "./modal-body.component";
import {ModalFooterComponent} from "./modal-footer.component";
import {ModalHeaderComponent} from "./modal-header.component";

export * from './modal.component';
export * from './modal-body.component';
export * from './modal-footer.component';
export * from './modal-header.component';

export const MODAL_DIRECTIVES: any[] = [
    ModalComponent,
    ModalHeaderComponent,
    ModalBodyComponent,
    ModalFooterComponent
];
