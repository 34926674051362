import { Component, OnInit, Input } from "@angular/core";
import { DataTableModel } from "../../../shared/components/data-table/data-table.model";
import { SortModel } from "../../../shared/components/data-table/sort.model";
import { DataTableColumnModel } from "../../../shared/components/data-table/columns.model";
import { RxOrderModel } from "src/app/ocp/shared/models/rx-order.model";
import { ErxOrderStore } from "src/app/ocp/shared/services/erx-order.store";

@Component({
	selector: "ocp-erx-status-history",
	templateUrl: "./erx-status-history.component.html",
	styleUrls: ["./erx-status-history.component.scss"]
})
export class ErxStatusHistoryComponent implements OnInit {
	loading: boolean = false;

	private _erx: RxOrderModel;

	@Input()
	get erx(): RxOrderModel {
		return this._erx;
	}

	set erx(value: RxOrderModel) {
		this._erx = value;
		this.refreshHistory();
	}

	statusHistoryItems: DataTableModel = new DataTableModel([], []);

	constructor(public erxStore: ErxOrderStore) {}

	ngOnInit(): void {
		let defaultSorting: SortModel[] = [];

		defaultSorting.push({
			column: "StatusDate",
			direction: "dsc"
		});

		let columns: DataTableColumnModel[] = [];
		columns.push(new DataTableColumnModel("StatusDateTime", "Date", "", true));
		columns.push(new DataTableColumnModel("StatusText", "Status", "centered", false));
		columns.push(new DataTableColumnModel("StatusDetail", "Additional Notes", "", false));

		this.statusHistoryItems = new DataTableModel(defaultSorting, columns);
		this.statusHistoryItems.populate([]);
		this.refreshHistory();
	}

	refreshHistory() {
		this.loading = true;

		if (this.erx) {
			this.erxStore.erxStatusHistory.observable.subscribe((results: any) => {
				this.loading = false;
				this.statusHistoryItems.populate(results);
			});
		} else {
			this.loading = false;
			this.statusHistoryItems.populate([]);
		}
	}
}
