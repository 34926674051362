export class PrescriberSearchResultModel {
	public NpiLocation: string;
	public Npi: string;
	public Dea: string;
	public LastName: string;
	public FirstName: string;
	public PrefixName: string;
	public SuffixName: string;
	public PhonePrimary: string;
	public Fax: string;
	public AddressLine1: string;
	public AddressLine2: string;
	public City: string;
	public State: string;
	public Zip: string;
	public StateLicenseNumber: string;
	public Name?: string;
}
