import {Pipe, ChangeDetectorRef, PipeTransform} from '@angular/core';
import * as moment from 'moment';

// under systemjs, moment is actually exported as the default export, so we account for that
const momentConstructor: (value?: any) => moment.Moment = (<any>moment).default || moment;

@Pipe({ name: 'amDateFormat'})
export class DateFormatPipe implements PipeTransform {
  transform(value: Date | moment.Moment, ...args: any[]): any {
    // return momentConstructor(value).local().format(args[0]);
    if(args.length > 1 && args[1] === true){
      return moment.utc(value).format(args[0]);
    }

    return moment.utc(value).local().format(args[0]);
  }
}

@Pipe({ name: 'amLocal' })
export class LocalTimePipe implements PipeTransform {
  transform(value: Date | moment.Moment | string | number): moment.Moment {
    return moment(value).local();
  }
}
