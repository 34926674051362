import { LineItem } from "./line-item.model";
import { DosespotPharmacy } from "./dosespot-pharmacy.model";

export class NewOrder {
	EffectiveDate: string;
	ClinicianId: number;
	Nickname: string;
	LineItems: LineItem[] = [];
	Pharmacy: DosespotPharmacy;
}
