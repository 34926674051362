export class UserInfoModel {
	SessionId: string;
	UserId: number;
	CustomerId: number;
	FirstName: string;
	LastName: string;
	IsPasswordTemporary: boolean;
	CustomerMrnFormat: string;
	CustomerMrnNameOverride: string;
	CustomerMrnFormatDescription: string;
	IsMrnRequired: boolean;
	IsSsnRequired: boolean;
	IsScreeningEnabled: boolean;
	HasFormulary: boolean;
	AllowMultipleFormularies: boolean;
	DoseSpotEnabled: boolean;
	PharmacyPhone: string;
	DoseSpotClinicianId?: number;
	IsPrescriber: boolean;
	userPhone: string;
	UserSearchTeamList: string;
	CustomerDefaultPharmacyNABP: string;
	PharmacyName: string;
	OrganizationId?: number;
	OrganizationProfileOn: boolean;
}
