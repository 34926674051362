export class MessageItemModel {
  UserMessageId: number;
  MessageType: string;
  MessageSubject: string;
  MessageText: string;
  IsRead: boolean;
  DateCreated: string;
  DateRead: string;

  isSelected: boolean = false;
}
