import { Component, Input, Output, EventEmitter } from "@angular/core";
import { RxOrderModel } from "../../shared/models/rx-order.model";
import { PatientInfoModel } from "../../shared/models/patient.model";
import { RxOrderSuccessModel } from "../../shared/models/rx-order-success.model";
import { ApplicationService } from "../../shared/services/application.service";

@Component({
  selector: "ocp-new-profile-order",
  templateUrl: "./profile-order.template.html",
  styleUrls: ["./profile-order.scss"]
})
export class ProfileOrderComponent {
  private _patient: PatientInfoModel;
  @Input()
  get patient(): PatientInfoModel {
    return this._patient;
  }
  set patient(p: PatientInfoModel) {
    if (!p || !p.patientId) return;

    this._patient = p;
    this.init();
  }

  @Input() isScreeningEnabled: boolean;
  @Output() onClose: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output()
  onOrderSuccess: EventEmitter<RxOrderSuccessModel> = new EventEmitter<
    RxOrderSuccessModel
  >();

  rxOrder: RxOrderModel;
  profileMedStep: string;
  confirmMed: boolean;

  modalTitle: string;
  subHeaderText: string;

  isProcessing: boolean = false;

  constructor(public appService: ApplicationService){}

  init() {
    this.rxOrder = new RxOrderModel();
    this.rxOrder.PatientId = this.patient.patientId;
    this.rxOrder.StartDate = new Date();
    this.rxOrder.IsCovered = null;
    this.confirmMed = false;
    this.profileMedStep = "details";

    this.modalTitle = "New Profile Medication";
    this.subHeaderText =
      " for " + this.patient.firstName + " " + this.patient.lastName;
  }

  goToOrderDetails() {
    this.profileMedStep = "details";
    this.subHeaderText =
      " for " + this.patient.firstName + " " + this.patient.lastName;
  }

  viewProfileMedConfirmation() {
    this.profileMedStep = "confirm";
    this.subHeaderText =
      "Confirm order for " +
      this.patient.firstName +
      " " +
      this.patient.lastName;
  }

  screenProfileMedDrug(isNextStep: boolean) {
    if (this.rxOrder.IsCompound || !this.isScreeningEnabled) {
      if (isNextStep) {
        this.viewProfileMedConfirmation();
      } else {
        this.goToOrderDetails();
      }
    } else {
      this.profileMedStep = "drugScreening";
      this.subHeaderText =
        "Drug screening for " +
        this.patient.firstName +
        " " +
        this.patient.lastName;
    }
  }

  screenProfileMedAllergies(isNextStep: boolean) {
    if (this.rxOrder.IsCompound || !this.isScreeningEnabled) {
      if (isNextStep) {
        this.viewProfileMedConfirmation();
      } else {
        this.goToOrderDetails();
      }
    } else {
      this.profileMedStep = "allergyScreening";
      this.subHeaderText =
        "Allergy screening for " +
        this.patient.firstName +
        " " +
        this.patient.lastName;
    }
  }

  dismiss() {
    this.onClose.emit(false);
    this.init();
  }

  orderSuccess(e: any) {
    this.onOrderSuccess.emit(e);
    this.init();
  }
}
