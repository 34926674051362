import {Component, OnInit, ViewChild} from '@angular/core';
import {UserService} from "../../shared/services/user.service";
import {CustomerTeamModel} from "../../shared/models/customer-team.model";
import {CustomerService} from "../../shared/services/customer.service";
import {ModalComponent} from "../../shared/components/modal/modal.component";
import {StatusMessageService} from "../../shared/components/status-message/status-message.service";
import {AuthService} from "../../shared/services/auth.service";
import {Permissions} from "../../shared/enums/permissions";
import {CustomerProfileModel} from "../../shared/models/customer-profile.model";
import {FormTeamDetails} from "../../shared/components/form-team-details/form-team-details.component";

@Component({
  selector: 'teams-tab',
  templateUrl: './teams.template.html',
  styleUrls: ['./partner-profile-tabs.scss']
})

export class TeamsTab implements OnInit {
  customerName: string;
  modalTitle: string;
  subHeaderText: string;
  permissions: any = Permissions;
  teamFilter: string[] = ['active'];
  customerTeams: CustomerTeamModel[] = [];
  filteredTeams: CustomerTeamModel[] = [];

  @ViewChild('createModal')  createModal: ModalComponent;
  @ViewChild('createTeamModal') createTeamModal: FormTeamDetails;

  get canAddTeams(): boolean {
    return this._authService.hasPermission(this.permissions.editCustomerTeams)
      || this._authService.hasPermission(this.permissions.administration);
  }

  constructor(private _statusMessageService: StatusMessageService,
              private _customerService: CustomerService,
              private _userService: UserService,
              private _authService: AuthService) {
  }

  ngOnInit() {
    this._userService.selectedCustomer.subscribe((customer: CustomerProfileModel) => {
      this.customerName = customer.Name
    });

    this.refreshTeams();
  }

  refreshTeams() {
    this._customerService.getCustomerTeamList(null, true, true).subscribe((teams: any[]) => {
      this.customerTeams = teams;
      this.filterTeamList();
    });
  }

  filterTeamList() {
    let self = this;
    let filteredTeams: CustomerTeamModel[] = this.customerTeams.filter((x: CustomerTeamModel) => {
      if (self.teamFilter.indexOf('active') !== -1 && x.IsActive) {
        return true;
      }

      if (self.teamFilter.indexOf('inactive') !== -1 && !x.IsActive) {
        return true;
      }

      return false;
    });

    filteredTeams.sort((a, b) => {
      if (a.TeamName < b.TeamName)
        return -1;

      if (a.TeamName > b.TeamName)
        return 1;

      return 0;
    });

    this.filteredTeams = filteredTeams;

  }

  updateTeamFilter(checked: boolean, value: string) {
    if (!checked) {
      this.teamFilter.splice(this.teamFilter.indexOf(value), 1);
    } else if (this.teamFilter.indexOf(value) === -1) {
      this.teamFilter.push(value);
    }

    this.filterTeamList();
  }

  addTeam() {
    this.createTeamModal.showDialog(null);
  }

  teamSaveSuccess(message: string) {
    if (message) {
      this._statusMessageService.changeStatusMessage('success', message);
    }
    this.refreshTeams();
  }
}
