import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from "@angular/core";
import { ErxOrderStore } from "../../../services/erx-order.store";
import { MedicationSearchResultModel } from "../../../models/medication-search-result.model";
import { RxOrderModel } from "../../../models/rx-order.model";
import { StatusMessageService } from "../../status-message/status-message.service";
import { NgForm } from "@angular/forms";
import { UserService } from "../../../services/user.service";
import { AdvancedSearch } from "../../advanced-search/advanced-search.component";
import { ISearchService } from "../../patient-search/search.interface";
import { AllergySearchService } from "../../../services/allergy-search.service";
import { SearchDosespotMedicationsComponent } from "../../search-dosespot-medications/search-dosespot-medications.component";
import { FadeInAnimation } from "src/app/ocp/animations";

@Component({
	selector: "ocp-form-erx-step-one",
	providers: [{ provide: ISearchService, useClass: AllergySearchService }],
	templateUrl: "./form-erx-step-one.component.html",
	styleUrls: ["./form-erx-step-one.component.scss"],
	animations: [FadeInAnimation]
})
export class ErxOrderFormStepOneComponent implements OnInit {
	rxTypes: string[] = ["medication", "compound", "supply"];
	isInternalUser: boolean = false;

	strengthOptions: any[] = [];
	showStrengthOptions: boolean = false;

	showNotControlled: boolean = false;

	@Input() rxOrderModel: RxOrderModel;
	@Input() patientId: number; // needed to screen for Drug Interactions and Allergies
	@Input() showSummary: boolean = false;
	@Input() formType: string; // "patientErx", "preferredErx"
	@Input() isReadOnly: boolean = false;

	@Output() onFormCompleted: EventEmitter<RxOrderModel> = new EventEmitter<RxOrderModel>();
	@Output() onChangeRx: EventEmitter<boolean> = new EventEmitter<boolean>();

	@ViewChild("addOrderForm") addOrderForm: NgForm;
	@ViewChild("searchMedications") searchMedications: SearchDosespotMedicationsComponent;
	@ViewChild("supplySearch") supplySearch: AdvancedSearch;

	constructor(public erxStore: ErxOrderStore, private _statusMessageService: StatusMessageService, private _userService: UserService) {}

	ngOnInit() {
		if (this.formType === "patientErx") {
			this.isInternalUser = this._userService.isInternalUser();
		}
	}

	getSearchFieldLabel(erxType: string) {
		switch (erxType) {
			case "medication":
				return "Search for a Medication";
			case "compound":
				return "Search for a Compound";
			case "supply":
				return "Search for Supplies";
			default:
				return "Search for a Medication";
		}
	}

	completeForm(e: any) {
		switch (this.rxOrderModel.erxType) {
			case "medication":
				this.selectMedication();
				break;
			case "compound":
				this.selectCompound();
				break;
			case "supply":
				this.selectSupply();
				break;
		}
	}

	private selectCompound() {
		var result = new MedicationSearchResultModel();
		result.DrugName = this.rxOrderModel.DrugName;

		this.rxOrderModel.selectedMedication = result;

		this.onFormCompleted.emit(this.rxOrderModel);
		this.showSummary = true;
	}

	checkForm(e: boolean) {
		if (e && this.rxOrderModel.DrugName && this.rxOrderModel.DrugName.trim().length > 0) {
			this.completeForm(null);
		}
	}

	private selectSupply() {
		this.onFormCompleted.emit(this.rxOrderModel);
		this.showSummary = true;
	}

	private selectMedication() {
		this.rxOrderModel.tempMedicationName = this.rxOrderModel.selectedMedication.DrugName;
		this.erxStore.getMedDetail(this.rxOrderModel.selectedMedication.DrugName, this.rxOrderModel.selectedMedication.Strength).then((result: any) => {
			if (result.Result.ResultCode === "ERROR") {
				this._statusMessageService.changeStatusMessage("error", "Unable to load medication details. Please try again.");
				this.medicationSearchCleared();
				return;
			}

			this.rxOrderModel.IsControl = +result.Item.Schedule >= 2;
			this.rxOrderModel.notControlled = !this.rxOrderModel.IsControl;
			this.rxOrderModel.DrugName = result.Item.DisplayName;
			this.rxOrderModel.selectedMedication.DrugName = result.Item.DisplayName;
			this.rxOrderModel.Ndc = result.Item.NDC;
			this.rxOrderModel.DispenseUnitId = result.Item.DispenseUnitId;
			this.rxOrderModel.lexiDrugSynId = result.Item.LexiDrugSynId;
			this.rxOrderModel.lexiGenProductId = result.Item.LexiGenProductId;
			this.rxOrderModel.lexiSynonymId = result.Item.LexiSynonymTypeId;
			this.rxOrderModel.dispenseUnitName = this.erxStore.units.get().find((unit: any) => unit.StandardDispenseUnitTypeID === result.Item.DispenseUnitId).SingularOrPlural;

			this.onFormCompleted.emit(this.rxOrderModel);
			this.showSummary = true;
		});
	}

	changeRx() {
		if (this.rxOrderModel.erxType === "compound") {
			this.rxOrderModel.notControlled = false;
		}
		this.showSummary = false;
		this.onChangeRx.emit();
	}

	compoundSearchCleared() {
		this.rxOrderModel.selectedMedication = null;
		this.rxOrderModel.DrugName = null;
		this.showNotControlled = false;

		this.onChangeRx.emit();
	}

	compoundSelected(compound: any) {
		if (typeof compound === "string") {
			this.showNotControlled = true;

			var result = new MedicationSearchResultModel();
			result.DrugName = compound;

			this.rxOrderModel.selectedMedication = result;
			this.rxOrderModel.DrugName = compound;
		} else {
			this.showNotControlled = false;

			var result = new MedicationSearchResultModel();
			result.DrugName = compound.Description;

			this.rxOrderModel.selectedMedication = result;
			this.rxOrderModel.compoundId = compound.CompoundId;
			this.rxOrderModel.DrugName = compound.Description;
			this.rxOrderModel.notControlled = false;
			this.rxOrderModel.compoundId = compound.CompoundId;

			this.completeForm(null);
		}
	}

	supplySearchCleared() {
		this.rxOrderModel.selectedMedication = null;
		this.rxOrderModel.DrugName = null;

		this.showStrengthOptions = false;
		this.onChangeRx.emit();
	}

	supplySelected(supply: any) {
		var result = new MedicationSearchResultModel();
		result.DrugName = supply.Name;

		this.rxOrderModel.DrugName = supply.Name;
		this.rxOrderModel.selectedMedication = result;

		this.rxOrderModel.supplyId = supply.SupplyId;

		this.completeForm(null);
	}

	medicationSearchCleared() {
		this.rxOrderModel.selectedMedication = null;
		this.rxOrderModel.DrugName = null;

		this.showStrengthOptions = false;
		this.onChangeRx.emit();
	}

	medicationSelected(medication: any) {
		var result = new MedicationSearchResultModel();
		result.DrugName = medication.Name;

		this.rxOrderModel.selectedMedication = result;
		this.strengthOptions = medication.Strength.map((strength: any) => {
			let text = strength.Strength;
			if (text == "-") {
				text = "None";
			}

			return { text: text, value: strength.Strength };
		});
	}
}
