import { Injectable } from "@angular/core";
import { HttpService } from "./http.service";
import { ApiResult } from "../models/report-subscription/api-result.model";
import { Subscription } from "../models/report-subscription/subscription.model";
import { ParameterValue } from "../models/report-subscription/parameter-value.model";
import { ReportParameter } from "../models/report-subscription/report-parameter.model";
import { SubscribableReport } from "../models/report-subscription/subscribable-report.model";
import { SubscriptionSummary } from "../models/report-subscription/subscription-summary.model";

@Injectable({ providedIn: "root" })
export class ReportSubscriptionService {
	constructor(private _http: HttpService) {}

	create(organizationId: number, subscription: Subscription): Promise<ApiResult<void>> {
		return new Promise<ApiResult<void>>(resolve => {
			let body = {
				ReportSubscriptionId: subscription.ReportSubscriptionId,
				SubscriptionName: subscription.SubscriptionName,
				ReportId: subscription.ReportId,
				RenderType: subscription.RenderType,
				FrequencyType: subscription.FrequencyType,
				Frequency: subscription.Frequency.join(","),
				EndOfMonthFlag: subscription.EndOfMonthFlag,
				ParameterValues: subscription.ParameterValues,
				Subscribers: subscription.Subscribers
			};

			this._http.post(`api/organizations/${organizationId}/subscriptions`, body).subscribe((result: any) => {
				resolve(new ApiResult<void>().deserialize(result));
			});
		});
	}

	getParameters(reportId: number, values: ParameterValue[]): Promise<ApiResult<ReportParameter[]>> {
		return new Promise<ApiResult<ReportParameter[]>>(resolve => {
			this._http.post(`api/subscribable-reports/${reportId}/parameters`, values).subscribe((result: any) => {
				resolve(new ApiResult<ReportParameter[]>().deserialize(result));
			});
		});
	}

	getReports(): Promise<ApiResult<SubscribableReport[]>> {
		return new Promise<ApiResult<SubscribableReport[]>>(resolve => {
			this._http.get(`api/subscribable-reports`).subscribe((result: any) => {
				resolve(new ApiResult<SubscribableReport[]>().deserialize(result));
			});
		});
	}

	getDetail(organizationId: number, subscriptionId: number): Promise<ApiResult<Subscription>> {
		return new Promise<ApiResult<Subscription>>(resolve => {
			this._http.get(`api/organizations/${organizationId}/subscriptions/${subscriptionId}`).subscribe((result: any) => {
				resolve(new ApiResult<Subscription>().deserialize(result));
			});
		});
	}

	getSubscriptions(organizationId: number): Promise<ApiResult<SubscriptionSummary[]>> {
		return new Promise<ApiResult<SubscriptionSummary[]>>(resolve => {
			this._http.get(`api/organizations/${organizationId}/subscriptions`).subscribe((result: any) => {
				resolve(new ApiResult<SubscriptionSummary[]>().deserialize(result));
			});
		});
	}

	toggleSubscription(organizationId: number, subscriptionId: number): Promise<ApiResult<void>> {
		return new Promise<ApiResult<void>>(resolve => {
			this._http.put(`api/organizations/${organizationId}/subscriptions/${subscriptionId}/toggle`, {}).subscribe((result: any) => {
				resolve(new ApiResult<void>().deserialize(result));
			});
		});
	}

	updateSubscription(organizationId: number, subscription: Subscription): Promise<ApiResult<void>> {
		return new Promise<ApiResult<void>>(resolve => {
			let body = {
				ReportSubscriptionId: subscription.ReportSubscriptionId,
				SubscriptionName: subscription.SubscriptionName,
				ReportId: subscription.ReportId,
				RenderType: subscription.RenderType,
				FrequencyType: subscription.FrequencyType,
				Frequency: subscription.Frequency.join(","),
				EndOfMonthFlag: subscription.EndOfMonthFlag,
				ParameterValues: subscription.ParameterValues,
				Subscribers: subscription.Subscribers
			};

			this._http.put(`api/organizations/${organizationId}/subscriptions/${subscription.ReportSubscriptionId}`, body).subscribe((result: any) => {
				resolve(new ApiResult<void>().deserialize(result));
			});
		});
	}

	updateSubscribers(organizationId: number, subscription: Subscription): Promise<ApiResult<void>> {
		return new Promise<ApiResult<void>>(resolve => {
			let body = {
				ReportSubscriptionId: subscription.ReportSubscriptionId,
				SubscriptionName: subscription.SubscriptionName,
				ReportId: subscription.ReportId,
				RenderType: subscription.RenderType,
				FrequencyType: subscription.FrequencyType,
				Frequency: subscription.Frequency.join(","),
				EndOfMonthFlag: subscription.EndOfMonthFlag,
				ParameterValues: subscription.ParameterValues,
				Subscribers: subscription.Subscribers
			};

			this._http.put(`api/organizations/${organizationId}/subscribers/${subscription.ReportSubscriptionId}`, body).subscribe((result: any) => {
				resolve(new ApiResult<void>().deserialize(result));
			});
		});
	}
}
