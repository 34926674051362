import { Component, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
import { Permissions } from "../shared/enums/permissions";
import { PermissionsService } from "../shared/services/permisisons.service";
import { ReportingDashboardComponent } from "../shared/components/reporting-dashboard/dashboard.component";

@Component({
	selector: "reporting",
	templateUrl: "./reporting.template.html",
	styleUrls: ["./reporting.scss"]
})
export class ReportingComponent implements OnInit {
	@ViewChild("dash", { static: true })
	dash: ReportingDashboardComponent;

	Permissions: any = Permissions;
	hasDashPermission: boolean = false;

	constructor(private _router: Router, private _permissionsService: PermissionsService) { }

	ngOnInit() {
		if (this._permissionsService.hasPermission(Permissions.analytics)) {
			this.hasDashPermission = true;
		} else {
			this.hasDashPermission = false;
			this.goToReportsTab();
		}
	}

	goToReportsTab() {
		this._router.navigate(["reporting/reports"]);
	}
}
