import { Component, ViewChild, Input, EventEmitter, Output, OnInit } from "@angular/core";
import { ModalComponent } from "../modal/modal.component";
import { UserService } from "../../../shared/services/user.service";
import { UserProfile } from "../../../shared/models/user-profile.model";
import { Permissions } from "../../../shared/enums/permissions";
import { StatusMessageService } from "../../../shared/components/status-message/status-message.service";
import { UserListItemModel } from "../../../shared/models/user-list-item.model";
import { CustomerService } from "../../services/customer.service";
import { CustomerSummaryModel } from "../../models/customer-summary.model";
import { OrganizationSummaryModel } from "../../models/organization-summary.model";
import * as _ from "lodash";

@Component({
	selector: "customer-users",
	templateUrl: "./customer-users.template.html",
	styleUrls: ["./customer-users.scss"]
})
export class CustomerUsersComponent implements OnInit {
	usersFilter: string[] = ["active"];
	modalTitle: string;
	subHeaderText: string;
	userToEdit: UserProfile = null;
	editMode: string;
	customerName: string;
	userListItemEdit: UserListItemModel = null;
	permissions: any = Permissions;

	organizationCustomers: CustomerSummaryModel[];
	organizations: OrganizationSummaryModel[];
	selectedCustomerId: number = null;
	selectedOrganizationId: number = null;
	userToChange: UserListItemModel = null;

	saving: boolean = false;

	@ViewChild("editModal") editModal: ModalComponent;

	@ViewChild("createModal") createModal: ModalComponent;

	@ViewChild("changeOrgModal") changeOrgModal: ModalComponent;

	@Input() customerId: number;
	@Input() organizationId: number;
	@Input() customerUsers: UserListItemModel[] = [];
	@Input() enableDoseSpot: boolean = false;
	@Input() isUserTeamEnabled: boolean = false;
	@Input() viewingAllUsers: boolean = false;
	@Input() showEditRoles: boolean = false;
	@Input() internal: boolean = false;
	@Input() showOrgSettings: boolean = false;

	@Output() userSaveSuccess: EventEmitter<any> = new EventEmitter<any>();
	@Output() updateUsers: EventEmitter<any> = new EventEmitter<any>();

	constructor(private _userService: UserService, private _statusMessageService: StatusMessageService, private _customerService: CustomerService) {}

	ngOnInit() {
		this.organizationCustomers = [];
		this.organizations = [];

		this._customerService.getOrganizationList(true).subscribe((organizations: OrganizationSummaryModel[]) => {
			this.organizations = organizations;

			//perhaps use filter
			this.organizations.forEach((org, index) => {
				if (org.OrganizationId === this.organizationId) this.organizations.splice(index, 1);
			});
			this.organizations = this.organizations.sort((a, b) => {
				if (a.Name < b.Name) return -1;

				if (a.Name > b.Name) return 1;

				return 0;
			});
		});
	}

	addUser() {
		this.userToEdit = new UserProfile();
		this.createModal.open("md");
		this.modalTitle = "New User";
	}

	editUser(userId: number) {
		this._userService.getUserProfile(userId, this.customerId).subscribe((user: UserProfile) => {
			this.userToEdit = user;
			this.userToEdit.UserId = userId;
			this.editModal.open("md");
			this.modalTitle = "Edit User";
			this.subHeaderText = "";
			this.editMode = "editUser";
		});
	}

	onEditClose() {
		this.userToEdit = null;
		this.userListItemEdit = null;
		this.editMode = null;
	}

	editUserSuccess() {
		this.userSaveSuccess.emit(this.userToEdit);
		if (this.editMode === "editUser") {
			this.editModal.dismiss();
		} else {
			this.createModal.dismiss();
		}
		this._statusMessageService.changeStatusMessage(
			"success",
			this.userToEdit.LastName + ", " + this.userToEdit.FirstName + " successfully " + (this.editMode === "editUser" ? "updated" : "added") + "."
		);
	}

	editOrgSettings(user: UserListItemModel) {
		this.userListItemEdit = user;
		this.editModal.open("sm");
		this.modalTitle = "Edit Org Settings";
		this.subHeaderText = " for " + user.LastName + ", " + user.FirstName;
		this.editMode = "editOrgSettings";
	}

	editUserRole(userInfo: UserListItemModel) {
		this.userListItemEdit = userInfo;
		this.editModal.open("md");
		this.modalTitle = "Edit User Roles";
		this.subHeaderText = " for " + userInfo.LastName + ", " + userInfo.FirstName;
		this.editMode = "editRole";
	}

	editUserLogin(userInfo: UserListItemModel) {
		this.userListItemEdit = userInfo;
		this.editModal.open("md");
		this.modalTitle = "Edit Login ID";
		this.subHeaderText = " for " + userInfo.LastName + ", " + userInfo.FirstName;
		this.editMode = "editLoginId";
	}

	editReportPermissions(userInfo: UserListItemModel) {
		this.userListItemEdit = userInfo;
		this.editModal.open("lg");
		this.modalTitle = "Report Permissions";
		this.subHeaderText = " for " + userInfo.LastName + ", " + userInfo.FirstName;
		this.editMode = "editReportPermissions";
	}

	userRolesSaveSuccess() {
		this._statusMessageService.changeStatusMessage("success", "Roles for " + this.userListItemEdit.LastName + ", " + this.userListItemEdit.FirstName + " successfully updated.");
		this.editModal.dismiss();
		this.userSaveSuccess.emit();
	}

	userReportPermissionsSaveSuccess() {
		this._statusMessageService.changeStatusMessage("success", "Report Permissions for " + this.userListItemEdit.LastName + ", " + this.userListItemEdit.FirstName + " successfully updated.");
		this.editModal.dismiss();
		this.userSaveSuccess.emit();
	}

	updateSubHeaderText(event) {
		this.subHeaderText = event;
	}

	changeUserLock(user: UserListItemModel) {
		this._userService.changeUserStatus(user.UserId, !user.IsLocked).subscribe((result: boolean) => {
			if (result) {
				this._statusMessageService.changeStatusMessage("success", "User's status successfully updated.");
				user.IsLocked = !user.IsLocked;
				this.userSaveSuccess.emit();
			} else {
				this._statusMessageService.changeStatusMessage("Error", "Error updating user's status.");
			}
		});
	}

	changeUserOrg(user: UserListItemModel) {
		this.userToChange = user;
		this.changeOrgModal.open("xs");
		this.modalTitle = "Transfer User";
		this.subHeaderText = " for " + user.LastName + ", " + user.FirstName;
		this.editMode = "changeCustomer";
	}

	cancelChangeOrgModal() {
		this.changeOrgModal.dismiss();
		this.selectedOrganizationId = null;
		this.customerId = null;
	}

	confirmChangeOrgModal() {
		if (this.userToChange && this.selectedCustomerId) {
			this._userService.transferUser(this.selectedCustomerId, this.userToChange.UserId).subscribe((result: any) => {
				if (result.Success) {
					this.updateUsers.emit();
					this._statusMessageService.changeStatusMessage("success", "User successfully moved.");
					this.selectedCustomerId = null;
					this.selectedOrganizationId = null;
				} else {
					this._statusMessageService.changeStatusMessage("error", "Error moving the user.");
				}

				this.changeOrgModal.dismiss();
			});
		} else {
			this._statusMessageService.changeStatusMessage("error", "Must select both an organization and a customer.");
		}
	}

	organizationSelected(id: number) {
		this.selectedOrganizationId = id;
		this._customerService.getOrganizationCustomerList(this.selectedOrganizationId).subscribe((customers: CustomerSummaryModel[]) => {
			customers = customers.sort((a, b) => {
				if (a.Name < b.Name) return -1;

				if (a.Name > b.Name) return 1;

				return 0;
			});
			this.organizationCustomers = customers;
			this.selectedCustomerId = null;
		});
	}

	customerSelected(id: number) {
		this.selectedCustomerId = id;
	}

	saveOrgSettings() {
		this.saving = true;
		this._userService.setOrganizationRole(this.userListItemEdit.UserId, this.organizationId, this.userListItemEdit.IsOrgAdmin).then(() => {
			this.saving = false;
			this.editModal.dismiss();
		});
	}
}
