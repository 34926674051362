import { DiagnosisModel } from "./diagnosis.model";
import { PatientAllergyModel } from "./allergy.model";

export class PatientInfoModel {
	public patientId: number;
	public firstName: string;
	public middleName: string;
	public lastName: string;
	public sex: string;
	public birthDate: string;
	public dischargeDate: string;
	public Mrn: string;
	public ssn: string;
	public homeAddress: string;
	public homeAddress2: string;
	public city: string;
	public state: string;
	public zipCode: string;
	public phone: string;
	public isFavorite: boolean = false;

	public patientStatus: number;

	public isAdmitted: boolean;
	public dateAdmitted: string;
	public hospiceType: number;
	public facilityName: string;
	public teamName: string;
	public customerTeamId: number;
	public pharmacyName: string;
	public pharmacyFax: string;

	public diagnosisName: string;
	public dateDiagnosed: string;
	public dateResolved: string;
	public icdCode: string;
	public isContributing: boolean;

	public altPhone: string;
	public deliveryDetails: string;
	public packaging: number;
	public packagingNarcSheets: boolean;
	public packagingPrefilledSyringes: boolean;
	public deliveryDesignationCode: string;

	public isDeceased: boolean = false;
	public isDischarged: boolean = false;
	public isPreadmit: boolean = false;

	public diagnosis: DiagnosisModel[] = [];
	public allergies: PatientAllergyModel[] = [];

	public isHomePatient: boolean;
	public isFacilityCare: boolean;
	public isIpuGpu: boolean;

	public frameworkFacId: string;
	public frameworkPatId: string;
	public doseSpotPatientId: number;

	public heightIn?: number;
	public weightLbs?: number;
	public episodeId: string;

	public memberId: string;
	public pcn: string;
	public bin: string;

	constructor() {
		this.patientStatus = 1; // default to Active status
	}

	fullName() {
		return (this.firstName || "") + " " + (this.lastName || "");
	}
}
