import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { DataTableModel } from "../../../shared/components/data-table/data-table.model";
import { SortModel } from "../../../shared/components/data-table/sort.model";
import { DataTableColumnModel } from "../../../shared/components/data-table/columns.model";
import { PatientMedicationModel } from "../../../shared/models/patient-medication.model";
import { PrescriptionSummaryActionModel } from "../../../shared/models/prescription-summary-action.model";
import { PatientCareStore } from "../../services/patient-care.store";
import { AuthService } from "src/app/ocp/shared/services/auth.service";
import { SelectionModel } from "@angular/cdk/collections";

@Component({
	selector: "prescription-summary-table",
	templateUrl: "./prescription-summary-table.component.html",
	styleUrls: ["./prescription-summary-table.component.scss"]
})
export class PrescriptionSummaryTableComponent implements OnInit {
	expandedIdx?: number = null;
	notCoveredByHospice: boolean = false;
	medicationItems: DataTableModel = new DataTableModel([], []);
	hasEnhancedErx: boolean = false;

	get disableERx(): boolean {
		return this.patientStore.getIsPediatricRestricted();
	}

	private _data: PatientMedicationModel[];
	@Input()
	get data(): PatientMedicationModel[] {
		return this._data;
	}
	set data(d: PatientMedicationModel[]) {
		this._data = d;
		this.initTable();
	}
	@Input() patientAdmitted: boolean = false;
	@Input() selectedItems: SelectionModel<PatientMedicationModel>;
	@Input() isAllSelected: boolean;

	@Output() modalAction: EventEmitter<PrescriptionSummaryActionModel> = new EventEmitter<PrescriptionSummaryActionModel>();
	@Output() onToggleAll: EventEmitter<any> = new EventEmitter<any>();
	@Output() onSelectItem: EventEmitter<PatientMedicationModel> = new EventEmitter<PatientMedicationModel>();

	constructor(public patientStore: PatientCareStore, private _authService: AuthService) {}

	ngOnInit() {
		this.hasEnhancedErx = this._authService.isInRole("Enhanced eRx");
	}

	initTable() {
		if (!this.data || !this.data) return;

		let defaultSorting: SortModel[] = [];

		defaultSorting.push({
			column: "DrugName",
			direction: "asc"
		});

		let columns: DataTableColumnModel[] = [];

		if (this._authService.isInRole("Enhanced eRx") && !this.disableERx) {
			columns.push(new DataTableColumnModel("select", "", "col-select", false));
		}

		columns.push(new DataTableColumnModel("expander", "", "", false));
		columns.push(new DataTableColumnModel("DrugName", "Medication", "", true));
		columns.push(new DataTableColumnModel("RxNumber", "Rx #", "", true));
		columns.push(new DataTableColumnModel("LastDispenseDate", "Last Filled", "", true));
		columns.push(new DataTableColumnModel("ExpireDate", "Expires", "", true));
		columns.push(new DataTableColumnModel("TotalQuantityRemaining", "Qty Rem", "right-aligned", true));
		columns.push(new DataTableColumnModel("IsActive", "Active", "centered", true));
		columns.push(new DataTableColumnModel("IsCovered", "Coverage", "centered", false));
		columns.push(new DataTableColumnModel("edit", "", "", false));

		this.medicationItems = new DataTableModel(defaultSorting, columns);
		this.medicationItems.pageLength = 9999;
		this.medicationItems.populate(this.data);
	}

	showRefillButton(rx: any): boolean {
		return rx.IsDispensing && rx.AllowRefill && this.patientAdmitted;
	}

	showDispenseHistoryButton(rx: any): boolean {
		return rx.IsDispensing && rx.IsCovered;
	}

	showDiscontinueButton(rx: any): boolean {
		return rx.IsActive;
	}

	isExpanded(idx: number): boolean {
		return this.expandedIdx === idx;
	}

	expand(idx: number) {
		if (this.expandedIdx === idx) {
			this.expandedIdx = null;
		} else {
			this.expandedIdx = idx;
		}
	}

	actionClick(actionType: string, rx: any) {
		let action = new PrescriptionSummaryActionModel();
		action.medItem = rx;
		action.type = actionType;
		this.modalAction.emit(action);
	}
}
