import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { StatusMessageService } from "../status-message/status-message.service";
import { ResetPasswordModel } from "../../models/reset-password.model";

@Component({
	selector: "oppc-change-password",
	templateUrl: "./form-change-password.template.html",
	styleUrls: ["./form-change-password.scss"]
})
export class FormChangePasswordComponent implements OnInit {
	resetPasswordModel: ResetPasswordModel = new ResetPasswordModel();
	focus: EventEmitter<any> = new EventEmitter<any>();
	passwordRequirements: string =
		"Password must be at least 8 characters long and contain three of the following four characters: upper case letter, lower case letter, number, and special character. Your password may not include your username.";

	@Input() saving: boolean = false;
	@Input() isTmpPasswordReset: boolean = false;
	@Output() resetPassword: EventEmitter<ResetPasswordModel> = new EventEmitter<ResetPasswordModel>();

	constructor(private _statusMessageService: StatusMessageService) {}

	ngOnInit() {
		setTimeout(() => {
			this.focus.emit();
		});
	}

	doResetPassword() {
		if (this.resetPasswordModel.newPassword !== this.resetPasswordModel.confirmNewPassword) {
			this._statusMessageService.changeStatusMessage("error", "The New Password and Confirmation do not match. Please try again.");
			return;
		}

		this.resetPassword.emit(this.resetPasswordModel);
	}
}
