import { Component, Output, EventEmitter, ViewChild, Input, OnInit } from "@angular/core";
import { ISearchService } from "../patient-search/search.interface";
import { DosespotMedSearchService } from "../../services/dosespot-medications-search.service";
import { MedicationSearchResultModel } from "../../models/medication-search-result.model";
import { AdvancedSearch } from "src/app/ocp/shared/components/advanced-search/advanced-search.component";
import * as _ from "lodash";
import { FormControl } from "@angular/forms";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { DosespotCompoundSearchService } from "../../services/dosespot-compound-search.service";

@Component({
	selector: "search-dosespot-compound",
	providers: [{ provide: ISearchService, useClass: DosespotCompoundSearchService }],
	templateUrl: "./search-dosespot-compound.template.html",
	styleUrls: ["./search-dosespot-compound.scss"]
})
export class SearchDosespotCompoundComponent implements OnInit {
	private _initialText: string;
	@Input()
	get initialText(): string {
		return this._initialText;
	}
	set initialText(txt: string) {
		this._initialText = txt;
		setTimeout(() => {
			this.setText(txt);
		});
	}

	@Output() onSearchCleared: EventEmitter<any> = new EventEmitter<any>();
	@Output() onItemSelect: EventEmitter<MedicationSearchResultModel> = new EventEmitter<MedicationSearchResultModel>();

	@ViewChild("searchControl") private searchControl: AdvancedSearch;

	tableHeaderLabels: string[] = ["Name"];

	private destroyed: Subject<any> = new Subject<any>();

	get filtering(): boolean {
		return this.numFiltersApplied > 0;
	}

	get numFiltersApplied(): number {
		return 0;
	}

	constructor() {}

	ngOnInit() {}

	clear() {
		this.clearFilters();
		this.onSearchCleared.emit();
	}

	clearSearch() {
		this.searchControl.clearSearch();
	}

	clearFilters() {
		this.filterResults();
	}

	filterResults() {}

	generateDropDownLists() {}

	setText(text: string) {
		this.searchControl.searchText.reset(text, { emitEvent: text && text.trim().length > 0 });
	}
}
