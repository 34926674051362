import { Routes, RouterModule } from "@angular/router";

import { PatientCare } from "./patient-care.component";
import { AuthGuard } from "../shared/gaurds/auth.gaurd";
import { PatientDetails } from "./patient-details.component";
import { PatientLayoutComponent } from "./patient-layout.component";
import { ErxIframeComponent } from "./components/erx-iframe/erx-iframe.component";
import { PlanOfTreatmentComponent } from "./components/plan-of-treatment/plan-of-treatment.component";
import { TierReportComponent } from "./components/tier-report/tier-report.component";
import { DeprescribingOptionsComponent } from "./components/deprescribing-options/deprescribing-options.component";
import { NewProfileMedicationComponent } from "./components/new-profile-medication/new-profile-medication.component";
import { ErxNewOrderComponent } from "../shared/components/erx-components/erx-new-order/erx-new-order.component";

export const PatientCareRoutes: Routes = [
    { path: "patient-care", component: PatientCare, canActivate: [AuthGuard] },
    {
        path: "patient-care/patient-details/:patientId",
        component: PatientLayoutComponent,
        canActivate: [AuthGuard],
        children: [
            { path: "detail", component: PatientDetails },
            { path: "erx", component: ErxIframeComponent },
            { path: "plan-of-treatment", component: PlanOfTreatmentComponent },
            { path: "cost-savings-report", component: TierReportComponent },
            { path: "deprescribing-options", component: DeprescribingOptionsComponent },
            { path: "order-intake", component: NewProfileMedicationComponent, data: { type: "intake" } },
            { path: "non-dispensing-order", component: NewProfileMedicationComponent, data: { type: "non-dispensing" } },
            { path: "new-erx-order", component: ErxNewOrderComponent, data: { type: "patient-erx" } }
        ]
    }
];

export const patientCareRouting = RouterModule.forChild(PatientCareRoutes);
