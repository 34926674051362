import { Component, OnInit } from "@angular/core";
import { UserService } from "../../shared/services/user.service";
import { CustomerProfileModel } from "../../shared/models/customer-profile.model";
import { Router } from "@angular/router";

@Component({
	selector: "roles-tab",
	templateUrl: "./roles.template.html",
	styleUrls: ["./roles.scss", "./partner-profile-tabs.scss"]
})
export class RolesTab implements OnInit {
	constructor() {}

	ngOnInit() {}
}
