export class DosespotError {
	propertyName: string;
	errorMessage: string;

	deserialize(input: any): DosespotError {
		this.propertyName = input.PropertyName;
		this.errorMessage = input.ErrorMessage;
		return this;
	}
}
