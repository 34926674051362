export class ApiResult<T>
{
    Message: string;
    StatusCode: number;
    Success: boolean;
    Data: T;

    deserialize(obj: any): ApiResult<T> {
        this.Message = obj.Message;
        this.StatusCode = obj.StatusCode;
        this.Success = obj.Success;

        if (obj.Data) {
            this.Data = obj.Data;
        }

        return this;
    }
}