export class DiagnosisModel {
  PatientDxId: number;
  IcdCodeId: number;
  IcdCode: string;
  IcdName: string;
  IsRelated: boolean = false;
  EffectiveDate: string;
  ResolvedDate: string;
  IsActive: boolean = false;

  constructor() {

  }
}
