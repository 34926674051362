import { Component, OnInit, ViewChild } from "@angular/core";
import { StatusMessageService } from "../shared/components/status-message/status-message.service";
import { UserService } from "../shared/services/user.service";
import { UserProfile } from "../shared/models/user-profile.model";
import { NgForm } from "@angular/forms";
import { ApiResult } from "../shared/models/api-result.model";
import { UtilitiesService } from "../shared/services/utilities.service";
import { CustomerService } from "../shared/services/customer.service";

@Component({
	selector: "account-settings",
	templateUrl: "./account-settings.template.html",
	styleUrls: ["./account.scss"]
})
export class AccountSettingsComponent implements OnInit {
	saving: boolean = false;
	userProfile: UserProfile = new UserProfile();

	@ViewChild("accountSettingsForm")
	accountSettingsForm: NgForm;

	get alternateEmailInvalid(): boolean {
		return this.userProfile.Email && !this._utility.validateEmail(this.userProfile.Email);
	}

	get userPhoneInvalid(): boolean {
		return (this.userProfile.Phone || "").length !== 10;
	}

	constructor(private _statusMessageService: StatusMessageService, private _userService: UserService, private _utility: UtilitiesService, public store: CustomerService) {}

	ngOnInit() {
		this._userService.getUserProfile(null, null).subscribe((profile: UserProfile) => {
			if (profile) {
				this.userProfile = profile;
			}
		});
	}

	saveProfile() {
		this.saving = true;
		this._userService.setUserProfile(this.userProfile, null, null, null, null).subscribe((results: ApiResult) => {
			this.saving = false;
			if (results.Success) {
				this._statusMessageService.changeStatusMessage("success", "Profile updated.");
				this.accountSettingsForm.form.markAsUntouched();
				this.accountSettingsForm.form.markAsPristine();
				this._userService.refreshUserInfo();
			} else {
				this._statusMessageService.changeStatusMessage("error", results.PublicMessage);
			}
		});
	}
}
