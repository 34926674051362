import {Component, OnInit, forwardRef, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {CustomerService} from "../../../shared/services/customer.service";

@Component({
  selector: 'select-customer-team',
  templateUrl: './select-customer-team.template.html',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SelectCustomerTeamComponent),
    multi: true
  }]
})

export class SelectCustomerTeamComponent implements ControlValueAccessor, OnInit {
  private _value: any;
  teamList: any[] = [];

  @Input() isRequired: boolean = false;

  get value() : number {
    return this._value;
  }

  set value(value: number) {
    this._value = value;
    this.propagateChange(this._value);
  }
  constructor(private _customerService: CustomerService) {
  }

  ngOnInit() {
    this._customerService.getCustomerTeamList(null, false, false).subscribe(teamList => {
      this.teamList = teamList;
    });
  }

  propagateChange = (_: number) => {
  };

  writeValue(obj: number) {
    if (obj !== undefined)
      this.value = obj;
  }

  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any) {
  } //Not currently needed
}

