/**
 * Created by dan on 12/14/2016.
 */
import { Component, Input, forwardRef, Output, EventEmitter, OnInit, ElementRef, AfterViewChecked, ChangeDetectorRef } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
	selector: "input-textbox",
	templateUrl: "./input-textbox.template.html",
	styleUrls: ["./input-textbox.scss"],
	// host: {
	//   '(document:click)': 'handleClick($event)',
	// },
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => InputTextBoxComponent),
			multi: true
		}
	]
})
export class InputTextBoxComponent implements ControlValueAccessor, OnInit, AfterViewChecked {
	public elementRef;
	value: string;

	get invalidOverride(): boolean {
		return false && this.isRequired && (this.value == null || this.value == undefined || this.value.length === 0);
	}

	@Input() label: string;
	@Input() hideLabel: boolean;
	@Input() isRequired: boolean = false;
	@Input() type: string;
	@Input() step?: number = null;
	@Input() tooltip: string;
	@Input() placeholderText: string;
	@Input() isDisabled: boolean;
	@Input() overrideValidation: boolean = false;
	@Input() focus: EventEmitter<any> = new EventEmitter<any>();

	@Output() isDisabledChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

	@Input() disableId: string;
	@Input() disabledLabel: string;
	@Input() isInvalid: boolean;
	@Input() maxLength: number;
	@Input() hintText: string;
	@Input() hasKeyupHint: boolean = false;

	constructor(myElement: ElementRef, private cdr: ChangeDetectorRef) {
		this.elementRef = myElement;
	}
	
	ngAfterViewChecked(): void {
		this.cdr.detectChanges();
	}

	ngOnInit() {
		if (this.hasKeyupHint) {
			this.hintText = "Press 'Enter' or 'Return' to continue"
		}

		setTimeout(() => {
			if (this.isRequired && !this.value) {
				this.isInvalid = true;
			} else {
				this.isInvalid = false;
			}
		}, 200)
	}

	propagateChange = (_: any) => {};

	inputChanged(value: string) {
		this.value = value;
		if (!this.overrideValidation) {
			if (this.isRequired && !this.value) {
				this.isInvalid = true;
			} else {
				this.isInvalid = false;
			}
		}

		if (this.type && this.type.toLowerCase() === "text") {
			// default empty string to null
			this.propagateChange(this.value || null);
		} else {
			this.propagateChange(this.value);
		}
	}

	writeValue(obj: string) {
		if (obj !== undefined) {
			this.value = obj;
		}
	}

	registerOnChange(fn: any) {
		this.propagateChange = fn;
	}

	registerOnTouched(fn: any) {} //Not currently needed
}
