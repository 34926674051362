import { Component, Input, Output, EventEmitter, OnInit, ViewChild } from "@angular/core";
import { MedicationSearchResultModel } from "../../../shared/models/medication-search-result.model";
import { RxService } from "../../../shared/services/rx.service";
import { RxOrderModel } from "../../../shared/models/rx-order.model";
import { SearchMedicationsComponent } from "../../../shared/components/search-medications/search-medications.component";
import { NgForm } from "@angular/forms";
import { ApiResult } from "../../../shared/models/api-result.model";
import { UserService } from "../../../shared/services/user.service";
import { StatusMessageService } from "../../../shared/components/status-message/status-message.service";
import * as moment from "moment";

@Component({
	selector: "form-profile-medication",
	templateUrl: "./form-profile-medication.template.html",
	styleUrls: ["./form-profile-medication.scss"]
})
export class FormProfileMedication implements OnInit {
	hasFormulary: boolean = false;
	checkingCoverage: boolean = false;
	coveredOptions: any[] = [
		{ text: "Covered by Hospice", value: true },
		{ text: "Non-covered", value: false }
	];
	coverageStatus: string;
	verifyCoverageSuccess: boolean = false;
	coverageMessage: string = "";
	loading: boolean = false;

	@ViewChild("addOrderForm")
	addOrderForm: NgForm;

	@ViewChild("searchMedications")
	searchMedications: SearchMedicationsComponent;

	@Input() rxOrderModel: RxOrderModel = new RxOrderModel();

	@Output() closeModal: EventEmitter<string> = new EventEmitter<string>();
	@Output() nextStep: EventEmitter<string> = new EventEmitter<string>();

	get canSave(): boolean {
		if (!this.rxOrderModel || !this.addOrderForm || !this.addOrderForm.form) return false;

		return (this.rxOrderModel.IsCompound || this.rxOrderModel.selectedMedication) && this.addOrderForm.form.valid && this.verifyCoverageSuccess;
	}

	get needsCoverageCheck(): boolean {
		return !this.verifyCoverageSuccess && !this.rxOrderModel.IsCompound && this.hasFormulary;
	}

	constructor(private _rxService: RxService, private _userService: UserService, private _statusMessageService: StatusMessageService) { }

	private setCoverageAsVerified() {
		this.verifyCoverageSuccess = true;
		this.rxOrderModel.overrideRequired = false;
		this.coverageMessage = "";
	}

	private setCoverageAsNotReviewed() {
		this.verifyCoverageSuccess = false;
		this.coverageMessage = "COVERAGE NOT REVIEWED";
	}

	private updateCoverageOverride() {
		if (this.rxOrderModel.IsCompound || !this.hasFormulary) {
			this.setCoverageAsVerified();
			return true;
		}
		this.setCoverageAsNotReviewed();
		return false;
	}

	ngOnInit() {
		this._userService.hasFormulary.subscribe((hasFormulary: boolean) => {
			this.hasFormulary = hasFormulary;
			this.updateCoverageOverride();
		});
	}

	reset() {
		if (this.searchMedications) {
			this.searchMedications.clear();
			this.searchMedications.clearSearch();
		}
		this.addOrderForm.form.reset();
		this.rxOrderModel.Ndc = null;
		this.rxOrderModel.DrugName = null;
		this.rxOrderModel.FrameworkName = null;
		this.rxOrderModel.Directions = null;
		this.rxOrderModel.StartDate = new Date();
		this.rxOrderModel.DiscontinueDate = null;
		this.rxOrderModel.IsCompound = null;
		this.rxOrderModel.IsCovered = null;
		this.rxOrderModel.IsPrn = null;
		this.rxOrderModel.AuthorizationStatusId = null;
		this.rxOrderModel.PriorAuthNote = null;
		this.rxOrderModel.DcoStartDate = null;
		this.rxOrderModel.DcoEndDate = null;
		this.rxOrderModel.DcoMaxQuantity = null;
		this.rxOrderModel.DcoMaxDaysSupply = null;
		this.rxOrderModel.DcoMaxRefills = null;
		this.rxOrderModel.selectedMedication = null;
		this.coverageStatus = null;
		this.verifyCoverageSuccess = false;
	}

	cancel() {
		this.reset();
		this.closeModal.emit();
	}

	medicationSearchCleared(obj: any) {
		this.rxOrderModel.selectedMedication = null;
		if (!this.rxOrderModel.IsCompound && this.hasFormulary) {
			this.setCoverageAsNotReviewed();
		}
	}

	medicationSelected(medication: MedicationSearchResultModel) {
		this.rxOrderModel.selectedMedication = medication;
		this.rxOrderModel.Ndc = medication.Ndc;
		this.rxOrderModel.DrugName = medication.DrugName;
		this.rxOrderModel.FrameworkName = medication.DrugName;
		if (!this.rxOrderModel.IsCompound && this.hasFormulary) {
			this.setCoverageAsNotReviewed();
		}
	}

	verifyCoverage() {
		if (this.updateCoverageOverride()) {
			return;
		}

		this.setCoverageAsNotReviewed();

		if (this.rxOrderModel.selectedMedication && typeof this.rxOrderModel.IsCovered !== "undefined") {
			this.checkingCoverage = true;

			this._rxService.verifyCoverage(this.rxOrderModel.PatientId, this.rxOrderModel.Ndc, this.rxOrderModel.IsCovered, this.rxOrderModel.StartDate).subscribe((result: ApiResult) => {
				this.checkingCoverage = false;

				if (this.updateCoverageOverride()) {
					return;
				}

				if (result.Success) {
					if (result.Result.Result === "Error") {
						this._statusMessageService.changeStatusMessage("error", result.Result.CustomMessage);
					} else {
						this.verifyCoverageSuccess = true;
						if (result.Result.Result === "OverrideRequired") {
							this.rxOrderModel.overrideRequired = true;
						} else {
							this.rxOrderModel.overrideRequired = false;
						}

						this.coverageMessage = result.Result.StatusMessage;
					}
				} else {
					this._statusMessageService.changeStatusMessage("error", result.PublicMessage);
				}
			});
		}
	}

	compoundChanged() {
		this.updateCoverageOverride();

		if (this.rxOrderModel.IsCompound) {
			this.rxOrderModel.Ndc = null;
		} else {
			if (this.rxOrderModel.selectedMedication) {
				this.rxOrderModel.Ndc = this.rxOrderModel.selectedMedication.Ndc;
				this.rxOrderModel.DrugName = this.rxOrderModel.selectedMedication.DrugName;
				this.rxOrderModel.FrameworkName = this.rxOrderModel.selectedMedication.DrugName;
			}
		}
	}

	medicationStartDateChanged() {
		if (this.rxOrderModel.StartDate && this.rxOrderModel.DiscontinueDate) {
			let startDate = new Date(this.rxOrderModel.StartDate);
			let discontinueDate = new Date(this.rxOrderModel.DiscontinueDate);

			if (startDate.getTime() - discontinueDate.getTime() >= 0) {
				// admit date is after discharge date
				this.rxOrderModel.DiscontinueDate = this.rxOrderModel.StartDate;
			}
		}
	}

	coverageChanged() {
		this.updateCoverageOverride();
	}

	confirm() {
		this.nextStep.emit();
	}
}
