import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { DataTableModel } from "../../../shared/components/data-table/data-table.model";
import { SortModel } from "../../../shared/components/data-table/sort.model";
import { DataTableColumnModel } from "../../../shared/components/data-table/columns.model";
import { ErxOrderStore } from "src/app/ocp/shared/services/erx-order.store";
import { RxOrderModel } from "src/app/ocp/shared/models/rx-order.model";

@Component({
	selector: "ct-erx-summary-table",
	templateUrl: "./erx-summary-table.component.html",
	styleUrls: ["./erx-summary-table.component.scss"]
})
export class ErxSummaryTableComponent implements OnInit {
	erxItems: DataTableModel = new DataTableModel([], []);

	private _data: RxOrderModel[];
	@Input()
	get data(): RxOrderModel[] {
		return this._data;
	}
	set data(d: RxOrderModel[]) {
		this._data = d;
		this.initTable();
	}
	@Input() isPrescriber: boolean = false;
	@Input() isPrescribingAgent: boolean = false;
	@Input() allowErx: boolean = false;

	@Output() onRowAction: EventEmitter<any> = new EventEmitter<any>();

	constructor(public erxStore: ErxOrderStore) {}

	ngOnInit() {}

	initTable() {
		if (!this.data || !this.data) return;

		let defaultSorting: SortModel[] = [];

		defaultSorting.push({
			column: "StartDate",
			direction: "dsc"
		});

		let columns: DataTableColumnModel[] = [];
		columns.push(new DataTableColumnModel("DrugName", "Medication", "pl-10px", true));
		columns.push(new DataTableColumnModel("Quantity", "Qty", "", false));
		columns.push(new DataTableColumnModel("StartDate", "Date", "", true));
		columns.push(new DataTableColumnModel("Refills", "Refills", "right-aligned", false));
		columns.push(new DataTableColumnModel("PharmacyId", "Pharmacy", "", false));
		columns.push(new DataTableColumnModel("sortedPrescriberName", "Prescriber [Agent]", "", true));
		columns.push(new DataTableColumnModel("orderStatus", "Status", "erx-status centered", true));
		columns.push(new DataTableColumnModel("edit", "", "px-md-0", false));

		this.erxItems = new DataTableModel(defaultSorting, columns);
		this.erxItems.maxSort = 1;
		this.erxItems.pageLength = 9999;
		this.erxItems.populate(this.data);
	}

	showRefillButton(erxStatus: string): boolean {
		if (!this.allowErx) return false;

		if (+erxStatus < 0) return true;

		var terminalStatuses: number[] = [6, 7, 10, 13];

		return terminalStatuses.includes(+erxStatus);
	}

	showEditButton(erxStatus: string): boolean {
		if (!this.allowErx) return false;

		if (+erxStatus < 0) return false;

		var allowEditStatuses: number[] = [1, 2, 5, 6, 8, 9, 10, 12];

		return allowEditStatuses.includes(+erxStatus);
	}

	actionClick(type: string, rx: RxOrderModel) {
		this.onRowAction.emit({ type, rx });
	}

	isReadyToSign(erxStatus: string, isControl: boolean): boolean {
		if (+erxStatus < 0 || (!this.isPrescriber && !this.isPrescribingAgent)) return false;

		if (isControl && !this.isPrescriber) return false;

		var signingStatuses: number[] = [1, 2, 8, 9, 12];

		return signingStatuses.includes(+erxStatus);
	}
}
