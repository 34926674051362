import { Injectable } from "@angular/core";
import { HttpService } from "../../services/http.service";

@Injectable()
export class FileUploadService {
	constructor(private _http: HttpService) {}

	makeFileRequest(url: string, id: number, files: File[]) {
		return this._http.makeFileRequest(url, id, files);
	}
}
