export class DataTableColumnModel {
  name: string;
  text: string;
  customClass: string = "";
  allowSort: boolean = false;
  sortDir: string = "";

  get fullClass() : string {
    let baseClass: string = (this.allowSort ? "allow-sort " : "");
    if (this.sortDir === "asc") {
      return baseClass + " cf-data-table__header--sorted-ascending " + (this.customClass || "");
    }

    if (this.sortDir === "dsc") {
      return baseClass + " cf-data-table__header--sorted-descending " + (this.customClass || "");
    }

    return baseClass + (this.customClass || "");
  }

  constructor(name: string, text: string, customClass: string, allowSort: boolean){
    this.name = name;
    this.text = text;
    this.customClass = customClass;
    this.allowSort = allowSort;
  }
}
