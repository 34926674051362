import { Injectable } from "@angular/core";
import { ReportSubscriptionService } from "./report-subscription.service";
import { ApiResult } from "../models/report-subscription/api-result.model";
import { Subscription } from "../models/report-subscription/subscription.model";
import { ParameterValue } from "../models/report-subscription/parameter-value.model";
import { ReportParameter } from "../models/report-subscription/report-parameter.model";
import { SubscribableReport } from "../models/report-subscription/subscribable-report.model";
import { SubscriptionSummary } from "../models/report-subscription/subscription-summary.model";
import { StoreObject } from "../models/store-object.model";
import * as _ from "lodash";
import { MultiSelectItemModel } from "../components/input-multi-select/multi-select-item.model";
import { Subscriber } from "../models/report-subscription/subscriber.model";
import { Subject, combineLatest } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { StatusMessageService } from "../components/status-message/status-message.service";
import { SubscriptionFiltersModel } from "../models/report-subscription/subscription-filters.model";
import { OrganizationUser } from "../models/organizations/organization-user.model";

@Injectable({ providedIn: "root" })
export class ReportSubscriptionStore {
	loadingMain: StoreObject<boolean> = new StoreObject<boolean>(false);
	loadingParamConfig: StoreObject<boolean> = new StoreObject<boolean>(false);

	subscription: StoreObject<Subscription> = new StoreObject<Subscription>(null);

	subscriptions: StoreObject<SubscriptionSummary[]> = new StoreObject<SubscriptionSummary[]>([]);
	reports: StoreObject<SubscribableReport[]> = new StoreObject<SubscribableReport[]>([]);

	parameters: StoreObject<ReportParameter[]> = new StoreObject<ReportParameter[]>([]);

	validTimer: any;
	canSave: StoreObject<boolean> = new StoreObject<boolean>(false);

	public readonly subscribers: StoreObject<Subscriber[]> = new StoreObject<Subscriber[]>([]);
	public readonly customerSubscribers: StoreObject<OrganizationUser[]> = new StoreObject<OrganizationUser[]>([]);

	isError: StoreObject<boolean> = new StoreObject<boolean>(false);

	reportId: number;
	private reportName: string;
	private organizationId: number;

	renderTypes: any[] = [
		{ text: "PDF", value: "PDF" },
		{ text: "Excel", value: "Excel" }
	];
	frequencies: any[] = [
		{ text: "Daily", value: "D" },
		{ text: "Weekly", value: "W" },
		{ text: "Monthly", value: "M" }
	];
	days: MultiSelectItemModel[] = [];

	private updateParameters$ = new Subject<boolean>();

	private daysOfWeek: any[] = [
		{ text: "Sunday", value: "1" },
		{ text: "Monday", value: "2" },
		{ text: "Tuesday", value: "3" },
		{ text: "Wednesday", value: "4" },
		{ text: "Thursday", value: "5" },
		{ text: "Friday", value: "6" },
		{ text: "Saturday", value: "7" }
	];

	public readonly createMode: StoreObject<"new" | "edit"> = new StoreObject<"new" | "edit">(null);
	public readonly step: StoreObject<number> = new StoreObject<number>(0);
	public filter: StoreObject<SubscriptionFiltersModel> = new StoreObject<SubscriptionFiltersModel>(new SubscriptionFiltersModel());
	public filteredSubscriptions: StoreObject<SubscriptionSummary[]> = new StoreObject<SubscriptionSummary[]>([]);

	public selectedReport: StoreObject<SubscribableReport> = new StoreObject<SubscribableReport>(null);

	public get filtersApplied(): boolean {
		let filters: SubscriptionFiltersModel = this.filter.get();
		return filters.reportName?.length > 0 || filters.enabledOnly;
	}

	constructor(private _service: ReportSubscriptionService, private _statusService: StatusMessageService) {
		this.days = _.map(this.daysOfWeek, (d: any) => {
			let model: MultiSelectItemModel = new MultiSelectItemModel();
			model.id = d.value;
			model.text = d.text;

			return model;
		});

		combineLatest([this.subscriptions.observable, this.filter.observable]).subscribe(([subscriptions, filter]) => {
			this.filteredSubscriptions.set(
				_.filter(subscriptions, (subscription: SubscriptionSummary) => {
					return (!filter.reportName || filter.reportName.indexOf(subscription.Report.ReportName) >= 0) && (!filter.enabledOnly || (filter.enabledOnly && subscription.IsActive == 1));
				})
			);
		});
	}

	load(organizationId: number) {
		this.organizationId = organizationId;

		this.subscriptions.set([]);
		this.reports.set([]);
		this.customerSubscribers.set([]);

		this.loadSubs();

		this.updateParameters$.pipe(debounceTime(1000)).subscribe(init => this.updateParametersImpl(init));

		this._service.getReports().then((result: ApiResult<SubscribableReport[]>) => {
			if (result.Success) {
				this.isError.set(false);
				this.reports.set(result.Data);
			} else {
				this._statusService.changeStatusMessage("error", "Unable to reach the Report Subscriptions API");
				this.isError.set(true);
			}
		});
	}

	newSubscription(reportId: number, reportName: string) {
		this.reportId = reportId;
		this.reportName = reportName;

		let subscription = new Subscription();
		subscription.ReportId = reportId;

		this.parameters.set([]);
		this.updateParameters(true);
		this.subscription.set(subscription);

		this.validTimer = setInterval(() => {
			let valid = this.validate();
			this.canSave.set(valid);
		}, 3000);
	}

	editSubscription(summary: SubscriptionSummary) {
		this.reportId = summary.Report.ReportId;
		this.reportName = summary.Report.ReportName;
		this.selectedReport.set(summary.Report);

		this.parameters.set([]);

		this.getDetail(summary);

		this.validTimer = setInterval(() => {
			let valid = this.validate();
			this.canSave.set(valid);
		}, 3000);
	}

	editSubscribers(summary: SubscriptionSummary): Promise<void> {
		return new Promise<void>(resolve => {
			this.reportId = summary.Report.ReportId;
			this.reportName = summary.Report.ReportName;
			this.selectedReport.set(summary.Report);
			this.subscribers.set([]);

			this.getDetail(summary).then((sub: Subscription) => {
				this.subscribers.set(sub.Subscribers);
				resolve();
			});

			this.validTimer = setInterval(() => {
				let valid = this.validateSubscribers();
				this.canSave.set(valid);
			}, 3000);
		});
	}

	private getDetail(summary: SubscriptionSummary): Promise<Subscription> {
		return new Promise<Subscription>(resolve => {
			this._service.getDetail(this.organizationId, summary.ReportSubscriptionId).then((result: ApiResult<Subscription>) => {
				let detail: Subscription = new Subscription().deserialize(result.Data);

				if (detail.FrequencyType == "W") {
					this.days = _.map(this.days, (d: MultiSelectItemModel) => {
						d.selected = _.some(detail.Frequency, (s: string) => {
							return s === d.id;
						});

						return d;
					});
				}

				this.doUpdateParameters(detail.ParameterValues);
				this.subscription.set(detail);
				console.log(detail);
				resolve(detail);
			});
		});
	}

	getParamValues(init: boolean): ParameterValue[] {
		let parameters: ReportParameter[] = this.parameters.get();
		let paramValues: ParameterValue[] = [];

		if (!init) {
			for (let i = 0; i < parameters.length; i++) {
				let p: ReportParameter = parameters[i];

				if (!p.MultiValue && p.value && p.value.trim().length > 0) {
					let paramValue: ParameterValue = new ParameterValue();
					paramValue.Name = p.Name;
					paramValue.Value = p.value;

					paramValues.push(paramValue);
				} else if (p.MultiValue) {
					paramValues = paramValues.concat(
						_.map(
							_.filter(p.options, (o: MultiSelectItemModel) => o.selected),
							(o: MultiSelectItemModel) => {
								let pv: ParameterValue = new ParameterValue();
								pv.Name = p.Name;
								pv.Value = o.id;

								return pv;
							}
						)
					);
				}
			}
		}

		return paramValues;
	}

	updateParameters(init: boolean = false) {
		if (!this.reportId || this.reportId <= 0) return;

		this.updateParameters$.next(init);
	}

	updateParametersImpl(init: boolean) {
		let paramValues: ParameterValue[] = this.getParamValues(init);
		this.doUpdateParameters(paramValues);
	}

	private doUpdateParameters(paramValues: ParameterValue[]) {
		this.loadingParamConfig.set(true);
		this._service.getParameters(this.reportId, paramValues).then((result: ApiResult<ReportParameter[]>) => {
			if (result.Success) {
				let params: ReportParameter[] = _.map(result.Data, (p: any) => new ReportParameter().deserialize(p));

				let filteredParams: ReportParameter[] = [];
				for (let i = 0; i < params.length; i++) {
					let d: ReportParameter = params[i];

					if (
						d.Name.toLowerCase() === "ddid" ||
						d.Name.toLowerCase() === "startdate" ||
						d.Name.toLowerCase() === "enddate" ||
						d.Name === "pharmTypeId" ||
						d.Name === "reportStyleID" ||
						d.Name === "allTeams"
					) {
						continue;
					}

					if (!d.PromptUser) continue;

					filteredParams.push(d);
				}

				this.parameters.set(filteredParams);
			}

			this.loadingParamConfig.set(false);
		});
	}

	cancel() {
		clearInterval(this.validTimer);

		this.reportId = 0;
		this.reportName = "";

		this.parameters.set([]);
		this.subscription.set(null);
		this.loadingParamConfig.set(false);
		this.selectedReport.set(null);
	}

	private validate(): boolean {
		let sub: Subscription = this.subscription.get();

		if (!sub) return false;

		if (!sub.SubscriptionName || sub.SubscriptionName.trim().length === 0) return false;

		if (!sub.FrequencyType || sub.FrequencyType.trim().length === 0) return false;

		if (sub.FrequencyType === "m" && !sub.EndOfMonthFlag && (!sub.Frequency || sub.Frequency.length === 0)) return false;

		if (sub.FrequencyType === "w") {
			if (!_.some(this.days, (d: MultiSelectItemModel) => d.selected)) return false;
		}

		if (!sub.RenderType || sub.RenderType.trim().length === 0) return false;

		return true;
	}

	private validateSubscribers(): boolean {
		if (!_.some(this.customerSubscribers.get(), (s: MultiSelectItemModel) => s.selected)) return false;

		return true;
	}

	save(update: boolean): Promise<void> {
		return new Promise<void>(resolve => {
			let sub: Subscription = this.subscription.get();

			if (sub.FrequencyType === "d") {
				sub.Frequency = [];
				sub.EndOfMonthFlag = 0;
			}

			if (sub.FrequencyType === "w") {
				sub.EndOfMonthFlag = 0;
				sub.Frequency = _.join(
					_.map(
						_.filter(this.days, (d: MultiSelectItemModel) => {
							return d.selected;
						}),
						(d: MultiSelectItemModel) => {
							return d.id;
						}
					),
					","
				);
			}

			let users: OrganizationUser[] = this.customerSubscribers.get();

			if (!users || users.length === 0) return;

			sub.Subscribers = _.map(users, (u: OrganizationUser) => {
				let subscriber: Subscriber = new Subscriber();

				subscriber.email = u.LoginId;
				subscriber.firstName = u.FirstName;
				subscriber.lastName = u.LastName;
				subscriber.externalSubscriberId = u.UserId.toString();

				return subscriber;
			});

			sub.ParameterValues = this.getParamValues(false);

			const step: number = this.step.get();
			if (update) {
				if (step === 3) {
					this._service.updateSubscribers(this.organizationId, sub).then((result: ApiResult<void>) => {
						this.loadSubs();
						this.cancel();
						resolve();
					});
				} else {
					this._service.updateSubscription(this.organizationId, sub).then((result: ApiResult<void>) => {
						this.loadSubs();
						this.cancel();
						resolve();
					});
				}
			} else {
				this._service.create(this.organizationId, sub).then((result: ApiResult<void>) => {
					this.loadSubs();
					this.cancel();
					resolve();
				});
			}
		});
	}

	private loadSubs() {
		this.loadingMain.set(true);
		this._service.getSubscriptions(this.organizationId).then((result: ApiResult<any>) => {
			if (result.Success) {
				this.subscriptions.set(result.Data.map((s: any) => new SubscriptionSummary().deserialize(s)));
				this.isError.set(false);
			} else {
				this._statusService.changeStatusMessage("error", "Unable to reach the Report Subscriptions API");
				this.isError.set(true);
			}

			this.loadingMain.set(false);
		});
	}

	disable(subscriptionId: number): Promise<void> {
		return new Promise<void>(resolve => {
			this._service.toggleSubscription(this.organizationId, subscriptionId).then((result: ApiResult<void>) => {
				this.loadSubs();
				resolve();
			});
		});
	}

	enable(subscriptionId: number): Promise<void> {
		return new Promise<void>(resolve => {
			this._service.toggleSubscription(this.organizationId, subscriptionId).then((result: ApiResult<void>) => {
				this.loadSubs();
				resolve();
			});
		});
	}

	setReportFilter(reportName: string) {
		let filter = this.filter.get();
		filter.reportName = reportName;
		this.filter.set(filter);
	}

	setStatusFilter(enabledOnly: boolean) {
		let filter = this.filter.get();
		filter.enabledOnly = enabledOnly;
		this.filter.set(filter);
	}

	clearFilter() {
		this.filter.set(new SubscriptionFiltersModel());
	}

	resetSubFrequency() {
		let updatedSubscription = this.subscription.get();
		updatedSubscription.Frequency = null;
		updatedSubscription.EndOfMonthFlag = 0;

		this.subscription.set(updatedSubscription);
	}
}
