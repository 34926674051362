import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from "@angular/core";
import { Clinician } from "../../../models/clinician-model";
import { NgForm } from "@angular/forms";
import * as _ from "lodash";

@Component({
	selector: "ocp-form-order-settings",
	templateUrl: "./form-order-settings.component.html",
	styleUrls: ["./form-order-settings.component.scss"]
})
export class FormOrderSettingsComponent implements OnInit {
	prescriberId: string;

	@Input() prescribers: any[] = [];
	@Input() effectiveDate: Date;
	@Input() selectedPrescriber: number;

	@Output() onDateChanged: EventEmitter<Date> = new EventEmitter<Date>();
	@Output() onPrescriberChanged: EventEmitter<number> = new EventEmitter<number>();

	@ViewChild("editSettingsForm") editSettingsForm: NgForm;

	constructor() {}

	ngOnInit(): void {}

	dateChanged(date: Date) {
		this.onDateChanged.emit(date);
	}

	selectPrescriber(e: number) {
		this.onPrescriberChanged.emit(e);
	}
}
