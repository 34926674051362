import { Component, OnInit } from "@angular/core";

@Component({
  selector: 'clinical-resources',
  templateUrl: './clinical-resources.template.html',
  styleUrls: ['./clinical-resources.scss']
})

export class ClinicalResources implements OnInit {
  selectedTab: string = 'formularies';
  navLinks: any[] = [
		{ label: "Formularies", path: "./formularies" },
		{ label: "Clinical Newsletter", path: "./newsletters" },
		{ label: "Symptom Guide", path: "./symptom-guide" },
    { label: "Webinar", path: "./webinars" }
	];

  constructor() {}

  ngOnInit() {}
}
