import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { PatientService } from "../../../shared/services/patient.service";
import { PatientAdmissionModel } from "../../../shared/models/patient-admission.model";
import { ApiResult } from "../../../shared/models/api-result.model";
import { StatusMessageService } from "../../../shared/components/status-message/status-message.service";

@Component({
	selector: "form-edit-admissions",
	templateUrl: "./form-edit-admissions.template.html",
	styleUrls: ["./form-edit-admissions.scss"]
})
export class FormEditAdmissionsComponent implements OnInit {
	saving: boolean = false;
	loading: boolean = false;
	admissionsHistory: PatientAdmissionModel[] = [];
	currentAdmission: PatientAdmissionModel;

	rowCurrentlyEditing?: number = null;
	selectedAdmissionAdmitDate: string = null;
	selectedAdmissionDischargeDate: string = null;
	selectedAdmissionIsDeceased: boolean = false;
	unsaved: boolean[] = [];
	minDate: string;
	maxDate: string;

	@Input() patientId: number;

	@Output() closeModal: EventEmitter<any> = new EventEmitter<any>();
	@Output() admissionsUpdated: EventEmitter<any> = new EventEmitter<any>();

	constructor(private _patientService: PatientService, private _statusMessageService: StatusMessageService) {}

	ngOnInit() {
		this.refreshAdmissions();
	}

	refreshAdmissions() {
		this.loading = true;
		this._patientService.getAdmissions(this.patientId).subscribe((admissions: PatientAdmissionModel[]) => {
			this.unsaved = [];
			this.loading = false;
			this.admissionsHistory = admissions;
			for (let i: number = 0; i < this.admissionsHistory.length; i++) {
				let admission: PatientAdmissionModel = this.admissionsHistory[i];

				let admitDate = new Date(admission.AdmitDate);
				admission.AdmitDate = new Date(admitDate.getUTCFullYear(), admitDate.getUTCMonth(), admitDate.getUTCDate()).toISOString();
				if (admission.DischargeDate) {
					if (!this.minDate && !admission.LastAdmission) {
						this.minDate = admission.DischargeDate;
					}
					let dischargeDate = new Date(admission.DischargeDate);
					admission.DischargeDate = new Date(dischargeDate.getUTCFullYear(), dischargeDate.getUTCMonth(), dischargeDate.getUTCDate()).toISOString();
				}
			}
			let currentAdmission: PatientAdmissionModel = this.admissionsHistory.filter((x: PatientAdmissionModel) => {
				return x.LastAdmission === true;
			})[0];

			if (currentAdmission) {
				this.currentAdmission = Object.assign({}, currentAdmission);
			}
			for (var i = 0; i < this.admissionsHistory.length; i++) this.unsaved.push(false);
		});
	}

	admissionDateChanged(index: number) {
		this.unsaved[index] = true;
		if (this.currentAdmission.AdmitDate && this.currentAdmission.DischargeDate) {
			let admitDate = new Date(this.selectedAdmissionAdmitDate);
			let dischargeDate = new Date(this.selectedAdmissionDischargeDate);

			if (this.selectedAdmissionDischargeDate && admitDate.getTime() - dischargeDate.getTime() >= 0) {
				// admit date is after discharge date
				this.selectedAdmissionDischargeDate = this.selectedAdmissionAdmitDate;
			}
		}
	}

	save() {
		this.saving = true;
		this._patientService.updateAdmissions(this.patientId, this.currentAdmission).subscribe((result: ApiResult) => {
			this.saving = false;
			if (result.Success) {
				this.admissionsUpdated.emit();
				this.closeModal.emit();
				this._statusMessageService.changeStatusMessage("success", "Current admissions successfully updated.");
				this.refreshAdmissions();
			} else {
				this._statusMessageService.changeStatusMessage("error", result.PublicMessage);
			}
		});
	}

	editAdmission(index: number) {
		this.currentAdmission = this.admissionsHistory[index];
		this.selectedAdmissionAdmitDate = this.currentAdmission.AdmitDate;
		this.selectedAdmissionDischargeDate = this.currentAdmission.DischargeDate;
		this.selectedAdmissionIsDeceased = this.selectedAdmissionIsDeceased;
		this.rowCurrentlyEditing = index;
		this.findMinMaxDate();
	}

	deleteAdmission(index: number) {
		this.saving = true;
		this._patientService.deleteAdmission(this.patientId, this.currentAdmission.PatientAdmissionId).subscribe((result: ApiResult) => {
			this.saving = false;
			if (result.Success) {
				this._statusMessageService.changeStatusMessage("success", "Admission successfully deleted.");
				this.admissionsHistory.splice(index, 1);
				this.admissionsUpdated.emit();
				this.refreshAdmissions();
				this.resetValues();
			} else {
				this._statusMessageService.changeStatusMessage("error", result.PublicMessage);
			}
		});
	}

	saveAdmission(index: number) {
		this.saving = true;
		let admission = new PatientAdmissionModel();
		admission.AdmitDate = this.selectedAdmissionAdmitDate;
		admission.DischargeDate = this.selectedAdmissionDischargeDate;
		admission.PatientAdmissionId = this.currentAdmission.PatientAdmissionId;
		admission.IsDeceased = this.selectedAdmissionIsDeceased;
		admission.LastAdmission = index === 0;

		this._patientService.updateAdmissions(this.patientId, admission).subscribe((result: ApiResult) => {
			this.saving = false;
			if (result.Success) {
				this.unsaved[index] = false;
				this.admissionsUpdated.emit();
				this._statusMessageService.changeStatusMessage("success", "Current admissions successfully updated.");
				this.refreshAdmissions();
				this.resetValues();
			} else {
				this._statusMessageService.changeStatusMessage("error", result.PublicMessage);
			}
		});
	}

	anyOthersUnsaved() {
		for (var i = 0; i < this.unsaved.length; i++) if (this.unsaved[i] && i !== this.rowCurrentlyEditing) return true;
		return false;
	}

	private resetValues() {
		this.currentAdmission = null;
		this.rowCurrentlyEditing = null;
		this.selectedAdmissionAdmitDate = null;
		this.selectedAdmissionDischargeDate = null;
		this.selectedAdmissionIsDeceased = null;
	}

	private findMinMaxDate() {
		if (this.rowCurrentlyEditing === null) return;
		if (this.admissionsHistory[this.rowCurrentlyEditing + 1] && this.admissionsHistory[this.rowCurrentlyEditing + 1].DischargeDate) {
			this.minDate = this.admissionsHistory[this.rowCurrentlyEditing + 1].DischargeDate;
		} else {
			this.minDate = null;
		}
		if (this.admissionsHistory[this.rowCurrentlyEditing - 1] && this.admissionsHistory[this.rowCurrentlyEditing - 1].AdmitDate) {
			this.maxDate = this.admissionsHistory[this.rowCurrentlyEditing - 1].AdmitDate;
		} else {
			this.maxDate = null;
		}
	}
}
