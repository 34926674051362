import { Component, Input, OnInit } from "@angular/core";
import { PatientInfoModel } from "../../../../../shared/models/patient.model";
import { RxOrderModel } from "../../../../../shared/models/rx-order.model";
import { PrescriberSearchResultModel } from "../../../../../shared/models/prescriber-search-result.model";
import { RxService } from "../../../../../shared/services/rx.service";
import * as moment from "moment";
import { AgentModel } from "../../../../models/agent-model";

@Component({
	selector: "order-form-printout",
	templateUrl: "./order-form-printout.template.html",
	styleUrls: ["./order-form-printout.scss"]
})
export class OrderFormPrintoutComponent implements OnInit {
	@Input() hospiceName: string;
	@Input() agent: AgentModel;
	@Input() patient: PatientInfoModel;
	@Input() prescriber: PrescriberSearchResultModel;
	@Input() rxOrders: RxOrderModel[];
	@Input() pharmacistName: string;

	dayTimeStamp: string;

	constructor(public _rxService: RxService) {}

	ngOnInit() {
		let now = new Date();
		this.dayTimeStamp = moment(now).format("YYYY-MM-DD/LT Z");
	}

	countControls(): number {
		let num = 0;
		for (var i = 0; i < this.rxOrders.length; i++) {
			if (this.rxOrders[i].IsControl) num++;
		}
		return num;
	}
}
