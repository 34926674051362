import { Directive, ElementRef, HostListener, OnInit } from '@angular/core';
import { JUMP_TO_TOP_TEMPLATE } from './jump-to-top.template';

@Directive({
  selector: '[stickyNav]'
})
export class StickyDirective implements OnInit {
  private startingPos = -1;
  private jumpIsVisible = false;
  private jumpToTop: HTMLElement;

  constructor(private _el: ElementRef) {
    this.jumpToTop = document.createElement("div");
    this.jumpToTop.id = "jump-to-top";
    this.jumpToTop.innerHTML = JUMP_TO_TOP_TEMPLATE;
    this.jumpToTop.style.position = "fixed";
    this.jumpToTop.style.top = "10px";
    this.jumpToTop.style.right = "0";
    this.jumpToTop.style.zIndex = "99999";
    this.jumpToTop.addEventListener("click", this.scrollToTop.bind(this));
  }

  ngOnInit(): void {
    window.addEventListener("scroll", this.checkScroll.bind(this));
  }

  scrollToTop(): void {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }

  @HostListener('window:scroll', ['$event'])
  checkScroll(): void {
    const el = this._el.nativeElement;
    const appContainer = document.getElementById("app-body");

    if (this.startingPos < 0) {
      this.startingPos = this.findPosY(el);
    }

    if (window.pageYOffset > this.startingPos) {
      if (this.jumpIsVisible) return;

      appContainer?.classList.add("fixed-nav-on");

      if (el.children[0].getBoundingClientRect().right > 0) {
        this.jumpIsVisible = true;
      }

      if (document.getElementById("jump-to-top")) {
        // this.jumpToTop.style.display = "block";
      } else {
        document.body.appendChild(this.jumpToTop);
      }
    } else {
      this.jumpIsVisible = false;
      this.jumpToTop.style.display = "none";
      appContainer?.classList.remove("fixed-nav-on");
    }
  }

  findPosY(obj: HTMLElement): number {
    let curtop = 0;

    if (obj.offsetParent) {
      while (obj.offsetParent) {
        curtop += obj.offsetTop;
        obj = <HTMLElement>obj.offsetParent;
      }
      curtop += obj.offsetTop;
    } else if (obj.getBoundingClientRect().y) {
      curtop += obj.getBoundingClientRect().y;
    }

    return curtop;
  }
}
