import { EventEmitter } from "@angular/core";
import { SortModel } from "./sort.model";
import { DataTableColumnModel } from "./columns.model";

export class DataTableModel {
	public origData: any[] = [];
	public data: any[] = [];

	public PageChanged: EventEmitter<any> = new EventEmitter<any>();
	public PageDataChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

	public pageLength: number = 10;
	public pageNumber: number = 0;
	public maxSort: number = 100;

	private sorting: SortModel[] = [];
	columns: DataTableColumnModel[] = [];

	public records: number;

	private sFilter: string = "";

	private timerId: any;
	private resetPaging: boolean = false;

	get sortedColumns(): DataTableColumnModel[] {
		let cols: DataTableColumnModel[] = [];

		for (let i: number = 0; i < this.sorting.length; i++) {
			let sort: SortModel = this.sorting[i];

			let col: DataTableColumnModel = this.getColumn(sort.column);

			if (col) {
				cols.push(col);
			}
		}

		return cols;
	}

	constructor(sorting: SortModel[], columns: DataTableColumnModel[]) {
		if (sorting) {
			this.sorting = sorting;
		}

		if (columns) {
			this.columns = columns;
		}

		this.initSorting();
	}

	initSorting() {
		for (let i: number = 0; i < this.sorting.length; i++) {
			let sort: SortModel = this.sorting[i];

			let col: DataTableColumnModel = this.getColumn(sort.column);

			if (col) {
				col.sortDir = sort.direction;
			}
		}
	}

	populate(d: any[]) {
		this.pageNumber = 0;
		this.resetPaging = true;
		this.origData = d;
		this.updatePageView();
		this.PageDataChanged.emit(true);
	}

	addData(d: any[]) {
		let data = this.origData.concat(d);
		this.resetPaging = false;
		this.page(data);
		this.origData = data;
		this.PageDataChanged.emit(true);
	}

	public changePageLength(pageLength) {
		this.pageLength = pageLength;
		this.pageNumber = 0;
		this.updatePaging(true);
	}

	public changePage(pageNum) {
		this.pageNumber = pageNum;
		this.updatePaging(false);
	}

	removeSort(column: string) {
		let sortIndex = -1;
		for (let i: number = 0; i < this.sorting.length; i++) {
			let sort: SortModel = this.sorting[i];
			if (sort.column == column) {
				sortIndex = i;
				let col: DataTableColumnModel = this.getColumn(sort.column);

				if (col) {
					col.sortDir = "";
				}

				break;
			}
		}

		if (sortIndex > -1) {
			this.sorting.splice(sortIndex, 1);
			this.updatePageView();
		}
	}

	private updatePageView() {
		this.origData.sort(this.getSortMethod(this.sorting));
		this.page(this.origData);
	}

	private updatePaging(resetPaging: boolean) {
		if (this.sFilter.length > 0) {
			this.filter(this.sFilter, "");
		} else {
			this.resetPaging = resetPaging;
			this.page(this.origData);
		}
	}

	private page(d: any[]) {
		this.records = d.length;
		let start = this.pageLength * this.pageNumber;

		this.data = d.slice(start, start + this.pageLength);
		this.PageDataChanged.emit(true);

		let self = this;
		// this.timerId = setInterval(function () {
		self.PageChanged.emit({ records: self.records, resetPaging: self.resetPaging });
		self.resetPaging = false;
		if (self.PageChanged.observers.length > 0) clearInterval(self.timerId);

		// }, 1000);
	}

	sort(column: string) {
		let col: DataTableColumnModel = this.getColumn(column);

		if (!col || !col.allowSort) return;

		let sorting: SortModel = this.getSort(column);

		if (sorting.direction === "asc") {
			sorting.direction = "dsc";
			col.sortDir = "dsc";
		} else if (sorting.direction === "dsc") {
			sorting.direction = "";
			col.sortDir = "";
		} else {
			sorting.direction = "asc";
			col.sortDir = "asc";
		}

		this.setSort(sorting);

		this.resetPaging = false;
		this.updatePageView();
	}

	getSortMethod(sorts: SortModel[]) {
		let _sorts = Array.prototype.slice.call(sorts);
		return function(a, b) {
			for (let i: number = 0; i < _sorts.length; i++) {
				let leftVal = a[_sorts[i].column];
				let rightVal = b[_sorts[i].column];
				let temp;

				if (leftVal instanceof Date && rightVal instanceof Date) {
					if (leftVal.getTime() === rightVal.getTime()) continue;

					if (_sorts[i].direction === "dsc") {
						temp = leftVal;
						leftVal = rightVal;
						rightVal = temp;
					}

					return leftVal < rightVal ? -1 : 1;
				} else {
					leftVal = typeof leftVal == "string" ? leftVal.toLowerCase() : leftVal / 1;
					rightVal = typeof rightVal == "string" ? rightVal.toLowerCase() : rightVal / 1;

					if (_sorts[i].direction === "dsc") {
						temp = leftVal;
						leftVal = rightVal;
						rightVal = temp;
					}
					if (leftVal != rightVal) {
						return leftVal < rightVal ? -1 : 1;
					}
				}
			}
		};
	}

	public filter(searchString: string, column: string) {
		this.sFilter = searchString;

		let filter = this.origData.filter(function(el, idx, arr) {
			if (!searchString || searchString.trim().length === 0 || searchString === "showall") return true;

			if (column) {
				return String(el[column]).toLowerCase() == searchString.toLowerCase();
			}

			for (let prop in el) {
				if (
					String(el[prop])
						.toLowerCase()
						.indexOf(searchString.toLowerCase()) >= 0
				) {
					return true;
				}
			}
		});

		this.resetPaging = false;
		this.page(filter);
	}

	private getSort(column: string): SortModel {
		for (let i = 0; i < this.sorting.length; i++) {
			if (this.sorting[i].column === column) {
				return this.sorting[i];
			}
		}

		let sort: SortModel = new SortModel();

		sort.column = column;
		sort.direction = "";
		return sort;
	}

	private getColumn(column: string): DataTableColumnModel {
		for (let i = 0; i < this.columns.length; i++) {
			if (this.columns[i].name === column) {
				return this.columns[i];
			}
		}

		return null;
	}

	// private setSort(column: string, direction: string) {
	//   for (let i = 0; i < this.headers.length; i++) {
	//     if (this.headers[i].column === column) {
	//       this.headers[i].sort = direction;
	//     } else {
	//       this.headers[i].sort = "";
	//     }
	//   }
	//
	//   this.headers.push({column: column, sort: direction});
	// }

	private setSort(sort: SortModel) {
		let hasColumn: boolean = false;
		let index: number = -1;
		for (let i = 0; i < this.sorting.length; i++) {
			if (this.sorting[i].column === sort.column) {
				hasColumn = true;
				index = i;
				this.sorting[i].direction = sort.direction;
			}
		}

		if (sort.direction) {
			if (!hasColumn) {
				if (this.sorting.length < this.maxSort) {
					this.sorting.push({ column: sort.column, direction: sort.direction });
				} else {
					let removed = this.sorting.shift();
					let col: DataTableColumnModel = this.getColumn(removed.column);

					if (col) {
						col.sortDir = "";
					}

					this.sorting.push({ column: sort.column, direction: sort.direction });
				}
			}
		} else if (hasColumn) {
			this.sorting.splice(index, 1);
		}
	}

	getFilters(filter) {}
}
