export class Pharmacy {
	id: number;
	frameworkPharmId: string;
	serverInstance: string;
	ncpdpNumber: string;
	deaNumber: string;
	pharmacyName: string;
	street1: string;
	street2: string;
	city: string;
	state: string;
	zip: string;
	phone: string;
	fax: string;
	npi: number;
	isEnabled: boolean;
	refillThreshold: number;
	fusionId?: number;

	deserialize(obj: any): Pharmacy {
		return Object.assign(this, obj);
	}
}
