import { DosespotError } from "./dosespot-error.model";

export class PropertyError {
	propertyName: string;
	errors: DosespotError[] = [];

	deserialize(input: any): PropertyError {
		this.propertyName = input.PropertyName;
		this.errors = input.Errors.map((error: any) => new DosespotError().deserialize(error));
		return this;
	}
}
