import {Component, Input} from "@angular/core";
import {MessageItemModel} from "../../models/message-item.model";
import {StatusMessageService} from "../status-message/status-message.service";
import {MessagesService} from "../../services/messages.service";

@Component({
  selector: "message",
  templateUrl: "./message.template.html",
  styleUrls: ["./message.scss"]
})

export class MessageComponent {
  @Input() message: MessageItemModel;

  showMore: boolean = false;

  constructor(private _statusMessageService: StatusMessageService, private _messagesService: MessagesService) {
  }

  readMessage() {
    this._messagesService.markMessagesAsRead([this.message.UserMessageId]).subscribe((result: any) => {
      if (result.Success) {
        this.message.IsRead = true;
        this._messagesService.refreshUnreadMessagesCount();
      } else {
        this._statusMessageService.changeStatusMessage('error', result.PublicMessage);
      }
    });
  }
}
