import { Directive, ElementRef, OnInit, ViewContainerRef } from "@angular/core";
import { MobileService } from "../services/mobile.service";
@Directive({
    selector: '[hideOnMobile]'
})
export class HideOnMobileDirective implements OnInit {

    constructor(private viewContainer: ViewContainerRef, private _service: MobileService, private el: ElementRef) {

    }

    ngOnInit(): void {
        if (this._service.isMobile()) {
            this.viewContainer.clear();
            this.el.nativeElement.style.display = "none";
        }
    }
}