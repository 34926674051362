export class CustomerTeamModel {
	CustomerTeamId: number;
	TeamName: string;
	Code: string;
	IsActive: boolean;
	IsDefault: boolean;
	PatientCount: number;
	Default: boolean;

	constructor(obj?: any) {
		Object.assign(this, obj);
	}
}
