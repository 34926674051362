import {Component, Input, Output, EventEmitter, ViewChild, OnInit} from '@angular/core';
import {CustomerSummaryModel} from "../../models/customer-summary.model";
import {CustomerService} from "../../services/customer.service";
import {StatusMessageService} from "../status-message/status-message.service";
import {ApiResult} from "../../models/api-result.model";
import {ModalComponent} from '../modal/modal.component';

@Component({
  selector: 'partner-summary',
  templateUrl: './partner-summary.template.html',
  styleUrls: ['./partner-summary.scss']
})

export class PartnerSummary {
  @Input() partner: CustomerSummaryModel;
  @Input() menuEnabled:boolean = false;

  @Output() openEditPartner:EventEmitter<number> = new EventEmitter<number>();
  @Output() openCustomerProfile:EventEmitter<number> = new EventEmitter<number>();
  @Output() customerStatusUpdated: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('confirmUpdateStatus') confirmUpdateStatus: ModalComponent;

  constructor(private _statusMessageService: StatusMessageService, private _customerService: CustomerService) {
  }

  updateStatus(isActive: boolean){
    this._customerService.updateCustomerStatus(this.partner.CustomerId, isActive).subscribe((result: ApiResult)=>{
      if(result.Success) {
        this._statusMessageService.changeStatusMessage('success', this.partner.Name + ' has been '
          + (isActive? 'activated.': 'deactivated.'));
        this.customerStatusUpdated.emit();
      } else {
        this._statusMessageService.changeStatusMessage('error', result.PublicMessage);
      }
    });
    this.confirmUpdateStatus.dismiss();
  }

  confirmUpdateCustomerStatus() {
    this.confirmUpdateStatus.open('xs');
  }
}
