import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from "@angular/core";
import { CustomerAuthenticationModel } from "../../models/customer-authentication.model";
import { FormCustomerAuthenticationComponent } from "../form-customer-authentication/form-customer-authentication.component";
import { StatusMessageService } from "../status-message/status-message.service";
import { UserService } from "../../services/user.service";
import { RoleModel } from "../../models/role.model";
import * as _ from "lodash";
import { SamlService } from "../../services/saml.service";

@Component({
	selector: "customer-authentication",
	templateUrl: "customer-authentication.template.html",
	styleUrls: ["customer-authentication.scss"]
})
export class CustomerAuthenticationComponent implements OnInit {
	editMode: string;
	startingStep: number;
	userRoles: any[] = [];
	@Input() customerAuth: CustomerAuthenticationModel;
	@Output() customerAuthUpdated: EventEmitter<CustomerAuthenticationModel> = new EventEmitter<CustomerAuthenticationModel>();

	@ViewChild("editAuthModal") editAuthModal: FormCustomerAuthenticationComponent;

	get authenticationType() {
		if (this.customerAuth.type === "default") {
			return "Default";
		} else if (this.customerAuth.type === "saml") {
			return "SAML";
		} else if (this.customerAuth.type === "twoFactor") {
			return "Two-Factor";
		}
	}

	constructor(private _statusMessageService: StatusMessageService, private _samlService: SamlService) {}

	ngOnInit() {
		if (this.customerAuth && this.customerAuth.type === "saml") {
			this._samlService.getRoles(this.customerAuth.subdomain).subscribe((result: any) => {
				if (result) {
					this.userRoles = _.map(result, (role: RoleModel) => {
						return {
							id: role.roleId,
							text: role.roleName
						};
					});
				} else {
					this.userRoles = [];
				}
			});
		}
	}

	editAuthentication(authModel) {
		this.editMode = "allSettings";
		this.startingStep = 1;
		this.editAuthModal.showDialog(Object.assign({}, authModel));
	}

	editMappedClaims(authModel) {
		this.editMode = "mappingOnly";
		this.startingStep = 2;
		this.editAuthModal.showDialog(Object.assign({}, authModel), 2);
	}

	downloadUserList() {
		//
	}

	authEditSuccess(auth: CustomerAuthenticationModel) {
		this.customerAuthUpdated.emit(auth);
	}

	certUploadSuccess(thumbprint: string) {
		this.customerAuth.certPath = thumbprint;
	}

	getRoles(index: number): string {
		if (!this.userRoles || !this.userRoles.length || this.userRoles.length === 0) return;
		var result = _.filter(this.userRoles, r => {
			return _.some(this.customerAuth.roleClaims[index].roleIds, c => {
				return c === r.id;
			});
		});
		if (result.length === 0) return "";
		var rolesList = _.reduce(
			result.slice(1),
			(acc, r) => {
				return acc + ", " + r.text;
			},
			result[0].text
		);
		return rolesList;
	}
}
