import { ParameterValue } from "./parameter-value.model";
import { Subscriber } from "./subscriber.model";

export class Subscription {
	ReportSubscriptionId: string;
	SubscriptionName: string;
	ReportId: number;
	RenderType: string;
	FrequencyType: string;

	Frequency: string[] = [];

	EndOfMonthFlag: number;

	ParameterValues: ParameterValue[] = [];
	Subscribers: Subscriber[] = [];

	get FrequencyTypeString(): string {
		switch (this.FrequencyType) {
			case "D":
				return "Daily";
			case "W":
				let days: string[] = [];

				for (let i = 0; i < this.Frequency.length; i++) {
					let day: number = parseInt(this.Frequency[i]);
					switch (day) {
						case 0:
							days.push("Sun");
							break;
						case 1:
							days.push("Mon");
							break;
						case 2:
							days.push("Tue");
							break;
						case 3:
							days.push("Wed");
							break;
						case 4:
							days.push("Thu");
							break;
						case 5:
							days.push("Fri");
							break;
						case 6:
							days.push("Sat");
							break;
					}
				}

				return "Weekly (" + days.join(", ") + ")";
			case "M":
				let freq: string[] = this.Frequency;

				if (this.EndOfMonthFlag) {
					freq.push("End of Month");
				}

				return "Monthly (" + freq.join(", ") + ")";
			default:
				return "Unknown";
		}
	}

	deserialize(json: any): Subscription {
		let sub: Subscription = new Subscription();

		sub.ReportSubscriptionId = json.ReportSubscriptionId;
		sub.SubscriptionName = json.SubscriptionName;
		sub.ReportId = json.ReportId;
		sub.RenderType = json.RenderType;
		sub.FrequencyType = json.FrequencyType;
		sub.EndOfMonthFlag = json.EndOfMonthFlag;

		if (json.Frequency && json.Frequency.trim().length > 0) {
			sub.Frequency = json.Frequency.split(",").map((f: any) => f.toString());
		}

		if (json.Parameters && json.Parameters.length > 0) {
			sub.ParameterValues = json.Parameters.map((p: any) => new ParameterValue().deserialize(p));
		}

		if (json.Subscribers && json.Subscribers.length > 0) {
			sub.Subscribers = json.Subscribers.map((s: any) => new Subscriber().deserialize(s));
		}

		return sub;
	}
}
