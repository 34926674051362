import {Injectable} from "@angular/core";
import {HttpService} from "./http.service";

@Injectable()
export class AccountService {
  private _changePasswordUrl: string = 'api/account/changepassword';

  constructor(private _http: HttpService) {
  }

  changePassword(currentPassword: string, newPassword: string) {
    return this._http.post(this._changePasswordUrl, {
      CurrentPassword: currentPassword,
      NewPassword: newPassword
    });
  }
}
