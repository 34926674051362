/**
 * Created by dan on 2/1/2017.
 */
import { Component, Input, forwardRef, OnInit } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
	selector: "input-textarea",
	templateUrl: "./input-textarea.template.html",
	styleUrls: ["./input-textarea.scss"],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => InputTextareaComponent),
			multi: true
		}
	]
})
export class InputTextareaComponent implements ControlValueAccessor, OnInit {
	@Input() labelText: string;
	@Input() isRequired: string;
	@Input() isInvalid: boolean;
	@Input() rows: number;
	@Input() placeholderText: string;
	@Input() hintText: string;
	@Input() toolTipOn: boolean = false;
	@Input() toolTipLink: string;
	@Input() toolTipContent: string;
	@Input() invalidAnswer: boolean = false;
	@Input() maxLength: number;
	@Input() altMaxLength: number = null;
	@Input() showNumCharactersLeft: boolean = false;
	@Input() isDisabled: boolean;

	value: string;

	get altLimitExcteeded(): boolean {
		if (this.showNumCharactersLeft && this.altMaxLength) {
			let l: number = this.altMaxLength;
			let curLen = (this.value || "").length;
			return l < curLen;
		} else {
			return false;
		}
	}

	get numCharactersLeft(): number {
		if (this.showNumCharactersLeft && this.altMaxLength) {
			let l: number = this.altMaxLength;
			let curLen = (this.value || "").length;
			return l - curLen;
		} else {
			let l: number = 5000;
			if (this.maxLength) {
				l = this.maxLength;
			}

			let curLen = (this.value || "").length;

			return l - curLen;
		}
	}

	ngOnInit() {
		setTimeout(() => {
			if (this.isRequired && !this.value) {
				this.isInvalid = true;
			} else {
				this.isInvalid = false;
			}
		}, 100);
	}

	propagateChange = (_: any) => {};

	inputChanged(value: string) {
		this.value = value;
		if (this.isRequired && !this.value) {
			this.isInvalid = true;
		} else {
			this.isInvalid = false;
		}
		this.propagateChange(this.value || null);
	}

	writeValue(obj: any) {
		if (obj !== undefined) {
			this.value = obj;
		} else {
			this.value = "";
		}
	}

	registerOnChange(fn: any) {
		this.propagateChange = fn;
	}

	registerOnTouched(fn: any) {} //Not currently needed

	appendText(text: string) {
		this.inputChanged((this.value || "") + text);
	}
}
