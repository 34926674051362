import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { PatientService } from "../../../shared/services/patient.service";
import { DataTableModel } from "../../../shared/components/data-table/data-table.model";
import { SortModel } from "../../../shared/components/data-table/sort.model";
import { DataTableColumnModel } from "../../../shared/components/data-table/columns.model";
import { DomSanitizer } from "@angular/platform-browser";
import { MobileService } from "src/app/ocp/shared/services/mobile.service";

@Component({
	selector: "dispense-history",
	templateUrl: "./table-dispense-history.template.html"
})
export class DispenseHistoryComponent implements OnInit {
	loading: boolean = false;

	private _patientId: number;
	private _ndc: string;

	@Input()
	get patientId(): number {
		return this._patientId;
	}

	set patientId(value: number) {
		this._patientId = value;
		this.refreshHistory();
	}

	@Input()
	get ndc(): string {
		return this._ndc;
	}

	set ndc(value: string) {
		this._ndc = value;
		this.refreshHistory();
	}

	isMobile: boolean = false;

	dispenseHistoryItems: DataTableModel = new DataTableModel([], []);

	@Input() type: number; // 1 - patient, 2 - medication

	@Output() onError: EventEmitter<any> = new EventEmitter<any>();

	constructor(private _patientService: PatientService, private _sanitizer: DomSanitizer, private _mobile: MobileService) {
		this.isMobile = this._mobile.isMobile();
	}

	ngOnInit() {
		let defaultSorting: SortModel[] = [];

		defaultSorting.push({
			column: "DispenseDate",
			direction: "dsc"
		});

		if (this.type === 1) {
			defaultSorting.push({
				column: "DrugName",
				direction: "asc"
			});
		}

		let columns: DataTableColumnModel[] = [];
		columns.push(new DataTableColumnModel("RxNumber", "Rx #", "", true));
		if (this.type === 1) {
			columns.push(new DataTableColumnModel("DrugName", "Drug", "", true));
		}
		columns.push(new DataTableColumnModel("DispenseDate", "Dispensed Date", "", true));
		columns.push(new DataTableColumnModel("Quantity", "Quantity Dispensed", "", true));
		columns.push(new DataTableColumnModel("Prescriber", "Prescriber", "", true));
		columns.push(new DataTableColumnModel("StatusMessage", "Status Message", "", true));

		this.dispenseHistoryItems = new DataTableModel(defaultSorting, columns);
		this.dispenseHistoryItems.populate([]);
		this.refreshHistory();
	}

	refreshHistory() {
		this.loading = true;
		const replacementText: string = "onclick=\"window.open(this.href, '_system', 'location=yes'); return false;\" href=\"";

		if (this.type === 1 && this.patientId) {
			this._patientService.getPatientDispenseHistory(this.patientId).subscribe((results: any) => {
				this.loading = false;
				if (this.isMobile) {
					results.forEach((row: any) => {
						let fixedMessage: string = row.StatusMessage.replace(/href=\"/g, replacementText);
						row.StatusMessage = this._sanitizer.bypassSecurityTrustHtml(fixedMessage);
					});
				}
				this.dispenseHistoryItems.populate(results);
			});
		} else if (this.type === 2 && this.patientId && this.ndc) {
			this._patientService.getMedicationDispenseHistory(this.patientId, this.ndc).subscribe((results: any) => {
				this.loading = false;
				if (this.isMobile) {
					results.forEach((row: any) => {
						let fixedMessage: string = row.StatusMessage.replace(/href=\"/g, replacementText);
						row.StatusMessage = this._sanitizer.bypassSecurityTrustHtml(fixedMessage);
					});
				}
				this.dispenseHistoryItems.populate(results);
			});
		} else {
			this.loading = false;
			this.dispenseHistoryItems.populate([]);
		}
	}
}
