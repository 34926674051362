export class DashboardMetricModel {
	id: number; // 1 - current, 2 - past
	label: string;
	value: number;
	change: number;
	start: Date;
	end: Date;
}

export class DashboardGroupModel {
	name: string;
	id: number;
	metrics: DashboardMetricModel[] = [];

	calculateChange(): number {
		if (!this.metrics || this.metrics.length < 2) return 0;

		let current: number = 0;
		let pastAverage: number = 0;
		let idx = -1;

		for (var i = 0; i < 2; i++) {
			let metric = this.metrics[i];

			if (metric.id === 1) {
				current = metric.value;
				idx = i;
			} else {
				pastAverage = metric.value;
			}
		}

		let change = ((current - pastAverage) / Math.abs(pastAverage)) * 100;
		change = Math.round(change * 100) / 100;

		this.metrics[idx].change = change;

		return change;
	}
}

export class DashboardModel {
	groups: DashboardGroupModel[] = [];
	averagePatientDays: number;
}

export class DashboardDetailModel {
	rows: DashboardDetailRow[];
	group: string;
	start: Date;
	end: Date;
}

export class DashboardDetailRow {
	dateFilled: Date;
	drugLabel: string;
	memberId: string;
	memberFirstName: string;
	memberLastName: string;
	prescriberFirstName: string;
	prescriberLastName: string;
	quantity: number;
	amountPaid: number;
	deaClass: string;
	facility?: string;

	get memberName(): string {
		return this.memberFirstName + " " + this.memberLastName;
	}

	get prescriberName(): string {
		return this.prescriberFirstName + " " + this.prescriberLastName;
	}
}
