import {Component, Input, OnInit} from '@angular/core';
import {PatientInfoModel} from "../../../shared/models/patient.model";
import {UserService} from "../../../shared/services/user.service";
import {CustomerProfileModel} from "../../../shared/models/customer-profile.model";

@Component({
  selector: 'patient-profile-tab',
  templateUrl: './patient-profile-tab.template.html',
  styleUrls: ['./patient-details-tabs.scss']
})

export class PatientProfileTab implements OnInit {
  isInternalUser: boolean = false;
  ssnRequired: boolean = false;
  hasTeams: boolean = false;

  @Input() patient: PatientInfoModel = new PatientInfoModel();


  constructor(private _userService: UserService) {

  }

  ngOnInit() {
    this._userService.isSsnRequired.subscribe((isRequired: boolean) => {
      this.ssnRequired = isRequired;
    });

    this._userService.selectedCustomer.subscribe((customer: CustomerProfileModel) => {
      if (customer) {
        this.hasTeams = customer.HasTeams;
      } else {
        this.hasTeams = false;
      }
    });

    this.isInternalUser = this._userService.isInternalUser();
  }

  deriveHeight(heightIn?: number): string {
    if (!heightIn) return "";

    let numFt = Math.floor(heightIn / 12);
    let numIn = heightIn % 12;

    return `${numFt}ft ${numIn}in (${heightIn}in)`
  }

  convertWeightLbsToKg(weightLbs?: number): string {
    return weightLbs ? (weightLbs / 2.20462).toFixed(1) + "" : "";
  }

  convertHeighInToCm(heightIn?: number): string {
    return heightIn ? Math.round(heightIn * 2.54) + "": "";
  }
}
