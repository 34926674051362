import {Component, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'partner-tab',
  templateUrl: './partner.template.html',
  styleUrls: ['./partner-profile-tabs.scss']
})

export class PartnerTab {
  @Output() backBtnTriggered: EventEmitter<any> = new EventEmitter<any>();

  goBack() {
    this.backBtnTriggered.emit();
  }
}
