import {NgModule} from "@angular/core";

import {SharedModule} from "../shared/shared.module";
import {PartnerProfile} from "./partner-profile.component";
import {PartnerProfileRouting} from "./partner-profile.routes";
import {OrganizationTab} from "./tabs/organization.component";
import {PartnerTab} from "./tabs/partner.component";
import {TeamsTab} from "./tabs/teams.component";
import {UsersTab} from "./tabs/users.component";
import {RolesTab} from "./tabs/roles.component";
import {ReactiveFormsModule} from "@angular/forms";

@NgModule({
  imports: [
    SharedModule,
    ReactiveFormsModule,
    PartnerProfileRouting
  ],
  declarations: [
    PartnerProfile,
    OrganizationTab,
    PartnerTab,
    TeamsTab,
    UsersTab,
    RolesTab
  ],
  providers: [],
  exports: [
    PartnerProfile,
    OrganizationTab,
    PartnerTab,
    TeamsTab,
    UsersTab,
    RolesTab
  ]
})
export class PartnerProfileModule {
}
