import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "localDate" })
export class LocalDatePipe implements PipeTransform {
	transform(value: string | Date, dateOnly?: boolean): string {
		let dateValue: Date = new Date();

		if (typeof value === "string") {
			dateValue = new Date(value);
		} else if (value instanceof Date) {
			dateValue = value;
		}

		const localTime = new Date(dateValue.getTime() - dateValue.getTimezoneOffset() * 60000);
		const usedLocale = "en-US";

		if (dateOnly) {
			return `${localTime.toLocaleDateString(usedLocale)}`;
		}

		return `${localTime.toLocaleDateString(usedLocale)} ${localTime.toLocaleTimeString(usedLocale, {
			hour: "numeric",
			minute: "numeric",
			timeZoneName: "short"
		})}`;
	}
}
