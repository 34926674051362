
import {takeUntil} from 'rxjs/operators';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {PlanOfTreatmentModel} from "../../../shared/models/plan-of-treatment.model";
import {PatientService} from "../../../shared/services/patient.service";
import {MedicationProfileModel} from "../../../shared/models/medication-profile.model";
import {DrugGroupSummaryModel} from "../../../shared/models/drug-group-summary.model";
import {PatientCareStore} from "../../services/patient-care.store";
import {PatientInfoModel} from "../../../shared/models/patient.model";
import {ActivatedRoute, Router} from "@angular/router";
import {Subject} from "rxjs";

@Component({
  selector: 'plan-of-treatment',
  templateUrl: "./plan-of-treatment.template.html",
  styleUrls: ["./plan-of-treatment.scss"]
})

export class PlanOfTreatmentComponent implements OnInit, OnDestroy {
  tabContent: string = "medications";

  planOfTreatment: PlanOfTreatmentModel;

  medicationProfileRoutine: MedicationProfileModel[] = [];
  medicationProfilePrn: MedicationProfileModel[] = [];

  labNotes: DrugGroupSummaryModel[] = [];

  patientId: number;
  firstName: string;
  lastName: string;

  private destroyed:Subject<any> = new Subject<any>();

  constructor(
      private _patientService: PatientService,
      private _store:PatientCareStore,
      private _route:ActivatedRoute,
      private _router:Router) {
  }

  ngOnInit() {
    this._store.Patient.pipe(takeUntil(this.destroyed)).subscribe((patient:PatientInfoModel) => {
      if(patient.patientId){
        this.patientId = patient.patientId;
        this.firstName = patient.firstName;
        this.lastName = patient.lastName;

        this.init();
      }
    });
  }

  ngOnDestroy(){
      this.destroyed.next();
      this.destroyed.unsubscribe();
  }

  private init(){
      this._patientService.getPlanOfTreatmentForPatientUrl(this.patientId).subscribe((planOfTreatment: PlanOfTreatmentModel) => {
          this.planOfTreatment = planOfTreatment;

          this.medicationProfileRoutine = this.planOfTreatment.MedicationProfile.filter((model: MedicationProfileModel) => {
              return model.RxType === 'Routine';
          });

          this.medicationProfilePrn = this.planOfTreatment.MedicationProfile.filter((model: MedicationProfileModel) => {
              return model.RxType === 'PRN'
          });

          this.labNotes = this.planOfTreatment.DrugGroupSummary.filter((model: DrugGroupSummaryModel) => {
              return model.Category > 0;
          });
      })
  }

  loadMedicationsTab() {
    this.tabContent = "medications";
  }

  loadTherapeuticTab() {
    this.tabContent = "therapeuticDup";
  }

  loadConsultTab() {
    this.tabContent = "consultNotes";
  }

  loadLabTab() {
    this.tabContent = "labNotes";
  }

  closePage() {
    this._router.navigate(["../detail"], {relativeTo:this._route});
  }
}
