import { Component, Input, Output, EventEmitter, ViewChild, AfterViewInit, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { PatientInfoModel } from "../../../shared/models/patient.model";
import { DiagnosisSearchService } from "./diagnosis-search.service";
import { ISearchService } from "../../../shared/components/patient-search/search.interface";
import { DiagnosisSearchResultModel } from "../../../shared/models/diagnosis-search-results.model";
import { DiagnosisModel } from "../../../shared/models/diagnosis.model";
import { UserService } from "../../../shared/services/user.service";
import { StatusMessageService } from "../../../shared/components/status-message/status-message.service";
import { PatientService } from "../../../shared/services/patient.service";
import { ApiResult } from "../../../shared/models/api-result.model";
import * as moment from "moment";
import { AdvancedSearch } from "src/app/ocp/shared/components/advanced-search/advanced-search.component";
import * as _ from "lodash";
import { ApplicationService } from "src/app/ocp/shared/services/application.service";

@Component({
	selector: "diagnosis-details",
	templateUrl: "./form-patient-diagnosis.template.html",
	providers: [{ provide: ISearchService, useClass: DiagnosisSearchService }],
	styleUrls: ["./form-patient-diagnosis.scss"]
})
export class FormPatientDiagnosis implements OnInit, AfterViewInit {
	saving: boolean = false;
	currentStep: number = 3;
	focusSearch: EventEmitter<any> = new EventEmitter<any>();

	selectedDiagnosis: DiagnosisSearchResultModel;
	dateDiagnosed: string = moment(new Date()).format("YYYY-MM-DD");
	dateResolved: string;
	isContributing: boolean = false;
	isOngoing: boolean = true;
	diagnosisListChanged: boolean = false;
	formChanged: boolean = false;
	isEditing: boolean = false;
	editingDiagnosis: DiagnosisModel;
	allowMultipleFormularies: boolean = false;
	mrnRequired: boolean = false;
	diseaseStates: string[] = [];
	icd: string;
	diseaseState: string;
	tableHeaderLabels: string[] = ["ICD", "Name", "Disease State"];

	searchDisplayTextFn: Function = (diagnosis: DiagnosisSearchResultModel) => {
		return diagnosis.IcdCode + " - " + diagnosis.IcdName;
	};

	@Input() mode: string;
	@Input() patient: PatientInfoModel = new PatientInfoModel();
	@Input() diagnosisToManage: DiagnosisModel[] = [];

	@Output() goBack: EventEmitter<string> = new EventEmitter<string>();
	@Output() closeModal: EventEmitter<string> = new EventEmitter<string>();
	@Output() goToStepFour: EventEmitter<string> = new EventEmitter<string>();
	@Output() saveSuccess: EventEmitter<string> = new EventEmitter<string>();
	@Output() onUnsavedChanges: EventEmitter<string> = new EventEmitter<string>();

	@ViewChild("diagnosisSearch") diagnosisSearch: AdvancedSearch;

	@ViewChild("form") diagnosisForm: NgForm;

	get filtering(): boolean {
		return this.numFiltersApplied > 0;
	}

	get numFiltersApplied(): number {
		return [this.icd, this.diseaseState].reduce((acc, val) => {
			return val && val.length > 0 ? ++acc : acc;
		}, 0);
	}

	get needsMrn(): boolean {
		return this.mrnRequired && this.patient.patientStatus !== 0 && !this.patient.Mrn && this.mode !== "Edit";
	}

	get hasRelatedDiagnosis() {
		return this.diagnosisList().filter((diag: DiagnosisModel) => {
			return diag.IsRelated;
		}).length;
	}

	get invalidFormulary(): boolean {
		if (this.allowMultipleFormularies || !this.dateDiagnosed || (!this.isEditing && !this.selectedDiagnosis)) {
			return false;
		}

		let diagnosedDate = new Date(this.dateDiagnosed);

		let resolvedDate = this.dateResolved ? new Date(this.dateResolved) : null;

		if (this.isContributing) {
			let currentIcdCodeId: number = 0;
			let isInvalid: boolean = false;
			if (this.editingDiagnosis) {
				currentIcdCodeId = this.editingDiagnosis.IcdCodeId;
			} else {
				currentIcdCodeId = this.selectedDiagnosis.IcdCodeId;
			}

			this.diagnosisList().forEach(item => {
				if (isInvalid) {
					// don't bother checking
					return;
				}
				let result: boolean = this.checkIfDiagnosisOverlaps(item, currentIcdCodeId, diagnosedDate, resolvedDate);
				if (result) {
					isInvalid = true;
				}
			});

			return isInvalid;
		}

		return false;
	}

	checkIfDiagnosisOverlaps(item: any, currentIcdCodeId: number, diagnosedDate: any, resolvedDate: any) {
		if ((currentIcdCodeId && item.IcdCodeId === currentIcdCodeId) || !item.IsRelated) {
			// Ignore dx if we are currently editing it or it is not is not related to terminal DX
			return false;
		}

		if (new Date(item.EffectiveDate) <= diagnosedDate) {
			// current diagnosis has a diagnosed date after a related to terminal diagnosis.
			// check related end dates

			if (!item.ResolvedDate || diagnosedDate <= new Date(item.ResolvedDate)) {
				// related terminal diagnosis is ongoing or diagnosed date is within the range
				// if a current terminal diagnosis, this is invalid..
				return true;
			}
		} else if (!resolvedDate || resolvedDate >= new Date(item.EffectiveDate)) {
			return true;
		}

		return false;
	}

	constructor(private _userService: UserService, private _patientService: PatientService, private _statusMessageService: StatusMessageService, public appService: ApplicationService) {}

	ngOnInit() {
		if (this.mode !== "Edit" && this.patient.patientStatus !== 0) {
			if (this.patient.diagnosis) {
				this.patient.diagnosis.forEach((diagnosis: DiagnosisModel) => {
					if (moment(diagnosis.EffectiveDate) < moment(this.patient.dateAdmitted)) {
						diagnosis.EffectiveDate = this.patient.dateAdmitted;

						if (diagnosis.ResolvedDate && moment(diagnosis.ResolvedDate) < moment(diagnosis.EffectiveDate)) {
							diagnosis.ResolvedDate = diagnosis.EffectiveDate;
						}
					}
				});
			}
		}
		this._userService.allowMultipleFormularies.subscribe((allowMultipleFormularies: boolean) => {
			this.allowMultipleFormularies = allowMultipleFormularies;
		});
		this._userService.isMrnRequired.subscribe((isRequired: boolean) => {
			this.mrnRequired = isRequired;
		});
	}

	ngAfterViewInit() {
		if (this.mode !== "Edit") {
			this.focusSearch.emit();
		}

		setTimeout(() => {
			this.diagnosisForm.form.markAsPristine();
		}, 100);
	}

	filterSearch() {
		this.diagnosisSearch.filteredAutoCompleteResults = _.filter(this.diagnosisSearch.autocompleteResults, r => {
			return (
				(!this.icd || (r.IcdCode && r.IcdCode.toLowerCase().indexOf(this.icd.toLowerCase()) !== -1)) &&
				(!this.diseaseState || (r.DiseaseStateName && r.DiseaseStateName.toLowerCase().indexOf(this.diseaseState.toLowerCase()) !== -1))
			);
		});

		this.diseaseStates = _.reduce(
			_.map(this.diagnosisSearch.autocompleteResults, r => r.DiseaseStateName),
			(acc: string[], s: string) => {
				if (acc.indexOf(s) === -1) {
					acc.push(s);
				}
				return acc;
			},
			[]
		);
	}

	clearFilters() {
		this.icd = this.diseaseState = null;
		this.filterSearch();
	}

	loadSelectedItem(selectedItem: DiagnosisSearchResultModel) {
		this.diagnosisForm.form.markAsDirty();
		let list = this.diagnosisList();

		if (
			!list.find(x => {
				return x.IcdCodeId === selectedItem.IcdCodeId;
			})
		) {
			this.selectedDiagnosis = selectedItem;
		} else {
			this._statusMessageService.changeStatusMessage("error", '"' + selectedItem.IcdCode + " - " + selectedItem.IcdName + '" has already been added.');
			this.selectedDiagnosis = null;
		}
	}

	diagnosisList() {
		if (this.mode !== "Edit") {
			if (this.patient.patientStatus !== 0) {
				return this.patient.diagnosis.filter((diagnosis: DiagnosisModel) => {
					return !diagnosis.ResolvedDate || moment(diagnosis.ResolvedDate) > moment(this.patient.dateAdmitted);
				});
			} else {
				return this.patient.diagnosis;
			}
		} else {
			return this.diagnosisToManage;
		}
	}

	canSaveDiagnosis() {
		return (this.isEditing || this.selectedDiagnosis) && this.dateDiagnosed && (this.isOngoing || this.dateResolved) && !this.invalidFormulary;
	}

	resolvedOngoingChanged(onGoing: boolean) {
		this.isOngoing = onGoing;
		if (this.isOngoing) {
			this.dateResolved = "";
		} else {
			this.dateResolved = moment(new Date()).format("YYYY-MM-DD");
		}
	}

	diagnosisChanged() {
		this.diagnosisListChanged = true;
	}

	diagnosisSearchCleared() {
		if (this.selectedDiagnosis) {
			this.selectedDiagnosis = null;
		}
		this.clearFilters();
	}

	dateDiagnosedChanged() {
		if (this.dateDiagnosed && this.dateResolved) {
			let dateDiagnosed = new Date(this.dateDiagnosed);
			let dateResolved = new Date(this.dateResolved);

			if (dateDiagnosed.getTime() - dateResolved.getTime() >= 0) {
				// admit date is after discharge date
				this.dateResolved = this.dateDiagnosed;
			}
		}
	}

	addDiagnosis() {
		if (this.selectedDiagnosis) {
			let diagnosis = new DiagnosisModel();
			diagnosis.IcdCodeId = this.selectedDiagnosis.IcdCodeId;
			diagnosis.IcdCode = this.selectedDiagnosis.IcdCode;
			diagnosis.IcdName = this.selectedDiagnosis.IcdName;
			diagnosis.EffectiveDate = this.dateDiagnosed;
			diagnosis.IsActive = true;
			if (!this.isOngoing) {
				diagnosis.ResolvedDate = this.dateResolved;
			} else {
				diagnosis.ResolvedDate = null;
			}
			diagnosis.IsRelated = this.isContributing;

			let list = this.diagnosisList();
			if (
				!list.find(x => {
					return x.IcdCodeId === diagnosis.IcdCodeId;
				})
			) {
				if (this.mode !== "Edit") {
					this.patient.diagnosis.push(diagnosis);
				} else {
					this.diagnosisToManage.push(diagnosis);
				}
				this.diagnosisListChanged = true;
				this.cancelEdit();
			} else {
				this._statusMessageService.changeStatusMessage("error", '"' + diagnosis.IcdCode + " - " + diagnosis.IcdName + '" has already been added.');
			}
		}
	}

	removeDiagnosis(diagnosis: any) {
		this.diagnosisListChanged = true;
		if (this.mode !== "Edit") {
			this.patient.diagnosis.splice(this.patient.diagnosis.indexOf(diagnosis), 1);
		} else {
			this.diagnosisToManage.splice(this.diagnosisToManage.indexOf(diagnosis), 1);
		}
	}

	editDiagnosis(diagnosis: DiagnosisModel) {
		this.selectedDiagnosis = null;
		this.dateDiagnosed = diagnosis.EffectiveDate;
		this.dateResolved = diagnosis.ResolvedDate;
		this.isContributing = diagnosis.IsRelated;
		this.isOngoing = !diagnosis.ResolvedDate;
		this.diagnosisSearch.searchText.setValue(diagnosis.IcdCode + " - " + diagnosis.IcdName, { emitEvent: false });
		this.isEditing = true;
		this.editingDiagnosis = diagnosis;
	}

	cancelEdit() {
		this.isEditing = false;
		this.reset(true);
	}

	saveDiagnosisEdit() {
		this.editingDiagnosis.EffectiveDate = this.dateDiagnosed;
		if (!this.isOngoing) {
			this.editingDiagnosis.ResolvedDate = this.dateResolved;
		} else {
			this.editingDiagnosis.ResolvedDate = null;
		}
		this.editingDiagnosis.IsRelated = this.isContributing;

		this.isEditing = false;
		this.diagnosisListChanged = true;
		this.reset(true);
	}

	reset(bypassListChange: boolean) {
		this.editingDiagnosis = null;
		this.dateDiagnosed = moment(new Date()).format("YYYY-MM-DD");
		this.dateResolved = null;
		this.isContributing = false;
		this.isOngoing = true;
		this.selectedDiagnosis = null;
		if (!bypassListChange) {
			this.diagnosisListChanged = false;
		}
		this.diagnosisSearch.clearSearch();
	}

	cancel() {
		this.diagnosisForm.form.markAsPristine();
		this.reset(false);
		this.closeModal.emit();
	}

	saveDiagnoses() {
		this.saving = true;
		this._patientService.updateDiagnosisList(this.patient.patientId, this.diagnosisToManage).subscribe((result: ApiResult) => {
			if (result.Success) {
				this.reset(false);
				this.diagnosisForm.form.markAsPristine();
				this.saving = false;
				this._statusMessageService.changeStatusMessage("success", "Patient diagnosis updated successfully.");
				this.saveSuccess.emit();
			} else {
				this.saving = false;
				this._statusMessageService.changeStatusMessage("error", result.PublicMessage);
			}
		});
	}

	allowSave() {
		return this.diagnosisListChanged && !this.isEditing && !this.selectedDiagnosis;
	}

	checkIfUnsavedChanges() {
		if (this.diagnosisForm.form.dirty || this.diagnosisListChanged || this.selectedDiagnosis) {
			this.onUnsavedChanges.emit();
		} else {
			this.cancel();
		}
	}
}
