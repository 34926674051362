import { MappedClaimModel } from "./mapped-claim.model";

export class CustomerAuthenticationModel {
	type: string;
	providerId: number;
	endpoint: string;
	endpointId: string;
	certPath: string;
	subdomain: string;
	notes: string;
	isEnabled: boolean;
	roleClaims: MappedClaimModel[];
	pharmacyClaims: any[];
	organizationId: number;
	customerId: number;
	userListFileName: string;
	scimAuthToken: string;

	constructor(obj: any) {
		this.roleClaims = [];
		this.pharmacyClaims = [];
		Object.assign(this, obj);
	}
}
