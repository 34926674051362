import { Injectable } from "@angular/core";
import { ISearchService } from "../components/patient-search/search.interface";
import { RxService } from "./rx.service";
import { SearchMedicationsModel } from "../models/search-medications.model";

@Injectable()
export class RxMedicationsSearchService implements ISearchService {
	constructor(private _rxService: RxService) {}

	search(params: string[]) {
		return this._rxService.searchMedications(
			new SearchMedicationsModel({
				drugName: params[0],
				strength: params[1],
				dosageForm: params[2],
				ndc: params[3]
			})
		);
	}
}
