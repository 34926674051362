import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'organization-profile-section',
  templateUrl: './organization-profile.component.html',
  styleUrls: ['./organization-profile.component.scss']
})
export class OrganizationProfileSectionComponent implements OnInit {
  constructor() { }

  ngOnInit(): void {
  }

}
