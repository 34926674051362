import { Injectable } from "@angular/core";
import { HttpService } from "./http.service";
import { RxOrderModel } from "../models/rx-order.model";
import { PatientMedicationModel } from "../models/patient-medication.model";
import { RxDeliveryModel } from "../models/rx-delivery.model";
import { ApiResult } from "../models/api-result.model";
import { RxOrderSuccessModel } from "../models/rx-order-success.model";
import { PriorAuthRequest } from "../models/prior-auth-request.model";
import { DomSanitizer } from "@angular/platform-browser";
import { SearchMedicationsModel } from "../models/search-medications.model";
import { StoreObject } from "../models/store-object.model";
import { PreFormattedERxOption } from "../models/preformatted-erx-option.model";
import { DispenseUnitType } from "../models/dispense-unit-type.model";
import { DeliveryTimeframe } from "../../patient-care/models/delivery-timeframe.model";

@Injectable()
export class RxService {
	IsVerified: boolean = true;
	Deliver: string;
	DeliveryNotes: string;

	private _addRxOrderUrl: string = "api/rx/addrxorder";
	private _searchMedicationsUrl: string = "api/rx/searchmedications?searchTerm=";
	private _searchPrescribersUrl: string = "api/rx/searchprescribers?searchTerm=";
	private _getDeliveryStatusListUrl: string = "api/rx/getdeliverystatuslist";
	private _getDoseSpotUrl: string = "api/rx/getdosespoturl?patientId=";
	private _getDosespotRefillsUrl: string = "api/rx/getdosespotrefillsurl";
	private _authorizeMedicationUrl: string = "api/rx/authorizemedication";
	private _denyMedicationUrl: string = "api/rx/denymedication";
	private _getPendingDoseSpotItemsForUserUrl: string = "api/rx/getpendingdosespotitemsforuser";
	private _verifyCoverageUrl: string = "api/rx/verifycoverage";
	private _screenAllergiesUrl: string = "api/rx/screenallergies";
	private _screenDrugInteractionsUrl: string = "api/rx/screendruginteractions";
	private _saveAsOppositeCoverageUrl: string = "api/rx/saveasoppositecoverage";
	private _requestPriorAuthUrl: string = "api/rx/AddPriorAuthRequest";
	private _sendToDoseSpotUrl: string = "api/rx/AddRxToDoseSpot";
	private _getDoseSpotNotificationCountInfoUrl = "api/rx/getdosespotusernotificationcountinfo";
	private _getUrlForDoseSpotNotificationsUrl = "api/rx/GetDoseSpotUrlForNoticiations";
	private _getCustomerERxUrl = "api/rx/getcustomererxurl";
	private _getPreFormattedERxOptionsUrls = "api/rx/GetPreFormattedERxNoteOptions";
	private _getDispenseUnitTypes = "api/rx/getdispenseunittypes";
	private _getDeliveryTimeframes = "api/rx/getdeliverytimeframes";

	public readonly preFormattedErxOptions: StoreObject<PreFormattedERxOption[]> = new StoreObject<PreFormattedERxOption[]>([]);
	public readonly dispenseUnitTypes: StoreObject<DispenseUnitType[]> = new StoreObject<DispenseUnitType[]>([]);

	constructor(private _http: HttpService, private _domSanitizer: DomSanitizer) {}

	getPreFormattedERxOptions(): Promise<PreFormattedERxOption[]> {
		return new Promise<PreFormattedERxOption[]>(resolve => {
			this._http.get(this._getPreFormattedERxOptionsUrls).subscribe((result: PreFormattedERxOption[]) => {
				if (result && result.length > 0) {
					this.preFormattedErxOptions.set(result);
					resolve(result);
				}
			});
		});
	}

	getDispenseUnitTypes(): Promise<DispenseUnitType[]> {
		return new Promise<DispenseUnitType[]>(resolve => {
			this._http.get(this._getDispenseUnitTypes).subscribe((result: DispenseUnitType[]) => {
				if (result && result.length > 0) {
					this.dispenseUnitTypes.set(result.map(x => new DispenseUnitType().deserialize(x)));
					resolve(result);
				}
			});
		});
	}

	getDeliveryTimeframes(): Promise<DeliveryTimeframe[]> {
		return new Promise<DeliveryTimeframe[]>((resolve, reject) => {
			this._http.get(this._getDeliveryTimeframes).subscribe((result: DeliveryTimeframe[]) => {
				resolve(result.filter(r => r.IsActive));
			});
		});
	}

	addRxOrder(data: RxOrderModel) {
		console.log({ method: "addRxOrder(data): ", data: data });
		return this._http.post(this._addRxOrderUrl, data);
	}

	searchMedications(search: SearchMedicationsModel) {
		return this._http.post(this._searchMedicationsUrl, search);
	}

	searchPrescribers(term: string) {
		return this._http.get(this._searchPrescribersUrl + term);
	}

	getDeliveryStatusList() {
		return this._http.cachedGet(true, this._getDeliveryStatusListUrl);
	}

	getDoseSpotRefillsUrl() {
		return this._http.get(this._getDosespotRefillsUrl);
	}

	getDoseSpotUrl(patientId): Promise<any> {
		return new Promise<any>((resolve, reject) => {
			this._http.get(this._getDoseSpotUrl + patientId).subscribe(
				(result: ApiResult) => {
					if (!result.Success) {
						reject(result.PublicMessage || "Unknown error");
					} else {
						resolve(this._domSanitizer.bypassSecurityTrustResourceUrl(result.Result));
					}
				},
				(error: any) => {
					reject(error);
				}
			);
		});
	}

	getDoseSpotURLForNewERX(patientId): Promise<any> {
		return new Promise<any>((resolve, reject) => {
			this._http.post(this._getDoseSpotUrl + patientId, {}).subscribe(
				(result: ApiResult) => {
					if (!result.Success) {
						reject(result.PublicMessage || "Unknown error");
					} else {
						resolve(this._domSanitizer.bypassSecurityTrustResourceUrl(result.Result));
					}
				},
				(error: any) => {
					reject(error);
				}
			);
		});
	}

	getCustomerERxUrl(): Promise<any> {
		return new Promise<any>((resolve, reject) => {
			this._http.get(this._getCustomerERxUrl).subscribe(
				(url: string) => {
					if (!url) {
						reject("DoseSpot not configured for the Logged in user");
					} else {
						resolve(this._domSanitizer.bypassSecurityTrustResourceUrl(url));
					}
				},
				(error: any) => {
					reject(error);
				}
			);
		});
	}

	submitPriorAuth(originalIsCovered: boolean, rxModel: RxOrderModel): Promise<string> {
		return new Promise((resolve, reject) => {
			if (rxModel.IsCovered !== originalIsCovered) {
				this.saveAsOppositeCoverage(rxModel.MedicationId).subscribe((result: ApiResult) => {
					if (result.Success) {
						resolve(
							rxModel.DrugName ||
								rxModel.ProductDescription + " has been saved as " + (rxModel.NewCoverageText.toLowerCase().indexOf("not") >= 0 ? "covered" : "non-covered") + " for " + rxModel.PatientName + "."
						);
					} else {
						reject(result.PublicMessage);
					}
				});
			} else {
				let msg = rxModel.NewCoverageText.toLowerCase().indexOf("not") >= 0 ? " has been denied for " : " has been authorized for ";
				this.authorizeMedication(
					rxModel.MedicationId,
					rxModel.PriorAuthNote,
					rxModel.DcoStartDate,
					rxModel.DcoEndDate,
					rxModel.DcoMaxDaysSupply,
					rxModel.DcoMaxQuantity,
					rxModel.DcoMaxRefills
				).subscribe((result: ApiResult) => {
					if (result.Success) {
						resolve(rxModel.DrugName || rxModel.ProductDescription + msg + rxModel.PatientName + ".");
					} else {
						reject(result.PublicMessage);
					}
				});
			}
		});
	}

	sendToDoseSpot(rxModel: any) {
		return this._http.post(this._sendToDoseSpotUrl, rxModel);
	}

	submitOrder(submitForReview: boolean, originalIsCovered: boolean, canAuthorize: boolean, overrideRequired: boolean, overrideCoverage: boolean, rxModel: RxOrderModel): Promise<RxOrderSuccessModel> {
		if (submitForReview) {
			rxModel.SubmitForReview = true;
			rxModel.IsCovered = originalIsCovered;
		} else {
			rxModel.SubmitForReview = false;
			if (canAuthorize && overrideRequired) {
				if (overrideCoverage) {
					rxModel.IsCovered = originalIsCovered;
				} else {
					rxModel.IsCovered = !originalIsCovered;
				}
			}
		}

		return this.addOrder(rxModel);
	}

	addOrder(rxModel: RxOrderModel): Promise<RxOrderSuccessModel> {
		return new Promise((resolve, reject) => {
			this.addRxOrder(rxModel).subscribe((results: any) => {
				if (results.Success) {
					let rxSuccessModel = new RxOrderSuccessModel();
					if (rxModel.selectedPrescriber) {
						rxSuccessModel.prescriberName =
							(rxModel.selectedPrescriber.PrefixName || "") +
							rxModel.selectedPrescriber.FirstName +
							" " +
							rxModel.selectedPrescriber.LastName +
							(rxModel.selectedPrescriber.SuffixName ? ", " + rxModel.selectedPrescriber.SuffixName : "");
					}
					rxSuccessModel.drugName = rxModel.DrugName || rxModel.ProductDescription;
					rxSuccessModel.directions = rxModel.Directions;
					rxSuccessModel.startDate = rxModel.StartDate;
					rxSuccessModel.submittedForReview = rxModel.SubmitForReview;
					resolve(rxSuccessModel);
				} else {
					reject(results.PublicMessage);
				}
			});
		});
	}

	authorizeMedication(medicationId: number, priorAuthNote: string, dcoStartDate: Date, dcoEndDate: Date, dcoMaxQuantity: number, dcoMaxDaysSupply: number, dcoMaxRefills: number) {
		return this._http.post(this._authorizeMedicationUrl, {
			MedicationId: medicationId,
			PriorAuthNote: priorAuthNote,
			DcoStartDate: dcoStartDate,
			DcoEndDate: dcoEndDate,
			DcoMaxQuantity: dcoMaxQuantity,
			DcoMaxDaysSupply: dcoMaxDaysSupply,
			DcoMaxRefills: dcoMaxRefills
		});
	}

	saveAsOppositeCoverage(medicationId: number) {
		return this._http.post(this._saveAsOppositeCoverageUrl, medicationId);
	}

	denyMedication(medicationId: number, reason: string) {
		return this._http.post(this._denyMedicationUrl, { MedicationId: medicationId, Reason: reason });
	}

	addPriorAuthRequest(model: PriorAuthRequest) {
		return this._http.post(this._requestPriorAuthUrl, model);
	}

	getPendingDoseSpotItemsForUser() {
		return this._http.get(this._getPendingDoseSpotItemsForUserUrl);
	}

	getDoseSpotNotificationCountInfoForUser() {
		return this._http.get(this._getDoseSpotNotificationCountInfoUrl);
	}

	getUrlForDoseSpotNotifications() {
		return this._http.get(this._getUrlForDoseSpotNotificationsUrl);
	}

	verifyCoverage(patientId: number, ndc: string, isCovered: boolean, startDate: Date) {
		return this._http.post(this._verifyCoverageUrl, { PatientId: patientId, NDC: ndc, Covered: isCovered, StartDate: startDate });
	}

	screenAllergies(patientId: number, gpi: string) {
		return this._http.post(this._screenAllergiesUrl, { PatientId: patientId, GPI: gpi });
	}

	screenDrugInteractions(patientId: number, gpi: string) {
		return this._http.post(this._screenDrugInteractionsUrl, { PatientId: patientId, GPI: gpi });
	}

	getUnableToRefillMessage(medication: PatientMedicationModel) {
		if (medication.RefillsRemaining === 0) {
			// No more refills available
			return "No refills remaining, a new prescription is required.";
		}

		if (medication.ExpireDate) {
			let expDate = new Date(medication.ExpireDate);
			let expDateUtc = new Date(expDate.getUTCFullYear(), expDate.getUTCMonth(), expDate.getUTCDate());
			let today = new Date();

			if (today.getTime() - expDateUtc.getTime() >= 0) {
				// prescription is expired
				if (medication.DeaClass === 2) {
					return "Order has expired, a new prescription is required.";
				} else {
					return "Order has expired, please call OnePoint Patient Care to renew the prescription.";
				}
			}
		}

		if (typeof medication.TotalQuantityRemaining !== "undefined" && medication.TotalQuantityRemaining === 0) {
			// quota for prescription has been met.
			return "The quantity written for this order has been met, a new prescription is required.";
		}

		return "";
	}
}
