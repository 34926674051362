import { Injectable } from "@angular/core";
import { StatusMessageService } from "../components/status-message/status-message.service";
import { AdminReportCategory } from "../models/admin-report-category.model";
import { AdminReport } from "../models/admin-report.model";
import { ApiResult } from "../models/api-result.model";
import { StoreObject } from "../models/store-object.model";
import { HttpService } from "./http.service";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import * as _ from "lodash";
import { AdminReportRole } from "../models/admin-report-role.model";
import { AdminReportRoleGrouping } from "../models/admin-report-role-grouping.model";
import { RoleModel } from "../models/role.model";
import { AdminReportRoleMapping } from "../models/admin-report-role-mapping.model";

@Injectable()
export class AdminReportRoleStore {
    public readonly reports: StoreObject<AdminReport[]> = new StoreObject<AdminReport[]>([]);
    public readonly reportRoles: StoreObject<AdminReportRole[]> = new StoreObject<AdminReportRole[]>([]);
    public readonly reportCategories: StoreObject<AdminReportCategory[]> = new StoreObject<AdminReportCategory[]>([]);
    public readonly reportRoleGroups: StoreObject<AdminReportRoleGrouping[]> = new StoreObject<AdminReportRoleGrouping[]>([]);
    public readonly roles: StoreObject<RoleModel[]> = new StoreObject<RoleModel[]>([]);

    private readonly _pendingReportRoles: StoreObject<AdminReportRole[]> = new StoreObject<AdminReportRole[]>([]);

    public loading: boolean = false;

    constructor(private _http: HttpService, private _statusMsgService: StatusMessageService){ 
        this._pendingReportRoles.observable.pipe(debounceTime(1000)).subscribe((pendingReportRoles: AdminReportRole[]) => {
            if ((pendingReportRoles || []).length === 0) return;

            this.setReportRoles(pendingReportRoles).then(() => {
                    this._statusMsgService.changeStatusMessage("success", "Permissions successfully saved.");
                    this._pendingReportRoles.set([])
                    this.refresh();
                },
                rejectMsg => {
                    this._statusMsgService.changeStatusMessage("error", rejectMsg || "Error saving mappings")
                    this._pendingReportRoles.set([])
                })
            })
         this.refresh();
    }

    private refresh(){
        this.loading = true;
        Promise.all([this.getReports(), this.getReportRoles(), this.getCategories(), this.getRoles()]).then(([reports, reportRoles, categories, roles]) => {
            this.loading = false;
            let groups = this.groupReportRoles(reports, reportRoles, categories, roles);
            this.reportRoleGroups.set(groups);
        })
    }

    public toggleChild(ev: boolean, roleId: number, reportId: number){
        let reportRoles = this.reportRoles.get()
        let rr = reportRoles.find(r => {
            return r.roleId == roleId && r.reportId == reportId;
        })

        if (rr){
            rr.isEnabled = ev;
            this._pendingReportRoles.set([...this._pendingReportRoles.get(), rr])
        } else {
            let newRR = new AdminReportRole();
            newRR.isEnabled = true;
            newRR.reportId = reportId;
            newRR.roleId = roleId;
            reportRoles.push(newRR)
            this._pendingReportRoles.set([...this._pendingReportRoles.get(), newRR])
        }
    }

    public toggleParent(ev: boolean, roleId: number, enabledRoles: RoleModel[], group: AdminReportRoleGrouping){
        group.reports.forEach(r => {
            this.toggleChild(ev, roleId, r.reportId);
        })
    }

    private getReports(): Promise<AdminReport[]> {
        return new Promise<AdminReport[]>(resolve => {
            this._http.get("api/admin/report").subscribe((result: AdminReport[]) => {
                if (result){
                    this.reports.set(result);
                    resolve(result)
                } else {
                    this.reports.set([]);
                    resolve([])
                }
              });
        })
    }

    private getRoles(): Promise<RoleModel[]> {
        return new Promise<RoleModel[]>((resolve, reject) => {
            this._http.get("api/customer/getcustomerroles").subscribe((roles: RoleModel[]) => {
                if (roles && roles.length > 0){
                    this.roles.set(roles);
                    resolve(roles);
                }
                else {
                    this.roles.set([]);
                    resolve([]);
                }
            })
        })
    }

    private getReportRoles(): Promise<AdminReportRole[]> {
        return new Promise<AdminReportRole[]>((resolve, reject) => {
            this._http.get("api/admin/report/report-roles").subscribe((r: any) => {
                if (r){
                    this.reportRoles.set(r);
                    resolve(r);
                }
                else {
                    this.reportRoles.set([]);
                    resolve([]);
                }
            })
        })
    }

    private setReportRoles(pendingReportRoles: AdminReportRole[]): Promise<boolean> {
        return new Promise<boolean>((resolve, reject) => {
            this._http.post("api/admin/report/report-roles", pendingReportRoles).subscribe((result: ApiResult) => {
                if (result && result.Success){
                    resolve(true)
                } else {
                    reject(result.PublicMessage);
                }
            })
        });
    }

    private getCategories(): Promise<AdminReportCategory[]> {
        return new Promise<AdminReportCategory[]>((resolve, reject) => {
            this._http.get("api/admin/report/category").subscribe((result: AdminReportCategory[]) => {
                if (result){
                    this.reportCategories.set(result);
                    resolve(result);
                } else {
                    this.reportCategories.set([]);
                    resolve([]);
                }
            });
        })
    }

    private groupReportRoles(reports: AdminReport[], reportRoles: AdminReportRole[], categories: AdminReportCategory[], roles: RoleModel[]): AdminReportRoleGrouping[] {
        let groups = categories.map((category: AdminReportCategory) => {
            let group = new AdminReportRoleGrouping();
            group.categoryId = category.id;
            group.categoryName = category.name;
            
            group.reports = reports.filter(r => r.categoryId == category.id).map(r => {
                let mapping = new AdminReportRoleMapping();
                
                mapping.reportId = r.id;
                mapping.reportName = r.name;
                mapping.enabledRoles = reportRoles.filter(rr => {
                    return (rr.reportId == r.id && rr.isEnabled)
                }).map(rr => {
                    let role = new RoleModel();
                    role.id = rr.roleId;
                    role.roleName = rr.roleName;
                    return role;
                })


                return mapping;
            });

            return group;
        })

        return groups;
    }
}