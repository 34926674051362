import {Directive, Input, OnChanges, SimpleChanges} from "@angular/core";
import {Validator, AbstractControl, NG_VALIDATORS, Validators, ValidatorFn} from "@angular/forms";

@Directive({
  selector: '[validIfEqual]',
  providers: [
    {provide: NG_VALIDATORS, useExisting: ValidIfEqualDirective, multi: true}
  ]
})
export class ValidIfEqualDirective implements Validator, OnChanges {
  private control: AbstractControl;
  private valFn = Validators.nullValidator;

  @Input() validIfEqual: string;

  validate(c: AbstractControl): {[p: string]: any} {
    this.control = c;
    return this.valFn(c);
  }

  ngOnChanges(changes: SimpleChanges): void {
    const change = changes['validIfEqual'];

    if (change) {
      const val: string = change.currentValue;
      this.valFn = this.validIfEqualFunction(val);
    } else {
      this.valFn = Validators.nullValidator;
    }
    if (this.control) {
      this.control.updateValueAndValidity();
    }
  }

  private validIfEqualFunction(validIfEqual: string): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} => {
      let value = control.value;

      // value not equal

      if (validIfEqual && value !== validIfEqual) {
        return {
          validIfEqual: false
        }
      }
      return null;
    };
  }
}


