import { OrderPreferenceDetail } from "./order-preference-detail.model";
import { RxOrderModel } from "../rx-order.model";
import { MedicationSearchResultModel } from "../medication-search-result.model";
import { LineItem } from "./line-item.model";
import { DosespotItem } from "./dosespot-item.model";
import { FacilityPreferenceSummary } from "./facility-preference-summary.model";
import { LineItemSummary } from "./line-item-summary.model";

export class OrderBuilder {
	static toRxType(itemTypeId: number): string {
		switch (itemTypeId) {
			case 1:
				return "medication";
			case 2:
				return "supply";
			case 3:
				return "compound";
			default:
				return "medication";
		}
	}

	static toItemType(rxType: string): number {
		switch (rxType) {
			case "medication":
				return 1;
			case "supply":
				return 2;
			case "compound":
				return 3;
			default:
				return 1;
		}
	}

	static fromOrders(rxOrders: RxOrderModel[]): LineItem[] {
		return rxOrders.map((rx: RxOrderModel) => {
			let li = new LineItem();

			li.DaysSupply = rx.daysSupply;
			li.Directions = rx.Directions;
			li.IsUrgent = rx.isUrgent;
			li.NoSubstitutions = rx.noSubstitutions;
			li.PharmacyNotes = rx.PharmacyNotes;
			li.Quantity = rx.Quantity ? rx.Quantity.toString() : "";
			li.Refills = rx.Refills;
			li.PrescriptionId = rx.prescriptionId;

			var dsItem = new DosespotItem();
			dsItem.DispenseUnitId = rx.DispenseUnitId;
			dsItem.ItemName = rx.selectedMedication.DrugName;
			dsItem.Strength = rx.selectedMedication.Strength;
			dsItem.LexiDrugSynId = rx.lexiDrugSynId;
			dsItem.LexiGenProductId = rx.lexiGenProductId;
			dsItem.LexiSynonymTypeId = rx.lexiSynonymId;
			dsItem.UnitName = rx.dispenseUnitName;
			dsItem.ItemTypeId = this.toItemType(rx.erxType);
			dsItem.SupplyId = rx.supplyId;
			dsItem.NotControlled = rx.notControlled && !rx.IsControl;
			dsItem.CompoundId = rx.compoundId;
			dsItem.DiagnosisId = rx.diagnosisId;

			li.DosespotItem = dsItem;

			return li;
		});
	}

	static fromFacilityPreference(item: FacilityPreferenceSummary): RxOrderModel {
		let rxModel = new RxOrderModel();
		rxModel.DrugName = item.Nickname;
		rxModel.preferredNickname = item.Nickname;
		rxModel.preferredType = "customerPreferred";
		rxModel.preferredByUser = item.AddedBy;
		rxModel.preferenceId = item.FacilityPreferenceId;

		if (item.LineItems.length === 1) {
			let med = new MedicationSearchResultModel();
			med.DosageForm = item.LineItems[0].UnitName;
			med.Strength = item.LineItems[0].Strength;
			med.DrugName = item.LineItems[0].ItemName;

			rxModel.selectedMedication = med;
			rxModel.erxType = OrderBuilder.toRxType(item.LineItems[0].ItemTypeId);

			rxModel.Quantity = this.parseInput(+item.LineItems[0].Quantity);
			rxModel.daysSupply = item.LineItems[0].DaysSupply;
			rxModel.dispenseUnitName = item.LineItems[0].UnitName;
			rxModel.sortedMedStrength = this.parseMedStrength(item.LineItems[0].Strength);
		} else {
			rxModel.Quantity = null;
			rxModel.daysSupply = null;
			rxModel.dispenseUnitName = "BUNDLE";
			rxModel.erxType = "kit";
			rxModel.sortedMedStrength = 0;

			let kitMed = new MedicationSearchResultModel();
			kitMed.DosageForm = "BUNDLE";
			kitMed.Strength = "";

			rxModel.selectedMedication = kitMed;

			rxModel.children = item.LineItems.map((lineItem: LineItemSummary) => {
				let child = new RxOrderModel();
				child.DrugName = lineItem.ItemName;
				child.erxType = OrderBuilder.toRxType(lineItem.ItemTypeId);
				child.preferredType = "customerPreferred";

				let childMed = new MedicationSearchResultModel();
				childMed.DosageForm = lineItem.UnitName;
				childMed.Strength = lineItem.Strength;

				child.selectedMedication = childMed;

				child.Quantity = +lineItem.Quantity;
				child.daysSupply = lineItem.DaysSupply;

				return child;
			});
		}

		return rxModel;
	}

	static fromOrderPreference(detail: OrderPreferenceDetail): RxOrderModel {
		let rxModel = new RxOrderModel();

		if (detail.LineItems.length === 1) {
			rxModel.DrugName = detail.LineItems[0].DosespotItem.ItemName;

			let med = new MedicationSearchResultModel();
			med.DrugName = detail.LineItems[0].DosespotItem.ItemName;
			med.DosageForm = detail.LineItems[0].DosespotItem.UnitName;
			med.Strength = detail.LineItems[0].DosespotItem.Strength;

			rxModel.selectedMedication = med;

			if (+detail.LineItems[0].Quantity > 0) rxModel.Quantity = +detail.LineItems[0].Quantity;

			if (+detail.LineItems[0].DaysSupply > 0) rxModel.daysSupply = detail.LineItems[0].DaysSupply;

			rxModel.PharmacyNotes = detail.LineItems[0].PharmacyNotes;
			rxModel.Directions = detail.LineItems[0].Directions;
			rxModel.noSubstitutions = detail.LineItems[0].NoSubstitutions;
			rxModel.Refills = detail.LineItems[0].Refills;

			rxModel.DispenseUnitId = detail.LineItems[0].DosespotItem.DispenseUnitId;
			rxModel.lexiDrugSynId = detail.LineItems[0].DosespotItem.LexiDrugSynId;
			rxModel.lexiGenProductId = detail.LineItems[0].DosespotItem.LexiGenProductId;
			rxModel.lexiSynonymId = detail.LineItems[0].DosespotItem.LexiSynonymTypeId;
			rxModel.dispenseUnitName = detail.LineItems[0].DosespotItem.UnitName;
			rxModel.erxType = this.toRxType(detail.LineItems[0].DosespotItem.ItemTypeId);
			rxModel.supplyId = detail.LineItems[0].DosespotItem.SupplyId;
			rxModel.notControlled = detail.LineItems[0].DosespotItem.NotControlled;
			rxModel.IsControl = !rxModel.notControlled;
			rxModel.compoundId = detail.LineItems[0].DosespotItem.CompoundId;
		} else {
			rxModel.children = detail.LineItems.map((li: LineItem) => {
				let child = new RxOrderModel();

				child.DrugName = li.DosespotItem.ItemName;
				let childMed = new MedicationSearchResultModel();
				childMed.DrugName = li.DosespotItem.ItemName;
				childMed.DosageForm = li.DosespotItem.UnitName;
				childMed.Strength = li.DosespotItem.Strength;

				child.selectedMedication = childMed;

				child.Quantity = +li.Quantity;
				child.daysSupply = li.DaysSupply;
				child.PharmacyNotes = li.PharmacyNotes;
				child.Directions = li.Directions;
				child.noSubstitutions = li.NoSubstitutions;
				child.Refills = li.Refills;

				child.DispenseUnitId = li.DosespotItem.DispenseUnitId;
				child.lexiDrugSynId = li.DosespotItem.LexiDrugSynId;
				child.lexiGenProductId = li.DosespotItem.LexiGenProductId;
				child.lexiSynonymId = li.DosespotItem.LexiSynonymTypeId;
				child.dispenseUnitName = li.DosespotItem.UnitName;
				child.erxType = this.toRxType(li.DosespotItem.ItemTypeId);
				child.supplyId = li.DosespotItem.SupplyId;
				child.notControlled = li.DosespotItem.NotControlled;
				child.IsControl = !child.notControlled;
				child.compoundId = li.DosespotItem.CompoundId;

				return child;
			});
		}

		return rxModel;
	}

	static parseMedStrength(input: string): number {
		let value: number;

		if (input.length > 0) {
			value = this.parseInput(input);

			if (isNaN(value)) {
				value = 9999;
			}
		} else {
			value = 0;
		}

		return value;
	}

	static parseInput(input: number | string): number {
		if (typeof input === "number") {
			return input;
		} else if (typeof input === "string") {
			return parseInt(input.replace(/[^\d.]/g, ""), 10);
		}
	}
}
