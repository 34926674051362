import { Component, OnInit, ViewChild, Output, EventEmitter } from "@angular/core";
import { ModalComponent } from "../../../shared/components/modal/modal.component";
import * as moment from "moment";
import { PatientInfoModel } from "../../../shared/models/patient.model";
import { PatientService } from "../../../shared/services/patient.service";
import { AuthService } from "../../../shared/services/auth.service";
import { UserService } from "../../../shared/services/user.service";
import { CustomerProfileModel } from "../../../shared/models/customer-profile.model";
import { ApplicationService } from "src/app/ocp/shared/services/application.service";

@Component({
	selector: "admit-patient-modal",
	templateUrl: "./admit-patient-modal.template.html",
	styleUrls: ["./admit-patient-modal.scss"]
})
export class AdmitPatientModalComponent implements OnInit {
	loading: boolean = false;
	headerText: string;
	subHeaderText: string;
	customerName: string;
	step: number = 1;
	mode: string = "New";
	patientId: number;
	patient: PatientInfoModel;
	demographicsCompleted: boolean = false;
	isPreAdmittedPatient: boolean = false;

	@ViewChild("admitPatientModal") admitPatientModal: ModalComponent;
	@ViewChild("confirmCancelModal") confirmCancelModal: ModalComponent;

	@Output() admitPatientSuccess: EventEmitter<any> = new EventEmitter<any>();
	@Output() modalClosed: EventEmitter<any> = new EventEmitter<any>();

	constructor(private _patientService: PatientService, private _authService: AuthService, private _userService: UserService, public appService: ApplicationService) {}

	ngOnInit() {
		this._userService.selectedCustomer.subscribe((customer: CustomerProfileModel) => {
			if (customer) {
				this.customerName = customer.Name;
			}
		});
	}

	goToStep(step: number) {
		this.step = step;

		switch (step) {
			case 1:
				this.subHeaderText = "";
				break;
			case 2:
				this.subHeaderText = " - Additional Details for " + this.patient.fullName();
				this.demographicsCompleted = true;
				break;
			case 3:
				this.subHeaderText = " - Diagnosis for " + this.patient.fullName();
				break;
			case 4:
				this.subHeaderText = " - Allergies for " + this.patient.fullName();
				break;
			case 5:
				this.subHeaderText = " - Confirm details for " + this.patient.fullName();
				break;
			default:
				this.step = 1;
				this.subHeaderText = "";
				break;
		}
	}

	open(patientId: number) {
		this.patientId = patientId;
		this.isPreAdmittedPatient = false;

		if (!this.patientId) {
			this.mode = "New";
			this.headerText = "Add New Patient";
			this.subHeaderText = "";
			this.patient = new PatientInfoModel();
			this.admitPatientModal.open("lg");
			this.demographicsCompleted = false;
			this.goToStep(1);
		} else {
			this.loading = true;
			this._patientService.getAdmitPatientProfile(this.patientId).subscribe((profile: PatientInfoModel) => {
				this.loading = false;
				this.demographicsCompleted = true;
				this.mode = "Admit";
				this.patient = profile;
				if (this.patient.isDischarged) {
					this.patient.diagnosis = [];
					this.headerText = "Readmit Patient";
				} else {
					this.headerText = "Admit Patient";
				}

				this.patient.dateAdmitted = moment(new Date()).format("YYYY-MM-DD");
				this.patient.dischargeDate = null;
				this.patient.patientStatus = 1;
				this.patient.isPreadmit = false;
				this.patient.isAdmitted = true;
				this.patient.isDischarged = false;
				this.patient.isDeceased = false;
				// this.subHeaderText = " " + this.patient.fullName();
				this.admitPatientModal.open("lg");
			});
		}
	}

	showDialog(patientId: number) {
		this._authService.checkSession().then((isValid: boolean) => {
			if (isValid) {
				this.open(patientId);
			}
		});
	}

	saveSuccess() {
		this.admitPatientSuccess.emit();
		this.closeAdmitPatientModal();
	}

	confirmCloseAdmitPatientModal() {
		this.confirmCancelModal.open("xs");
	}

	closeAdmitPatientModal() {
		this.confirmCancelModal.dismiss();
		this.admitPatientModal.dismiss();
	}

	onModalClose() {
		this.headerText = "";
		this.subHeaderText = "";
		this.patientId = null;
		this.patient = null;
		this.step = 1;
		this.modalClosed.emit();
	}
}
