import {Component, Input, Output, EventEmitter, OnInit} from '@angular/core';
import {CustomerService} from "../../services/customer.service";
import {OrganizationProfile} from "../../models/organization-profile.model";
import {ApiResult} from "../../models/api-result.model";
import {StatusMessageService} from "../status-message/status-message.service";
import states from "../../models/constants/state-list.constant";

@Component({
  selector: 'organization-details',
  templateUrl: './organization-details.template.html'
})

export class OrganizationDetails implements OnInit {
  saving: boolean = false;
  stateList = states.STATE_LIST;

  @Input() mode: string;
  @Input() selectedOrganization: OrganizationProfile = null;

  @Output() closeModal: EventEmitter<string> = new EventEmitter<string>();
  @Output() saveSuccess: EventEmitter<string> = new EventEmitter<string>();

  constructor(private _customerService: CustomerService, private _statusMessageService: StatusMessageService) {
  }

  ngOnInit() {

  }

  save() {
    this.saving = true;
    if (this.mode === 'New') {
      this._customerService.addOrganizationProfile(this.selectedOrganization).subscribe((results: ApiResult) => {
        this.saving = false;
        if (results.Success) {
          this.saveSuccess.emit(this.selectedOrganization.Name);
        } else {
          this._statusMessageService.changeStatusMessage('error', results.PublicMessage);
        }
      });
    } else if (this.mode === 'Edit') {
      // update
      this._customerService.updateOrganizationProfile(this.selectedOrganization).subscribe((results: ApiResult) => {
        this.saving = false;
        if (results.Success) {
          this.saveSuccess.emit(this.selectedOrganization.Name);
        } else {
          this._statusMessageService.changeStatusMessage('error', results.PublicMessage);
        }
      });
    }
  }
}
