import { map } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { HttpEvent, HttpInterceptor, HttpHandler, HttpResponse, HttpRequest } from "@angular/common/http";
import { AuthService } from "./auth.service";

import { Observable } from "rxjs";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
	constructor(private _authService: AuthService) {}

	intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		return next.handle(request).pipe(
			map((event: HttpEvent<any>) => {
				if (event instanceof HttpResponse) {
					let body = event.body;

					if (!body || !body.hasOwnProperty("Success")) return event;

					if (!body.Success) {
						console.log(body);
						if (body.PublicMessage && body.PublicMessage.toLowerCase() === "invalid session key") {
							if (this._authService.isAuthenticated()) {
								this._authService.doLogout("Your session has expired");
								throw new Error(body.PublicMessage);
							}
						}
					}

					if (request.method === "GET") {
						return event.clone({ body: body.Result });
					}

					return event;
				}
			})
		);
	}
}
