import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { PatientInfoModel } from "../../../../shared/models/patient.model";
import { UserService } from "../../../../shared/services/user.service";
import { PatientService } from "../../../../shared/services/patient.service";
import { StatusMessageService } from "../../../../shared/components/status-message/status-message.service";
import { ApiResult } from "../../../../shared/models/api-result.model";
import { Router } from "@angular/router";
import { DiagnosisModel } from "../../../../shared/models/diagnosis.model";
import * as moment from "moment";
import { CustomerProfileModel } from "../../../../shared/models/customer-profile.model";

@Component({
	selector: "patient-confirmation",
	templateUrl: "./form-patient-confirmation.template.html",
	styleUrls: ["./form-patient-confirmation.scss"]
})
export class FormPatientConfirmation implements OnInit {
	saving: boolean = false;
	isInternalUser: boolean = false;
	currentStep: number = 5;
	selectedPostSaveOption: string = "1";
	showSsn: boolean = false;
	mrnRequired: boolean = false;
	hasTeams: boolean = false;
	postSaveOptions: any[] = [{ text: "Open patient profile", value: "1" }, { text: "Add another patient", value: "2" }];

	@Input() mode: string;
	@Input() patient: PatientInfoModel = new PatientInfoModel();
	@Input() formSubmitted: boolean;

	@Output() goBack: EventEmitter<number> = new EventEmitter<number>();
	@Output() closeModal: EventEmitter<string> = new EventEmitter<string>();
	@Output() addNew: EventEmitter<string> = new EventEmitter<string>();
	@Output() saveSuccess: EventEmitter<string> = new EventEmitter<string>();

	get needsMrn(): boolean {
		return this.mrnRequired && this.patient.patientStatus !== 0 && !this.patient.Mrn;
	}

	get diagnoses() {
		if (this.patient.patientStatus !== 0) {
			return this.patient.diagnosis.filter((diagnosis: DiagnosisModel) => {
				return !diagnosis.ResolvedDate || moment(diagnosis.ResolvedDate) > moment(this.patient.dateAdmitted);
			});
		} else {
			return this.patient.diagnosis;
		}
	}

	constructor(private _userService: UserService, private _patientService: PatientService, private _statusMessageService: StatusMessageService, private _router: Router) {}

	ngOnInit() {
		this._userService.isSsnRequired.subscribe((isRequired: boolean) => {
			this.showSsn = isRequired;
		});

		this._userService.isMrnRequired.subscribe((isRequired: boolean) => {
			this.mrnRequired = isRequired;
		});

		this._userService.selectedCustomer.subscribe((customer: CustomerProfileModel) => {
			this.hasTeams = customer.HasTeams;
		});

		this.isInternalUser = this._userService.isInternalUser();
	}

	savePatient(postSaveOption: string) {
		this.saving = true;
		if (this.patient.patientStatus !== 0) {
			this.patient.diagnosis = this.patient.diagnosis.filter((diagnosis: DiagnosisModel) => {
				return !diagnosis.ResolvedDate || moment(diagnosis.ResolvedDate) > moment(this.patient.dateAdmitted);
			});
		}

		if (this.mode === "New") {
			this._patientService.addPatient(this.patient, this.patient.dischargeDate).subscribe((results: ApiResult) => {
				if (results.Success) {
					this.saving = false;
					document.getElementById("app-body").classList.remove("modal-open");
					switch (postSaveOption) {
						case "1":
							// go to profile
							//this.closeAdmitPatientModal();
							this._router.navigate(["/patient-care/patient-details/" + results.Result.PatientId + "/detail"]);
							document.body.classList.remove("no-scroll");
							break;
						case "2":
							// add new
							this.patient = new PatientInfoModel();
							this._statusMessageService.changeStatusMessage("success", "A new patient has been successfully created.");
							this.addNew.emit();
							break;
						default:
							// just close dialog
							this.saveSuccess.emit();
							this._statusMessageService.changeStatusMessage("success", "A new patient has been successfully created.");
							break;
					}
				} else {
					this.saving = false;
					this._statusMessageService.changeStatusMessage("error", results.PublicMessage);
				}
			});
		} else {
			this._patientService.admitPatient(this.patient.patientId, this.patient).subscribe((results: ApiResult) => {
				if (results.Success) {
					this.saving = false;
					this._statusMessageService.changeStatusMessage("success", this.patient.fullName() + " has been successfully admitted.");
					this.saveSuccess.emit();
				} else {
					this.saving = false;
					this._statusMessageService.changeStatusMessage("error", results.PublicMessage);
				}
			});
		}
	}

	deriveHeight(heightIn?: number): string {
		if (!heightIn) return "";
	
		let numFt = Math.floor(heightIn / 12);
		let numIn = heightIn % 12;
	
		return `${numFt}ft ${numIn}in`
	  }
}
