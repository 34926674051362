import { Injectable } from "@angular/core";
import { MultiSelectItemModel } from "../components/input-multi-select/multi-select-item.model";
import { ApiResult } from "../models/api-result.model";
import { StoreObject } from "../models/store-object.model";
import { PharmacyItem, SystemAlert } from "../models/system-alert.model";
import { HttpService } from "./http.service";
import { combineLatest } from "rxjs";

@Injectable()
export class AdminSystemAlertStore {
    public readonly loading: StoreObject<boolean> = new StoreObject<boolean>(false);

    public readonly displayedAlerts: StoreObject<SystemAlert[]> = new StoreObject<SystemAlert[]>([]);
    public readonly alerts: StoreObject<SystemAlert[]> = new StoreObject<SystemAlert[]>([]);
    public readonly alert: StoreObject<SystemAlert> = new StoreObject<SystemAlert>(null);

    public readonly pharmacies: StoreObject<PharmacyItem[]> = new StoreObject<PharmacyItem[]>([]);
    public readonly pharmacySelectOptions: StoreObject<MultiSelectItemModel[]> = new StoreObject<MultiSelectItemModel[]>([]);

    public availableStatuses: string[] = [
        "Completed",
        "Active",
        "Future"
    ];

    public statuses: StoreObject<string[]> = new StoreObject<string[]>(["Active", "Future"])

    constructor(private _http: HttpService) {
        this.init();

        this.pharmacies.observable.subscribe((pharmacies: PharmacyItem[]) => {
            this.pharmacySelectOptions.set(
                (pharmacies || []).map(p => {
                    let i: MultiSelectItemModel = {
                        id: p.PharmacyId,
                        text: p.Name,
                        selected: false
                    };
                    return i;
                })
            )
        });

        combineLatest([this.alerts.observable, this.statuses.observable]).subscribe(([alerts, statuses]) => {
            this.filter();
        });
    }

    init(){
        this.loading.set(true);
        this.getPharmacyList().then(() => {
            this.getSystemAlerts().then(() => {
                this.associatePharmacies();
                this.loading.set(false); // alert items rely on pharmacy list
            });
        })
    }

    filter(){
        this.displayedAlerts.set(
            this.alerts.get()
                .filter(a => this.statuses.get().indexOf(a.status) > -1)
        );
    }

    getSystemAlerts(): Promise<SystemAlert[]> {
        return new Promise<SystemAlert[]>(resolve => {
            this._http.get("api/systemalert/getallsystemalerts").subscribe((result: SystemAlert[]) => {
                if (result){
                    this.alerts.set(result.map(r => new SystemAlert().deserialize(r)));
                } else {
                    this.alerts.set([]);
                }
                resolve(this.alerts.get());
            });
        });
    }

    getPharmacyList(): Promise<PharmacyItem[]> {
        return new Promise<PharmacyItem[]> (resolve => {
            this._http.get("api/systemalert/getpharmacylist").subscribe((result: PharmacyItem[]) => {
                if (result){
                    this.pharmacies.set(result);
                } else {
                    this.pharmacies.set([]);
                }
                resolve(this.pharmacies.get());        
            });
        });
    }

    updateAlert(): Promise<boolean> {
        this.loading.set(true);
        return new Promise<boolean> (resolve => {
            let alert = this.alert.get()

            alert.endDateUTC = alert.endDate ? new Date(new Date(alert.endDate).toDateString() + " " + (alert.endTime || "00:00")) : null; 
            alert.startDateUTC = new Date(new Date(alert.startDate).toDateString() + " " + (alert.startTime || "00:00"));

            if (alert.enableAllPharmacies){
                alert.pharmacyIds = [];
                alert.pharmacies = [];
            } else {
                alert.pharmacies = (alert.pharmacies || []).filter(p => {
                    return alert.pharmacyIds.some(id => id == p.PharmacyId)
                });
            }

            this._http.post(`api/systemalert/updatealert?alertId=${alert.id}`, alert).subscribe((result: ApiResult) => {
                this.loading.set(false);
                this.init();
                resolve(result.Result);
            })
        })
    }

    disableAlert(): Promise<boolean> {
        this.loading.set(true);
        return new Promise<boolean> (resolve => {
            let alertId = this.alert.get().id;
            this._http.post(`api/systemalert/disablealert?alertId=${alertId}`, {}).subscribe((result: ApiResult) => {
                this.loading.set(false);
                this.init();
                resolve(result.Result);
            })
        })
    }

    addAlert(): Promise<boolean> {
        this.loading.set(true);
        return new Promise<boolean> (resolve => {
            let alert = this.alert.get()

            alert.endDateUTC = alert.endDate ? new Date(new Date(alert.endDate).toDateString() + " " + (alert.endTime || "00:00")) : null; 
            alert.startDateUTC = new Date(new Date(alert.startDate).toDateString() + " " + (alert.startTime || "00:00"));

            if (alert.enableAllPharmacies){
                alert.pharmacyIds = [];
                alert.pharmacies = [];
            }

            this._http.post(`api/systemalert/addalert`, alert).subscribe((result: ApiResult) => {
                this.loading.set(false);
                this.init();
                resolve(result.Result);
            })
        })
    }

    updateStatusFilter(status: string){
        let statuses = this.statuses.get();

        let idx = statuses.indexOf(status);
        if (idx > -1){
            statuses.splice(idx, 1);
        } else {
            statuses.push(status);
        }

        this.statuses.set(statuses);
    }

    private associatePharmacies(): void {
        let pharmacies = this.pharmacies.get() || [];
        let alerts = this.alerts.get() || [];
        
        alerts.forEach(a => {
            a.pharmacies = pharmacies.filter(p => (a.pharmacyIds || []).some(id => id == p.PharmacyId));

            if (a.enableAllPharmacies)
                a.pharmacies = pharmacies;
        });

        this.alerts.set(alerts);
    }
}
