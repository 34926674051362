import {Pipe, PipeTransform} from "@angular/core";

@Pipe({name: "gender"})
export class GenderPipe implements PipeTransform{
  transform(gender:string){
    if(gender)
      gender = gender.toLowerCase();

    return gender === "m" ? "Male" : "Female";
  }
}
