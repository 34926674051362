import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, OnInit } from "@angular/core";
import { RxService } from "../../services/rx.service";
import { StatusMessageService } from "../status-message/status-message.service";
import { ApiResult } from "../../models/api-result.model";
import { RxOrderModel } from "../../models/rx-order.model";
import { Permissions } from "../../enums/permissions";

@Component({
	selector: "alert",
	templateUrl: "./alert.template.html",
	styleUrls: ["./alert.scss"]
})
export class AlertComponent {
	@ViewChild("oLink") private _oLink: ElementRef;

	permissions: any = Permissions;
	approvalStatus: string;
	confirmAction: boolean;
	confirmationType: string;
	denyReason: string = "";
	isMobile: boolean = false; // Not set
	todayDate: Date = new Date();

	@Input() fullData: any;
	@Input() alertType: string;
	@Input() title: string;
	@Input() date: any;
	@Input() content: string;
	@Input() firstName: string;
	@Input() lastName: string;
	@Input() doseSpotPending: number;
	@Input() doseSpotError: number;
	@Input() submitterFullName: string;
	@Input() medicationName: string;
	@Input() patientFullName: string;
	@Input() startDate: string;
	@Input() discontinueDate: Date;
	@Input() dosageAmt: string;
	@Input() instructions: string;
	@Input() medicationId: number;
	@Input() customerName: string;
	@Input() patientId: number;
	@Input() eRxUrl: string;
	@Input() teamName: string;
	@Input() requestingUser: string;
	@Input() newCoverageText: string;
	@Input() productDescription: string;
	@Input() pharmaciesStr: string;
	@Input() numPharmacies: number;
	@Input() allPharmaciesEnabled: boolean;

	@Output() alertUpdated: EventEmitter<any> = new EventEmitter<any>();
	@Output() priorAuthApprove: EventEmitter<RxOrderModel> = new EventEmitter<RxOrderModel>();
	@Output() onViewProfile: EventEmitter<any> = new EventEmitter<any>();

	constructor(private _rxService: RxService, private _statusMessageService: StatusMessageService) {
	}

	confirmApprove() {
		this.priorAuthApprove.emit(this.fullData);
	}

	confirmDeny() {
		this.confirmAction = true;
		this.confirmationType = "Deny";
	}

	closeConfirmation() {
		this.confirmAction = false;
		this.denyReason = "";
	}

	denyAuth() {
		this._rxService.denyMedication(this.medicationId, this.denyReason).subscribe((results: ApiResult) => {
			if (results.Success) {
				this.confirmAction = false;
				this.approvalStatus = "Denied";
				this.alertUpdated.emit();
			} else {
				this._statusMessageService.changeStatusMessage("error", results.PublicMessage);
			}
		});
	}

	alertClick() {
		if (this.alertType === "erx" && this.eRxUrl) {
			setTimeout(() => {
				this._oLink.nativeElement.click();
			}, 500);
		}
	}
}
