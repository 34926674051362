import {Component, Input, Output, EventEmitter, OnInit} from '@angular/core';
import {ClinicalResourcesService} from "../../../shared/services/clinical-resources.service";
import {DocumentInfoModel} from "../../../shared/models/document-info.model";

@Component({
  selector: 'document-thumbnail',
  templateUrl: './document-thumbnail.template.html',
  styleUrls: ['./thumbnail-item.css']
})

export class DocumentThumbnail implements OnInit {
  @Input() documentType: string = "Document";
  @Input() thumbnailSrc: any;
  @Input() documentId: number;
  @Input() itemName: string;
  @Input() publishDate: string;

  @Output() quickView: EventEmitter<string> = new EventEmitter<string>();

  isIos: boolean = false;

  constructor(private _clinicalResourcesService: ClinicalResourcesService) {
  }

  ngOnInit() {
    this._clinicalResourcesService.getThumbnail('', this.documentId, null, null).subscribe((results: any) => {
      this.thumbnailSrc = 'data:image/jpeg;base64,' + results;
    });
    
    this.detectiOS();
  }

  view() {
    if (!this.documentId) return;

    this._clinicalResourcesService.getDocuments(this.documentId, null).subscribe((result: DocumentInfoModel[]) => {
      if (!result.length) return;
      let doc = result[0];
      this._clinicalResourcesService.downloadDocument(this.documentId, true).then((result: Blob) => {
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(result, doc.OriginalFileName);
        }
        else {
          let fileURL = URL.createObjectURL(result);
          if (this.isIos){
            window.open(fileURL, "_blank");
          } else {
            window.open(fileURL);
          }
        }
      }, error => {
        console.log(error);
      });
    });
  }

  download() {
    if (!this.documentId) return;

    this._clinicalResourcesService.getDocuments(this.documentId, null).subscribe((result: DocumentInfoModel[]) => {
      if (!result.length) return;
      let doc = result[0];
      this._clinicalResourcesService.downloadDocument(this.documentId, true).then((result: Blob) => {
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(result, doc.OriginalFileName);
        }
        else {
          let a = document.createElement("a");
          document.body.appendChild(a);
          a.style.display = "none";

          let url = window.URL.createObjectURL(result);
          a.href = url;
          a.setAttribute("download", doc.OriginalFileName);
          a.click();
          window.URL.revokeObjectURL(url);
          document.body.removeChild(a);
        }
      }, error => {
        console.log(error);
      });
    });
  }

  detectiOS() {
		const iDevices = ["iPad Simulator", "iPhone Simulator", "iPod Simulator", "iPad", "iPhone", "iPod"];
		if (!!navigator.platform) {
			while (iDevices.length) {
        console.log(navigator.platform)
				if (navigator.platform === iDevices.pop()) {
					console.log("isIos = true");
					return (this.isIos = true);
				}
			}
		}
		console.log("isIos = false");
		return (this.isIos = false);
	}
}
