/**
 * Created by dan on 1/2/2017.
 */
import { Component, Input, forwardRef, EventEmitter, Output, OnInit, AfterViewInit } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { OptionModel } from "../select-menu/option.model";

@Component({
	selector: "input-selectmenu",
	templateUrl: "./input-selectmenu.template.html",
	styleUrls: ["./input-selectmenu.scss"],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => InputSelectMenuComponent),
			multi: true
		}
	]
})
export class InputSelectMenuComponent implements ControlValueAccessor, OnInit {
	@Input() hideLabel: boolean = false;
	@Input() label: string;
	@Input() captionText: string;
	@Input() isRequired: boolean = false;
	@Input() focus: EventEmitter<any> = new EventEmitter<any>();
	@Input() disabled: boolean = false;

	private _displayTextKey: string = "";
	@Input() get displayTextKey(): string {
		return this._displayTextKey;
	}
	set displayTextKey(value: string) {
		if (value == this._displayTextKey) return;

		this._displayTextKey = value;
		this.resetList();
	}

	private _itemKey: string = "";
	@Input() get itemKey(): string {
		return this._itemKey;
	}
	set itemKey(value: string) {
		if (value == this._itemKey) return;

		this._itemKey = value;
		this.resetList();
	}

	private _valKey: string = "";
	@Input() get valKey(): string {
		return this._valKey;
	}
	set valKey(value: string) {
		if (value == this._valKey) return;

		this._valKey = value;
		this.resetList();
	}

	@Output() onChange: EventEmitter<any> = new EventEmitter();

	isInvalid: boolean = false;

	private _itemsList: any[];
	@Input()
	get itemsList(): any[] {
		return this._itemsList;
	}

	set itemsList(items: any[]) {
		this._itemsList = items;
		this.resetList();
	}

	private resetList() {
		this.optionsList = [];
		let items = this._itemsList;

		if (!items || items.length === 0) return;

		for (var i = 0; i < items.length; i++) {
			if (this.valKey && this.valKey.trim().length > 0) {
				this.optionsList.push(new OptionModel(items[i][this.itemKey], items[i][this.valKey]));
			} else if (this.displayTextKey) {
				this.optionsList.push(new OptionModel(items[i][this.displayTextKey], items[i]));
			} else {
				this.optionsList.push(new OptionModel(items[i], items[i]));
			}
		}
	}

	@Input() isStateSelect: boolean = false;
	@Input() listPos: string;
	optionsList: OptionModel[];

	value: any;

	ngOnInit() {
		setTimeout(() => {
			let found = this.valKey && this.valKey.trim().length > 0 ? this.itemsList.map(v => v[this.valKey]).some(i => i == this.value) : this._itemsList.some(i => i == this.value);
			if (this.isRequired && !found) {
				this.isInvalid = true;
			} else {
				this.isInvalid = false;
			}
		}, 200);
	}

	propagateChange = (_: any) => {};

	inputChanged(value: any) {
		// let newVal = this.valKey ? value[this.valKey] : value;
		if (this.value !== value) {
			this.value = value;
			this.propagateChange(this.value);
			this.onChange.emit(value);
		}

		if (this.isRequired && !this.value && this.value != 0) {
			this.isInvalid = true;
		} else {
			this.isInvalid = false;
		}
	}

	writeValue(obj: any) {
		if (obj !== undefined) {
			this.value = obj;
		} else {
			this.value = "";
		}
	}

	registerOnChange(fn: any) {
		this.propagateChange = fn;
	}

	registerOnTouched(fn: any) {} //Not currently needed
}
