import { Component, OnInit, OnDestroy, Output, EventEmitter, ViewChild } from "@angular/core";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { ErxOrderStore } from "../../services/erx-order.store";
import { RxOrderModel } from "../../models/rx-order.model";
import { ActivatedRoute } from "@angular/router";
import { OrderPreferenceDetail } from "../../models/erx/order-preference-detail.model";
import { OrderBuilder } from "../../models/erx/order.builder";
import { SelectionModel } from "@angular/cdk/collections";
import { ModalComponent } from "../modal/modal.component";
import { CustomerSummaryModel } from "../../models/customer-summary.model";
import { StatusMessageService } from "../status-message/status-message.service";
import { PreferenceManagementStore } from "../../services/preference-management.store";

@Component({
	selector: "customer-preferred-erx",
	templateUrl: "./customer-preferred-erx.component.html"
})
export class CustomerPreferredErxComponent implements OnInit, OnDestroy {
	isLoading: boolean = false;
	customerPreferredErx: RxOrderModel[] = [];
	selection = new SelectionModel<RxOrderModel>(true, []);
	modalLoading: boolean = false;
	step: number = 1;
	selectedFacilities: CustomerSummaryModel[];
	organizationId: number;

	@Output() onLoadErxForm: EventEmitter<any> = new EventEmitter<any>();

	@ViewChild("copyPreferencesModal") copyPreferencesModal: ModalComponent;

	private _destroyed: Subject<void> = new Subject<void>();

	constructor(public erxStore: ErxOrderStore, public store: PreferenceManagementStore, private _route: ActivatedRoute, private _status: StatusMessageService) {}

	private _customerId: number;

	ngOnInit(): void {
		this._customerId = +this._route.snapshot.params["partnerId"];

		this.initTable();
		this.erxStore.setCustomer(this._customerId);
		this.erxStore.getCustomerPreferences();
	}

	ngOnDestroy(): void {
		this._destroyed.next();
		this._destroyed.unsubscribe();
	}

	initTable() {
		this.erxStore.customerPreferredErx.observable.pipe(takeUntil(this._destroyed)).subscribe((preferred: RxOrderModel[]) => {
			this.customerPreferredErx = preferred;
		});
	}

	loadErxForm(nickname: string = null, preferenceId: number = null) {
		this.onLoadErxForm.emit({ nickname, preferenceId });
	}

	newPreferred() {
		this.erxStore.resetSelectedErx();
		this.erxStore.mode.set("new");
		this.loadErxForm();
	}

	doAction(action: any) {
		if (action.type === "edit") {
			this.edit(action.rx);
		} else if (action.type === "delete") {
			this.delete(action.rx);
		}
	}

	edit(erx: RxOrderModel) {
		this.erxStore.resetSelectedErx();

		this.erxStore.loadPreference(erx.preferenceId, "facility").then((detail: OrderPreferenceDetail) => {
			let rxModel = OrderBuilder.fromOrderPreference(detail);

			if (rxModel.children.length === 0) {
				this.erxStore.selectedErx.set([rxModel]);
			} else {
				this.erxStore.selectedErx.set(rxModel.children);
			}

			this.erxStore.mode.set("edit");
			this.loadErxForm(erx.preferredNickname, erx.preferenceId);
		});
	}

	delete(erx: RxOrderModel) {
		this.erxStore.deleteFacilityPreference(this._customerId, erx.preferenceId);
	}

	isAllSelected() {
		const numSelected = this.selection.selected.length;
		const numRows = this.customerPreferredErx.length;
		return numSelected === numRows;
	}

	masterToggle() {
		this.isAllSelected() ? this.clearSelections() : this.selectAllPreferences();
	}

	clearSelections() {
		this.selection.clear();
	}

	selectAllPreferences() {
		this.customerPreferredErx.forEach(row => this.selection.select(row));
	}

	openCopyPreferences() {
		// Nullify the preferenceId of each selected preference that's are being copied
		// In Step 2 of Copy eRx Preferences, the UI checks if the preferenceId is null to mark it as "new"
		this.store.selectSources(this._customerId, this.selection.selected);
		this.step = 1;
		this.copyPreferencesModal.open("lg");
	}

	closeModal() {
		this.copyPreferencesModal.dismiss();
		this.clearSelections();
	}

	goToStep(step: number) {
		this.step = step;
	}

	copyPrefSuccess(saveResults: number[]) {
		let newPrefCount = saveResults[0];
		let facilityCount = saveResults[1];
		this.closeModal();
		this._status.changeStatusMessage("success", "Successfully copied " + newPrefCount + " eRx Preferences to " + facilityCount + " partners.");
	}

	confirmPreferences() {
		this.goToStep(2);
	}
}
