import {Component, Input, Output, EventEmitter, OnInit} from '@angular/core';
import {UserService} from "../../../services/user.service";
import {CustomerPermissionModel} from "../../../models/customer-permission.model";
import {CustomerService} from "../../../services/customer.service";
import {CustomerSummaryModel} from "../../../models/customer-summary.model";
import {CustomerPermissionsChangedModel} from "../customer-permissions-changed.model";
import {StatusMessageService} from "../../status-message/status-message.service";

@Component({
  selector: 'user-customer-permissions',
  templateUrl: './user-customer-permissions.template.html'
})

export class UserCustomerPermissionsComponent implements OnInit {
  permissions: CustomerPermissionModel[] = [];

  @Input() userId: number;
  @Input() customerId: number;
  @Input() organizationId: number;

  @Output() permissionsChanged: EventEmitter<CustomerPermissionsChangedModel> = new EventEmitter<CustomerPermissionsChangedModel>();

  constructor(private _userService: UserService,
              private _customerService: CustomerService,
              private _statusMessageService: StatusMessageService) {
  }

  ngOnInit() {
    if (this.userId) {
      this._userService.getUserCustomerPermissions(this.userId).subscribe((permissions: CustomerPermissionModel[]) => {
        permissions.sort((a, b) => {
          if (a.CustomerName < b.CustomerName)
            return -1;

          if (a.CustomerName > b.CustomerName)
            return 1;

          return 0;
        });
        this.permissions = permissions;
        this.permissionsChanged.emit(new CustomerPermissionsChangedModel(this.permissions, true));
      });
    } else {
      this._customerService.getOrganizationCustomerList(this.organizationId).subscribe((customers: CustomerSummaryModel[]) => {
        customers.sort((a, b) => {
          if (a.Name < b.Name)
            return -1;

          if (a.Name > b.Name)
            return 1;

          return 0;
        });

        customers.forEach((customer: CustomerSummaryModel) => {
          this.permissions.push({
            CustomerId: customer.CustomerId,
            CustomerName: customer.Name,
            Location: customer.City + ", " + customer.State,
            Permitted: customer.CustomerId === this.customerId
          });
        });

        this.permissionsChanged.emit(new CustomerPermissionsChangedModel(this.permissions, true));
      });
    }
  }

  validatePermissionChanged(permission: CustomerPermissionModel) {
    if (!permission.Permitted && this.permissions.filter((perm) => {
        return perm.Permitted;
      }).length === 0) {
      permission.Permitted = true;
      this._statusMessageService.changeStatusMessage("error", "User must have one or more customer's selected.");
    } else {
      this.permissionsChanged.emit(new CustomerPermissionsChangedModel(this.permissions, false));
    }
  }
}
