import { Component, OnInit } from "@angular/core";
import { StatusMessageService } from "../shared/components/status-message/status-message.service";
import { ActivatedRoute, Router } from "@angular/router";
import { AuthService } from "../shared/services/auth.service";

@Component({
	selector: "reset-password",
	styleUrls: ["./login.css"],
	templateUrl: "./reset-password.template.html"
})
export class ResetPasswordComponent implements OnInit {
	loginId: string;
	token: string;
	password: string;
	confirmPassword: string;

	constructor(private _statusMessageService: StatusMessageService, private _router: Router, private _activeRoute: ActivatedRoute, private _authService: AuthService) {
		console.log("CTOR");
	}

	ngOnInit() {
		this.token = this._activeRoute.snapshot.params["token"];

		if (!this.token) {
			this.invalidToken();
			return;
		}

		this._authService.validateToken(this.token).subscribe((result: any) => {
			if (result.Success) {
				if (!result.Result) {
					this.invalidToken();
				} else {
					this.loginId = result.Result.LoginId;
				}
			} else {
				this._statusMessageService.changeStatusMessage("error", result.PublicMessage);
			}
		});
	}

	resetPassword() {
		this._authService.resetPassword({ Token: this.token, NewPassword: this.password }).subscribe((x: any) => {
			if (x.Success) {
				this._statusMessageService.changeStatusMessage("success", "Your password was successfully reset. You will now be redirected to the login page in 5 seconds...");
				let self = this;
				setTimeout(function() {
					self._router.navigate(["/login"]);
				}, 5000);
			} else {
				this._statusMessageService.changeStatusMessage("error", x.PublicMessage);
			}
		});
	}

	invalidToken() {
		this._statusMessageService.changeStatusMessage("error", "Your link to this page is invalid. Please try the forgot password process again. You will be redirected in 5 seconds...");

		let self = this;
		setTimeout(function() {
			self._router.navigate(["/login"]);
		}, 5000);
	}
}
