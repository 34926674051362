import { takeUntil } from "rxjs/operators";
import { Component, Input, Output, EventEmitter, OnInit, OnDestroy } from "@angular/core";
import { DataTableModel } from "./data-table.model";
import { Subject } from "rxjs";

@Component({
	selector: "data-table",
	templateUrl: "./data-table.template.html",
	styleUrls: ["./data-table.scss"]
})
export class DataTableComponent implements OnInit, OnDestroy {
	private _data: DataTableModel;
	@Input()
	set data(val: DataTableModel) {
		this._data = val;
		this.dataLength = this._data.origData.length;
		this.checkPaging();
	}

	get data(): DataTableModel {
		return this._data;
	}

	@Input() selectedObject: any;
	@Input() filters: any[];
	@Input() customWrapperClass: string = "";
	@Input() customTableClass: string = "";

	@Input() showAdd: boolean = true;
	@Input() showFilter: boolean = true;
	@Input() disableFooter: boolean = false;
	@Input() canMultiSelect: boolean = false;
	@Input() msChecked: boolean = false;
	@Input() msIndeterminate: boolean = false;

	@Output() onEdit: EventEmitter<any> = new EventEmitter();
	@Output() onAdd: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() onFilter: EventEmitter<any> = new EventEmitter<any>();
	@Output() onDelete: EventEmitter<any> = new EventEmitter<any>();
	@Output() onSelectAll: EventEmitter<any> = new EventEmitter<any>();

	canGoNext: boolean = true;
	canGoPrev: boolean = false;

	pageLength: number = 10;
	pageNum: number = 0;

	start: number = 1;
	end: number = this.pageLength;

	rowsPerPage: number[] = [10, 25, 50, 100];

	dataLength: number = 0;

	filterOptions: any[];
	selectedFilter: any;
	filterIsStatus: boolean = false;

	private destroyed: Subject<any> = new Subject<any>();

	constructor() {}

	onEditClick() {
		this.onEdit.emit(this.selectedObject);
	}

	onAddClick() {
		this.onAdd.emit(true);
	}

	onDeleteClick() {
		this.onDelete.emit(this.selectedObject);
	}

	pageLengthChanged(e) {
		this.pageLength = e;
		this.data.changePageLength(this.pageLength);

		this.checkPaging();
	}

	nextPage() {
		if (this.canGoNext) {
			this.pageNum++;
			this.data.changePage(this.pageNum);
		}

		this.checkPaging();
	}

	prevPage() {
		if (this.canGoPrev) {
			this.pageNum--;
			this.data.changePage(this.pageNum);
		}

		this.checkPaging();
	}

	checkPaging() {
		if (this.pageNum > 0) {
			this.canGoPrev = true;
		} else {
			this.canGoPrev = false;
		}

		let nextStart = (this.pageNum + 1) * this.pageLength;

		if (nextStart < this.dataLength) {
			this.canGoNext = true;
		} else {
			this.canGoNext = false;
		}

		if (this.data.origData.length) {
			this.start = this.pageLength * this.pageNum + 1;
		} else {
			this.start = 0;
		}
		let end = this.start + this.pageLength - 1;

		this.end = this.dataLength < end ? this.dataLength : end;
	}

	ngOnInit() {
		this.data.PageChanged.pipe(takeUntil(this.destroyed)).subscribe((params: any) => {
			if (this.filters && this.filters.length > 0) {
				this.initFilters();
			}

			if (params.records !== this.dataLength || params.resetPaging) {
				this.pageNum = 0;
			}
			this.dataLength = params.records;
			this.checkPaging();
		});

		this.data.PageDataChanged.pipe(takeUntil(this.destroyed)).subscribe(val => {
			this.selectedObject = null;
		});
	}

	ngOnDestroy() {
		this.destroyed.next();
		this.destroyed.unsubscribe();
	}

	initFilters() {
		if (this.filterOptions && this.filterOptions.length > 0) {
			return;
		}

		this.filterOptions = [];

		for (var i = 0; i < this.filters.length; i++) {
			this.filterOptions.push({ filter: this.filters[i], options: [] });
		}

		var d = this.data.origData;

		for (var j = 0; j < d.length; j++) {
			for (var i = 0; i < this.filterOptions.length; i++) {
				var value = d[j][this.filterOptions[i].filter.name];

				if (this.filterOptions[i].options.indexOf(value) < 0) {
					this.filterOptions[i].options.push(value);
				}
			}
		}
	}

	doFilter(filter, option) {
		var name = filter.name;

		if (option === "showall") {
			this.selectedFilter = "";
		} else {
			this.selectedFilter = option;
		}

		this.filterIsStatus = filter.isStatus;

		this.onFilter.emit({ name: name, option: option });
	}
}
