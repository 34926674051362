/**
 * Created by John on 2016-03-02.
 */
import {Component, Input} from "@angular/core";
import {ModalComponent} from "./modal.component";

@Component({
    selector:"modal-header",
    templateUrl: "./modal-header.template.html"
})
export class ModalHeaderComponent {
    @Input('show-close') showClose:boolean = false;
    constructor(public modal:ModalComponent){}
}
