import {Directive, Input, TemplateRef, ViewContainerRef} from "@angular/core";
import {PermissionsService} from "../services/permisisons.service";
@Directive({
  selector: '[ifHasPermissionsAndAlso]'
})
export class IfHasPermissionAndAlsoDirective {
  private _hasView = false;
  @Input() set ifHasPermissionsAndAlso(options: any) {
    if (!options.permissions || !options.andAlso) {
      this.viewContainer.clear();
      return;
    }

    let hasPermission = false;
    let self = this;

    options.permissions.forEach(permission => {
      if (permission && self._permissionService.hasPermission(permission)) {
        hasPermission = true;
      }
    });

    if (hasPermission && !this._hasView) {
      // If condition is true add template to DOM
      this.viewContainer.createEmbeddedView(this.templateRef);
      this._hasView = true;
    } else if(!hasPermission && this._hasView) {
      // Else remove template from DOM
      this.viewContainer.clear();
      this._hasView = false;
    }
  }

  constructor(private templateRef: TemplateRef<any>,
              private viewContainer: ViewContainerRef,
              private _permissionService: PermissionsService) {
  }
}
