import {Component, OnInit} from "@angular/core";
import {UserProfile} from "../shared/models/user-profile.model";
import {UserService} from "../shared/services/user.service";
import {ApiResult} from '../shared/models/api-result.model';
import {StatusMessageService} from '../shared/components/status-message/status-message.service';
import { UtilitiesService } from "../shared/services/utilities.service";

@Component({
  templateUrl: "./change-login-id.template.html",
  styleUrls: ['./account.scss']
})
export class ChangeLoginIdComponent implements OnInit {
  oldId: string;
  userProfile: UserProfile = new UserProfile();
  saving:boolean = false;

  constructor(private _userService: UserService, private _statusMessageService: StatusMessageService, private _utility: UtilitiesService) {}

  get isInvalid(): boolean {
    return !this._utility.validateEmail(this.userProfile.LoginId);
  }

  ngOnInit() {
    this._userService.getUserProfile(null, null).subscribe((profile: UserProfile) => {
      if (profile) {
        this.oldId = profile.LoginId;
        this.userProfile = profile;
      }
    });
  }

  change() {
    this.saving = true;
    this._userService.setUserLogin(this.userProfile, this.userProfile.UserId).subscribe((result: ApiResult) => {
      this.saving = false;
      if (result.Success) {
        this._statusMessageService.changeStatusMessage("success", "Login successfully updated.");
      } else {
        this._statusMessageService.changeStatusMessage("error", result.PublicMessage);
      }
    });
  }
}
