import {Component, Input} from '@angular/core';

@Component({
  selector: 'svg-icon',
  templateUrl: './svg-icons.template.html',
  styleUrls: ["./svg-icons.scss"]
})

export class IconComponent {
  @Input() iconName:string;
}
