import { Component, Input, Output, EventEmitter, ViewChild, OnInit } from "@angular/core";
import { CustomerAuthenticationModel } from "../../../../models/customer-authentication.model";
import { StatusMessageService } from "../../../status-message/status-message.service";
import { MappedClaimModel } from "src/app/ocp/shared/models/mapped-claim.model";
import { UserService } from "src/app/ocp/shared/services/user.service";
import * as _ from "lodash";
import { ApiResult } from "src/app/ocp/shared/models/api-result.model";
import { RoleModel } from "src/app/ocp/shared/models/role.model";
import { SamlService } from "src/app/ocp/shared/services/saml.service";

@Component({
	selector: "edit-claim-mapping",
	templateUrl: "./edit-claim-mapping.template.html",
	styleUrls: ["./edit-claim-mapping.scss"]
})
export class EditClaimMappingComponent implements OnInit {
	saving: boolean = false;
	currentStep: number = 2;
	userRoles: any[] = []; // = [{ id: 1, text: "ADMINISTATOR", selected: false }, { id: 2, text: "ACCOUNT MANAGER", selected: false }, { id: 3, text: "TECHNICIAN", selected: false }];

	claimCurrentlyEditing?: number = null;
	currentClaim: MappedClaimModel;
	editingExisting: boolean = false;

	currentSelected: any[] = [];

	@Input() customerAuth: CustomerAuthenticationModel;
	@Input() editMode: string;

	@Output() closeModal: EventEmitter<string> = new EventEmitter<string>();
	@Output() goToStepOne: EventEmitter<string> = new EventEmitter<string>();

	constructor(private _statusMessageService: StatusMessageService, private _samlService: SamlService) {}

	ngOnInit() {
		this.currentClaim = new MappedClaimModel();
		this._samlService.getRoles(this.customerAuth.subdomain).subscribe((result: any) => {
			if (result) {
				this.userRoles = _.map(result, (role: RoleModel) => {
					return {
						id: role.roleId,
						text: role.roleName,
						selected: false
					};
				});
			} else {
				this.userRoles = [];
			}
		});
	}

	removeClaim(index: number, claim: MappedClaimModel) {
		if (this.unsavedClaimExists() && this.claimCurrentlyEditing != null) {
			this.customerAuth.roleClaims.splice(this.claimCurrentlyEditing, 1);
		}
		this.editingExisting = false;
		this.claimCurrentlyEditing = null;
		this._samlService.deleteMappedClaim(claim.claimValue).subscribe((result: any) => {
			if (result) {
				this.customerAuth.roleClaims.splice(index, 1);
				if (index === this.claimCurrentlyEditing) this.claimCurrentlyEditing = null;
				this._statusMessageService.changeStatusMessage("success", "Claim successfully deleted.");
			} else {
				this._statusMessageService.changeStatusMessage("error", result.PublicMessage || "Unable to delete claim.");
			}
		});
	}

	addClaim() {
		if (this.userRoles) {
			this.userRoles.forEach(role => {
				role.selected = false;
			});
		}
		this.currentClaim = new MappedClaimModel();
		this.currentClaim.saved = false;
		this.customerAuth.roleClaims.push(this.currentClaim);
		this.claimCurrentlyEditing = this.customerAuth.roleClaims.length - 1;
	}

	saveClaim(index: number) {
		if (!this.currentClaim.claimValue || this.currentClaim.claimValue.length === 0) {
			this._statusMessageService.changeStatusMessage("error", "Must enter a claim value.");
			return;
		} else if (!this.currentClaim.roleIds || this.currentClaim.roleIds.length === 0) {
			this._statusMessageService.changeStatusMessage("error", "Must map claim value to at least one role.");
			return;
		} else if (this.doesClaimAlreadyExist() && this.claimCurrentlyEditing != null) {
			this._statusMessageService.changeStatusMessage("error", "A claim '" + this.currentClaim.claimValue + "' already exists.");
			return;
		}
		this._samlService.addMappedClaim(this.currentClaim, this.customerAuth.providerId).subscribe((result: ApiResult) => {
			if (result.Success) {
				this._statusMessageService.changeStatusMessage("success", "Claim successfully saved.");
				this.currentClaim.saved = true;
				this.customerAuth.roleClaims[index] = new MappedClaimModel(this.currentClaim);
				this.claimCurrentlyEditing = null;
				this.editingExisting = false;
			} else {
				this._statusMessageService.changeStatusMessage("error", result.PublicMessage || "Error saving claim");
			}
		});
	}

	editClaim(index: number) {
		if (this.unsavedClaimExists() && this.claimCurrentlyEditing != null) {
			this.customerAuth.roleClaims.splice(this.claimCurrentlyEditing, 1);
		}
		this.currentClaim = new MappedClaimModel();
		this.currentClaim.claimValue = this.customerAuth.roleClaims[index].claimValue;
		this.currentClaim.roleIds = this.customerAuth.roleClaims[index].roleIds;
		this.currentClaim.saved = this.customerAuth.roleClaims[index].saved;
		this.refreshMultiSelect();
		this.claimCurrentlyEditing = index;
		this.editingExisting = true;
	}

	cancel() {
		if (this.claimCurrentlyEditing && !this.customerAuth.roleClaims[this.claimCurrentlyEditing].saved) {
			this.customerAuth.roleClaims.splice(this.claimCurrentlyEditing, 1);
			this.claimCurrentlyEditing = null;
			this.currentClaim = null;
			this.editingExisting = false;
		}
		this.closeModal.emit();
	}

	isEditing(row: number) {
		return this.claimCurrentlyEditing === row;
	}

	getRoles(index: number): string {
		if (!this.userRoles || !this.userRoles.length || this.userRoles.length === 0) return;
		var result = _.filter(this.userRoles, r => {
			return _.some(this.customerAuth.roleClaims[index].roleIds, c => {
				return c === r.id;
			});
		});
		if (result.length === 0) return "";
		var rolesList = _.reduce(
			result.slice(1),
			(acc, r) => {
				return acc + ", " + r.text;
			},
			result[0].text
		);
		return rolesList;
	}

	cancelEdit(index: number) {
		if (!this.currentClaim || !this.currentClaim.saved) {
			this.customerAuth.roleClaims.splice(index, 1);
		}
		this.claimCurrentlyEditing = null;
		this.currentClaim = null;
		this.editingExisting = false;
	}

	save() {
		this.saving = true;
		let self = this;
		setTimeout(function() {
			self.saving = false;
			self.closeModal.emit();
			self._statusMessageService.changeStatusMessage("success", "Authentication settings have been saved.");
		}, 300);
	}

	private unsavedClaimExists(): boolean {
		return this.customerAuth.roleClaims.some((claim: MappedClaimModel) => {
			return !claim.saved;
		});
	}

	private refreshMultiSelect() {
		this.userRoles.forEach(role => {
			role.selected = false;
			this.currentClaim.roleIds.forEach(id => {
				if (id === role.id) {
					role.selected = true;
				}
			});
		});
	}

	private doesClaimAlreadyExist() {
		let claims = _.map(this.customerAuth.roleClaims, (claim: MappedClaimModel) => {
			return new MappedClaimModel(claim);
		});
		claims.splice(this.claimCurrentlyEditing, 1);
		return claims.some((claim: MappedClaimModel) => {
			return claim.claimValue.toLowerCase() === this.currentClaim.claimValue.toLowerCase();
		});
	}
}
