import {Component, OnInit} from '@angular/core';
import {StatusMessageService} from '../shared/components/status-message/status-message.service';
import {AccountService} from '../shared/services/account.service';
import {ResetPasswordModel} from "../shared/models/reset-password.model";
import {ApiResult} from "../shared/models/api-result.model";

@Component({
  selector: 'change-password',
  templateUrl: './change-password.template.html',
  styleUrls: ['./account.scss']
})

export class ChangePasswordComponent implements OnInit {
  saving: boolean = false;

  constructor(private _statusMessageService: StatusMessageService, private _accountService: AccountService) {
  }

  ngOnInit() {

  }

  resetPassword(model: ResetPasswordModel) {
    if (model.newPassword !== model.confirmNewPassword) {
      this._statusMessageService.changeStatusMessage('error', 'Confirm password mismatch.');
      return;
    }

    this.saving = true;
    this._accountService.changePassword(model.currentPassword, model.newPassword).subscribe((x: ApiResult) => {
      this.saving = false;
      if (x.Success) {
        this._statusMessageService.changeStatusMessage('success', 'Password successfully changed.');
        model.currentPassword = '';
        model.newPassword = '';
        model.confirmNewPassword = '';
      } else {
        this._statusMessageService.changeStatusMessage('error', x.PublicMessage);
      }
    });
  }
}

