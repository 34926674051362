import { NgModule } from "@angular/core";

import { SharedModule } from "../shared/shared.module";
import { loginRouting } from "./login.routes";
import { LoginComponent } from "./login.component";
import { ForgotPasswordComponent } from "./forgot-password.component";
import { ResetPasswordComponent } from "./reset-password.component";
import { LoginChangePasswordComponent } from "./change-password.component";
import { VerifyAccountComponent } from "./verify-account.component";

@NgModule({
	imports: [SharedModule, loginRouting],
	declarations: [LoginChangePasswordComponent, LoginComponent, ForgotPasswordComponent, ResetPasswordComponent, VerifyAccountComponent],
	providers: []
})
export class LoginModule {}
