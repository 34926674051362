import { NgModule } from "@angular/core";
import { ScrollingModule } from "@angular/cdk/scrolling";

import { SharedModule } from "../shared/shared.module";
import { Administration } from "./administration.component";
import { AdministrationRouting } from "./administration.routes";
import { OrganizationsAdminTab } from "./organizations-admin/organizations-admin.component";
import { OrganizationSummary } from "./organizations-admin/organization-summary/organization-summary.component";
import { RolesAdminTab } from "./roles-admin/roles-admin.component";
import { FileManagerTab } from "./file-manager/file-manager.component";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { InternalUsersTabComponent } from "./internal-users/internal-users.component";
import { FileDetails } from "./file-manager/form-file-details/file-details.component";
import { SystemAlertsAdminTab } from "./system-alerts/system-alerts.component";
import { SystemAlertGuard } from "../shared/gaurds/system-alert.guard";
import { PharmaciesAdminTab } from './pharmacies-admin/pharmacies-admin.component';
import { ReportsAdminTab } from './reports-admin/reports-admin.component';
import { FormPharmacyDetailsComponent } from './pharmacies-admin/form-pharmacy-details/form-pharmacy-details.component';
import { FormReportDetailsComponent } from './reports-admin/form-report-details/form-report-details.component';
import { AdminPharmaciesGuard } from "../shared/gaurds/admin-pharmacies.guard";
import { AdminReportsGuard } from "../shared/gaurds/admin-reports.guard";
import { ManageReportsComponent } from './reports-admin/manage-reports/manage-reports.component';
import { PermissionSettingsComponent } from './reports-admin/permission-settings/permission-settings.component';

@NgModule({
	imports: [
		CommonModule, 
		FormsModule, 
		SharedModule, 
		ReactiveFormsModule, 
		AdministrationRouting, 
		ScrollingModule
	],
	declarations: [
		Administration, 
		OrganizationsAdminTab, 
		OrganizationSummary, 
		RolesAdminTab, 
		FileDetails, 
		FileManagerTab, 
		SystemAlertsAdminTab, 
		InternalUsersTabComponent, 
		PharmaciesAdminTab, 
		ReportsAdminTab, 
		FormPharmacyDetailsComponent, 
		FormReportDetailsComponent, 
		ManageReportsComponent, 
		PermissionSettingsComponent
	],
	providers: [
		SystemAlertGuard, 
		AdminPharmaciesGuard, 
		AdminReportsGuard
	],
	exports: []
})
export class AdministrationModule {}
