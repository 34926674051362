import {Component, Input} from "@angular/core";

@Component({
  selector: "loading-spinner",
  templateUrl: "./loading-spinner.template.html",
  styleUrls: ["./loading-spinner.scss"]
})

export class LoadingSpinnerComponent{
  @Input() isLoading:boolean = false;
}
