import { Injectable } from "@angular/core";
import { CustomerService } from "./customer.service";
import { OrganizationSummaryModel } from "../models/organization-summary.model";
import { StoreObject } from "../models/store-object.model";
import { CustomerSummaryModel } from "../models/customer-summary.model";
import { FacilityPreferenceSummary } from "../models/erx/facility-preference-summary.model";
import { FacilityPreferences } from "../models/erx/facility-preferences.model";
import { HttpService } from "./http.service";
import { combineLatest } from "rxjs";
import { FacilityPreference } from "../models/erx/facility-preference.model";
import { OrderBuilder } from "../models/erx/order.builder";
import { RxOrderModel } from "../models/rx-order.model";
import { CopyPreferences } from "../models/erx/copy-preferences.model";
import { CopyDestination } from "../models/erx/copy-destination.model";

@Injectable()
export class PreferenceManagementStore {
	public readonly sourcePreferences: StoreObject<RxOrderModel[]> = new StoreObject<RxOrderModel[]>([]);

	public readonly organizations: StoreObject<OrganizationSummaryModel[]> = new StoreObject<OrganizationSummaryModel[]>([]);
	public readonly customers: StoreObject<CustomerSummaryModel[]> = new StoreObject<CustomerSummaryModel[]>([]);

	public readonly customerPreferences: StoreObject<FacilityPreferences[]> = new StoreObject<FacilityPreferences[]>([]);

	public readonly action: StoreObject<string> = new StoreObject<string>("append");

	private readonly selectedCustomers: StoreObject<CustomerSummaryModel[]> = new StoreObject<CustomerSummaryModel[]>([]);
	private readonly facilityPreferences: StoreObject<FacilityPreferenceSummary[]> = new StoreObject<FacilityPreferenceSummary[]>([]);

	public readonly loading: StoreObject<boolean> = new StoreObject<boolean>(false);

	private _organizationId: number;
	private _customerId: number;

	constructor(private _customerService: CustomerService, private _http: HttpService) {
		combineLatest([this.selectedCustomers.observable, this.facilityPreferences.observable]).subscribe(([customers, preferences]) => {
			if (customers.length === 0) {
				this.customerPreferences.set([]);
				return;
			}

			this.customerPreferences.set(
				customers.map(c => {
					let model = new FacilityPreferences();
					model.customerId = c.CustomerId;
					model.name = c.Name;

					let customerPreferences = preferences.filter(p => p.CustomerId === c.CustomerId);

					model.existingCount = customerPreferences.length;

					model.preferences = [
						...this.sourcePreferences.get().map(s => s),
						...customerPreferences.map(p => {
							let model = OrderBuilder.fromFacilityPreference(p);
							model.retainPreference = true;

							return model;
						})
					];

					return model;
				})
			);
		});

		this.action.observable.subscribe(action => {
			if (this.customerPreferences.get().length === 0) return;

			this.customerPreferences.set(
				this.customerPreferences.get().map(c => {
					c.preferences = c.preferences.map(p => {
						if (!p.preferenceId) return p;

						p.retainPreference = action === "append";

						return p;
					});

					return c;
				})
			);
		});
	}

	loadOrganizations() {
		this._organizationId = null;
		this.customers.set([]);
		this._customerService.getOrganizationList(true).subscribe((organizations: OrganizationSummaryModel[]) => {
			organizations = organizations.sort((a, b) => {
				if (a.Name < b.Name) return -1;

				if (a.Name > b.Name) return 1;

				return 0;
			});

			organizations = organizations.filter(org => org.Name.indexOf("INACTIVE") === -1);

			this.organizations.set(organizations);
		});
	}

	loadCustomers(organizationId: number): Promise<void> {
		this._organizationId = organizationId;
		return new Promise<void>(resolve => {
			this._customerService.getOrganizationCustomerList(this._organizationId).subscribe((customers: CustomerSummaryModel[]) => {
				customers = customers.sort((a, b) => {
					if (a.Name < b.Name) return -1;

					if (a.Name > b.Name) return 1;

					return 0;
				});

				this.customers.set(customers.filter(c => c.IsActive && c.CustomerId !== this._customerId));
				resolve();
			});
		});
	}

	selectCustomers(customers: CustomerSummaryModel[]) {
		this.loading.set(true);
		this.selectedCustomers.set(customers);
		this.facilityPreferences.set([]);

		this._http.get("/api/erx/OrganizationPreferences?organizationId=" + this._organizationId).subscribe((result: any) => {
			this.facilityPreferences.set(result);
			this.loading.set(false);
		});
	}

	selectSources(customerId: number, sources: RxOrderModel[]) {
		this._customerId = customerId;
		this.sourcePreferences.set(
			sources.map(s => {
				s.sourcePreference = true;

				return s;
			})
		);
	}

	copy(): Promise<any> {
		return new Promise<any>(resolve => {
			this.loading.set(true);
			let model: CopyPreferences = new CopyPreferences();

			model.sourcePreferences = this.sourcePreferences.get().map(s => s.preferenceId);

			model.destinations = this.customerPreferences.get().map(c => {
				let dest = new CopyDestination();

				dest.customerId = c.customerId;
				dest.removedPreferences = c.preferences.filter(p => p.preferenceId && !p.retainPreference && !p.sourcePreference).map(p => p.preferenceId);

				return dest;
			});

			this._http.post("/api/erx/CopyCustomerPreferences", model).subscribe((result: any) => {
				this.loading.set(false);
				resolve({ newPrefCount: model.sourcePreferences.length, facilityCount: model.destinations.length });
			});
		});
	}
}
