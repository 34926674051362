import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { BulkUploadUser } from "src/app/ocp/shared/models/bulk-upload-user.model";
import { UserService } from "src/app/ocp/shared/services/user.service";
import { StatusMessageService } from "../../../status-message/status-message.service";

@Component({
	selector: "confirm-user-list",
	templateUrl: "./confirm-user-list.template.html",
	styleUrls: ["./confirm-user-list.scss"]
})
export class ConfirmUserListComponent implements OnInit {
	currentStep: number = 3;
	
	loading: boolean = false;

	@Input() uploadResults: any[] = [];
	@Input() customerId?: number = null;

	@Output() goBack: EventEmitter<string> = new EventEmitter<string>();
	@Output() closeModal: EventEmitter<string> = new EventEmitter<string>();

	get successfulUploads(): number {
		return this.uploadResults.filter(x => {
			return !x.errorMessage;
		}).length;
	}

	constructor(private _statusMessageService: StatusMessageService, private _userService: UserService) {}

	ngOnInit() {}

	showErrorPopover(e) {
		let userInQueue = e;
		if (userInQueue === true) {
			return false;
		} else {
			return true;
		}
	}

	confirmUsers() {
		let newUserCount: string;
		if (this.successfulUploads == 1) {
			newUserCount = "1 new user account";
		} else {
			newUserCount = this.successfulUploads + " new user accounts";
		}

		this.loading = true;
		this._userService.uploadBulkUsers(this.uploadResults, this.customerId).then(result => {
			this.loading = false;
			if (result.Success){
				this._statusMessageService.changeStatusMessage(
					"success", 
					"Creating  " + newUserCount + ". Please allow a few minutes for processing those without future effective dates.",
					10000
				);

				this.closeModal.emit();
			} else {
				this._statusMessageService.changeStatusMessage(
					"error", 
					"There was an error when creating one or more of the users.",
					10000
				);
			}

		});
	}

	getRoleNameList(user: BulkUploadUser): string {
		return user.roles.reduce((acc: string, role: any, idx: number) => {
			return acc + role.RoleName + (idx == user.roles.length - 1 ? "" : ", ");
		}, "");
	}
}
