import { Component, Input, OnInit } from "@angular/core";
import { CustomerService } from "../../shared/services/customer.service";
import { PermissionMatrixModel } from "../../shared/models/permission-matrix.model";
import { PermissionsService } from "../../shared/services/permisisons.service";

@Component({
	selector: "roles-admin",
	templateUrl: "./roles-admin.template.html",
	styleUrls: ["../administration-tabs.scss"]
})
export class RolesAdminTab implements OnInit {
	private _customerId: number;
	customerName: string = "";
	permissions: PermissionMatrixModel[] = [];

	@Input()
	set customerId(customerId: number) {
		this._customerId = customerId;
		this.loadCustomerInfo();
	}

	get customerId() {
		return this._customerId;
	}

	constructor(private _customerService: CustomerService, private _permissionsService: PermissionsService) {}

	ngOnInit() {
		this._permissionsService.getInternalPermissionMatrix().subscribe((permissions: PermissionMatrixModel[]) => {
			this.permissions = permissions;
		});
	}

	loadCustomerInfo() {
		this._customerService.getCustomerProfile(this.customerId).subscribe((customer: any) => {
			this.customerName = customer.CustomerName;
		});
	}
}
