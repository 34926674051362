import {Pipe, PipeTransform} from "@angular/core";
import {PatientService} from "../services/patient.service";

@Pipe({name: "patientStatusName"})
export class PatientStatusNamePipe implements PipeTransform {
  constructor(private _patientService: PatientService) {
  }

  value: string = "";

  transform(patientStatus: number) {
    if(!patientStatus && patientStatus !== 0) return "Unknown";

    return this._patientService.getPatientStatuses().find(x => {
      return x.value === patientStatus
    }).text;
  }
}
