import {
  Component, Input, Output, EventEmitter, OnInit, AfterViewInit, ViewChild
} from '@angular/core';
import {PatientInfoModel} from "../../../shared/models/patient.model";
import {UserService} from "../../../shared/services/user.service";
import {PatientService} from "../../../shared/services/patient.service";
import {StatusMessageService} from "../../../shared/components/status-message/status-message.service";
import {CustomerProfileModel} from "../../../shared/models/customer-profile.model";
import {ApiResult} from "../../../shared/models/api-result.model";
import { NgForm, Validators } from '@angular/forms';
import { UtilitiesService } from 'src/app/ocp/shared/services/utilities.service';
import states from "../../../shared/models/constants/state-list.constant";

@Component({
  selector: 'patient-profile',
  templateUrl: './form-patient-profile.template.html'
})

export class FormPatientProfile implements OnInit, AfterViewInit {
  @ViewChild('profileForm') form: NgForm;

  saving: boolean = false;
  currentStep: number = 1;
  stateList = states.STATE_LIST;
  focusFirstName: EventEmitter<any> = new EventEmitter<any>();
  genderOptions: any[] = [
    { text: 'Male', value: 'M' }, 
    { text: 'Female', value: 'F' }
  ];
  preferenceList: any[] = [];
  ssnRequired: boolean = false;
  mrnRequired: boolean = false;
  isPhoneRequired: boolean = false;
  isInternalUser: boolean = false;
  hasTeams: boolean = false;

  @Input() mode: string;
  @Input() patient: PatientInfoModel = new PatientInfoModel();
  @Input() stepCompleted: boolean = false;

  @Output() closeModal: EventEmitter<string> = new EventEmitter<string>();
  @Output() goToStepTwo: EventEmitter<string> = new EventEmitter<string>();
  @Output() saveSuccess: EventEmitter<string> = new EventEmitter<string>();

  get requireMrn(): boolean {
    return this.mrnRequired;
  }

  get heightInvalid(): boolean {
    let height = this.patient.heightIn;
    
    return !!height && !Number.isInteger(+height);
  }

  constructor(private _userService: UserService,
              private _patientService: PatientService,
              private _statusMessageService: StatusMessageService,
              private _util: UtilitiesService) {
  }

  ngOnInit() {
    this._userService.isSsnRequired.subscribe((isRequired: boolean) => {
      this.ssnRequired = isRequired;
    });

    this._userService.isMrnRequired.subscribe((isRequired: boolean) => {
      this.mrnRequired = isRequired;
    });

    this._userService.selectedCustomer.subscribe((customer: CustomerProfileModel) => {
      this.hasTeams = customer.HasTeams;
      // the phone number is required if DoseSpot is enabled for the active partner
      this.isPhoneRequired = customer.IsDoseSpotActive;
    });

    this._patientService.getPackingPreferenceList().subscribe(preferenceList => {
      this.preferenceList = preferenceList;
    });

    this.isInternalUser = this._userService.isInternalUser();
  }

  ngAfterViewInit() {
    if (this.mode === 'New') {
      this.focusFirstName.emit();
    }

    // setTimeout(() => {
    //   this.setHWEValidators();
    // }, 250)
  }

  save() {
    this.saving = true;
    this._patientService.updatePatientProfile(this.patient.patientId, this.patient).subscribe((updateProResults: ApiResult) => {
      if (updateProResults.Success) {
        this._patientService.updateFavoritePatient(this.patient.patientId, this.patient.isFavorite).subscribe((updateFavResult: ApiResult) => {
          this.saving = false;
          if (updateFavResult.Success) {
            this._statusMessageService.changeStatusMessage("success", "Information for " + this.patient.fullName() + " successfully updated.");
            this.saveSuccess.emit();
          } else {
            this._statusMessageService.changeStatusMessage("error", updateFavResult.PublicMessage);
          }
        });
      } else {
        this.saving = false;
        this._statusMessageService.changeStatusMessage("error", updateProResults.PublicMessage);
      }
    });
  }

  // isPediatric(): boolean{
  //   return this.patient && this.patient.birthDate && this._util.calcAge(this.patient.birthDate) < 18
  // }

  // setHWEValidators(){
  //   if (!this.form || !this.form.form || !this.form.form.get('weight') || !this.form.form.get('height')){
  //     return;
  //   }

  //   let validators = [];

  //   if (this.isPediatric()){
  //     validators = [Validators.required]
  //   }

  //   this.form.form.get('weight').setValidators(validators)
  //   this.form.form.get('weight').updateValueAndValidity();

  //   this.form.form.get('height').setValidators(validators)
  //   this.form.form.get('height').updateValueAndValidity();
  // }
}
