export class UserListItemModel {
	UserId: number;
	LoginId: string;
	FirstName: string;
	LastName: string;
	IsLocked: boolean;
	FullName: string;
	RoleList: string;
	CanViewReports: boolean;
	IsOrgAdmin: boolean;
}
