import { NgModule } from "@angular/core";

import { SharedModule } from "../shared/shared.module";
import { accountRouting } from "./account.routes";
import { ChangePasswordComponent } from "./change-password.component";
import { AccountSettingsComponent } from "./account-settings.component";
import { ChangeLoginIdComponent } from "./change-login-id.component";
import { NotificationSettingsComponent } from "./notification-settings.component";
import { AccountLayoutComponent } from "./account-layout.component";
import { UserPreferredErxComponent } from "./user-preferred-erx.component";

@NgModule({
  imports: [
    SharedModule,
    accountRouting
  ],
  declarations: [
    AccountSettingsComponent,
    ChangePasswordComponent,
    ChangeLoginIdComponent,
    NotificationSettingsComponent,
    AccountLayoutComponent,
    UserPreferredErxComponent
  ],
  providers: [

  ]
})
export class AccountModule { }
