import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { DataTableModel } from "../../data-table/data-table.model";
import { SortModel } from "../../data-table/sort.model";
import { DataTableColumnModel } from "../../data-table/columns.model";
import { ErxOrderStore } from "../../../services/erx-order.store";
import { RxOrderModel } from "../../../models/rx-order.model";
import { OrderPreferenceDetail } from "../../../models/erx/order-preference-detail.model";
import { OrderBuilder } from "../../../models/erx/order.builder";
import { SelectionModel } from "@angular/cdk/collections";

@Component({
	selector: "ocp-preferred-erx-table",
	templateUrl: "./preferred-erx-table.component.html",
	styleUrls: ["./preferred-erx-table.component.scss"]
})
export class PreferredErxTableComponent implements OnInit {
	preferredItems: DataTableModel = new DataTableModel([], []);
	expandedIdx?: number = null;

	private _data: RxOrderModel[];
	@Input()
	get data(): RxOrderModel[] {
		return this._data;
	}
	set data(d: RxOrderModel[]) {
		this._data = d;
		setTimeout(() => {
			this.preferredItems.populate(d);
		}, 0);
	}
	@Input() parent: string; // "user", "customer", "erxOrder"
	@Input() selectedErx: RxOrderModel;
	@Input() canSelect: boolean = false;
	@Input() selectedItems: SelectionModel<RxOrderModel>;
	@Input() isAllSelected: boolean;
	@Input() canMultiSelect: boolean = false;

	@Output() onSelect: EventEmitter<RxOrderModel> = new EventEmitter<RxOrderModel>();
	@Output() onRowAction: EventEmitter<any> = new EventEmitter<any>();
	@Output() onToggleAll: EventEmitter<any> = new EventEmitter<any>();
	@Output() onSelectItem: EventEmitter<RxOrderModel> = new EventEmitter<RxOrderModel>();

	constructor(public erxStore: ErxOrderStore) {}

	ngOnInit(): void {
		this.initTable();
	}

	initTable() {
		if (!this.data || !this.data) return;

		let defaultSorting: SortModel[] = [];

		defaultSorting.push({
			column: "preferredNickname",
			direction: "asc"
		});

		let columns: DataTableColumnModel[] = [];
		if (this.canSelect) {
			columns.push(new DataTableColumnModel("select", "", "", false));
		}
		if (this.canMultiSelect) {
			columns.push(new DataTableColumnModel("select", "", "col-select", false));
		}
		columns.push(new DataTableColumnModel("expander", "", "expanded-el-th", false));
		columns.push(new DataTableColumnModel("preferredNickname", "Nickname", "pl-md-1", true));
		columns.push(new DataTableColumnModel("Quantity", "Qty", "right-aligned", true));
		columns.push(new DataTableColumnModel("dispenseUnitName", "Dispense Unit", "", true));
		columns.push(new DataTableColumnModel("sortedMedStrength", "Strength", "", true));
		columns.push(new DataTableColumnModel("daysSupply", "Days Supply", "right-aligned", true));
		if (this.parent === "customer") {
			columns.push(new DataTableColumnModel("preferredByUser", "Added By", "", true));
		}
		if (this.parent !== "erxOrder") {
			columns.push(new DataTableColumnModel("actions", "", "", false));
		}

		this.preferredItems = new DataTableModel(defaultSorting, columns);
		this.preferredItems.pageLength = 9999;
	}

	isExpanded(idx: number): boolean {
		return this.expandedIdx === idx;
	}

	expand(idx: number) {
		if (this.expandedIdx === idx) {
			this.expandedIdx = null;
		} else {
			this.expandedIdx = idx;
		}
	}

	preferredSelected(selected: RxOrderModel) {
		this.selectedErx = selected;

		this.erxStore.loadPreference(selected.preferenceId, selected.preferenceType).then((detail: OrderPreferenceDetail) => {
			let rxModel = OrderBuilder.fromOrderPreference(detail);
			this.selectedErx = rxModel;
			this.onSelect.emit(this.selectedErx);
		});
	}

	actionClick(type: string, rx: RxOrderModel) {
		this.onRowAction.emit({ type, rx });
	}

	getPreferredItemMetadata(preference: RxOrderModel): string {
		let metadata: string;

		if (preference.children?.length > 0) {
			metadata = preference.children.length + " items included";
		} else {
			metadata = preference.selectedMedication.DrugName;
		}

		return metadata;
	}
}
