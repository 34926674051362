/**
 * Created by dan on 1/3/2017.
 */
import {Component, Input, Output, EventEmitter} from "@angular/core";
import {PatientAllergyModel} from "../../../shared/models/allergy.model";

@Component({
  selector: "allergy-summary",
  templateUrl: "./allergy-summary.template.html",
  styleUrls: ["./allergy-summary.scss"]
})

export class AllergySummary {
  @Input() isRemovable:boolean = false;
  @Input() allergy: PatientAllergyModel = null;
  // @Input() allergyName:string;
  // @Input() reactionTypes:any[]=[];
  // @Input() dateEffective:string;

  @Output() remove: EventEmitter<any> = new EventEmitter<any>();
  @Output() editAllergy: EventEmitter<PatientAllergyModel> = new EventEmitter<PatientAllergyModel>();
  @Output() deleteAllergy: EventEmitter<PatientAllergyModel> = new EventEmitter<PatientAllergyModel>();

}
