import {Component, Input, Output, EventEmitter, OnInit, AfterViewInit} from '@angular/core';
import {PatientInfoModel} from "../../../shared/models/patient.model";
import {PatientService} from "../../../shared/services/patient.service";
import {UserService} from "../../../shared/services/user.service";
import {ApiResult} from "../../../shared/models/api-result.model";
import {StatusMessageService} from "../../../shared/components/status-message/status-message.service";
import * as moment from "moment";

@Component({
  selector: 'admission-details',
  templateUrl: './form-admission-details.template.html',
  styleUrls: ["./form-admission-details.css"]
})

export class FormAdmissionDetails implements OnInit, AfterViewInit {
  saving: boolean = false;
  isInternalUser: boolean = false;
  currentStep: number = 2;
  focusPatientStatus: EventEmitter<any> = new EventEmitter<any>();

  preferenceList: any[] = [];
  statusList = this._patientService.getPatientStatuses();

  @Input() patient: PatientInfoModel = new PatientInfoModel();
  @Input() mode: string;
  @Input() admissionsType: string;

  @Output() goBack: EventEmitter<string> = new EventEmitter<string>();
  @Output() goToStepThree: EventEmitter<string> = new EventEmitter<string>();
  @Output() closeModal: EventEmitter<string> = new EventEmitter<string>();
  @Output() saveSuccess: EventEmitter<string> = new EventEmitter<string>();

  constructor(private _patientService: PatientService,
              private _userService: UserService,
              private _statusMessageService: StatusMessageService) {
  }

  ngOnInit() {
    this._patientService.getPackingPreferenceList().subscribe(preferenceList => {
      this.preferenceList = preferenceList;
    });

    this.isInternalUser = this._userService.isInternalUser();

    if (this.mode === 'Edit') {
      if (!this.patient.dischargeDate && this.admissionsType === 'discharge') {
        this.patient.dischargeDate = moment(new Date()).format("YYYY-MM-DD");
      }

      if (!this.patient.dateAdmitted && this.admissionsType === 'admit') {
        this.patient.dateAdmitted = moment(new Date()).format("YYYY-MM-DD");
      }
    }
  }

  ngAfterViewInit() {
    if (this.mode === 'New') {
      this.focusPatientStatus.emit();
    }
  }

  deceasedChanged(bool: boolean) {
    this.patient.dischargeDate = this.patient.dischargeDate ? this.patient.dischargeDate : moment(new Date()).format("YYYY-MM-DD");
    this.patient.isDeceased = bool;
  }


  patientStatusChanged(patientStatusId: number) {
    if (patientStatusId) {
      if (!this.patient.dateAdmitted) {
        this.patient.dateAdmitted = moment(new Date()).format("YYYY-MM-DD");
      }

      if (patientStatusId === 2 || patientStatusId === 3 && !this.patient.dischargeDate) {
        this.patient.dischargeDate = moment(new Date()).format("YYYY-MM-DD");
      }
    } else {
      this.patient.dateAdmitted = null;
      this.patient.dischargeDate = null;
    }
  }

  save() {
    this.saving = true;
    if (this.admissionsType === "discharge") {
      this._patientService.dischargePatient(this.patient.patientId, this.patient.dischargeDate, this.patient.isDeceased)
        .subscribe((results: ApiResult) => {
          this.saving = false;
          if (results.Success) {
            this._statusMessageService.changeStatusMessage("success", this.patient.fullName() + " has been discharged.");
            this.saveSuccess.emit();
            this.closeModal.emit();
          } else {
            this.saving = false;
            this._statusMessageService.changeStatusMessage("error", results.PublicMessage);
          }
        });
    }
  }
}
