import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, OnInit } from "@angular/core";
import { DiagnosisModel } from "../../../shared/models/diagnosis.model";

@Component({
	selector: "diagnosis-summary",
	templateUrl: "./diagnosis-summary.template.html",
	styleUrls: ["./diagnosis-summary.scss"]
})
export class DiagnosisSummary implements OnInit {
	showDetails: boolean = false;
	ellipsisActive: boolean;

	@Input() diagnosisName: string;
	@Input() dateDiagnosed: string;
	@Input() dateResolved: string;
	@Input() icdCode: string;
	@Input() diseaseState: string;
	@Input() isContributing: boolean = false;
	@Input() isRemovable: boolean = false;
	@Input() diagnosis: DiagnosisModel;

	@Output() removeDiagnosis: EventEmitter<DiagnosisModel> = new EventEmitter<DiagnosisModel>();
	@Output() updateDiagnosis: EventEmitter<any> = new EventEmitter<any>();
	@Output() resolveDiagnosis: EventEmitter<DiagnosisModel> = new EventEmitter<DiagnosisModel>();
	@Output() diagnosisNameClicked: EventEmitter<DiagnosisModel> = new EventEmitter<DiagnosisModel>();

	@ViewChild("diagnosisNameText") diagnosisNameText: ElementRef;

	isEllipsisActive(element) {
		if (element.scrollWidth > element.offsetWidth) {
			this.ellipsisActive = true;
		} else {
			this.ellipsisActive = false;
		}
	}

	ngOnInit() {
		setTimeout(() => {
			this.isEllipsisActive(this.diagnosisNameText.nativeElement);
		}, 100);
	}

	updateIsRelated(isRelated: boolean) {
		this.isContributing = isRelated;
		if (this.isRemovable) {
			// is manage diagnosis
			this.diagnosis.IsRelated = isRelated;
			this.updateDiagnosis.emit(this.diagnosis);
		} else {
			let diagnosis = Object.assign({}, this.diagnosis);
			diagnosis.IsRelated = isRelated;
			this.updateDiagnosis.emit(diagnosis);
		}
	}

	showMore() {
		if (!this.showDetails) {
			this.showDetails = true;
		} else {
			this.showDetails = false;
		}
	}

	nameClicked() {
		this.diagnosisNameClicked.emit(this.diagnosis);
	}
}
