import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";
import { RxOrderModel } from "../../../models/rx-order.model";
import { ErxOrderStore } from "../../../services/erx-order.store";
import { PreferenceManagementStore } from "../../../services/preference-management.store";

@Component({
	selector: "copy-preferences-step-two",
	templateUrl: "./copy-preferences-step-two.component.html",
	styleUrls: ["./copy-preferences-step-two.component.scss"]
})
export class CopyPreferencesStepTwoComponent implements OnInit {
	saving: boolean = false;
	currentStep: number = 2;
	selectedAction: string = "append";
	actions: any[] = [
		{ value: "overwrite", name: "Overwrite Existing" },
		{ value: "append", name: "Append as New" }
	];

	@Input() selectedPreferences: RxOrderModel[];

	@Output() goBack: EventEmitter<any> = new EventEmitter<any>();
	@Output() saveSuccess: EventEmitter<number[]> = new EventEmitter<number[]>();

	constructor(public erxStore: ErxOrderStore, public store: PreferenceManagementStore) {}

	ngOnInit(): void {}

	togglePreference(pref: RxOrderModel) {
		if (!pref.sourcePreference) {
			pref.retainPreference = !pref.retainPreference;
		}
	}

	toggleAllExisting(action: string) {
		this.store.action.set(action);
	}

	confirmPreferences() {
		this.saving = true;
		this.store.copy().then((obj: any) => {
			this.saveSuccess.emit([obj.newPrefCount, obj.facilityCount]);
			this.saving = false;
		});
	}
}
