import {Component, OnInit, forwardRef, Input} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {PatientService} from "../../../shared/services/patient.service";

@Component({
  selector: 'select-hospice',
  templateUrl: './select-hospice.template.html',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => SelectHospiceComponent),
    multi: true
  }]
})

export class SelectHospiceComponent implements ControlValueAccessor, OnInit {
  private _value: number;
  hospiceCareList: any[] = [];

  @Input() isRequired: boolean = false;

  get value() : number {
    return this._value;
  }

  set value(value: number) {
    this._value = value;
    this.propagateChange(this._value);
  }

  constructor(private _patientService: PatientService) {
  }

  ngOnInit() {
    this._patientService.getLocationTypes().subscribe(locationTypes => {
      this.hospiceCareList = locationTypes;
    });
  }

  propagateChange = (_: number) => {
  };

  writeValue(obj: any) {
    if (obj !== undefined)
      this.value = obj;
  }

  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any) {
  } //Not currently needed
}

