export class PharmacySearchFiltersModel {
	name: string;
	address: string;
	city: string;
	state: string;
	zipCode: string;
	phone: string;
	isEpcs: boolean;
	ncpdpid: string;

	deserialize(obj: any): PharmacySearchFiltersModel {
		return Object.assign(this, obj);
	}
}
