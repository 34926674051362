import {Directive, Input, TemplateRef, ViewContainerRef} from "@angular/core";
import {PermissionsService} from "../services/permisisons.service";
@Directive({
  selector: '[ifHasPermission]'
})
export class IfHasPermissionDirective {
  private _hasView = false;

  @Input() set ifHasPermission(permissions: string[]) {
    let hasPermission = false;
    let self = this;

    permissions.forEach(permission => {
      if (permission && self._permissionService.hasPermission(permission)) {
        hasPermission = true;
      }
    });

    if (hasPermission && !this._hasView) {
      // If condition is true add template to DOM
      this.viewContainer.createEmbeddedView(this.templateRef);
      this._hasView = true;
    } else if(!hasPermission && this._hasView) {
      // Else remove template from DOM
      this.viewContainer.clear();
      this._hasView = false;
    }
  }

  constructor(private templateRef: TemplateRef<any>,
              private viewContainer: ViewContainerRef,
              private _permissionService: PermissionsService) {
  }
}
