import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { AdminReportRoleStore } from 'src/app/ocp/shared/services/admin-report-role.store';
import { AdminReportStore } from 'src/app/ocp/shared/services/admin-report.store';
import { DataTableModel } from "../../../shared/components/data-table/data-table.model";
import { ReportItemModel } from "../../../shared/models/report-item.model";

@Component({
  selector: 'ct-manage-reports',
  templateUrl: './manage-reports.component.html',
  styleUrls: ['./manage-reports.component.scss']
})
export class ManageReportsComponent implements OnInit {
  @Input() reports: DataTableModel = new DataTableModel([], []);
  @Input() selectedCategory: string;

  @Output() onCategoryChange: EventEmitter<string> = new EventEmitter<string>();
  @Output() onEdit: EventEmitter<ReportItemModel> = new EventEmitter<ReportItemModel>();
  @Output() onDelete: EventEmitter<ReportItemModel> = new EventEmitter<ReportItemModel>();

  constructor(public store: AdminReportStore) { }

  ngOnInit() {
  }

  getCategoryItemCount(category: string): number {
    let reports = this.store.reports.get();
    return (reports || []).reduce((acc: number, r) => {
      return acc + (category != "all" ? ((r.category || "").toLowerCase() === (category || "").toLowerCase() ? 1 : 0) : 1);
    }, 0);
	}

  categoryChanged(category: string) {
    this.onCategoryChange.emit(category);
	}

  editReport(report: ReportItemModel) {
    this.onEdit.emit(report);
	}

  deleteReport(report: ReportItemModel){
    this.onDelete.emit(report);
  }
}
