import {NgModule} from "@angular/core";

import {SharedModule} from "../shared/shared.module";
import {errorRouting} from "./error.routes";
import {AccessDeniedComponent} from "./access-denied.component";

@NgModule({
  imports:[
    SharedModule,
    errorRouting
  ],
  declarations:[
    AccessDeniedComponent
  ],
  providers:[

  ]
})
export class ErrorModule{}
