import { Component, Output, EventEmitter, ViewChild, Input, OnInit } from "@angular/core";
import { ModalComponent } from "../../../shared/components/modal/modal.component";
import { PatientService } from "../../../shared/services/patient.service";
import { PrescriptionSummaryActionModel } from "../../../shared/models/prescription-summary-action.model";
import { DiscontinueRxComponent } from "./form-discontinue-rx/form-discontinue-rx.component";
import { StatusMessageService } from "../../../shared/components/status-message/status-message.service";
import { RxService } from "../../../shared/services/rx.service";
import { PatientMedicationModel } from "../../../shared/models/patient-medication.model";
import { PatientInfoModel } from "src/app/ocp/shared/models/patient.model";
import { FadeInQuickAnimation } from "src/app/ocp/animations";
import { GridViewStore } from "src/app/ocp/shared/services/grid-view.store";
import { ApplicationService } from "src/app/ocp/shared/services/application.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ErxOrderStore } from "src/app/ocp/shared/services/erx-order.store";
import { SelectionModel } from "@angular/cdk/collections";
import { ErxSearchModel } from "src/app/ocp/shared/models/erx/erx-search.model";

@Component({
	selector: "patient-rx-tab",
	templateUrl: "./patient-rx-tab.template.html",
	styleUrls: ["./patient-details-tabs.scss"],
	animations: [FadeInQuickAnimation]
})
export class PatientRxTab implements OnInit {
	saving: boolean = false;
	isLoading: boolean = false;
	modalTitle: string;
	subHeaderText: string;
	modalContent: string;
	unableToRefillMessage: string;
	patientMedications: PatientMedicationModel[] = [];
	filteredMedications: PatientMedicationModel[] = [];
	discontinueRxFormValid: boolean = false;
	selectedPrescriptionAction: PrescriptionSummaryActionModel = new PrescriptionSummaryActionModel();
	selectedMedication: PatientMedicationModel;
	selectedMedicationTab: string = "All";
	showActiveMedsOnly: boolean = true;
	medicationFilters: any[] = [
		{ value: "All", name: "All Orders & Profiles" },
		{ value: "Dispensing", name: "OPPC Dispensing Orders" },
		{ value: "Profile", name: "Non Dispensing Profiles" },
		{ value: "Discontinued", name: "Discontinued Orders & Profiles" }
	];
	viewOptions: string[] = ["grid", "table"];
	selection = new SelectionModel<PatientMedicationModel>(true, []);

	get filteredResultsDescription(): string {
		if (this.selectedMedicationTab === "Dispensing") {
			return "Medications that OnePoint has a valid prescription on file. These medications have been dispensed or can be dispensed by a OnePoint pharmacy.";
		} else if (this.selectedMedicationTab === "Profile") {
			return "Medication records used to communicate hospice coverage. These records are not prescriptions and a medication on this list cannot be dispensed by a OnePoint pharmacy until a valid prescription is received.";
		} else if (this.selectedMedicationTab === "Discontinued") {
			return "Medication records and prescriptions that have been discontinued by hospice.";
		} else if (this.selectedMedicationTab === "All") {
			return "";
		}
	}

	@Input() patientId: number;
	@Input() patientAdmitted: boolean = false;

	@ViewChild("refillRxModal") refillRxModal: ModalComponent;
	@ViewChild("actionModal") actionModal: ModalComponent;
	@ViewChild("discontinueRx") discontinueRx: DiscontinueRxComponent;
	@ViewChild("refillWarningModal") refillWarningModal: ModalComponent;
	@ViewChild("unableToRefillModal") unableToRefillModal: ModalComponent;
	@ViewChild("mobileFiltersModal") mobileFiltersModal: ModalComponent;
	@ViewChild("erxWarningModal") erxWarningModal: ModalComponent;
	@ViewChild("addErxModal") addErxModal: ModalComponent;
	@ViewChild("editInstructionsModal") editInstructionsModal: ModalComponent;

	constructor(
		private _patientService: PatientService,
		private _statusMessageService: StatusMessageService,
		private _rxService: RxService,
		public gridViewStore: GridViewStore,
		public appService: ApplicationService,
		private _router: Router,
		private _route: ActivatedRoute,
		private erxStore: ErxOrderStore
	) {}

	ngOnInit() {
		this.loadMedications();
	}

	popoverContent(viewType: string): string {
		if (viewType === "grid") {
			return "View as Grid";
		} else if (viewType === "table") {
			return "View as List";
		}
	}

	viewSelected(viewType: string): boolean {
		return this.gridViewStore.patientRxViewMode.get() === viewType;
	}

	getFilteredResultsTitle(medFilterValue: string): string {
		switch (medFilterValue) {
			case "Dispensing":
				return "OPPC Dispensing";
			case "Profile":
				return "Non-Dispensing";
			case "Discontinued":
				return "Discontinued";
			default:
				return "All";
		}
	}

	loadMedications() {
		this.isLoading = true;
		this._patientService.getPatientMedications(this.patientId).subscribe((medications: PatientMedicationModel[]) => {
			this.isLoading = false;
			medications.sort((a, b) => {
				if (a.DrugName < b.DrugName) return -1;

				if (a.DrugName > b.DrugName) return 1;

				return 0;
			});
			this.patientMedications = medications;
			this.filterMedications();
		});
	}

	filterMedications() {
		if (!this.selectedMedicationTab) {
			this.filteredMedications = this.patientMedications;
		} else {
			this.filteredMedications = this.patientMedications
				.filter(med => {
					let showInList = true;

					if (this.selectedMedicationTab === "Dispensing" && (!med.IsDispensing || !med.IsActive)) {
						showInList = false;
					} else if (this.selectedMedicationTab === "Profile" && (med.IsDispensing || !med.IsActive)) {
						showInList = false;
					} else if (this.selectedMedicationTab === "Discontinued" && med.IsActive) {
						showInList = false;
					} else if (this.selectedMedicationTab === "All" && this.showActiveMedsOnly && !med.IsActive) {
						showInList = false;
					}

					return showInList;
				})
				.map(med => med);
		}
	}

	toggleActiveOnly(event) {
		this.showActiveMedsOnly = event;
		this.tabChanged();
	}

	tabChanged() {
		this.filterMedications();
	}

	viewChanged(view: string) {
		this.gridViewStore.patientRxViewMode.set(view);
	}

	onActionModelError(statusMessage: any) {
		this._statusMessageService.changeStatusMessage(statusMessage.alertType, statusMessage.message);
	}

	refillRxModelClose() {
		this.modalContent = "";
		this.selectedMedication = null;
	}

	showRefillModal() {
		this.modalContent = "refillRx";
		this.refillRxModal.open("md");
	}

	openModal(e: PrescriptionSummaryActionModel) {
		this.selectedMedication = e.medItem;

		let actionType = e.type;

		if (actionType === "refillRx") {
			this.modalContent = "refillRx";
			this.unableToRefillMessage = this._rxService.getUnableToRefillMessage(this.selectedMedication);

			if (this.unableToRefillMessage) {
				this.unableToRefillModal.open("xs");
				this.selectedMedication = null;
				return;
			}

			if (this.selectedMedication.IsEarlyRefill) {
				this.refillWarningModal.open("xs");
			} else {
				this.showRefillModal();
			}
		} else if (actionType === "dispenseHistory") {
			this.modalTitle = "Dispense History";
			this.subHeaderText = "for " + this.selectedMedication.DrugName;
			this.modalContent = actionType;
			this.actionModal.open("lg");
		} else if (actionType === "discontinueMed") {
			this.discontinueRxFormValid = false;
			this.modalTitle = "Discontinue Medication";
			this.subHeaderText = "for " + this.selectedMedication.DrugName;
			this.modalContent = actionType;
			this.actionModal.open("xs");
		} else if (actionType === "activateMed") {
			this.modalTitle = "Activate Medication";
			this.subHeaderText = "for " + this.selectedMedication.DrugName;
			this.modalContent = actionType;
			this.actionModal.open("xs");
		} else if (actionType === "submitErx") {
			this.modalContent = actionType;
			if (!this.selectedMedication.IsCovered) {
				this.erxWarningModal.open("md");
			} else if (this.selectedMedication.IsCovered) {
				this.showAddErxModal();
			}
		} else if (actionType === "editInstructions") {
			this.modalTitle = "Edit Directions";
			this.subHeaderText = "for " + this.selectedMedication.DrugName;
			this.modalContent = actionType;
			this.editInstructionsModal.open("xs");
		}
	}

	closeActionModel() {
		this.actionModal.dismiss();
	}

	actionModelClose() {
		this.modalContent = "";
		this.selectedMedication = null;
	}

	confirmDiscontinueRx() {
		this.saving = true;
		this.discontinueRx.confirm();
	}

	discontinueRxSuccess(e: any) {
		this.saving = false;
		this.closeActionModel();
		this._statusMessageService.changeStatusMessage("success", "Order Number: " + e.orderNumber + ' - "' + e.drugName + '" has been discontinued.');
		this.loadMedications();
	}

	discontinueRxFail(message: string) {
		this.saving = false;
		this._statusMessageService.changeStatusMessage("error", message);
	}

	cancelRefillRequest() {
		this.modalContent = "";
		this.selectedMedication = null;
		this.refillWarningModal.dismiss();
	}

	refillWarningContinue() {
		this.refillWarningModal.dismiss();
		this.showRefillModal();
	}

	erxWarningCancel() {
		this.actionModelClose();
		this.erxWarningModal.dismiss();
	}

	erxWarningContinue() {
		this.erxWarningModal.dismiss();
		this.showAddErxModal();
	}

	showAddErxModal() {
		this.erxStore.resetSelectedErx();
		this.erxStore.mode.set("new");

		let searchModel: ErxSearchModel = new ErxSearchModel();
		searchModel.drugName = this.selectedMedication.DrugName;
		searchModel.ndc = this.selectedMedication.NDC;
		searchModel.directions = this.selectedMedication.Directions;

		this.erxStore.setSearchModels([searchModel]);

		this._router.navigate(["../new-erx-order"], {
			relativeTo: this._route,
			queryParams: {
				fromMedList: true
			}
		});
	}

	closeAddErxModal() {
		this.actionModelClose();
		this.addErxModal.dismiss();
	}

	saveDirections(directions: string) {
		this.selectedMedication.Directions = directions;
		this.editInstructionsModal.dismiss();

		this._patientService.updatePatientMedicationDirections(this.patientId, this.selectedMedication.OrderNumber, directions, this.selectedMedication.IsPRN).subscribe((result: boolean) => {
			if (result) this._statusMessageService.changeStatusMessage("success", "Directions succsessfully changed.");
			else this._statusMessageService.changeStatusMessage("error", "Error has occured saving directions.");
		});
		this.selectedMedication = null;
		this.modalContent = "";
	}

	cancelSaveDirections() {
		this.editInstructionsModal.dismiss();
		this.selectedMedication = null;
		this.modalContent = "";
	}

	onChangeIsPRN(val: boolean) {
		if (this.selectedMedication) this.selectedMedication.IsPRN = val;
	}

	isAllSelected() {
		const numSelected = this.selection.selected.length;
		const numRows = this.filteredMedications.length;
		return numSelected === numRows;
	}

	masterToggle() {
		this.isAllSelected() ? this.clearSelections() : this.selectAllMedications();
	}

	clearSelections() {
		this.selection.clear();
	}

	selectAllMedications() {
		this.filteredMedications.forEach(row => this.selection.select(row));
	}

	startErxOrder() {
		let models: ErxSearchModel[] = this.selection.selected.map(med => {
			let model = new ErxSearchModel();
			model.drugName = med.DrugName;
			model.ndc = med.NDC;
			model.directions = med.Directions;
			return model;
		});

		this.erxStore.resetSelectedErx();
		this.erxStore.mode.set("new");

		this.erxStore.setSearchModels(models);

		this._router.navigate(["../new-erx-order"], {
			relativeTo: this._route,
			queryParams: {
				fromMedList: true
			}
		});
	}
}
