import {Injectable} from "@angular/core";
import {ISearchService} from "../components/patient-search/search.interface";
import {RxService} from "./rx.service";

@Injectable()
export class RxPrescribersSearchService implements ISearchService {
  constructor(private _rxService: RxService) {
  }

  search(text: string) {
    return this._rxService.searchPrescribers(text);
  }
}
