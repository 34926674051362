import { Component, Output, EventEmitter, OnInit, Input, ViewChild } from "@angular/core";
import { DiagnosisModel } from "../../../shared/models/diagnosis.model";
import { PatientService } from "../../../shared/services/patient.service";
import { StatusMessageService } from "../../../shared/components/status-message/status-message.service";
import { ModalComponent } from "../../../shared/components/modal/modal.component";
import { PatientInfoModel } from "../../../shared/models/patient.model";
import * as _ from "lodash";
import { ApiResult } from "../../../shared/models/api-result.model";

@Component({
	selector: "patient-diagnosis-tab",
	templateUrl: "./patient-diagnosis-tab.template.html",
	styleUrls: ["./patient-details-tabs.scss"]
})
export class PatientDiagnosisTab implements OnInit {
	isLoading: boolean = false;
	patientDiagnosis: DiagnosisModel[] = [];
	editDiagnosis: DiagnosisModel = null;
	diagnosisFilter: string[] = ["Current"];
	displayDiagnosis: DiagnosisModel[] = [];

	@Input() patient: PatientInfoModel;

	@Output() openAddDiagnosis: EventEmitter<string> = new EventEmitter<string>();

	@ViewChild("resolveDiagnosisDialog") resolveDiagnosisDialog: ModalComponent;

	constructor(private _patientService: PatientService, private _statusMessageService: StatusMessageService) {}

	ngOnInit() {
		this.loadPatientDiagnosis();
	}

	loadPatientDiagnosis() {
		this.isLoading = true;
		this._patientService.getPatientDiagnosis(this.patient.patientId).subscribe((diags: DiagnosisModel[]) => {
			this.isLoading = false;
			this.patientDiagnosis = diags;
			this.filterDiagnosis();
		});
	}

	filterDiagnosis() {
		let self = this;
		this.displayDiagnosis = this.patientDiagnosis.filter((x: DiagnosisModel) => {
			if (self.diagnosisFilter.indexOf("Current") > -1 && !x.ResolvedDate) {
				return true;
			}

			if (self.diagnosisFilter.indexOf("Resolved") > -1 && x.ResolvedDate) {
				return true;
			}

			return false;
		});
	}

	updateFilter(checked: boolean, value: string) {
		if (!checked) {
			this.diagnosisFilter.splice(this.diagnosisFilter.indexOf(value), 1);
		} else if (this.diagnosisFilter.indexOf(value) === -1) {
			this.diagnosisFilter.push(value);
		}

		this.filterDiagnosis();
	}

	removeDiagnosis(diagnosis: DiagnosisModel) {
		this._patientService.removePatientDiagnosis(this.patient.patientId, diagnosis.PatientDxId).subscribe((x: any) => {
			if (x.Success) {
				this.loadPatientDiagnosis();
				this._statusMessageService.changeStatusMessage("success", 'Diagnosis "' + diagnosis.IcdName + '" removed.');
			} else {
				this._statusMessageService.changeStatusMessage("error", x.Result.PublicMessage);
			}
		});
	}

	resolveDiagnosis(diagnosis: DiagnosisModel) {
		this.editDiagnosis = _.cloneDeep(diagnosis);
		this.resolveDiagnosisDialog.open("xs");
	}

	closeResolveDiagnosis(saveSuccess) {
		this.editDiagnosis = null;
		this.resolveDiagnosisDialog.dismiss();

		if (saveSuccess) {
			this.loadPatientDiagnosis();
		}
	}

	updateDiagnosis(diagnosis: DiagnosisModel) {
		this._patientService.updateDiagnosis(this.patient.patientId, diagnosis).subscribe((x: ApiResult) => {
			if (x.Success) {
				this.loadPatientDiagnosis();
				this._statusMessageService.changeStatusMessage("success", '"' + diagnosis.IcdName + '" successfully updated.');
			} else {
				this._statusMessageService.changeStatusMessage("error", x.Result.PublicMessage);
			}
		});
	}
}
