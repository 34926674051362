import { NgModule } from "@angular/core";

import { SharedModule } from "../shared/shared.module";
import { ReportingComponent } from "./reporting.component";
import { ReportingRouting } from "./reporting.routes";
import { ReportingReportsComponent } from "./reporting-reports.component";
import { ViewReportComponent } from "./view-report.component";
import { ReportsComponent } from "./components/tabs/reports.component";


@NgModule({
	imports: [SharedModule, ReportingRouting],
	declarations: [
		ReportingComponent, 
		ReportingReportsComponent, 
		ViewReportComponent, 
		ReportsComponent
	],
	providers: [],
	exports: [ReportingComponent, ViewReportComponent]
})
export class ReportingModule {}
