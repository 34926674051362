export class PatientResult {
	BirthDate: string;
	FirstName: string;
	IsFavorite: boolean;
	IsHomePatient: boolean;
	IsFacilityCare: boolean;
	IsIpuGpu: boolean;
	LastName: string;
	MedicalRecordNumber: string;
	MiddleName: string;
	PatientID: number;
	Sex: string;
	AdmissionStatusCode: number;
	LocationTypeId: number;
	State: string;
	City: string;
	TeamName: string;
	WeightLbs: number;
	HeightIn: number;
}
