import {Component, EventEmitter, Input, OnInit, Output} from "@angular/core";
import {UserService} from "../../services/user.service";
import { UtilitiesService } from "../../services/utilities.service";
import {StatusMessageService} from "../status-message/status-message.service";

@Component({
    selector:"user-login-id",
    templateUrl: "./user-login-id.template.html"
})
export class UserLoginIdComponent implements OnInit{
    @Input() userProfile:any;

    @Output() closeModal: EventEmitter<string> = new EventEmitter<string>();
    @Output() saveSuccess: EventEmitter<string> = new EventEmitter<string>();

    saving:boolean = false;

    oldId:string;

    get isInvalid(): boolean {
        return !this._utility.validateEmail(this.userProfile.LoginId);
    }

    constructor(private _service:UserService, private _statusMessageService: StatusMessageService, private _utility: UtilitiesService) {}

    save(){
        this._service.setUserLogin(this.userProfile, this.userProfile.UserId).subscribe((result:any) => {
            if(result.Success){
                this.saveSuccess.emit("");
            }else{
                this._statusMessageService.changeStatusMessage("error", result.PublicMessage);
            }

        });
    }

    cancel(){
        this.userProfile.LoginId = this.oldId;

        this.closeModal.emit()
    }

    ngOnInit(){
        this.oldId = this.userProfile.LoginId;
    }
}