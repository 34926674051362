import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { PrescriptionSummaryActionModel } from "../../../shared/models/prescription-summary-action.model";
import { Permissions } from "../../../shared/enums/permissions";
import { PatientMedicationModel } from "../../../shared/models/patient-medication.model";
import { RxService } from "../../../shared/services/rx.service";
import { UserService } from "../../../shared/services/user.service";
import { AuthService } from "src/app/ocp/shared/services/auth.service";
import { PatientCareStore } from "../../services/patient-care.store";

@Component({
	selector: "prescription-summary",
	templateUrl: "./prescription-summary.template.html",
	styleUrls: ["./prescription-summary.scss"]
})
export class PrescriptionSummary implements OnInit {
	showDetails: boolean = false;
	popoverContent: string;
	permissions: any = Permissions;
	hasEnhancedErx: boolean = false;

	@Input() medItem: PatientMedicationModel;
	@Input() isSelected: boolean = false;
	@Input() rxName: string;
	@Input() rxAmount: number;
	@Input() rxUnitType: string;
	@Input() instructions: string;
	@Input() rxNumber: number;
	@Input() lastFilled: string;
	@Input() expirationDate: string;
	@Input() discontinueDate: string;
	@Input() quantityRemaining: number;
	@Input() isActive: boolean = false;
	@Input() formulary: boolean = false;
	@Input() insCovered: boolean = false;
	@Input() isDispensing: boolean = false;
	@Input() isQuickRefill: boolean = false;
	@Input() ndc: string;
	@Input() orderNumber: number;
	@Input() patientAdmitted: boolean = false;
	@Input() disableTooltip: boolean = false;
	@Input() tooltipMessage: string;

	@Output() modalAction: EventEmitter<PrescriptionSummaryActionModel> = new EventEmitter<PrescriptionSummaryActionModel>();
	@Output() clicked: EventEmitter<PatientMedicationModel> = new EventEmitter<PatientMedicationModel>();

	get showActionButton(): boolean {
		return !this.isQuickRefill && (this.showRefillButton || this.showDispenseHistoryButton || this.showDiscontinueButton);
	}

	get showRefillButton(): boolean {
		return this.isDispensing && this.medItem.AllowRefill && this.patientAdmitted;
	}

	get showDispenseHistoryButton(): boolean {
		return this.isDispensing && this.insCovered;
	}

	get showDiscontinueButton(): boolean {
		return this.isActive;
	}

	get disableERx(): boolean {
		return this.patientStore.getIsPediatricRestricted();
	}

	constructor(
		private _rxService: RxService, 
		public userService: UserService, 
		private _authService: AuthService, 
		public patientStore: PatientCareStore
	) {}

	ngOnInit() {
		this.hasEnhancedErx = this._authService.isInRole("Enhanced eRx");

		// Set the tooltip content for each Prescription's Icon
		if (this.isActive && this.isDispensing && this.insCovered) {
			this.popoverContent = "Active Order (Covered).";
		} else if (this.isActive && this.isDispensing && !this.insCovered) {
			this.popoverContent = "Active Order (Not Covered).";
		} else if (!this.isActive && this.isDispensing && this.insCovered) {
			this.popoverContent = "Discontinued Order (Covered).";
		} else if (!this.isActive && this.isDispensing && !this.insCovered) {
			this.popoverContent = "Discontinued Order (Not Covered).";
		} else if (this.isActive && !this.isDispensing) {
			if (this.insCovered) {
				this.popoverContent = "Active Profile Medication (Covered).";
			} else {
				this.popoverContent = "Active Profile Medication (Not Covered).";
			}
		} else if (!this.isActive && !this.isDispensing) {
			this.popoverContent = "Discontinued Profile Medication.";
		}

		if (this.isQuickRefill) {
			this.tooltipMessage = this._rxService.getUnableToRefillMessage(this.medItem);
		}
	}

	showMore() {
		this.showDetails = !this.showDetails;
	}

	inputChanged() {
		if (this.isQuickRefill && this.tooltipMessage.length > 0) return;
		if (this.isSelected) {
			this.isSelected = false;
		} else {
			this.isSelected = true;
		}
	}

	actionClick(actionType: string) {
		let action = new PrescriptionSummaryActionModel();
		action.medItem = this.medItem;
		action.type = actionType;
		this.modalAction.emit(action);
	}
}
