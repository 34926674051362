import { Component, OnInit, OnDestroy } from "@angular/core";
import { FcmService } from "../shared/services/fcm.service";
import { DataTableModel } from "../shared/components/data-table/data-table.model";
import { SortModel } from "../shared/components/data-table/sort.model";
import { DataTableColumnModel } from "../shared/components/data-table/columns.model";
import { takeUntil } from "rxjs/operators";
import { Subject } from "rxjs";
import { PushRegistration } from "../shared/models/push-registration.model";

@Component({
    selector: 'notification-settings',
    templateUrl: "./notification-settings.template.html",
    styleUrls: ['./notification-settings.scss']
})
export class NotificationSettingsComponent implements OnInit, OnDestroy {
    registrations: DataTableModel = new DataTableModel([], []);

    deviceName: string = "";
    activating: boolean = false;

    erx: boolean;
    pa: boolean;
    sys: boolean;

    constructor(public fcm: FcmService) { }

    ngOnInit(): void {
        this.initTable();

        this.fcm.getDevices();
        this.fcm.getPreferences();
    }

    private _destroyed: Subject<void> = new Subject<void>();
    ngOnDestroy(): void {
        this._destroyed.next();
        this._destroyed.unsubscribe();
    }

    activate() {
        if (!this.deviceName || this.deviceName.trim().length === 0) return;

        this.activating = true;

        this.fcm.getPermission(this.deviceName).then(() => this.activating = false);
    }

    initTable() {
        let defaultSorting: SortModel[] = [];

        defaultSorting.push({
            column: "DeviceName",
            direction: "asc"
        });

        let columns: DataTableColumnModel[] = [];
        columns.push(new DataTableColumnModel("DeviceName", "Device Name", "", true));
        columns.push(new DataTableColumnModel("DeviceId", "Id", "", true));
        columns.push(new DataTableColumnModel("actions", "", "", false));

        this.registrations = new DataTableModel(defaultSorting, columns);

        this.fcm.registrations.observable
            .pipe(takeUntil(this._destroyed))
            .subscribe((registrations: PushRegistration[]) => {
                this.registrations.populate(registrations);
            });
    }

    updateNotificationType(setting: string, event: boolean) {
        switch (setting) {
            case "erx": {
                this.erx = event;
                break;
            }
            case "pa": {
                this.pa = event;
                break;
            }
            case "sys": {
                this.sys = event;
                break;
            }
        }
    }
}