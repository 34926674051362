import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'multiselect-actions-row',
  templateUrl: './multiselect-actions-row.component.html'
})
export class MultiselectActionsRowComponent implements OnInit {
  @Input() itemType: string;
  @Input() itemsSelected: number;
  @Input() disableSelectAll: boolean;

  @Output() onClear: EventEmitter<any> = new EventEmitter<any>();
  @Output() onSelectAll: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {}

  getSelectedCount(selected: number): string {
    if (selected == 1) {
      return "(" + selected + ") " + this.itemType + " selected";
    } else {
      return "(" + selected + ") " + this.itemType + "s selected";
    }
  }
}
