import { Component, ViewChild, OnInit } from "@angular/core";
import { ModalComponent } from "../../shared/components/modal/modal.component";
import { StatusMessageService } from "../../shared/components/status-message/status-message.service";
import { ClinicalResourcesService } from "../../shared/services/clinical-resources.service";
import { DocumentTypeModel } from "../../shared/models/document-type.model";
import { DocumentInfoModel } from "../../shared/models/document-info.model";
import { ApiResult } from "../../shared/models/api-result.model";

@Component({
	selector: "file-manager",
	templateUrl: "./file-manager.template.html",
	styleUrls: ["../administration-tabs.scss"]
})
export class FileManagerTab implements OnInit {
	loading: boolean = false;
	editMode: string;
	createMode: string;
	modalTitle: string;
	subHeaderText: string;
	confirmDelete: boolean = false;
	fileCategoriesList: DocumentTypeModel[] = [];
	selectedType: number = -1;
	documents: DocumentInfoModel[] = [];
	documentToEdit: DocumentInfoModel;

	@ViewChild("editModal") editModal: ModalComponent;

	@ViewChild("createModal") createModal: ModalComponent;

	constructor(private _statusMessageService: StatusMessageService, private _clinicalReferencesService: ClinicalResourcesService) {}

	ngOnInit() {
		this.loading = true;
		this._clinicalReferencesService.getDocumentTypes().subscribe((results: DocumentTypeModel[]) => {
			this.fileCategoriesList = results;
			let defaultModel = new DocumentTypeModel();
			defaultModel.TypeName = "Show All Types";
			defaultModel.DocumentTypeId = -1;
			this.fileCategoriesList.unshift(defaultModel);
			this.refreshDocuments();
		});
	}

	refreshDocuments() {
		this.loading = true;
		this._clinicalReferencesService.getDocuments(null, this.selectedType > 0 ? this.selectedType : null).subscribe((documents: DocumentInfoModel[]) => {
			this.documents = documents;
			this.loading = false;
		});
	}

	addNewFile() {
		this.createModal.open("md");
		this.editMode = "New";
		this.modalTitle = "Upload New File";
	}

	editFile(document: DocumentInfoModel) {
		this.documentToEdit = Object.assign({}, document);
		this.editModal.open("md");
		this.editMode = "Edit";
		this.modalTitle = "Edit File";
	}

	fileEditSuccess(fileName: string) {
		if (this.editMode === "New") {
			this.editMode = null;
			this.createModal.dismiss();
			this._statusMessageService.changeStatusMessage("success", '"' + fileName + '" successfully added.');
		} else {
			this.closeEditModal();
			this._statusMessageService.changeStatusMessage("success", '"' + fileName + '" successfully updated.');
		}
		this.refreshDocuments();
	}

	closeEditModal() {
		this.editMode = null;
		this.documentToEdit = null;
		this.editModal.dismiss();
	}

	deleteFile(document: DocumentInfoModel) {
		document.confirmDelete = true;
	}

	cancelDelete(document: DocumentInfoModel) {
		document.confirmDelete = false;
	}

	confirmDeleteFile(documentId: number) {
		this._clinicalReferencesService.removeDocument(documentId).subscribe((result: ApiResult) => {
			if (result.Success) {
				this._statusMessageService.changeStatusMessage("success", "File successfully deleted.");
				this.refreshDocuments();
			} else {
				this._statusMessageService.changeStatusMessage("error", result.PublicMessage);
			}
		});
	}

	documentTypeChanged() {
		this.refreshDocuments();
	}
}
