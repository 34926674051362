import {Routes, RouterModule} from "@angular/router";
import {AuthGuard} from './shared/gaurds/auth.gaurd';
import {PatientCare} from "./patient-care/patient-care.component";
import { ErxIframeComponent } from "./patient-care/components/erx-iframe/erx-iframe.component";

export const routes: Routes = [
  {path: "", component: PatientCare, canActivate: [AuthGuard]},
  {path: "erx-alerts", component: ErxIframeComponent}
];

export const routing = RouterModule.forChild(routes);
