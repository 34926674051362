import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { RxOrderModel } from "../../../models/rx-order.model";
import { DataTableModel } from "../../data-table/data-table.model";
import { SortModel } from "../../data-table/sort.model";
import { DataTableColumnModel } from "../../data-table/columns.model";
import { ErxOrderStore } from "../../../services/erx-order.store";
import { PreferenceManagementStore } from "../../../services/preference-management.store";
import { CustomerSummaryModel } from "../../../models/customer-summary.model";
import { SelectionModel } from "@angular/cdk/collections";

@Component({
	selector: "copy-preferences-step-one",
	templateUrl: "./copy-preferences-step-one.component.html",
	styleUrls: ["./copy-preferences-step-one.component.scss"]
})
export class CopyPreferencesStepOneComponent implements OnInit {
	saving: boolean = false;
	currentStep: number = 1;
	data: DataTableModel = new DataTableModel([], []);
	expandedIdx?: number = null;
	isSelected: boolean;
	selection = new SelectionModel<CustomerSummaryModel>(true, []);
	selectedOrg: number;
	partnersLoading: boolean = false;

	private _selectedPreferences: RxOrderModel[];
	@Input()
	get selectedPreferences(): RxOrderModel[] {
		return this._selectedPreferences;
	}
	set selectedPreferences(d: RxOrderModel[]) {
		this._selectedPreferences = d;
		setTimeout(() => {
			this.data.populate(d);
		}, 0);
	}

	@Output() closeModal: EventEmitter<string> = new EventEmitter<string>();
	@Output() goToStepTwo: EventEmitter<any> = new EventEmitter<any>();

	constructor(public erxStore: ErxOrderStore, public store: PreferenceManagementStore) {}

	ngOnInit(): void {
		this.initTable();
		this.store.loadOrganizations();
	}

	initTable() {
		if (!this.data || !this.data) return;

		let defaultSorting: SortModel[] = [];

		defaultSorting.push({
			column: "preferredNickname",
			direction: "asc"
		});

		let columns: DataTableColumnModel[] = [];

		columns.push(new DataTableColumnModel("expander", "", "expanded-el-th", false));
		columns.push(new DataTableColumnModel("preferredNickname", "Nickname", "pl-md-1", true));

		this.data = new DataTableModel(defaultSorting, columns);
		this.data.pageLength = 9999;
	}

	isExpanded(idx: number): boolean {
		return this.expandedIdx === idx;
	}

	expand(idx: number) {
		if (this.expandedIdx === idx) {
			this.expandedIdx = null;
		} else {
			this.expandedIdx = idx;
		}
	}

	isAllSelected() {
		const numSelected = this.selection.selected.length;
		const numRows = this.store.customers.get().length;
		return numSelected === numRows;
	}

	masterToggle() {
		this.isAllSelected() ? this.clearSelections() : this.selectAllPartners();
	}

	clearSelections() {
		this.selection.clear();
	}

	selectAllPartners() {
		this.store.customers.get().forEach(row => this.selection.select(row));
	}

	updateFacilities(org: number) {
		this.partnersLoading = true;
		this.clearSelections();
		this.store.loadCustomers(org).then(() => {
			this.partnersLoading = false;
		});
	}

	nextStep() {
		this.store.selectCustomers(this.selection.selected);
		this.goToStepTwo.emit();
	}
}
