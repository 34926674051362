import {Directive, ElementRef, Input, Inject, EventEmitter} from "@angular/core";
@Directive({
  selector: '[focusElement]'
})
export class FocusElementDirective {
  private _focusEmitterSubscription;

  constructor(@Inject(ElementRef) private element: ElementRef) {
  }

  @Input()
  set focusElement(focusEmitter: EventEmitter<any>) {
    if (this._focusEmitterSubscription) {
      this._focusEmitterSubscription.unsubscribe();
    }
    this._focusEmitterSubscription = focusEmitter.subscribe(
      (() => {
        setTimeout(() => {
          this.element.nativeElement.focus()
        });
      }).bind(this))
  }
}
