import {Component, ViewChild, Input, EventEmitter, Output} from '@angular/core';
import {StatusMessageService} from "../../../shared/components/status-message/status-message.service";
import {CustomerTeamModel} from "../../../shared/models/customer-team.model";
import {FormTeamDetails} from "../form-team-details/form-team-details.component";

@Component({
  selector: 'customer-teams',
  templateUrl: './customer-teams.template.html',
  styleUrls: ['./customer-teams.css']
})

export class CustomerTeamsComponent {
  modalTitle: string;
  subHeaderText: string;

  @Input() customerId: number;
  @Input() teams: CustomerTeamModel[] = [];

  @Output() teamSaveSuccess: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('editTeamModal') editTeamModal: FormTeamDetails;

  constructor(private _statusMessageService: StatusMessageService) {
  }

  updateTeam(customerTeamId: number) {
    let team = this.teams.find(x => {
      return x.CustomerTeamId === customerTeamId;
    });
    if(team) {
      this.editTeamModal.showDialog(Object.assign({}, team));
    }
  }

  teamStatusUpdated(statusResult: any) {
    this._statusMessageService.changeStatusMessage(statusResult.success ? 'success' : 'error', statusResult.message);
    this.teamSaveSuccess.emit();
  }

  teamEditSuccess(successMessage: string) {
    this._statusMessageService.changeStatusMessage('success', successMessage);
    this.teamSaveSuccess.emit();
  }
}
