import { Injectable, EventEmitter } from '@angular/core';
import { StatusMessageModel } from './status-message.model';

@Injectable()
export class StatusMessageService {
  private statusMessageChanged: EventEmitter<StatusMessageModel> = new EventEmitter<StatusMessageModel>();
  private statusMessageClosed: EventEmitter<StatusMessageModel> = new EventEmitter<StatusMessageModel>();

  constructor() { }

  changeStatusMessage(messageType: string, message: string, duration: number = 5000) {
    this.closeMessage().then(() => {
      this.statusMessageChanged.emit(new StatusMessageModel(messageType, message, duration));
    })
  }

  getStatusMessageChangedEmitter() {
    return this.statusMessageChanged;
  }

  getCloseStatusMessageEmitter() {
    return this.statusMessageClosed;
  }

  closeMessage(): Promise<void> {
    return new Promise<void>(resolve => {
      this.statusMessageClosed.emit();
      setTimeout(() => {
        resolve();
      }, 100);
    })
  }
}
