import { NgModule, APP_INITIALIZER } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { FormsModule } from "@angular/forms";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

import { OcpModule } from "./ocp/ocp.module";

import { routing, APP_ROUTER_PROVIDERS } from "./app.routes";

import { App } from "./app.component";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { HttpService } from "./ocp/shared/services/http.service";
import { AuthService } from "./ocp/shared/services/auth.service";
import { CacheService } from "./ocp/shared/services/cache.service";
import { AuthGuard } from "./ocp/shared/gaurds/auth.gaurd";
import { StatusMessageService } from "./ocp/shared/components/status-message/status-message.service";
import { AuthInterceptor } from "./ocp/shared/services/auth.interceptor";
import { AppConfig } from "./ocp/app.config";
import { MobileService } from "./ocp/shared/services/mobile.service";
import { FirebaseService } from "./ocp/shared/services/firebase.service";
import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from "ng-recaptcha";
import { environment } from "../environments/environment";

export function initConfig(config: AppConfig) {
	return () => config.load();
}

@NgModule({
	imports: [BrowserModule, FormsModule, BrowserAnimationsModule, HttpClientModule, routing, OcpModule, RecaptchaV3Module],
	declarations: [App],
	bootstrap: [App],
	providers: [
		APP_ROUTER_PROVIDERS,
		HttpService,
		AuthService,
		CacheService,
		AuthGuard,
		StatusMessageService,
		FirebaseService,
		{ provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
		AppConfig,
		{ provide: APP_INITIALIZER, useFactory: initConfig, deps: [AppConfig], multi: true },
		MobileService,
		{ provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.googleSiteId }
	]
})
export class AppModule {}
