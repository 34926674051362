import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: "dosespotStatus" })
export class DosespotStatusPipe implements PipeTransform {
	transform(status: string) {
		if (!status || status.trim().length === 0) return "Entered";

		switch (status) {
			// Medication statuses
			case "-1":
				return "Active";
			case "-2":
				return "Inactive";
			case "-3":
				return "Discontinued";
			case "-4":
				return "Deleted";
			case "-5":
				return "Completed";
			case "-6":
				return "Cancellation Requested";
			case "-7":
				return "Cancellation Pending";
			case "-8":
				return "Cancelled";
			case "-9":
				return "Cancellation Denied";
			case "-10":
				return "Changed";
			case "-11":
				return "Full Fill";
			case "-12":
				return "Partial Fill";
			case "-13":
				return "No Fill";
			// Prescription statuses
			case "1":
				return "Entered";
			case "2":
				return "Printed";
			case "3":
				return "Sending";
			case "4":
				return "eRx Sent";
			case "5":
				return "Faxed";
			case "6":
				return "Error";
			case "7":
				return "Deleted";
			case "8":
				return "Requested";
			case "9":
				return "Edited";
			case "10":
				return "EPCS Error";
			case "11":
				return "EPCS Signed";
			case "12":
				return "Ready to Sign";
			case "13":
				return "Pharmacy Verified";
		}

		return "Unknown";
	}
}
