import { Component, Input, forwardRef, Output, EventEmitter, OnInit, ElementRef, ViewChild } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
	selector: "input-time",
	templateUrl: "./input-time.template.html",
	styleUrls: ["../input-textbox/input-textbox.scss"],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => InputTimeComponent),
			multi: true
		}
	]
})
export class InputTimeComponent implements ControlValueAccessor, OnInit {
	public elementRef;
	value: string;

	@Input() label: string;
	@Input() hideLabel: boolean;
	@Input() isRequired: boolean = false;
	@Input() tooltip: string;
	@Input() placeholderText: string;
	@Input() isDisabled: boolean;
	@Input() overrideValidation: boolean = false;
    @Input() focus: EventEmitter<any> = new EventEmitter<any>();
    @Input() defaultValue: string;

	@Output() isDisabledChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

	@Input() disableId: string;
	@Input() disabledLabel: string;
	@Input() isInvalid: boolean;
	@Input() maxLength: number;


	constructor(myElement: ElementRef) {
		this.elementRef = myElement;
	}

	ngOnInit() {
        if (this.defaultValue){
            
        }
    }

	propagateChange = (_: any) => {};

	inputChanged(value: string) {
		this.value = value;
		if (!this.overrideValidation) {
			if (this.isRequired && !this.value) {
				this.isInvalid = true;
			} else {
				this.isInvalid = false;
			}
		}

        this.propagateChange(this.value);
	}

	writeValue(obj: string) {
		if (obj !== undefined) {
				this.value = obj;
		}
	}

	registerOnChange(fn: any) {
		this.propagateChange = fn;
	}

	registerOnTouched(fn: any) {} //Not currently needed
}
