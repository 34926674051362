import {Routes, RouterModule} from "@angular/router";

import {AuthGuard} from "../shared/gaurds/auth.gaurd";
import {AccessDeniedComponent} from "./access-denied.component";

export const ErrorRoutes: Routes = [
  {path: "access-denied", component: AccessDeniedComponent, canActivate: [AuthGuard]}
];

export const errorRouting = RouterModule.forChild(ErrorRoutes);
