import {Component, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'organization-tab',
  templateUrl: './organization.template.html',
  styleUrls: ['./partner-profile-tabs.scss']
})

export class OrganizationTab {
  @Output() backToCurrentPartner: EventEmitter<any> = new EventEmitter<any>();
  @Output() goBackToOrgList: EventEmitter<any> = new EventEmitter<any>();

  backToPartner() {
    this.backToCurrentPartner.emit();
  }

  backToList(){
    this.goBackToOrgList.emit();
  }
}
