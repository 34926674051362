import { PropertyError } from "./property-error.model";

export class PatientUpdateResult {
	dosespotPatientId: number;
	errors: PropertyError[] = [];

	deserialize(input: any): PatientUpdateResult {
		this.errors = input.Errors.map((error: any) => new PropertyError().deserialize(error));
		this.dosespotPatientId = input.DosespotPatientId;
		return this;
	}
}
