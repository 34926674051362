import { Component, OnInit } from "@angular/core";
import { ReportingService } from "../../../shared/services/reporting.service";
import { ReportItemModel } from "../../../shared/models/report-item.model";
import { Router } from "@angular/router";

@Component({
	selector: "reports-tab",
	templateUrl: "./reports.component.html",
	styleUrls: ["./reports.scss"]
})
export class ReportsComponent implements OnInit {
	isLoading: boolean = false;
	reports: ReportItemModel[] = [];

	constructor(private _router: Router, private _reportingService: ReportingService) {}

	ngOnInit() {
		this.isLoading = true;
		this._reportingService.getUserReports().subscribe((reports: ReportItemModel[]) => {
			this.isLoading = false;

			reports.sort(this.reportComparer);
			this.reports = reports;
		});
	}

	viewReport(report: ReportItemModel) {
		this._router.navigate(["/reporting/view-report", report.ReportId, report.DisplayName]);
	}

	reportComparer(a, b) {
		let sorts: string[] = ["CategoryName", "DisplayName"];
		for (let i: number = 0; i < sorts.length; i++) {
			let leftVal = a[sorts[i]];
			let rightVal = b[sorts[i]];

			leftVal = leftVal.toLowerCase().replace(/ /g, "");
			rightVal = rightVal.toLowerCase().replace(/ /g, "");

			if (leftVal != rightVal) {
				return leftVal < rightVal ? -1 : 1;
			}
		}
	}
}
