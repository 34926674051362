export class PharmacySearchResult {
	id: number;
	pharmacyName: string;
	street1: string;
	street2: string;
	city: string;
	state: string;
	zip: string;
	phone: string;
	fax: string;
	isDefault: boolean;
	isPreferred: boolean;

	deserialize(obj: any): PharmacySearchResult {
		this.id = obj.Id || obj.PharmacyId;
		this.pharmacyName = obj.StoreName;
		this.street1 = obj.Address1;
		this.street2 = obj.Address2;
		this.city = obj.City;
		this.phone = obj.PrimaryPhone;
		this.fax = obj.PrimaryFax;
		this.state = obj.State;
		this.zip = obj.ZipCode;
		this.isDefault = obj.IsDefault;
		this.isPreferred = obj.IsPreferred;

		return this;
	}
}
