import { Injectable } from "@angular/core";

@Injectable({
	providedIn: "root"
})
export class LocalStorageService {
	private _cacheSeparator: string = "__cacheService_";
	private _durable: string[] = ["decryptText", "iv", "deviceId", "bioAuthEnabled", "username", "lastCustomer"];

	get(key: string): any {
		var obj = localStorage.getItem(this._cacheSeparator + key);

		if (obj) {
			var objVal = JSON.parse(obj);
			return objVal.d;
		}

		return null;
	}

	set(key: string, data: string) {
		var jData = JSON.stringify({ d: data });

		localStorage.setItem(this._cacheSeparator + key, jData);
	}

	remove(key: string) {
		localStorage.removeItem(this._cacheSeparator + key);
	}

	clear() {
		var arr: any[] = [];

		for (var i = 0; i < localStorage.length; i++) {
			if (localStorage.key(i).substring(0, this._cacheSeparator.length) === this._cacheSeparator) {
				arr.push(localStorage.key(i));
			}
		}

		for (var j = 0; j < arr.length; j++) {
			if (this._durable.indexOf(arr[j].replace(this._cacheSeparator, "")) >= 0) continue; // don't clear durable items

			localStorage.removeItem(arr[j]);
		}
	}

	exists(key: string): boolean {
		var obj = localStorage.getItem(this._cacheSeparator + key);

		if (obj) return true;

		return false;
	}
}
