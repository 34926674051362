import { Component, Input, OnInit, Output, EventEmitter } from "@angular/core";
import { RefillRequestModel } from "../../../../shared/models/refill-request.model";
import { GenerateRefillRequestModel } from "../../../../shared/models/generate-refill-request.model";
import { PatientService } from "../../../../shared/services/patient.service";
import { StatusMessageService } from "../../../../shared/components/status-message/status-message.service";
import { ApiResult } from "../../../../shared/models/api-result.model";
import { DeliveryTimeframe } from "../../../models/delivery-timeframe.model";
import { RxService } from "src/app/ocp/shared/services/rx.service";

@Component({
	selector: "form-refill-rx",
	templateUrl: "./form-refill-rx.template.html",
	styleUrls: ["./form-refill-rx.scss"]
})
export class FormRefillRxComponent implements OnInit {
	saving: boolean = false;
	comments: string;
	deliveryTimeframes: DeliveryTimeframe[] = [];

	@Input() patientId: number;
	@Input() drugName: string;
	@Input() rxNumber: number;
	@Input() orderNumber: number;
	@Input() lastFilledDate: string;
	@Input() callbackPhone: string;
	@Input() deliveryTimeframe: string = "Standard";

	@Output() closeModal: EventEmitter<any> = new EventEmitter<any>();

	get showAdditionalChargeWarning(): boolean {
		const selectedTimeframeItem = this.deliveryTimeframes.find(a => a.TimeframeName == this.deliveryTimeframe);
		return selectedTimeframeItem && selectedTimeframeItem.HasAdditionalCharge;
	}

	constructor(private _patientService: PatientService, private _statusMessageService: StatusMessageService, private _rxService: RxService) { }

	ngOnInit() {
		this._rxService.getDeliveryTimeframes().then(result => (this.deliveryTimeframes = result));
	}

	submitRefill() {
		this.saving = true;
		let refillRequest = new RefillRequestModel();
		refillRequest.Comments = this.comments;
		refillRequest.PatientId = this.patientId;
		refillRequest.RxNumber = this.rxNumber;
		refillRequest.LabelName = this.drugName;
		refillRequest.CallbackPhone = this.callbackPhone;
		refillRequest.DeliveryTimeframe = this.deliveryTimeframe;

		let generateRefillModal = new GenerateRefillRequestModel();
		generateRefillModal.RefillRequests = [refillRequest];
		this._patientService.createRefillRequest(generateRefillModal).subscribe((result: ApiResult) => {
			this.saving = false;
			if (!result.Success) {
				let message = "";
				if (result.Result) {
					for (let i = 0; i < result.Result.length; i++) {
						let refillResult = result.Result[i];
						if (!refillResult.Success) {
							message += refillResult.PublicMessage + " ";
						}
					}
					if (message) {
						this._statusMessageService.changeStatusMessage("error", message);
					}
				} else {
					this._statusMessageService.changeStatusMessage("error", result.PublicMessage);
				}
			} else {
				this.closeModal.emit();
				this._statusMessageService.changeStatusMessage("success", "Refill request complete");
			}
		});
	}
}
