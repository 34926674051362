import { Component, Output, EventEmitter, OnInit, Input } from "@angular/core";
import { PatientService } from "../../../../shared/services/patient.service";
import { DiagnosisModel } from "../../../../shared/models/diagnosis.model";
import { PatientInfoModel } from "../../../../shared/models/patient.model";
import { StatusMessageService } from "../../../../shared/components/status-message/status-message.service";
import { ApiResult } from "../../../../shared/models/api-result.model";
import * as moment from "moment";

@Component({
	selector: "form-resolve-diagnosis",
	templateUrl: "./form-resolve-diagnosis.template.html",
	styleUrls: ["./form-resolve-diagnosis.scss"]
})
export class FormResolveDiagnosis implements OnInit {
	saving: boolean = false;
	isLoading: boolean = false;
	resolvedDate: string;

	@Input() patient: PatientInfoModel;
	@Input() diagnosis: DiagnosisModel;

	@Output() closeModal: EventEmitter<string> = new EventEmitter<string>();
	@Output() resolveDiagnosisSuccess: EventEmitter<boolean> = new EventEmitter<boolean>();

	constructor(private _patientService: PatientService, private _statusMessageService: StatusMessageService) {}

	ngOnInit() {
		this.resolvedDate = moment(new Date()).format("YYYY-MM-DD");
	}

	resolve() {
		this.saving = true;
		this._patientService.resolveDiagnosis(this.patient.patientId, this.diagnosis.PatientDxId, this.resolvedDate).subscribe((results: ApiResult) => {
			this.saving = false;
			if (results.Success) {
				this._statusMessageService.changeStatusMessage("success", 'Diagnosis "' + this.diagnosis.IcdCode + " - " + this.diagnosis.IcdName + '" has been resolved.');
				this.resolveDiagnosisSuccess.emit(true);
			} else {
				this._statusMessageService.changeStatusMessage("error", results.PublicMessage);
			}
		});
	}
}
