import { Component, OnInit } from "@angular/core";
import { Permissions } from "../shared/enums/permissions";

@Component({
	selector: "partner-profile",
	templateUrl: "./partner-profile.template.html",
	styleUrls: ["./partner-profile.scss"]
})
export class PartnerProfile implements OnInit {
	tabContent: string = "partner";

	permissions: any = Permissions;

	constructor() {}

	ngOnInit() {}

	loadOrganizationTab() {
		this.tabContent = "organization";
	}

	loadPartnerTab() {
		this.tabContent = "partner";
	}
}
