export class DeviceIdentifier {
    private static readonly STORAGE_KEY = 'uniqueDeviceId';

    /**
     * Get the device's unique ID from localStorage or generate one if it doesn't exist.
     */
    public static getDeviceId(): string {
        let deviceId = localStorage.getItem(this.STORAGE_KEY);

        if (!deviceId) {
            deviceId = this.generateUniqueId();
            localStorage.setItem(this.STORAGE_KEY, deviceId);
        }

        return deviceId;
    }

    public static setDeviceId(id: string) {
        localStorage.setItem(this.STORAGE_KEY, id);
    }

    /**
     * Generate a unique ID combining the current time and a random number.
     */
    private static generateUniqueId(): string {
        const timestamp = new Date().getTime();
        const randomPart = Math.random().toString(36).substring(2, 15);
        return `${timestamp}-${randomPart}`;
    }
}
