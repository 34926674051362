import { Component, ViewChild } from "@angular/core";
import { AuthService } from "../../services/auth.service";
import { ModalComponent } from "../modal/modal.component";

@Component({
	selector: "session-logged-out-dialog",
	templateUrl: "./session-logged-out-dialog.template.html"
})
export class SessionLoggedOutDialogComponent {
	message: string = "";

	@ViewChild("loggedOutModal") loggedOutModal: ModalComponent;

	constructor(private _authService: AuthService) {}

	showDialog(message: string) {
		this.message = message;
		this.loggedOutModal.open("xs");
	}

	onModalClose() {
		this._authService.doLogout("");
	}
}
