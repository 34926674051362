export class UserProfile {
	UserId: number;
	FirstName: string;
	LastName: string;
	LoginId: string;
	Email: string;
	JobTitle: string;
	Phone: string;
	PhoneExt: string;
	ClinicianId: number;
	FobSerial: string;
	IsPrescriber: boolean;
	BirthDate: Date;
	userRoles?: number[];
	ocpRoleIds?: number[];
	DefaultCustomerId?: number;
}
