import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { BulkUploadUser } from "src/app/ocp/shared/models/bulk-upload-user.model";
import { CustomerService } from "src/app/ocp/shared/services/customer.service";

@Component({
  selector: 'ct-select-roles',
  templateUrl: './select-roles.component.html',
  styleUrls: ['./select-roles.component.scss']
})
export class SelectRolesComponent implements OnInit {
  currentStep: number = 2;

  userRoleOptions: any[] = [];

	@Input() users: BulkUploadUser[] = [];
  @Input() customerId: number;
  @Input() stepCompleted: boolean = false;

	@Output() goBack: EventEmitter<string> = new EventEmitter<string>();
  @Output() goToStepThree: EventEmitter<any[]> = new EventEmitter<any[]>();

  get disable(): boolean {
    return this.users.some(u => (u.roles || []).length === 0);
  }

	constructor(private _customerService: CustomerService) {}

	ngOnInit() {
    this._customerService.getCustomerRoles().subscribe((roles: any[]) => {
      this.userRoleOptions = roles;
    });
  }

  getResultsText(results: number): string {
    if (results == 1) {
      return results + " new user has";
    } else {
      return results + " new users have";
    }
  }

  toggleRoleForUser(checked: boolean, userIdx: number, role: any) {
    let user = this.users[userIdx];

    if (checked){
      if (!user.roles) user.roles = [];
      user.roles.push(role);
    } else {
      let roleIdx = (user.roles || []).findIndex(ur => ur.RoleId == role.RoleId);
      user.roles.splice(roleIdx, 1);
    }
  }

  toggleRoleForAll(checked: boolean, role: any) {
    this.users.forEach((user: BulkUploadUser) => {
      if (checked) {
        if (!user.roles) user.roles = [];
        user.roles.push(role);
      } else {
        user.roles = (user.roles || []).filter(ur => ur.RoleId != role.RoleId);
      }
    });
  }

  submitRoleSelections() {
    this.goToStepThree.emit();
  }

  isUserRoleSelected(roleId: number, userIdx: number): boolean {
    return (this.users[userIdx].roles || []).some(role => role.RoleId == roleId);
  }

  isAllUserRoleSelected(roleId: number): boolean {
    return this.users.every((user: BulkUploadUser) => {
      return (user.roles || []).findIndex(role => role.RoleId == roleId) !== -1
    });
  }
}
