import {Component, Input, forwardRef, OnInit} from "@angular/core";
import {
  ControlValueAccessor, NG_VALUE_ACCESSOR, Validator, FormControl, NG_VALIDATORS
} from '@angular/forms';
import {UserService} from "../../../../shared/services/user.service";

@Component({
  selector: "input-textbox-mrn",
  templateUrl: "./input-textbox-mrn.template.html",
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => InputTextBoxMrnComponent),
    multi: true
  }, {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => InputTextBoxMrnComponent),
    multi: true
  }]
})
export class InputTextBoxMrnComponent implements ControlValueAccessor, OnInit, Validator {
  regexValidator: string;
  changed: boolean = false;

  label: string;
  formatDescription: string;
  value: string;

  @Input() isInvalid: boolean;
  @Input() isAddNew: boolean = false;
  @Input() isRequired: boolean = false;

  constructor(private _userService: UserService) {
  }

  ngOnInit() {
    this._userService.customMrnFormat.subscribe((regex: string) => {
      this.regexValidator = regex || '';
    });
    this._userService.mrnOverride.subscribe((mrnLabel: string) => {
      this.label = mrnLabel;
    });
    this._userService.mrnFormatDescription.subscribe((mrnDesc: string) => {
      this.formatDescription = mrnDesc;
    });

    this.changed = false;
  }

  validate(control: FormControl) {
    if (!this.value) {
      if (!this.isRequired) {
        return null;
      }

      if (this.changed) {
        this.isInvalid = true;
      }

      return {
        formatError: {valid: false}
      }
    }
    if (this.regexValidator) {
      let validator = new RegExp(this.regexValidator, 'i');
      if (!validator.test(control.value)) {
        this.isInvalid = true;

        if (this.changed) {
          control.markAsDirty();
          return {
            formatError: {
              valid: false
            }
          };
        } else {
          return null
        }
      }
    }

    this.isInvalid = false;

    return null;
  }

  propagateChange = (_: any) => {
  };

  inputChanged(value: string) {
    this.changed = true;
    this.value = value;

    this.propagateChange(this.value);
  }

  writeValue(obj: string) {
    this.value = obj;
  }

  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any) {
  } //Not currently needed
}
