import {Component, OnInit} from '@angular/core';
import {ClinicalResourcesService} from "../../../shared/services/clinical-resources.service";
import {DocumentTypeModel} from "../../../shared/models/document-type.model";

@Component({
  selector: 'documentation-tab',
  templateUrl: './documentation.template.html',
  styleUrls: ['./clinical-resources-tabs.css']
})

export class DocumentationTab implements OnInit {
  private _documentTypeId: number = 2; // documentation
  documents: DocumentTypeModel[] = [];

  constructor(private _clinicalResourcesService: ClinicalResourcesService) {
  }

  ngOnInit() {
    this._clinicalResourcesService.getDocuments(null, this._documentTypeId).subscribe((documents: DocumentTypeModel[]) => {
      this.documents = documents;
    });
  }

  viewDocument(documentId: number, download: boolean) {

  }
}
