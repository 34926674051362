import { Component, Output, EventEmitter, OnInit } from "@angular/core";
import { MessageItemModel } from "../../models/message-item.model";
import { StatusMessageService } from "../status-message/status-message.service";
import { MessagesService } from "../../services/messages.service";
import { FadeInAnimation } from "../../../animations";

@Component({
	selector: "messages-listing",
	templateUrl: "./messages-listing.template.html",
	styleUrls: ["./alerts-messages.scss"],
	animations: [FadeInAnimation]
})
export class MessagesListingComponent implements OnInit {
	messages: MessageItemModel[] = [];
	loadingMessages: boolean = true;

	@Output() closePanel: EventEmitter<string> = new EventEmitter<string>();

	get unreadMessageCount(): number {
		return this.messages.filter((x: MessageItemModel) => {
			return !x.IsRead;
		}).length;
	}

	get messageSelected(): boolean {
		return (
			this.messages.filter((x: MessageItemModel) => {
				return x.isSelected;
			}).length > 0
		);
	}

	get allMessagesRead(): boolean {
		return (
			this.messages.filter((x: MessageItemModel) => {
				return !x.IsRead;
			}).length === 0
		);
	}

	constructor(private _statusMessageService: StatusMessageService, private _messagesService: MessagesService) {}

	ngOnInit() {
		this.load();
	}

	load() {
		this.loadingMessages = true;
		this._messagesService.getMessageList(true).then((results: MessageItemModel[]) => {
			this.messages = results ?? [];
			this.loadingMessages = false;
		});
	}

	markAllAsRead() {
		this._messagesService.markMessagesAsRead(null).subscribe((result: any) => {
			if (result.Success) {
				this.messages.forEach((x: MessageItemModel) => {
					if (!x.IsRead) {
						x.IsRead = true;
						x.isSelected = false;
					}
				});

				this._messagesService.refreshUnreadMessagesCount();
			} else {
				this._statusMessageService.changeStatusMessage("error", result.PublicMessage);
			}
		});
	}

	markSelectedAsRead() {
		let selectedMessages = this.messages
			.filter((x: MessageItemModel) => {
				return !x.IsRead && x.isSelected;
			})
			.map(x => {
				return x.UserMessageId;
			});

		this._messagesService.markMessagesAsRead(selectedMessages).subscribe((result: any) => {
			if (result.Success) {
				this.messages.forEach((x: MessageItemModel) => {
					if (!x.IsRead && x.isSelected) {
						x.IsRead = true;
						x.isSelected = false;
					}
				});
				this._messagesService.refreshUnreadMessagesCount();
			} else {
				this._statusMessageService.changeStatusMessage("error", result.PublicMessage);
			}
		});
	}
}
