/**
 * Created by John on 2017-01-26.
 */
import {Pipe, PipeTransform} from "@angular/core";

@Pipe({name: "age"})
export class AgePipe implements PipeTransform{
  transform(date:string){
    if(!date)
      return;

    var today = new Date();
    var birthDate = new Date(date);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }
}
