import { Component, Input, Output, EventEmitter, OnInit } from "@angular/core";
import { FileUploadService } from "./file-upload.service";
import { UploadFileSuccessModel } from "../../models/upload-file-success.model";
import { ApiResult } from "../../models/api-result.model";

@Component({
	selector: "file-upload",
	templateUrl: "./file-upload.template.html",
	providers: [FileUploadService],
	styleUrls: ["./file-upload.scss"]
})
export class FileUploadComponent implements OnInit {
	filesToUpload: File[] = [];
	fileNames: string = "";
	loading: boolean = false;
	isInvalid: boolean = false;

	@Input() url: string;
	@Input() showUpload: boolean;
	@Input() showFilenames: boolean;
	@Input() uploadOnSelect: boolean = false;
	@Input() label: string;
	@Input() hinttext: string;
	@Input() uploadType: string = "clinicalresources";
	@Input() id: number = 0;
	@Input() isRequired: boolean = false;
	@Input() isReadOnly: boolean = false;
	@Input() readOnlyFileName: string = null;

	@Output() onSuccess: EventEmitter<any> = new EventEmitter<any>();
	@Output() onError: EventEmitter<any> = new EventEmitter<any>();
	@Output() fileSelected: EventEmitter<any> = new EventEmitter<any>();

	constructor(private _fileUploadService: FileUploadService) {}

	ngOnInit() {
		setTimeout(() => {
			if (this.isRequired && !this.fileNames) {
				this.isInvalid = true;
			} else {
				this.isInvalid = false;
			}
		}, 200)
	}

	upload(id: number) {
		if (this.isReadOnly) return;

		this.loading = true;
		this._fileUploadService.makeFileRequest(this.url, id, this.filesToUpload).then(
			(result: ApiResult) => {
				this.loading = false;
				if (result.Success) {
					if (this.uploadType === "bulkuserupload") {
						this.onSuccess.emit({
							originalFileName: this.fileNames,
							systemFileName: null,
							results: result.Result
						});
					} else if (this.uploadType === "certificateupload") {
						this.onSuccess.emit({
							originalFileName: this.fileNames,
							systemFileName: result.Result,
							success: result.Success,
							thumbprint: result.Result
						});
					} else {
						this.onSuccess.emit({
							originalFileName: this.fileNames,
							systemFileName: result.Result,
							results: result.Result
						});
					}
					this.filesToUpload = [];
					this.isInvalid = false;
					//this.fileNames = "";
				} else {
					this.onError.emit(result.PublicMessage);
					this.fileNames = "";
					this.filesToUpload = [];
					if (this.isRequired) {
						this.isInvalid = true;
					}
				}
			},
			error => {
				this.onError.emit(error);
			}
		);
	}

	fileChangeEvent(fileInput: any, id: number = 0) {
		this.filesToUpload = <File[]>fileInput.target.files;
		this.fileNames = "";

		for (let i = 0; i < fileInput.target.files.length; i++) {
			this.fileNames += fileInput.target.files[i].name + " ";
		}

		this.fileNames = this.fileNames.trim();

		if (!this.validateFileType(this.fileNames)) {
			this.onError.emit("Invalid file type.");
			this.fileNames = "";
			this.filesToUpload = [];
			if (this.isRequired) {
				this.isInvalid = true;
			}
		} else {
			this.fileSelected.emit(this.fileNames);
			if (this.uploadOnSelect) {
				this.upload(id);
			}
		}
	}

	validateFileType(fileName: string) {
		switch (this.uploadType.toLowerCase()) {
			case "clinicalresources": {
				return fileName.toLowerCase().endsWith(".pdf") || fileName.toLowerCase().endsWith(".ppt") || fileName.toLowerCase().endsWith(".pptx");
			}
			case "bulkuserupload": {
				return fileName.toLowerCase().endsWith(".xlsx") || fileName.toLowerCase().endsWith(".xls");
			}
			case "certificateupload": {
				return fileName.toLowerCase().endsWith(".cer");
			}
			default: {
				return false;
			}
		}
	}
}
