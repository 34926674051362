export class MappedClaimModel {
	key: number;
	claimValue: string;
	roleIds: number[];
	providerId: number;
	saved: boolean;

	constructor(obj?: any) {
		Object.assign(this, obj);
	}
}
