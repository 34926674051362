
import {distinctUntilChanged, debounceTime} from 'rxjs/operators';
import { Component, OnInit, ViewChild } from "@angular/core";

import { UserService } from "../../shared/services/user.service";
import { UserListItemModel } from "../../shared/models/user-list-item.model";
import { UserProfile } from "../../shared/models/user-profile.model";
import { Permissions } from "../../shared/enums/permissions";
import { ModalComponent } from "../../shared/components/modal/modal.component";
import { StatusMessageService } from "../../shared/components/status-message/status-message.service";
import { FormControl } from "@angular/forms";

@Component({
	selector: "internal-users",
	templateUrl: "./internal-users.template.html",
	styleUrls: ["../administration-tabs.scss", "./internal-users.scss"]
})
export class InternalUsersTabComponent implements OnInit {
	usersFilter: string[] = ["active"];
	modalTitle: string;
	subHeaderText: string;
	users: UserListItemModel[] = [];
	filteredUsers: UserListItemModel[] = [];
	userToEdit: UserProfile = null;
	editMode: string;
	customerName: string;
	userListItemEdit: UserListItemModel = null;
	permissions: any = Permissions;

	userSearchText: FormControl = new FormControl();

	@ViewChild("editModal") editModal: ModalComponent;

	@ViewChild("createModal") createModal: ModalComponent;

	constructor(private _userService: UserService, private _statusMessageService: StatusMessageService) {}

	ngOnInit() {
		this.userSearchText.valueChanges.pipe(
			debounceTime(400),
			distinctUntilChanged(),)
			.subscribe((term: string) => {
				this.filterUserList(term);
			});
		this.refreshUsers();
	}

	refreshUsers() {
		this._userService.getInternalUsers().subscribe((users: UserListItemModel[]) => {
			this.users = users;
			this.filterUserList(this.userSearchText.value || "");
		});
	}

	filterUserList(nameFilter: string) {
		nameFilter = nameFilter || "";
		let self = this;
		let users: UserListItemModel[] = this.users.filter((user: UserListItemModel) => {
			let nameMatches: boolean = false;

			if (!nameFilter) {
				nameMatches = true;
			} else {
				nameFilter = nameFilter
					.trim()
					.replace(/\s\s+/g, " ")
					.replace(",", "")
					.toLowerCase();
				let searchNameParts: string[] = nameFilter.split(" ");
				let matchPartsCount: number = 0;
				searchNameParts.forEach((part: string) => {
					if (user.FirstName.toLowerCase().indexOf(part) > -1 || user.LastName.toLowerCase().indexOf(part) > -1 || user.LoginId.toLowerCase().indexOf(part) > -1) {
						matchPartsCount++;
					}
				});

				if (matchPartsCount === searchNameParts.length) {
					nameMatches = true;
				}
			}

			if (nameMatches) {
				if (self.usersFilter.indexOf("active") !== -1 && !user.IsLocked) {
					return true;
				}

				if (self.usersFilter.indexOf("locked") !== -1 && user.IsLocked) {
					return true;
				}
			}

			return false;
		});

		users.sort((a, b) => {
			if (a.LastName < b.LastName) return -1;

			if (a.LastName > b.LastName) return 1;

			return 0;
		});

		this.filteredUsers = users;
	}

	updateUserFilter(checked: boolean, value: string) {
		if (!checked) {
			this.usersFilter.splice(this.usersFilter.indexOf(value), 1);
		} else if (this.usersFilter.indexOf(value) === -1) {
			this.usersFilter.push(value);
		}

		this.filterUserList(this.userSearchText.value || "");
	}

	clearSearch() {
		this.userSearchText.setValue("");
	}

	addUser() {
		this.userToEdit = new UserProfile();
		this.createModal.open("md");
		this.modalTitle = "New User";
		this.subHeaderText = "";
		this.editMode = "addUser";
	}

	editUser(userId: number) {
		this._userService.getUserProfile(userId, null).subscribe((user: UserProfile) => {
			this.userToEdit = user;
			this.userToEdit.UserId = userId;
			this.editModal.open("md");
			this.modalTitle = "Edit User";
			this.subHeaderText = "";
			this.editMode = "editUser";
		});
	}

	editUserLogin(userInfo: UserListItemModel) {
		this.userListItemEdit = userInfo;
		this.editModal.open("md");
		this.modalTitle = "Edit Login ID";
		this.subHeaderText = " for " + userInfo.LastName + ", " + userInfo.FirstName;
		this.editMode = "editLoginId";
	}

	onEditClose() {
		this.userToEdit = null;
		this.userListItemEdit = null;
		this.editMode = null;
	}

	editUserSuccess() {
		if (this.editMode === "editUser") {
			this.editModal.dismiss();
		} else if (this.editMode === "addUser") {
			this.createModal.dismiss();
		}

		if (this.editMode) {
			this._statusMessageService.changeStatusMessage(
				"success",
				this.userToEdit.LastName + ", " + this.userToEdit.FirstName + " successfully " + (this.editMode === "editUser" ? "updated" : "added") + "."
			);
		}

		this.refreshUsers();
	}

	closeCreateModal(refreshUsers: boolean){
		if (refreshUsers) this.refreshUsers();
		this.createModal.dismiss();
	}

	editUserRole(userInfo: UserListItemModel) {
		this.userListItemEdit = userInfo;
		this.editModal.open("md");
		this.modalTitle = "Edit User Roles";
		this.subHeaderText = " for " + userInfo.LastName + ", " + userInfo.FirstName;
		this.editMode = "editRole";
	}

	userRolesSaveSuccess() {
		this._statusMessageService.changeStatusMessage("success", "Roles for " + this.userListItemEdit.LastName + ", " + this.userListItemEdit.FirstName + " successfully updated.");
		this.editModal.dismiss();
		this.refreshUsers();
	}

	editReportPermissions(userInfo: UserListItemModel) {
		this.userListItemEdit = userInfo;
		this.editModal.open("lg");
		this.modalTitle = "Report Permissions";
		this.subHeaderText = " for " + userInfo.LastName + ", " + userInfo.FirstName;
		this.editMode = "editReportPermissions";
	}

	userReportPermissionsSaveSuccess() {
		this._statusMessageService.changeStatusMessage("success", "Report permissions for " + this.userListItemEdit.LastName + ", " + this.userListItemEdit.FirstName + " successfully updated.");
		this.editModal.dismiss();
		this.refreshUsers();
	}
}
