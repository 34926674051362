import { Injectable } from "@angular/core";
import { AuthService } from "./auth.service";
import { Permissions } from "../enums/permissions";
import { HttpService } from "./http.service";
import { RoleModel } from "../models/role.model";

@Injectable()
export class PermissionsService {
	private _getInternalPermissionMatrixUrl: string = "api/permissions/getinternalpermissionmatrix";
	constructor(private _httpService: HttpService, private _authService: AuthService) { }

	getInternalPermissionMatrix() {
		return this._httpService.get(this._getInternalPermissionMatrixUrl);
	}

	getInternalRoleList(): Promise<RoleModel[]> {
		return new Promise<RoleModel[]>(resolve => {
			this.getInternalPermissionMatrix().subscribe((matrix: any) => {
				let roles = [];
				if (matrix && matrix.length) {
					roles = matrix.reduce((acc: any[], item) => {
						if (!acc.some(i => i.RoleId == item.RoleId)) {
							acc.push({ RoleId: item.RoleId, RoleName: item.RoleName })
						}
						return acc;
					}, [])
				}
				resolve(roles);
			})
		})
	}

	hasPermission(permission: string) {
		return this._authService.hasPermission(permission);
	}

	hasRole(role: string) {
		return this._authService.hasRole(role);
	}

	canNavigateToSection(sectionPath: string) {
		sectionPath = sectionPath.toLowerCase();
		if (sectionPath.startsWith("/")) {
			sectionPath = sectionPath.substr(1);
		}

		let locationBase: string = sectionPath.length ? sectionPath.split("/")[0] : "";

		switch (locationBase) {
			case "patient-care":
				return this.hasPermission(Permissions.patientCare);
			case "clinical-resources":
				return this.hasPermission(Permissions.clinicalTools);
			case "partner-profile":
				return this.hasPermission(Permissions.partnerProfile) || this.hasPermission(Permissions.viewCustomerIntegrations);
			case "administration":
				return this.hasPermission(Permissions.administration);
			default:
				return true;
		}
	}

	canAccessSystemAlerts() {
		return this.hasPermission(Permissions.modifySystemAlerts);
	}

	canAccessAdminPharmacies() {
		return this.hasPermission(Permissions.administration) && this._authService.isUserInternal();
	}

	canAccessAdminReports() {
		return this.hasPermission(Permissions.administration) && this.hasRole("IT") && this._authService.isUserInternal();
	}
}
