import {Component, OnInit, Input, Output, EventEmitter, ElementRef} from "@angular/core";
import {UtilitiesService} from "../../services/utilities.service";

@Component({
  selector: 'toggle-switch',
  templateUrl: './toggle-switch.template.html',
  styleUrls: ['./toggle-switch.scss']
})

export class ToggleSwitchComponent implements OnInit {
  switchId:string;

  @Input() label: string;
  @Input() checked: boolean;
  @Input() small: boolean = false;
  @Input() fullWidth: boolean = false;
  @Input() icon: string;

  @Output() onChange:EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private _utils:UtilitiesService, private _el:ElementRef) {}

  ngOnInit() {
    this.switchId = this._utils.makeId(7);
  }

  toggle(checked:boolean){
    this.checked = checked;
    this.onChange.emit(this.checked);
  }

  set(val:boolean){
    this.checked = val;
  }
}
