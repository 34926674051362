import { Routes, RouterModule } from "@angular/router";
import { ResetPasswordComponent } from "./reset-password.component";
import { LoginComponent } from "./login.component";
import { ForgotPasswordComponent } from "./forgot-password.component";
import { LoginChangePasswordComponent } from "./change-password.component";
import { VerifyAccountComponent } from "./verify-account.component";

export const LoginRoutes: Routes = [
	{ path: "login", component: LoginComponent },
	{ path: "forgot-password", component: ForgotPasswordComponent },
	{ path: "verify-account", component: VerifyAccountComponent },
	{ path: "login/reset-password/:token", component: ResetPasswordComponent },
	{ path: "login/change-password", component: LoginChangePasswordComponent }
];

export const loginRouting = RouterModule.forChild(LoginRoutes);
