import { Directive, Input, TemplateRef, ViewContainerRef } from "@angular/core";
import { PermissionsService } from "../services/permisisons.service";
@Directive({
	selector: "[ifHasRole]"
})
export class IfHasRoleDirective {
	private _hasView = false;

	@Input()
	set ifHasRole(roles: string[]) {
		let hasRole = false;
		let self = this;

		roles.forEach(role => {
			if (role && self._permissionService.hasRole(role)) {
				hasRole = true;
			}
		});

		if (hasRole && !this._hasView) {
			// If condition is true add template to DOM
			this.viewContainer.createEmbeddedView(this.templateRef);
			this._hasView = true;
		} else if (!hasRole && this._hasView) {
			// Else remove template from DOM
			this.viewContainer.clear();
			this._hasView = false;
		}
	}

	constructor(private templateRef: TemplateRef<any>, private viewContainer: ViewContainerRef, private _permissionService: PermissionsService) {}
}
