import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { SubscribableReport } from 'src/app/ocp/shared/models/report-subscription/subscribable-report.model';
import { MultiSelectItemModel } from '../../../../input-multi-select/multi-select-item.model';
import { ReportParameter } from 'src/app/ocp/shared/models/report-subscription/report-parameter.model';
import { Subscription } from 'src/app/ocp/shared/models/report-subscription/subscription.model';
import { InputMultiSelectComponent } from '../../../../input-multi-select/input-multi-select.component';

@Component({
  selector: 'ocp-form-configure-subscription',
  templateUrl: './form-configure-subscription.component.html',
  styleUrls: ['./form-configure-subscription.component.scss']
})
export class FormConfigureSubscriptionComponent implements OnInit {
  @Input() selectedReport: SubscribableReport;
  @Input() subscription: Subscription;
  @Input() renderTypes: any[];
  @Input() frequencies: any[];
  @Input() days: MultiSelectItemModel[];
  @Input() parameters: ReportParameter[];
  @Input() loadingParams: boolean;
  @Input() mode: string;

  @Output() onParametersChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() onFrequencyChange: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild("daysSelect") daysSelect: InputMultiSelectComponent;
  
  constructor() { }

  ngOnInit(): void {
  }

  frequencyTypeChanged() {
    // Bubble up to tell store to clear the values of Frequency and EndOfMonthFlag
    this.onFrequencyChange.emit();

    // *ToDo: Figure out how to clear selections made in the 'Days of Week' multi-select field
    //        when a change occurs in the 'FrequencyType' select field. Not sure why the forEach() doesn't work.

    // this.daysSelect.items.forEach(i => i.selected = false);
  }
}
