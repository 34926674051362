import {Component, Input, Output, EventEmitter, OnInit} from '@angular/core';
import {CustomerService} from "../../services/customer.service";
import {CustomerTeamModel} from "../../models/customer-team.model";
import {Permissions} from "../../enums/permissions";
import {AuthService} from "../../services/auth.service";

@Component({
  selector: 'team-summary',
  templateUrl: './customer-team-summary.template.html',
  styleUrls: ['./customer-team-summary.css']
})

export class CustomerTeamSummaryComponent implements OnInit {
  popoverContent: string;
  permissions: any = Permissions;

  @Input() team: CustomerTeamModel;

  @Output() editTeam: EventEmitter<number> = new EventEmitter<number>();
  @Output() statusUpdated: EventEmitter<any> = new EventEmitter<any>();

  get canEditTeams(): boolean {
    return this._authService.hasPermission(this.permissions.editCustomerTeams)
      || this._authService.hasPermission(this.permissions.administration);
  }

  constructor(private _customerService: CustomerService, private _authService: AuthService) {
  }

  ngOnInit() {
    if (this.team.IsActive) {
      this.popoverContent = 'Click to Deactivate';
    } else if (this.team.IsActive === false) {
      this.popoverContent = 'Click to Activate';
    }
  }

  updateIsActive() {
    if(!this.canEditTeams){
      return;
    }

    this._customerService.updateCustomerTeamStatus(this.team.CustomerTeamId, !this.team.IsActive).subscribe((x:any) => {
      if (x.Success) {
        this.team.IsActive = !this.team.IsActive;
        this.statusUpdated.emit({
          success: true,
          message: 'Status for team "' + this.team.TeamName + '" has been changed to '
          + (this.team.IsActive ? 'Active' : 'Inactive') + '.'
        });
      } else {
        this.statusUpdated.emit({success: false, message: 'Failed to update status for ' + this.team.TeamName + '.'});
      }
    });
  }
}
