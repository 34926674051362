import {Component, Input, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'submit-button',
  templateUrl: './submit-button.template.html',
  styleUrls: ['./submit-button.scss']
})

export class SubmitButton {
  @Input() isDisabled: boolean = false;
  @Input() isProcessing: boolean = false;
  @Input() buttonText: string;
  @Input() isAccentButton: boolean = false;

  @Output() onBtnClick: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(){}

  ngOnInit(){}

}
