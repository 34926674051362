import {Component, Input, forwardRef, Output, EventEmitter, OnInit} from "@angular/core";
import {ControlValueAccessor, NG_VALUE_ACCESSOR, Validator, FormControl, NG_VALIDATORS} from '@angular/forms';

@Component({
  selector: "input-masked-textbox",
  templateUrl: "./input-masked-textbox.template.html",
  styleUrls: ["./input-masked-textbox.scss"],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: forwardRef(() => InputMaskedTextBoxComponent),
    multi: true
  }, {
    provide: NG_VALIDATORS,
    useExisting: forwardRef(() => InputMaskedTextBoxComponent),
    multi: true
  }]
})
export class InputMaskedTextBoxComponent implements ControlValueAccessor, OnInit, Validator {
  @Input() label: string;
  @Input() isRequired: boolean = false;
  @Input() type: string;
  @Input() placeholderText: string;
  @Input() isDisabled: boolean;
  @Input() isLenZeroInvalid: boolean = false;
  @Output() isDisabledChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Input() disableId: string;
  @Input() disabledLabel: string;
  @Input() isInvalid: boolean;

  mask: any = false;
  inputMode: string = "verbatim"; // verbatim is the default according to spec.
  pattern: string = "";// no pattern by default.
  inputType: string = "text";
  changed: boolean = false;
  value: string;

  propagateChange = (_: any) => {
  };

  validate(control: FormControl) {
    if (this.isDisabled || !this.changed) {
      // if the control is disabled then we do not want to be validated.
      return null;
    }
    if (this.isRequired && !this.value) {
      if (this.changed) {
        this.isInvalid = true;
      }
      return {
        formatError: {valid: false}
      }
    }

    switch (this.type) {
      case 'ssn':
        this.isInvalid = this.invalidLength(9, control.value);
        break;
      case 'tel':
        this.isInvalid = this.invalidLength(10, control.value);
        break;
      case 'zip':
        this.isInvalid = this.invalidLength(5, control.value);
        break;
      default:
        this.isInvalid = this.isRequired && !control.value;
    }
    if (this.isInvalid) {
      return {
        formatError: {
          valid: false
        }
      };
    }

    return null;
  }

  inputChanged(value: string) {
    this.changed = true;
    this.value = value.replace(/\D/g, '');

    // blank values should always be null.
    this.propagateChange(this.value || null);
  }

  private invalidLength(length: number, value: string): boolean {
    if (this.isLenZeroInvalid && (value || "").length === 0) {
      return true;
    } else {
      return (this.isRequired && !value ) || ( value && value.length !== length);
    }
  }

  writeValue(value: string) {
    this.value = value;
  }

  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any) {
  } //Not currently needed


  ngOnInit() {
    this.changed = false;
    
    setTimeout(() => {
			if (this.isRequired && !this.value) {
				this.isInvalid = true;
			} else {
				this.isInvalid = false;
			}
    }, 200)
    
    switch (this.type) {
      case 'tel':
        this.mask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
        this.inputMode = "numeric";
        this.pattern = "[0-9]*";
        this.inputType = 'tel';
        break;
      case 'ssn':
        this.mask = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
        this.inputMode = "numeric";
        this.pattern = "[0-9]*";
        this.inputType = 'tel';
        break;
      case 'zip':
        this.mask = [/\d/, /\d/, /\d/, /\d/, /\d/];
        this.inputMode = "numeric";
        this.pattern = "[0-9]*";
        this.inputType = 'tel';
        break;
      default:
        this.mask = false;
        this.pattern = "";
        this.inputMode = "verbatim";
        this.inputType = 'text';
        break;
    }

    if (this.isRequired && (!this.value || this.value.length === 0)) {
			this.isInvalid = true;
		} else {
			this.isInvalid = false;
    }

  }
}
