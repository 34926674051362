import { DosespotItem } from "./dosespot-item.model";

export class LineItem {
	DosespotItemId: number;
	DaysSupply: number;
	Quantity: string;
	Directions: string;
	Refills: number;
	PharmacyNotes: string;
	IsUrgent: boolean;
	NoSubstitutions: boolean;

	PrescriptionId?: number;

	DosespotItem: DosespotItem;
}
