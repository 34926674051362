import {CustomerPermissionModel} from "../../models/customer-permission.model";
export class CustomerPermissionsChangedModel{
  public isInit: boolean = false;
  public customerPermissions: CustomerPermissionModel[] = [];

  constructor(customerPermissions:CustomerPermissionModel[] , isInit: boolean ){
    this.customerPermissions = customerPermissions;
    this.isInit = isInit;
  }
}
