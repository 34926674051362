import {Component, Input, OnInit} from "@angular/core";
import {MedicationProfileModel} from "../../../../shared/models/medication-profile.model";

@Component ({
  selector: "medications-table",
  templateUrl: "./medications-table.template.html",
  styleUrls: ["./plan-of-treatment-tabs.scss"]
})

export class POTMedicationsComponent implements OnInit {
  selectedRow: number;
  setClickedRow: Function;

  @Input() medicationProfile: MedicationProfileModel[] = [];

  constructor() {
  }

  ngOnInit() {
    this.setClickedRow = function(index){
      this.selectedRow = index;
    }
  }
}
