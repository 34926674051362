
import {takeUntil} from 'rxjs/operators';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {PatientService} from "../../../shared/services/patient.service";
import {DeprescribingOptionModel} from "../../models/DeprescribingOption.model";
import {ActivatedRoute, Router} from "@angular/router";
import {PatientCareStore} from "../../services/patient-care.store";
import {PatientInfoModel} from "../../../shared/models/patient.model";
import {Subject} from "rxjs";

@Component({
  selector: 'deprescribing-options',
  templateUrl: './deprescribing-options.template.html',
  styleUrls: ["./deprescribing-options.css"]
})

export class DeprescribingOptionsComponent implements OnInit, OnDestroy {
  loading: boolean = false;
  selectedRow: number;
  deprescribingOptions: DeprescribingOptionModel[] = [];

  patientId: number;
  firstName: string;
  lastName: string;

  private destroyed:Subject<any> = new Subject<any>();

  constructor(
      private _patientService: PatientService,
      private _route:ActivatedRoute,
      private _router:Router,
      private _store:PatientCareStore) {
  }

  ngOnInit() {
    this.loading = true;

    this._store.Patient.pipe(takeUntil(this.destroyed)).subscribe((patient:PatientInfoModel) => {
      if(!patient.patientId) return;

      this.patientId = patient.patientId;
      this.firstName = patient.firstName;
      this.lastName = patient.lastName;

      this._patientService.getDeprescribingOptions(this.patientId).subscribe((options: DeprescribingOptionModel[]) => {
          this.loading = false;
          this.deprescribingOptions = options;
      });
    });
  }

  ngOnDestroy(){
    this.destroyed.next();
    this.destroyed.unsubscribe();
  }

  rowClicked(index) {
    this.selectedRow = index;
  }

  closePage() {
    this._router.navigate(["../detail"], {relativeTo: this._route});
  }


  cleanTerms(terms: string) {
    terms = terms || '';

    return terms.trim().replace(/(?:\r\n|\r|\n)/g, '<br />');//.replace(/\s{2,}/g, ' ');
  }
}
