import {Component, Output, EventEmitter, OnInit, Input} from '@angular/core';
import {DoseSpotService} from "../../services/dosespot.service";
import {DoseSpotCustomerConfigModel} from "../../models/dosespot-customer-config.model";
import {ApiResult} from "../../models/api-result.model";
import {StatusMessageService} from "../status-message/status-message.service";

@Component({
  selector: 'form-edit-dosespot-customer',
  templateUrl: './form-edit-dosespot-customer.template.html'
})

export class FormEditDoseSpotCustomerComponent implements OnInit {
  saving: boolean = false;
  doseSpotConfig: DoseSpotCustomerConfigModel  = new DoseSpotCustomerConfigModel();;

  @Input() customerId: number;

  @Output() closeModal: EventEmitter<string> = new EventEmitter<string>();
  @Output() saveSuccess: EventEmitter<string> = new EventEmitter<string>();

  constructor(private _doseSpotService: DoseSpotService, private _statusMessageService: StatusMessageService) {
  }

  ngOnInit() {
    this._doseSpotService.getConfigurationForCustomer(this.customerId).subscribe((config: DoseSpotCustomerConfigModel) => {
      if (config) {
        this.doseSpotConfig = config;
      } else {
      }
    });
  }

  save() {
    this.saving = true;
    this._doseSpotService.setConfigurationForCustomer(this.customerId, this.doseSpotConfig).subscribe((result: ApiResult) => {
      this.saving = false;
      if (result.Success) {
        this.doseSpotConfig = null;
        this._statusMessageService.changeStatusMessage("success", "DoseSpot configuration successfully updated.");
        this.saveSuccess.emit();
      } else {
        this._statusMessageService.changeStatusMessage("error", result.PublicMessage)
      }
    })
  }
}
