import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { ErxOrderStore } from "../../../services/erx-order.store";
import { RxOrderModel } from "../../../models/rx-order.model";
import { FadeInQuickAnimation } from "src/app/ocp/animations";

@Component({
	selector: "ocp-prescription-items-card",
	templateUrl: "./prescription-items-card.component.html",
	styleUrls: ["./prescription-items-card.component.scss"],
	animations: [FadeInQuickAnimation]
})
export class PrescriptionItemsCardComponent implements OnInit {
	showList: boolean = true;

	@Input() items: RxOrderModel[];
	@Input() selectedItem: RxOrderModel;
	@Input() isCollapsible: boolean = false;

	@Input() icdRequired: boolean = false;
	@Input() mode: string = "";
	@Input() disableAdd: boolean = false;

	@Output() onAddItem: EventEmitter<boolean> = new EventEmitter<boolean>();
	@Output() onSelectItem: EventEmitter<RxOrderModel> = new EventEmitter<RxOrderModel>();
	@Output() onRemoveItem: EventEmitter<number> = new EventEmitter<number>();

	constructor(public erxStore: ErxOrderStore) {}

	ngOnInit(): void {}

	getNewItemPlaceholder(erxType: string) {
		let itemType = this.erxStore.getTooltipContent(erxType);
		return "New " + itemType + "...";
	}

	addItem() {
		this.onAddItem.emit();
	}

	selectItem(selected: RxOrderModel) {
		this.selectedItem = selected;
		this.onSelectItem.emit(selected);
	}

	removeItem(selected: number) {
		this.onRemoveItem.emit(selected);
	}

	toggleItemsList() {
		this.showList = !this.showList;
	}

	hasErrors(med: RxOrderModel, i: number) {
		return med.validate(this.icdRequired, this.mode, 0).length > 0 || med.isRejected;
	}

	getErrorTooltip(med: RxOrderModel) {
		let tooltip: string;

		if (med) {
			if (med.isRejected) {
				tooltip = "Prescription rejected";
			} else {
				tooltip = "Details missing";
			}
		}

		return tooltip;
	}
}
