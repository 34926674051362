import {Component, Output, EventEmitter, OnInit, Input, ViewChild} from '@angular/core';
import {PatientService} from "../../../shared/services/patient.service";
import {PatientAllergyModel} from "../../../shared/models/allergy.model";
import {StatusMessageService} from "../../../shared/components/status-message/status-message.service";
import {ModalComponent} from "../../../shared/components/modal/modal.component";
import {PatientInfoModel} from "../../../shared/models/patient.model";
import {ApiResult} from "../../../shared/models/api-result.model";

@Component({
  selector: 'patient-allergies-tab',
  templateUrl: './patient-allergies-tab.template.html',
  styleUrls: ['./patient-details-tabs.scss']
})

export class PatientAllergiesTab implements OnInit {
  saving: boolean = false;
  isLoading: boolean = false;
  allergies: PatientAllergyModel[] = [];
  selectedAllergy: PatientAllergyModel;

  @Input() patient: PatientInfoModel;
  @Output() openAddAllergy: EventEmitter<string> = new EventEmitter<string>();

  @ViewChild('editAllergiesDialog')
  editAllergiesDialog: ModalComponent;

  constructor(private _patientService: PatientService, private _statusMessageService: StatusMessageService) {
  }

  ngOnInit() {
    this.loadAllergies();
  }

  loadAllergies() {
    this.isLoading = true;
    this._patientService.getPatientAllergies(this.patient.patientId).subscribe((allergies: PatientAllergyModel[]) => {
      this.isLoading = false;
      this.allergies = allergies;
    });
  }

  editAllergy(allergy: PatientAllergyModel) {
    this.selectedAllergy = Object.assign({}, allergy);
    this.editAllergiesDialog.open('xs');
  }

  cancelAllergyEdit() {
    this.selectedAllergy = null;
    this.editAllergiesDialog.dismiss();
  }

  saveAllergy() {
    this.saving = true;
    this._patientService.updateAllergy(this.patient.patientId, this.selectedAllergy).subscribe((x: ApiResult) => {
      this.saving = false;
      if (x.Success) {
        this.loadAllergies();
        this._statusMessageService.changeStatusMessage('success', 'Allergy for "' + this.selectedAllergy.AllergyName + '" updated.');
        this.selectedAllergy = null;
        this.editAllergiesDialog.dismiss();
      } else {
        this._statusMessageService.changeStatusMessage('error', x.PublicMessage)
      }
    });
  }

  deleteAllergy(allergy: PatientAllergyModel) {
    this._patientService.removeAllergy(this.patient.patientId, allergy.PatientAllergyID).subscribe((x:any) => {
      if (x.Success) {
        this.loadAllergies();
        this._statusMessageService.changeStatusMessage('success', 'Allergy for "' + allergy.AllergyName + '" removed.');
      } else {
        this._statusMessageService.changeStatusMessage('error', x.Result.PublicMessage)
      }
    });
  }
}
